import { Flex, Select } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../util/context";

export default function ReusableForm({
  title,
  onChange,
  onChange1,
  appraisal,
}) {
  const { user } = useContext(AuthContext);
  const options = [1, 2, 3, 4, 5];
  const [average, setAverage] = useState({
    employee: 0,
    supervisor: 0,
  });

  return (
    <Flex w="100%" borderBottom="1px solid #ccc">
      <Flex
        borderRight="1px solid #ccc"
        borderLeft="1px solid #ccc"
        w="90%"
        align="center"
        justify="space-between"
      >
        <ReusableText
          text={title}
          color="#7F7E7E"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          p="0.4rem"
          w="55%"
          align="start"
        />
        <Flex w="44.5%" borderLeft="1px solid #ccc" align="center">
          <Select
            placeholder="Select"
            isDisabled={
              appraisal?.appraisal_status?.toLowerCase() ===
              "awaiting_supervisor_response"
            }
            onChange={(e) => {
              onChange(e);
              setAverage({ ...average, employee: +e.target.value });
            }}
            w="30%"
            mx="auto"
            borderRadius="0px"
          >
            {options.map((el, i) => (
              <option key={i} value={el}>
                {el}
              </option>
            ))}
          </Select>

          <Select
            isDisabled={user?.job_role?.toLowerCase() === "lawyer"}
            onChange={(e) => {
              onChange1(e);
              setAverage({ ...average, supervisor: +e.target.value });
            }}
            placeholder="Select"
            w="30%"
            mx="auto"
            borderRadius="0px"
          >
            {options.map((el, i) => (
              <option key={i} value={el}>
                {el}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
      <ReusableText
        text={(+average.employee + +average.supervisor) / 2}
        color="#7F7E7E"
        weight="bold"
        size={{ lg: "14px", md: "10px", base: "6px" }}
        p="0.4rem"
        w="10%"
        align="start"
      />
    </Flex>
  );
}
