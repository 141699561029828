import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";

export default function ReceiptTitle() {
  return (
    <Flex
      w="100%"
      px="1rem"
      py="1.5rem"
      borderTopRadius="8px"
      bg="#EEEFF3"
      align="center"
    >
      <ReusableText
        text="Receipt ID"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="20%"
        align="center"
      />
      <ReusableText
        text="Payer Name"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="20%"
        align="center"
      />
      <ReusableText
        text="Amount"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="20%"
        align="center"
      />
      <ReusableText
        text="Date"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="20%"
        align="center"
      />
      <ReusableText
        text="Action"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="20%"
        align="center"
      />
    </Flex>
  );
}
