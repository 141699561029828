import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import ReactApexChart from "react-apexcharts";

export default function EarningChart({ data, name, height }) {
  useEffect(() => {
    setChart({
      series: [{ name: name, data }],
    });
  }, [data, name]);

  const [chart, setChart] = useState({
    series: [
      {
        name: "Data",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        height: 250,
        type: "bar",
        width: "100%",
      },
      plotOptions: {
        bar: {
          borderRadius: 14,
          dataLabels: {
            position: "center", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },

      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        position: "top",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          },
        },
      },
      title: {
        text: "",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444",
        },
      },
    },
  });

  return (
    <Box w="100%">
      <ReactApexChart
        options={chart.options}
        series={chart.series}
        type="bar"
        height={350}
        width="100%"
      />
    </Box>
  );
}
