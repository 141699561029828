import { NavLink } from "react-router-dom";
import { Tooltip } from "@chakra-ui/react";

export default function NavItem({ link, title, icon, display, label }) {
  return (
    <Tooltip hasArrow label={label}>
      <NavLink to={`/${link}`} className="menu">
        {icon}
        {display === "flex" ? title : ""}
      </NavLink>
    </Tooltip>
  );
}
