import { Flex } from "@chakra-ui/react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";
import DocumentDetailCenter from "./DocumentDetailCenter";
import { useParams } from "react-router-dom";

export default function DocumentDetailContainer({ list, setList }) {
  const params = useParams();
  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      h="100%"
      gap="1rem"
      p="2rem"
    >
      <ReusableEmployeeHeader
        title="Documents"
        text="Manage your documents and files here"
      />
      <DocumentDetailCenter
        show={params.id === "shared-documents" ? false : true}
        setList={setList}
        list={list}
      />
    </Flex>
  );
}
