import { Button, Flex, Heading, useToast } from "@chakra-ui/react";
import InputComponent from "../UI/Input";
import TextareaComponent from "../UI/Textarea";
import CheckboxComponent from "../UI/Checkbox";
import { useState } from "react";
import { createEvent, getEmployees, getClients } from "../../util/http";
import Modal from "../overlay/Modal";
import Overlay from "../overlay/OverLay";
import SelectComponent from "../UI/Select";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../../util/context";

export default function CreateEvent({ show, start, setStart, type }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [employee, setEmployee] = useState([]);
  const [list, setList] = useState([]);
  const [empDetail, setEmpDetail] = useState([]);
  const [clientNames, setClientName] = useState([]);
  const [allClient, setAllClient] = useState([]);
  const [client, setClient] = useState("");
  const [end, setEnd] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getClients();
        const newClients = response?.clients
          ?.filter((el) => el.deleted_at === null)
          .map((el) => {
            return { name: el.client_name, id: el.client_id };
          });
        const names = newClients.map((el) => el.name);
        setAllClient(newClients);
        setClientName(names);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const employeeList = await getEmployees();

        const employeeObj = employeeList?.employees
          ?.filter((el) => {
            const canAssign =
              el?.job_role?.toLowerCase() === "lawyer" ||
              el?.job_role?.toLowerCase() === "hod" ||
              el?.job_role?.toLowerCase() === "hop" ||
              el?.job_role?.toLowerCase() === "user" ||
              el?.job_role?.toLowerCase() === "accountant" ||
              el?.job_role?.toLowerCase() === "hr";
            return el.deleted_at === null && canAssign;
          })
          .map((el) => {
            return { name: `${el.first_name} ${el.last_name}`, id: el.id };
          });
        const employeeNames = employeeObj.map((el) => el.name);
        setEmpDetail(employeeObj);
        setList(employeeNames);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  async function eventHandler() {
    const client_id = allClient?.find((el) => el.name == client)?.id;
    const assignee_id = employee.map((el, i) => {
      const arr = [];
      for (let key in empDetail) {
        if (empDetail[key]?.name == el) {
          arr.push(empDetail[key].id || "");
        }
      }
      return arr;
    });

    let allAssigned = [...assignee_id, user.id];

    const startDate = new Date(start).getDate();
    const startMonth = new Date(start).getMonth() + 1;
    const startYear = new Date(start).getFullYear();
    const startHour = new Date(start).getHours();
    const startMin = new Date(start).getMinutes();
    const startSec = new Date(start).getSeconds();

    const endDate = new Date(end).getDate();
    const endMonth = new Date(end).getMonth() + 1;
    const endYear = new Date(end).getFullYear();
    const endHour = new Date(end).getHours();
    const endMin = new Date(end).getMinutes();
    const endSec = new Date(end).getSeconds();

    const data = {
      start_time: `${startYear}-${startMonth
        .toString()
        .padStart(2, 0)}-${startDate.toString().padStart(2, 0)} ${startHour
        .toString()
        .padStart(2, 0)}:${startMin.toString().padStart(2, 0)}:${startSec
        .toString()
        .padStart(2, 0)}`,
      end_time: `${endYear}-${endMonth.toString().padStart(2, 0)}-${endDate
        .toString()
        .padStart(2, 0)} ${endHour.toString().padStart(2, 0)}:${endMin
        .toString()
        .padStart(2, 0)}:${endSec.toString().padStart(2, 0)}`,
      title,
      description,
      url,
      assignee_id: JSON.stringify(allAssigned?.flatMap((el) => el)),
      client,
      client_id,
    };

    if (!start  || !title) {
      toast({
        title: `Event details missing`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (new Date(end) < new Date(start)) {
      toast({
        title: "Enter a valid due date",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await createEvent(data);
      show(false);
      toast({
        title: `Event created`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      show(false);
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Overlay hide={() => show(false)} />
      <Modal hide={() => show(false)}>
        <Flex align="center" direction="column" gap="2rem" w="100%">
          <Heading
            size="md"
            textTransform="capitalize"
            color="var(--container_color)"
          >
            New Event
          </Heading>
          <InputComponent
            onChange={(e) => setTitle(e.target.value)}
            label="Title"
            placeholder="Enter Title"
          />
          <TextareaComponent
            label="Description"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter a description..."
          />
          <Flex align="center" justify="space-between" gap="2rem" w="100%">
            {type === "default" && (
              <InputComponent
                onChange={(e) => setStart(e.target.value)}
                type="datetime-local"
                label="Start Time"
                value={start}
              />
            )}
            <InputComponent
              onChange={(e) => setEnd(e.target.value)}
              type="datetime-local"
              label="End Time"
              value={end}
            />
          </Flex>

          <SelectComponent
            label="Invite people(OPTIONAL)"
            options={list || ""}
            multiple={true}
            onChange={(e) => {
              const lawyers = e?.map((el) => el.value);
              setEmployee(lawyers);
            }}
          />

          <SelectComponent
            label="Invite Client(OPTIONAL)"
            options={clientNames || ""}
            onChange={(e) => setClient(e.value)}
          />

          <InputComponent
            label="Attach meeting link (OPTIONAL)"
            placeholder="https://event.com(OPTIONAL)"
            onChange={(e) => setUrl(e.target.value)}
            value={url}
          />

          <Flex align="center" justify="space-between" w="100%">
            <Button
              onClick={() => show(false)}
              variant="ghost"
              colorScheme="red"
            >
              Cancel
            </Button>
            <Button
              onClick={eventHandler}
              isLoading={loading}
              loadingText="Processing"
              variant="solid"
              bg="brand.900"
              color="white"
            >
              Create
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
