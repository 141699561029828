import { Flex } from "@chakra-ui/react";
import React from "react";
import ReusableTitleText from "../../../UI/ReusableTitleText";

export default function InvoicesTitle() {
  return (
    <Flex
      align="center"
      bg="#EEEFF3"
      py="1rem"
      borderTopRadius="0.75rem"
      w="100%"
    >
      <ReusableTitleText
        w="5%"
        text=""
        color="#7F7E7E"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        weight="medium"
      />
      <ReusableTitleText
        text="Invoice ID"
        color="#7F7E7E"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        weight="medium"
        w="10%"
      />
      
      <ReusableTitleText
        text="Client Name"
        color="#7F7E7E"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        weight="medium"
        w="20%"
      />
      <ReusableTitleText
        text="Invoice Title"
        color="#7F7E7E"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        weight="medium"
        w="20%"
      />
      <ReusableTitleText
        text="Brief Title"
        color="#7F7E7E"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        weight="medium"
        w="15%"
      />
      <ReusableTitleText
        text="Amount"
        color="#7F7E7E"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        weight="medium"
        w="10%"
      />
      <ReusableTitleText
        text="Status"
        color="#7F7E7E"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        weight="medium"
        w="10%"
      />
      <ReusableTitleText
        text="Actions"
        color="#7F7E7E"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        weight="medium"
        w="10%"
        align="center"
      />
    </Flex>
  );
}
