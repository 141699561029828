import { Flex, useToast, Button, Heading } from "@chakra-ui/react";
import Rate from "../../UI/Rating";
import { clientFeedback } from "../../../util/http";
import { useState } from "react";
import TextareaComponent from "../../UI/Textarea";

export default function Feedback({ brief_id }) {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function feedbackHandler() {
    const data = {
      feedback: comment,
      rating,
      brief_id,
    };

    if (!comment || !rating) {
      toast({
        title: "Missing details",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await clientFeedback(data);
      toast({
        title: `Thank you! Your review was received`,
        description: `You have successfully requested for leave.`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setRating(0);
      setComment("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }
  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      bg="white"
      gap="1rem"
      boxShadow="lg"
      borderRadius="16px"
      p="1rem"
    >
      <Heading size="md" color="#333" fontWeight="bold">
        Client Feedback
      </Heading>
      <Rate onRating={setRating} rating={rating} readonly={false} />
      <Flex
        w={{ lg: "70%", md: "80%", base: "100%" }}
        align="start"
        direction="column"
        gap="1rem"
      >
        <TextareaComponent
          onChange={(e) => setComment(e.target.value)}
          required={false}
          placeholder="Tell us your experience"
        />
        <Button
          onClick={feedbackHandler}
          variant="solid"
          bg="brand.900"
          color="white"
          isLoading={loading}
          loadingText="Processing"
        >
          Send
        </Button>
      </Flex>
    </Flex>
  );
}
