import { Flex, Heading, Text } from "@chakra-ui/react";

export default function TaskDescription({ task }) {
  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      bg="white"
      gap="1rem"
      boxShadow="lg"
      borderRadius="16px"
      p="1rem"
    >
      <Heading
        textTransform="capitalize"
        size="md"
        color="#333"
        fontWeight="bold"
      >
        Task Description
      </Heading>
      <Text
        color="#333"
        textTransform="capitalize"
        fontSize="16px"
        fontWeight="normal"
      >
        {task?.description}
      </Text>
    </Flex>
  );
}
