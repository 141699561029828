import ReusableFilter from "../UI/ReusableFilter";
import { getClients, getCorporateClients } from "../../util/http";
import { useState, useEffect } from "react";

export default function ClientSubheader({ setList, setProgress }) {
  const [status, setStatus] = useState("");

  async function getCorporateHandler() {
    try {
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const response = await getCorporateClients("corporate");
      console.log(response);
      setProgress(80);
      setProgress(100);
      setList(response?.clients?.reverse());
    } catch (error) {
      setProgress(100);
      console.log(error);
    }
  }

  async function getClientHandler() {
    try {
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const response = await getCorporateClients("individual");

      setProgress(80);
      setProgress(100);
      setList(response?.clients?.reverse());
    } catch (error) {
      setProgress(100);
      console.log(error);
    }
  }

  useEffect(() => {
    async function sortData() {
      if (!status) {
        return;
      } else {
        try {
          const response = await getClients();

          const suspendedClients = response?.clients
            ?.filter((el) => el.is_banned === "1" && el.deleted_at === null)
            .reverse();

          const archivedClients = response?.clients
            ?.filter((el) => el.deleted_at !== null)
            .reverse();

          if (status === "all" || !status) {
            setList(
              response?.clients
                ?.filter((el) => el.deleted_at === null)
                .reverse()
            );
          } else if (status === "suspended") {
            setList(suspendedClients);
          } else if (status === "archived") {
            setList(archivedClients);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    sortData();
  }, [status]);

  function activeFilter() {
    setStatus("all");
  }

  function suspendedFilter() {
    setStatus("suspended");
  }

  function archivedFilter() {
    setStatus("archived");
  }

  async function searchHandler(e) {
    const response = await getClients();
    const filteredData = response?.clients.filter((el) =>
      el.client_name
        ?.toLowerCase()
        .includes(e.target.value.trim().toLowerCase())
    );
    setList(filteredData);
  }

  return (
    <ReusableFilter
      menu={[
        { text: "Individual", onClick: getClientHandler },
        { text: "Corporate", onClick: getCorporateHandler },
        { text: "Active", onClick: activeFilter },
        { text: "Suspended", onClick: suspendedFilter },
        { text: "Archived", onClick: archivedFilter },
      ]}
      onSearch={searchHandler}
    />
  );
}
