import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import ReusableText from "../UI/ReusableText";
import PasswordInput from "../UI/PasswordInput";

export default function DeleteModal({
  isOpen,
  onClose,
  title,
  text,
  show = true,
  onClick,
  onChange,
}) {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody py="2rem" w="100%">
          <Flex align="center" direction="column" gap="1rem">
            <Flex
              w="2.5rem"
              align="center"
              justify="center"
              h="2.5rem"
              borderRadius="100%"
              bg="red.100"
            >
              <InfoOutlineIcon color="error.900" />
            </Flex>
            <ReusableText
              text={title}
              size="18px"
              color="var(--container_color)"
              weight="bold"
            />
            <ReusableText
              size="14px"
              color="var(--container_color)"
              text={text}
              align="center"
              weight="normal"
            />
            {false && <PasswordInput onChange={onChange} label="Password" />}
          </Flex>
        </ModalBody>

        <ModalFooter w="100%">
          <Flex w="100%" align="center" justify="space-between" gap="1rem">
            <Button
              onClick={onClick}
              borderRadius="1.5rem"
              variant="solid"
              colorScheme="red"
            >
              Yes, Delete
            </Button>
            <Button
              borderRadius="1.5rem"
              colorScheme="teal"
              onClick={onClose}
              variant="outline"
            >
              Cancel
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
