import { Flex } from "@chakra-ui/react";
import AppraisalTitle from "./AppraisalTitle";
import AppraisalList from "./AppraisalList";

export default function CustomList({ list }) {
  return (
    <Flex w="100%" direction="column">
      <AppraisalTitle />
      <AppraisalList list={list} />
    </Flex>
  );
}
