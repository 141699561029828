import { Button, Flex, useToast } from "@chakra-ui/react";
import InputComponent from "../../UI/Input";
import ReusableModal from "../../UI/ReusableModal";
import TextareaComponent from "../../UI/Textarea";
import { useState } from "react";
import { requestExtension } from "../../../util/http";

export default function RequestExtension({ isOpen, onClose, task }) {
  const [reason, setReason] = useState("");
  const [due_date, setDue_date] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function requestHandler() {
    const startDay = new Date(due_date).getDate();
    const startMonth = new Date(due_date).getMonth() + 1;
    const startYear = new Date(due_date).getFullYear();
    const startHour = new Date(due_date).getHours();
    const startMin = new Date(due_date).getMinutes();
    const startSec = new Date(due_date).getSeconds();
    const data = {
      new_due_date: `${startYear}-${startMonth
        .toString()
        .padStart(2, 0)}-${startDay.toString().padStart(2, 0)} ${startHour
        .toString()
        .padStart(2, 0)}:${startMin.toString().padStart(2, 0)}:${startSec
        .toString()
        .padStart(2, 0)}`,
      reason,
    };

    if (!due_date || !reason) {
      return;
    }

    try {
      setLoading(true);
      await requestExtension(task?.id, data);
      setLoading(false);

      toast({
        title: "Task extension request sent",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <ReusableModal
      title="Request task extension"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Flex w="100%" align="start" direction="column" gap="1rem" py="1rem">
        <InputComponent
          onChange={(e) => setDue_date(e.target.value)}
          value={due_date}
          label="Select due date"
          type="datetime-local"
        />
        <TextareaComponent
          label="Reason for extension"
          placeholder="Enter reason here"
          onChange={(e) => setReason(e.target.value)}
          value={reason}
        />
        <Button
          variant="solid"
          bg="brand.900"
          color="white"
          isLoading={loading}
          loadingText=""
          onClick={requestHandler}
        >
          Submit
        </Button>
      </Flex>
    </ReusableModal>
  );
}
