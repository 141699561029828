import React, { Fragment, useContext } from "react";
import ViewBrief from "../../brief/view brief/ViewBrief";
import { AuthContext } from "../../../util/context";
import Login from "../../auth/Login";
import { getStoredItem } from "../../../util/lib";
import Dashboard from "../../dashboard/Dashboard";

export default function ViewBriefPage() {
  let view;
  const { isAuthenticated, user, isUser } = useContext(AuthContext);
  const userType = getStoredItem("user-type");

  const viewBrief =
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "seed" ||
    userType === "client";

  if (viewBrief) {
    view = <ViewBrief />;
  } else {
    view = <Dashboard />;
  }
  return <Fragment>{isAuthenticated && isUser ? view : <Login />}</Fragment>;
}
