import { Button, Flex, useToast } from "@chakra-ui/react";
import ReusableModal from "../../../UI/ReusableModal";
import InputComponent from "../../../UI/Input";
import { useEffect, useState } from "react";
import SelectComponent from "../../../UI/Select";
import { addMilestone, getEmployees } from "../../../../util/http";

export default function AddMilestone({ isOpen, onClose, task }) {
  const [loading, setLoading] = useState(false);
  const [empDetail, setEmpDetail] = useState([]);
  const toast = useToast();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    assignee: "",
    assignee_id: "",
  });
  const lawyers = task?.assignee && JSON.parse(task?.assignee);

  useEffect(() => {
    async function fetchData() {
      try {
        const employeeList = await getEmployees();
        const employeeObj = employeeList?.employees
          ?.filter((el) => {
            return el.deleted_at === null;
          })
          .map((el) => {
            return { name: `${el.first_name} ${el.last_name}`, id: el.id };
          });

        setEmpDetail(employeeObj);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  async function milestoneHandler() {
    const start = new Date(formData.startDate);
    const end = new Date(formData.endDate);

    const startDay = start.getDate();
    const startMonth = start.getMonth() + 1;
    const startYear = start.getFullYear();
    const startHour = start.getHours();
    const startMin = start.getMinutes();
    const startSec = start.getSeconds();

    const endDay = end.getDate();
    const endMonth = end.getMonth() + 1;
    const endYear = end.getFullYear();
    const endHour = end.getHours();
    const endMin = end.getMinutes();
    const endSec = end.getSeconds();

    const assignee_id = empDetail.find(
      (el) => el.name.toLowerCase() === formData.assignee.toLowerCase()
    )?.id;

    const data = {
      title: formData.name,
      description: formData.description,
      start_date: `${startYear}-${startMonth
        .toString()
        .padStart(2, 0)}-${startDay.toString().padStart(2, 0)} ${startHour
        .toString()
        .padStart(2, 0)}:${startMin.toString().padStart(2, 0)}:${startSec
        .toString()
        .padStart(2, 0)}`,
      end_date: `${endYear}-${endMonth.toString().padStart(2, 0)}-${endDay
        .toString()
        .padStart(2, 0)} ${endHour.toString().padStart(2, 0)}:${endMin
        .toString()
        .padStart(2, 0)}:${endSec.toString().padStart(2, 0)}`,
      // assignee: formData.assignee,
      assignee_id,
    };

    console.log(data);

    if (
      !formData.name ||
      !formData.startDate ||
      !formData.endDate ||
      !formData.assignee
    ) {
      toast({
        title: `Milestone details missing`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (end < start) {
      toast({
        title: "Enter a valid due date",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await addMilestone(task?.id, data);
      window.location.reload();
      toast({
        title: `Milestone created`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      onClose();
      setFormData({
        name: "",
        description: "",
        startDate: "",
        endDate: "",
        assignee: "",
        assignee_id: "",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `${error?.response?.data.message || "something went wrong"}`,
        description: ``,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <ReusableModal title="Add Milestone" isOpen={isOpen} onClose={onClose}>
      <Flex w="100%" align="start" direction="column" gap="1rem">
        <InputComponent
          label="Milestone Name"
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          value={formData.name}
          required={false}
          placeholder="Enter milestone name"
        />
        <InputComponent
          label="Milestone Description"
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          value={formData.description}
          required={false}
          placeholder="Add description(OPTIONAL)"
        />
        <Flex w="100%" align="center" gap="1rem">
          <InputComponent
            onChange={(e) =>
              setFormData({ ...formData, startDate: e.target.value })
            }
            value={formData.startDate}
            required={false}
            type="datetime-local"
            label="Start date"
            placeholder="Add Date"
          />
          <InputComponent
            onChange={(e) =>
              setFormData({ ...formData, endDate: e.target.value })
            }
            value={formData.endDate}
            required={false}
            type="datetime-local"
            label="End date"
            placeholder="Add Date"
          />
        </Flex>
        <SelectComponent
          label="Assign Milestone"
          options={lawyers || []}
          onChange={(e) => setFormData({ ...formData, assignee: e.value })}
        />
        <Button
          w="100%"
          isLoading={loading}
          loadingText=""
          onClick={milestoneHandler}
          bg="brand.900"
          color="#fff"
        >
          Add Timeline
        </Button>
      </Flex>
    </ReusableModal>
  );
}
