import { Flex, useDisclosure } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";
import ReceiptTitle from "./ReceiptTitle";
import ReceiptList from "./ReceiptList";
import DeleteReceipt from "./DeleteReceipt";

export default function Receipt({ receipt }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex align="start" gap="1rem" pb="2rem" direction="column">
      <ReusableText
        text="Receipt"
        color="var(--container_color)"
        size="16px"
        weight="medium"
      />
      <Flex w="100%" direction="column">
        <ReceiptTitle />
        <ReceiptList list={receipt} onOpen={onOpen} />
        <DeleteReceipt isOpen={isOpen} onClose={onClose} />
      </Flex>
    </Flex>
  );
}
