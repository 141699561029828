import { AddIcon } from "@chakra-ui/icons";
import { Badge, Box, Button, Flex, Heading } from "@chakra-ui/react";
import AddTimeline from "../view brief/AddTimeline";
import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import UpdateTimeline from "./UpdateTimeline";
import { getStoredItem } from "../../../util/lib";

function ReusableActivity({ setShow, setId, el }) {
  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  return (
    <Flex w="100%" align="start" direction="column">
      <Flex w="100%" align="center" justify="space-between">
        <Flex align="start" gap="1rem">
          <Box
            w="20px"
            h="20px"
            borderRadius="100%"
            bg={getRandomColor()}
          ></Box>
          <Flex align="start" direction="column" gap="8px">
            <Heading
              textTransform="capitalize"
              size="sm"
              color="#333"
              fontWeight="bold"
            >
              {el?.title}
            </Heading>
          </Flex>
        </Flex>
        <Heading size="xs" color="#333" fontWeight="normal">
          {new Date(el?.start_date).toLocaleDateString()} -{" "}
          {new Date(el?.end_date).toLocaleDateString()}
        </Heading>
      </Flex>
      <Flex w="100%" align="center" justify="space-between">
        <Heading
          textTransform="capitalize"
          size="xs"
          color="#333"
          fontWeight="normal"
        >
          {el?.description}
        </Heading>
        <Flex align="center" gap="1rem">
          <Badge
            colorScheme={
              el?.status?.toLowerCase() === "pending"
                ? "gray"
                : el?.status?.toLowerCase() === "completed"
                ? "green"
                : el?.status?.toLowerCase() === "draft"
                ? "red"
                : "yellow"
            }
          >
            {el?.status}
          </Badge>
          <Button
            onClick={() => {
              setId(el?.id);
              setShow(true);
            }}
            variant="ghost"
            colorScheme="blue"
          >
            <FiEdit color="blue" />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default function Activity({ brief }) {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [id, setId] = useState("");
  const userType = getStoredItem("user-type");

  return (
    <>
      <Flex
        w="100%"
        align="start"
        direction="column"
        bg="white"
        gap="1rem"
        boxShadow="lg"
        borderRadius="16px"
        p="1rem"
      >
        <Heading size="md" color="#333" fontWeight="bold">
          Brief Timeline
        </Heading>
        <Flex
          w="100%"
          align="start"
          direction="column"
          gap="1rem"
          maxHeight="400px"
          overflow="scroll"
        >
          {brief?.timeline?.length > 0 ? (
            <>
              {brief?.timeline?.map((el, i) => (
                <ReusableActivity
                  el={el}
                  key={i}
                  setShow={setShow1}
                  setId={setId}
                />
              ))}
            </>
          ) : (
            <Flex w="100%" align="center" justify="center" h="20vh">
              Empty Timeline...
            </Flex>
          )}
        </Flex>
        {userType === "employee" && (
          <Flex align="end" justify="end" w="100%">
            <Button
              leftIcon={<AddIcon />}
              onClick={() => setShow(true)}
              variant="solid"
              bg="#364273"
              color="white"
            >
              Add Timeline
            </Button>
          </Flex>
        )}
      </Flex>
      {show1 && <UpdateTimeline id={id} show={setShow1} />}
      {show && <AddTimeline id={brief?.id} show={setShow} />}
    </>
  );
}
