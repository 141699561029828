import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../UI/ReusableText";
import RestrictedList from "./RestrictedList";

export default function UserCannot({ notPermitted }) {
  return (
    <Flex align="start" h="70vh" overflow="scroll" w="50%" direction="column">
      <Box w="100%" bg="#FFC2B1" p="1rem">
        <ReusableText text="Users Can't" color="#515050" weight="bold" />
      </Box>
      <RestrictedList list={notPermitted} />
    </Flex>
  );
}
