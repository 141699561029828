import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

export default function SmallSearch({ color, bg, w, border, onSearch }) {
  return (
    <InputGroup w={w}>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color={color ? color : "#333"} />
      </InputLeftElement>
      <Input
        onChange={(e) => onSearch(e)}
        border={border ? border : "none"}
        color={color ? color : "#333"}
        _placeholder={{ color: "inherit" }}
        bg={bg ? bg : "white"}
        type="text"
        placeholder="Search"
      />
    </InputGroup>
  );
}
