import TodoList from "./TodoList";
import TaskWrapper from "../TaskWrapper";

export default function Todo({ list, setList }) {
  return (
    <TaskWrapper title="TODO" total={list?.length} status="todo">
      <TodoList setList={setList} list={list} />
    </TaskWrapper>
  );
}
