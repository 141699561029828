import { Flex } from "@chakra-ui/react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import { AddIcon } from "@chakra-ui/icons";
import BriefSubheader from "./BriefSubheader";
import BriefTitle from "./BriefTitle";
import BriefList from "./BriefList";
import ReusableFooter from "../UI/ReusableFooter";
import { useNavigate } from "react-router-dom";
import { getStoredItem } from "../../util/lib";

export default function BriefContainer({
  list,
  total,
  perPage,
  currentPage,
  setCurrentPage,
  setList,
  loading,
  setPage,
  firstIndex,
  lastIndex,
}) {
  const navigate = useNavigate();
  const userType = getStoredItem("user-type");

  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      gap="1rem"
      p={{ lg: "2rem", md: "1rem", base: "6px" }}
    >
      <ReusableEmployeeHeader
        title="Briefs"
        text="Manage your brief and their details here"
        btnText="Create Brief"
        icon={userType === "employee" && <AddIcon />}
        show={userType === "employee" ? true : false}
        onClick={() => userType === "employee" && navigate("/brief/create")}
      />

      <BriefSubheader setPage={setPage} setList={setList} />
      <Flex
        w="100%"
        align="start"
        direction="column"
        borderTopLeftRadius="0.75rem"
        borderTopRightRadius="0.75rem"
        bg="var(--container)"
        boxShadow="lg"
      >
        <BriefTitle />
        <BriefList loading={loading} list={list} />
        <ReusableFooter
          total={total}
          itemPerPage={perPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Flex>
    </Flex>
  );
}
