import { Box, Flex } from "@chakra-ui/react";
import { AiOutlineEye } from "react-icons/ai";
import ReusableText from "../UI/ReusableText";
import { useNavigate } from "react-router-dom";

export default function AppraisalItem({ item }) {
  const navigate = useNavigate();
  const departmentNum = item?.departments ? JSON.parse(item?.departments) : [];
  const employeeNum = item?.lawyer_id ? JSON.parse(item?.lawyer_id) : [];

  return (
    <Flex
      w="100%"
      align="center"
      px="1rem"
      py="1rem"
      cursor="pointer"
      _hover={{ opacity: "80%" }}
      borderBottom="1px solid #333"
    >
      <ReusableText
        text={`${employeeNum?.length} Employees`}
        color="var(--container_color)"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        w="25%"
        align="center"
      />
      <ReusableText
        text={`${departmentNum?.length} departments`}
        color="var(--container_color)"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        w="15%"
        align="center"
        transform={true}
      />
      <ReusableText
        text={item?.title?.slice(0, 27)}
        title={item?.title}
        color="var(--container_color)"
        size={{ lg: "14px", md: "12px", base: "6px" }}
        weight="medium"
        w="20%"
        align="center"
      />

      <ReusableText
        text={new Date(item?.due_date)?.toLocaleDateString()}
        color="var(--container_color)"
        size={{ lg: "14px", md: "12px", base: "5px" }}
        weight="medium"
        w="15%"
        align="start"
      />
      <Flex
        align="center"
        justify="center"
        w="15%"
        gap="0.5rem"
        borderRadius="1rem"
      >
        <Box
          w="0.3rem"
          height="0.3rem"
          borderRadius="100%"
          bg={"#1FA159"}
        ></Box>
        <ReusableText
          text={item?.status}
          transform={true}
          size={{ lg: "12px", md: "10px", base: "6px" }}
          weight="medium"
          color="var(--container_color)"
        />
      </Flex>
      <Flex align="center" w="10%" gap="1rem" justify="center">
        <AiOutlineEye
          color="var(--container_color)"
          cursor="pointer"
          onClick={() => navigate(`/appraisal/${item?.id}`)}
        />
      </Flex>
    </Flex>
  );
}
