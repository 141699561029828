import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import EmployeeTitle from "../../../employee/EmployeeTitle";
import EmployeeList from "../../../employee/EmployeeList";
import { Link } from "react-router-dom";
import ReusableText from "../../../UI/ReusableText";

export default function Employee({ list, onOpen }) {
  return (
    <Flex w="100%" direction="column">
      <Flex py="1rem" align="center" w="100%" justify="space-between">
        <ReusableText
          text="Employee List"
          color="var(--container_color)"
          size="18px"
          weight="medium"
        />
        <Link to="/employees" style={{}}>
          View all {">"}
        </Link>
      </Flex>
      <EmployeeTitle />
      <EmployeeList list={list} onOpen={onOpen} />
    </Flex>
  );
}
