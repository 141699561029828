import { Button, Divider, Flex, Heading, useToast } from "@chakra-ui/react";
import Modal from "../overlay/Modal";
import InputComponent from "../UI/Input";
import { AiOutlineClose } from "react-icons/ai";
import { useState } from "react";
import SelectComponent from "../UI/Select";
import { useEffect } from "react";
import { getEmployees, listDepartments } from "../../util/http";
import CheckboxComponent from "../UI/Checkbox";
import AppraisalFields from "./AppraisalFields";

export default function CreateAppraisal({ show }) {
  const [title, setTitle] = useState("");
  const [due_date, setDueDate] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [empDetail, setEmpDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [employees, setEmployees] = useState("");
  const [showDept, setShowDept] = useState({
    all: true,
    department: false,
  });
  const [showEmp, setShowEmp] = useState({
    all: true,
    employee: false,
  });
  const toast = useToast();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getEmployees();
        setEmployeeList(
          response?.employees
            ?.filter((el) => {
              const mappedDept = departments.map((el) => el?.toLowerCase());
              return (
                el.deleted_at === null &&
                mappedDept.includes(el?.department?.toLowerCase())
              );
            })
            .map((el) => `${el?.first_name} ${el?.last_name}`)
        );
        setEmpDetail(
          response?.employees
            ?.filter((el) => {
              const mappedDept = departments.map((el) => el?.toLowerCase());
              return (
                el.deleted_at === null &&
                mappedDept.includes(el?.department?.toLowerCase())
              );
            })
            .map((el) => {
              return {
                name: `${el?.first_name} ${el?.last_name}`,
                id: el?.id,
              };
            })
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [departments]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await listDepartments();
        setDepartmentList(response?.departments?.map((el) => el?.department));
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  function closeMenu() {
    show(false);
  }

  async function createHandler() {
    const data = {
      title,
      due_date,
    };

    try {
      setLoading(true);

      closeMenu();
      toast({
        title: `Appraisal Created`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // toast({
      //   title: `Error`,
      //   description: `${
      //     error?.response?.data.message || "something went wrong"
      //   }`,
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      //   position: "top-right",
      // });
    }
  }

  return (
    <>
      <Modal hide={closeMenu}>
        <Flex w="100%" align="start" direction="column" gap="1.5rem" py="1rem">
          <Flex w="100%" align="center" justify="space-between">
            <Heading size="md" color="#333">
              Create a new Appraisal
            </Heading>
            <Button variant="ghost" onClick={closeMenu} colorScheme="red">
              <AiOutlineClose />
            </Button>
          </Flex>
          <Divider />
          <InputComponent
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            placeholder="Enter title"
            label="Appraisal Title"
          />
          <InputComponent
            onChange={(e) => setDueDate(e.target.value)}
            value={due_date}
            type="datetime-local"
            label="Due Date"
          />
          <Flex w="100%" align="center" gap="1rem">
            <CheckboxComponent
              checked={showDept.all}
              onChange={(e) => {
                setShowDept({ ...showDept, all: e.target.checked });
                if (e.target.checked) {
                  setShowDept({ department: false, all: e.target.checked });
                }
              }}
              title="All Employees"
            />
            <CheckboxComponent
              checked={showDept.department}
              onChange={(e) => {
                setShowDept({ ...showDept, department: e.target.checked });
                if (e.target.checked) {
                  setShowDept({ all: false, department: e.target.checked });
                }
              }}
              title="Select Departments"
            />
          </Flex>
          {showDept.department && (
            <SelectComponent
              multiple={true}
              label="Select Departments"
              options={departmentList || []}
              onChange={(e) => {
                setDepartments(e?.map((el) => el.value));
              }}
            />
          )}
          {departments?.length > 0 && !showDept.all && (
            <Flex w="100%" align="center" gap="1rem">
              <CheckboxComponent
                checked={showEmp.all}
                onChange={(e) => {
                  setShowEmp({ ...showEmp, all: e.target.checked });
                  if (e.target.checked) {
                    setShowEmp({ employee: false, all: e.target.checked });
                  }
                }}
                title="All Employees"
              />
              <CheckboxComponent
                checked={showEmp.employee}
                onChange={(e) => {
                  setShowEmp({ ...showEmp, employee: e.target.checked });
                  if (e.target.checked) {
                    setShowEmp({ all: false, employee: e.target.checked });
                  }
                }}
                title="Select Employees"
              />
            </Flex>
          )}
          {showEmp.employee && (
            <SelectComponent
              multiple={true}
              onChange={(e) => {
                setEmployees(e?.map((el) => el.value));
              }}
              label="Select Employee"
              options={employeeList || []}
            />
          )}
          <AppraisalFields />
          <Flex w="100%" align="start">
            <Button
              isLoading={loading}
              loadingText=""
              onClick={createHandler}
              variant="solid"
              bg="brand.900"
              color="white"
            >
              Submit
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
