import { Box, Flex, Image , Button , useDisclosure } from "@chakra-ui/react";
import { BiFile } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import ReusableText from "../UI/ReusableText";
import { useContext } from "react";
import { AuthContext } from "../../util/context";
import { useMediaQuery } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import ViewDocument from "./ViewDocument";


const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
];

export default function ViewFile({ file, setFile }) {
    const modified = new Date(file.lastModified);

    const modifiedMonth = modified?.getMonth() + 1;
    const modifiedDay = modified?.getDate();
    const modifiedYear = modified?.getFullYear();
    const modifiedHour = modified?.getHours();
    const modifiedMinute = modified?.getMinutes();

    const { user } = useContext(AuthContext);
    const newArr = file?.path?.split("/");
    newArr?.splice(0, 3);
    const newPath = newArr?.join("/");
    const fileUrl = `https://${newPath}`
    const url = 'https://corsproxy.io/?' + encodeURIComponent(fileUrl)
    const mobile = useMediaQuery("(max-width: 768px)");
    const desktop = useMediaQuery("(min-width: 769px)");


    const  {onOpen , isOpen , onClose} =  useDisclosure()

    return (
        <Flex
            //   w="20%"
            borderLeft="1px solid #ccc"
            align="center"
            direction="column"
            // justify="center"
            gap="1rem"
            p="1rem"

            borderRadius={'sm'}
            boxShadow={'md'}


        >
            <Flex w={'full'} onClick={() => setFile({})} justify={'flex-end'} >
                <AiOutlineClose color="red" fontSize="1rem" cursor="pointer" />
            </Flex>
            <Box  w={{
                        base: "20rem",
                        lg: "30rem",

                    }} >
            {
                // check for extension
                file?.extension === "pdf" || file?.extension === 'docx' || file?.extension === '' ? (
                    <iframe
                        src={url}
                        title="pdf"
                        width={'100%'}
                        height={'300px'}
                        style={{ border: "none" }}
                    ></iframe>
                ) : (
                    <Image src={url} alt="file"  w={{
                        base: "20rem",
                        lg: "30rem",
                    }} h={{
                        base: "15rem",
                        lg: "20rem",

                    }}  />
                )

            }
            </Box>

            <ReusableText
                text={file.file_name}
                size="18px"
                color="#3F434A"
                weight="medium"
                align="center"
            />
            <Flex w="100%" align="start" direction="column" gap="1rem">
                <ReusableText
                    text="Information"
                    size="16px"
                    color="#3F434A"
                    weight="medium"
                    align="start"
                />
                <Flex w="100%" align="center" gap="2rem">
                    <ReusableText
                        text="Type"
                        align="start"
                        size="16px"
                        weight="normal"
                        color="#595F69"
                    />
                    <ReusableText
                        text={file?.mimetype}
                        align="start"
                        size="14px"
                        weight="light"
                        color="#8A9099"
                    />
                </Flex>
                <Flex w="100%" align="center" gap="2rem">
                    <ReusableText
                        text="Extension"
                        align="start"
                        size="16px"
                        weight="normal"
                        color="#595F69"
                    />
                    <ReusableText
                        text={file?.extension}
                        align="start"
                        size="14px"
                        weight="light"
                        color="#8A9099"
                    />
                </Flex>
                <Flex w="100%" align="center" gap="2rem">
                    <ReusableText
                        text="Size"
                        align="start"
                        size="16px"
                        weight="normal"
                        color="#595F69"
                    />
                    <ReusableText
                        text={file.size}
                        align="start"
                        size="14px"
                        weight="light"
                        color="#8A9099"
                    />
                </Flex>
                <Flex w="100%" align="center" gap="2rem">
                    <ReusableText
                        text="Owner"
                        align="start"
                        size="16px"
                        weight="normal"
                        color="#595F69"
                    />
                    <ReusableText
                        text={`${user?.first_name} ${user?.last_name}`}
                        align="start"
                        size="14px"
                        weight="light"
                        color="#8A9099"
                    />
                </Flex>
                <Flex w="100%" align="center" gap="2rem" >
                    <ReusableText
                        text="Location"
                        align="start"
                        size="16px"
                        weight="normal"
                        color="#595F69"
                    />
                    <ReusableText
                        text="My Files"
                        align="start"
                        size="14px"
                        weight="light"
                        color="#8A9099"
                    />
                </Flex>
                {/* <Flex w="100%" align="center" gap="2rem">
          <ReusableText
            text="Modified"
            align="start"
            size="16px"
            weight="normal"
            color="#595F69"
          />
          <ReusableText
            text={`${
              months[file?.lastModifiedDate?.getMonth() + 1]
            } ${file?.lastModifiedDate
              ?.getDate()
              ?.toString()
              ?.padStart(
                2,
                0
              )} ${file?.lastModifiedDate?.getFullYear()} ${file?.lastModifiedDate
              ?.getHours()
              ?.toString()
              ?.padStart(2, 0)}:${file?.lastModifiedDate
              ?.getMinutes()
              ?.toString()
              ?.padStart(2, 0)}`}
            align="start"
            size="14px"
            weight="light"
            color="#8A9099"
          />
        </Flex>{" "} */}
                {/* <Flex w="100%" align="center" gap="2rem">
          <ReusableText
            text="Created"
            align="start"
            size="16px"
            weight="normal"
            color="#595F69"
          />
          <ReusableText
            text={`${months[modifiedMonth]} ${modifiedDay ?modifiedDay
              .toString()
              .padStart(2, 0) : ''} ${modifiedYear} ${modifiedHour ? modifiedHour
              .toString()
              .padStart(2, 0) : ''}:${modifiedMinute ? modifiedMinute?.toString()?.padStart(2, 0) : ''}`}
            align="start"
            size="14px"
            weight="light"
            color="#8A9099"
          />
        </Flex> */}
        <Flex w={'full'} justify={'space-between'} mt={'1rem'} gap={'1rem'} align={'center'}>
        <Link href={fileUrl} download>
              <Button bg={'#364273'} colorScheme="messenger" mr={3}>
                Download
              </Button>
            </Link>
            <Button  colorScheme="gray" onClick={onOpen}>
                View
            </Button>
        </Flex>
            </Flex>
            <ViewDocument isOpen={isOpen} onClose={onClose} fileUrl={`https://${newPath}`} />
        </Flex>
    );
}
