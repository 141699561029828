import React, { useState, useEffect } from "react";
import Modal from "../../overlay/Modal";
import { Button, Divider, Flex, Heading, useToast } from "@chakra-ui/react";
import SelectComponent from "../../UI/Select";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { generateReport, getEmployees, salaryReport } from "../../../util/http";
import { filterDate } from "../../../util/lib";
import AreaChart from "../../UI/AreaChart";

function monthFilter(data, month) {
  return data?.filter((el) => {
    const createdMonth = new Date(el.created_at).getMonth() + 1;
    return +createdMonth === +month;
  });
}

export default function StaffReportDetail({ show }) {
  const [filter, setFilter] = useState("weekly");
  const [days, setDays] = useState(0);
  const [list, setList] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [employee, setEmployee] = useState("");
  const [data, setData] = useState({
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  });
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function reportHandler() {
    const salary = [data];
    const data1 = {
      report: "briefs",
      data: JSON.stringify(salary),
    };
    try {
      setLoading(true);
      await generateReport(data1);
      toast({
        title: `Salary report generated`,
        description: "",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  useEffect(() => {
    if (filter?.toLowerCase() === "daily") {
      setDays(1);
    } else if (filter?.toLowerCase() === "weekly") {
      setDays(7);
    } else if (filter?.toLowerCase() === "monthly") {
      setDays(30);
    } else {
      setDays("");
    }
  }, [filter]);
  function closeModal() {
    show(false);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getEmployees();
        let employeeObj;

        employeeObj = response?.employees
          ?.filter((el) => el.deleted_at === null)
          .map((el) => {
            return {
              name: `${el.first_name} ${el.last_name}`,
              id: el.id,
            };
          });

        setAllEmployees(employeeObj);

        const employeeNames = employeeObj?.map((el) => el.name);
        setList(employeeNames);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (!employee) {
        return;
      }
      const emp_id = allEmployees?.find((el) => el.name == employee)?.id;

      try {
        const response = await salaryReport(emp_id);

        const reports = response?.report?.filter((el) => {
          if (!days) {
            return el;
          }
          const milliSec = +days * 24 * 60 * 60 * 1000;
          const startDate = filterDate(milliSec);
          const createdDate = new Date(el?.created_at);
          return startDate < createdDate;
        });

        setData({
          January: monthFilter(reports, 1)
            ?.map((el) => +el.salary)
            ?.reduce((acc, sum) => acc + sum, 0),
          February: monthFilter(reports, 2)
            ?.map((el) => +el.salary)
            ?.reduce((acc, sum) => acc + sum, 0),
          March: monthFilter(reports, 3)
            ?.map((el) => +el.salary)
            ?.reduce((acc, sum) => acc + sum, 0),
          April: monthFilter(reports, 4)
            ?.map((el) => +el.salary)
            ?.reduce((acc, sum) => acc + sum, 0),
          May: monthFilter(reports, 5)
            ?.map((el) => +el.salary)
            ?.reduce((acc, sum) => acc + sum, 0),
          June: monthFilter(reports, 6)
            ?.map((el) => +el.salary)
            ?.reduce((acc, sum) => acc + sum, 0),
          July: monthFilter(reports, 7)
            ?.map((el) => +el.salary)
            ?.reduce((acc, sum) => acc + sum, 0),
          August: monthFilter(reports, 8)
            ?.map((el) => +el.salary)
            ?.reduce((acc, sum) => acc + sum, 0),
          September: monthFilter(reports, 9)
            ?.map((el) => +el.salary)
            ?.reduce((acc, sum) => acc + sum, 0),
          October: monthFilter(reports, 10)
            ?.map((el) => +el.salary)
            ?.reduce((acc, sum) => acc + sum, 0),
          November: monthFilter(reports, 11)
            ?.map((el) => +el.salary)
            ?.reduce((acc, sum) => acc + sum, 0),
          December: monthFilter(reports, 12)
            ?.map((el) => +el.salary)
            ?.reduce((acc, sum) => acc + sum, 0),
        });
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [allEmployees, employee, days]);

  return (
    <Modal hide={() => closeModal()} title="Client">
      <Flex align="start" direction="column" gap="2rem" w="100%">
        <Heading size="md" color="var(--container_color)" fontWeight={600}>
          Salary Report
        </Heading>
        <Divider />
        <Flex w="100%" align="center" justify="space-between">
          <Flex align="center" w="60%" gap="1rem">
            <SelectComponent
              onChange={(e) => setEmployee(e.value)}
              label="Employees"
              options={list || ""}
            />
            <SelectComponent
              label="Duration"
              onChange={(e) => setFilter(e.value)}
              options={["Daily", "Weekly", "Monthly", "All Time"]}
            />
          </Flex>
          <Button
            variant="solid"
            bg="brand.900"
            color="white"
            rightIcon={<ChevronDownIcon />}
            isLoading={loading}
            loadingText="Generating"
            onClick={reportHandler}
          >
            Generate Report
          </Button>
        </Flex>
        <AreaChart name="Salary" data={Object.values(data)} />
      </Flex>
    </Modal>
  );
}
