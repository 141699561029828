import { Flex, Card, Text, CardBody, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { getKPI } from "../../../../util/http";
import KPIHistory from "./KPIHistory";

export default function KPI({ w }) {
  const [kpi, setKpi] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getKPI();
        setKpi(response);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {show && <KPIHistory show={setShow} />}
      <Card
        boxShadow="xl"
        borderRadius="1.4rem"
        w={{ lg: w ? w : "30%", md: "45%", base: "90%" }}
        bg="white"
        mx="auto"
      >
        <CardBody>
          <Flex w="100%" align="start" direction="column" gap="0.5rem">
            <Flex w="100%" align="center" justify="space-between">
              <Text>KPI</Text>
              <Heading size="md" color="#000">
                <AiOutlineArrowDown
                  onClick={() => setShow(true)}
                  fontSize={20}
                  cursor="pointer"
                />
              </Heading>
            </Flex>
            <Flex w="100%" align="start" justify="space-between" gap="1rem">
              <Flex
                w="100%"
                align="start"
                direction="column"
                justify="space-between"
              >
                <Heading size="xs" color="#333">
                  Total
                </Heading>
                <Heading size="sm" fontWeight="bold" color="#333">
                  {kpi?.kpi_point}
                </Heading>
              </Flex>
              <Flex
                w="100%"
                align="start"
                direction="column"
                justify="space-between"
              >
                <Heading size="xs" color="#333">
                  Positive
                </Heading>
                <Heading size="sm" fontWeight="bold" color="green.600">
                  {kpi?.positive_points}
                </Heading>
              </Flex>
              <Flex
                w="100%"
                align="start"
                direction="column"
                justify="space-between"
              >
                <Heading size="xs" color="#333">
                  Negative
                </Heading>
                <Heading size="sm" color="red.600">
                  {kpi?.negative_points}
                </Heading>
              </Flex>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    </>
  );
}
