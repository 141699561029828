import React from "react";
import DashboardReusable from "../UI/DashboardReusable";
import SettingContainer from "./SettingContainer";
import NewSettingContainer from "./new-setting/NewSettingContainer";

export default function Setting() {
  return (
    <DashboardReusable>
      <NewSettingContainer />
      {false && <SettingContainer />}
    </DashboardReusable>
  );
}
