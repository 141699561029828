import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { getClient, getInvoices } from "../../../util/http";
import AllInvoiceContainer from "./AllInvoiceContainer";
import RetainershipContainer from "./retainership/RetainershipContainer";
import { AuthContext } from "../../../util/context";
import { getStoredItem } from "../../../util/lib";

export default function InvoiceTab() {
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(9);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const lastIndex = currentPage * perPage;
  const firstIndex = lastIndex - perPage;

  const { user } = useContext(AuthContext);
  const userType = getStoredItem("user-type");

  const canViewRetainer =
    user?.job_role?.toLowerCase() === "seed" ||
    user?.job_role?.toLowerCase() === "accountant";

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        let response;
        if (userType === "client") {
          response = await getClient(user?.client_id);
          setList(response?.client[0]?.invoices?.reverse());
        } else {
          response = await getInvoices();
          if (
            user?.job_role?.toLowerCase().includes("hod") ||
            user?.job_role?.toLowerCase() === "hop"
          ) {
            setList(
              response?.invoices
                ?.filter(
                  (el) =>
                    el.deleted_at === null &&
                    String(el?.user_id) === String(user?.id)
                )
                .reverse()
            );
          } else {
            setList(
              response?.invoices
                ?.filter((el) => el.deleted_at === null)
                .reverse()
            );
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    fetchData();
  }, [user]);

  useEffect(() => {
    setTotal(list?.length);
  }, [list]);

  const invoiceList = list?.slice(firstIndex, lastIndex);
  return (
    <>
      {userType === "employee" ? (
        <Tabs variant="soft-rounded" colorScheme="blue" w="100%">
          <TabList>
            <Tab>Invoice</Tab>
            {canViewRetainer && <Tab>Retainership</Tab>}
          </TabList>

          <TabPanels>
            <TabPanel>
              <AllInvoiceContainer
                setList={setList}
                list={invoiceList}
                total={total}
                loading={loading}
                perPage={perPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </TabPanel>
            {canViewRetainer && (
              <TabPanel>
                <RetainershipContainer />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      ) : (
        <AllInvoiceContainer
          setList={setList}
          list={invoiceList}
          total={total}
          perPage={perPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
}
