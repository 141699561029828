import { Flex, Box, Button, useToast } from "@chakra-ui/react";
import ClientPersonalDetail from "./ClientPersonalDetail";
import Address from "../../employee/create employee/Address";
import ContactPerson from "./ContactPerson";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { createClient } from "../../../util/http";
import PhoneNumberInput from "./PhoneNumber";


export default function ClientDetail() {
  const params = useParams();
  const toast = useToast();
  const { type } = params;
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [state, setState] = useState("");
  const [code, setCode] = useState("");
  const [image, setImage] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessType, setBusinessType] = useState("");

  const [kinFirstName, setKinFirstName] = useState("");
  const [kinLastName, setKinLastName] = useState("");
  const [kinEmail, setKinEmail] = useState("");
  const [kinPhone, setKinPhone] = useState("");
  const [kinAddress1, setKinAddress1] = useState("");
  const [kinAddress2, setKinAddress2] = useState("");
  const [kinCity, setKinCity] = useState("");
  const [kinCountry, setKinCountry] = useState("");
  const [kinState, setKinState] = useState("");
  const [kinCode, setKinCode] = useState("");
  const [loading, setLoading] = useState(false);

  

  async function createHandler() {
    let data;

    const validateCorporate =
      !businessName || !businessType || !email || !phone;

    const validateIndividual = !firstName || !lastName || !email || !phone;

    if (type.toLowerCase() === "corporate" && validateCorporate) {
      toast({
        title: `Client details missing`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (type.toLowerCase() === "individual" && validateIndividual) {
      toast({
        title: `Client details missing`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (type.toLowerCase() === "corporate") {
      data = {
        business_name: businessName,
        business_type: businessType,
        email,
        phone_number: phone,
        address1,
        address2,
        city,
        state,
        country,
        image,

        client_type: "corporate",
        postal_code: code,
        contact_email: kinEmail,
        contact_first_name: kinFirstName,
        contact_last_name: kinLastName,
        contact_phone_number: kinPhone,
        contact_country: kinCountry,
        contact_city: kinCity,
        contact_state: kinState,
        contact_postal_code: kinCode,
        contact_address1: kinAddress1,
        contact_address2: kinAddress2,
      };
    } else {
      data = {
        first_name: firstName,
        last_name: lastName,
        image,
        email,
        phone_number: phone,
        address1,
        client_type: "individual",
        address2,
        city,
        state,
        country,
        postal_code: code,
        contact_email: kinEmail,
        contact_first_name: kinFirstName,
        contact_last_name: kinLastName,
        contact_phone_number: kinPhone,
        contact_country: kinCountry,
        contact_city: kinCity,
        contact_state: kinState,
        contact_postal_code: kinCode,
        contact_address1: kinAddress1,
        contact_address2: kinAddress2,
      };
    }



    try {
      setLoading(true);
      await createClient(data);
      setLoading(false);
      navigate("/client");
      toast({
        title: `Client created`,
        description: `You have successfully created a client`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });

    } catch (error) {
      setLoading(false);
      console.log(error);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Flex
      align="center"
      height={{ lg: "80vh", md: "100%", base: "100%" }}
      w="100%"
      p="1rem"
      direction="column"
    >
      <Box
        w="100%"
        overflow="scroll"
        // height={{ lg: "70vh", md: "100%", base: "100%" }}
      >
        <ClientPersonalDetail
          setCountry={setCountry}
          setPhone={setPhone}
          setEmail={setEmail}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setImage={setImage}
          setBusinessName={setBusinessName}
          setBusinessType={setBusinessType}

        />
        <Address
          setAddress1={setAddress1}
          setAddress2={setAddress2}
          setCity={setCity}
          setCode={setCode}
          setCountry={setCountry}
          setState={setState}
          country={country}
          title={
            type.toLowerCase() === "corporate" ? "Business Address" : "Address"
          }
        />
        <ContactPerson
          setKinAddress1={setKinAddress1}
          setKinAddress2={setKinAddress2}
          setKinCity={setKinCity}
          setKinCode={setKinCode}
          setKinCountry={setKinCountry}
          setKinEmail={setKinEmail}
          setKinFirstName={setKinFirstName}
          setKinLastName={setKinLastName}
          setKinPhone={setKinPhone}
          setKinState={setKinState}
          country={kinCountry}
          title={
            type.toLowerCase() === "corporate"
              ? "Contact Person"
              : "Next of Kin"
          }
        />
      </Box>
      <Flex pt="1rem" w="100%" align="center" justify="space-between">
        <Button
          onClick={() => navigate("/client")}
          variant="outline"
          colorScheme="red"
        >
          Cancel
        </Button>
        <Button
          onClick={createHandler}
          variant="solid"
          bg="brand.900"
          color="white"
          isLoading={loading}
          loadingText="Processing"
        >
          Create
        </Button>
      </Flex>
    </Flex>
  );
}
