import { Flex } from "@chakra-ui/react";
import CreateClientHeader from "./CreateClientHeader";
import ClientDetail from "./ClientDetail";

export default function CreateClientContainer() {
  return (
    <Flex
      w="100%"
      align="center"
      direction="column"
      gap="1rem"
      p={{ lg: "2rem", md: "1rem", base: "6px" }}
    >
      <CreateClientHeader />
      <ClientDetail />
    </Flex>
  );
}
