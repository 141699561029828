import { Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../UI/ReusableText";

export default function QueryTitle() {
  return (
    <Flex
      bg="#EEEFF3"
      borderTopRadius="0.5rem"
      w="100%"
      px="0.5rem"
      py="1rem"
      align="center"
      justify="center"
    >
      <ReusableText
        text="Query ID"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="10%"
        align="center"
      />
      <ReusableText
        text="Date Initiated"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="10%"
        align="center"
      />
      <ReusableText
        text="Initiated by"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="20%"
        align="center"
      />
      <ReusableText
        text="Description"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="20%"
        align="center"
      />
      <ReusableText
        text="Issued to"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="20%"
        align="center"
      />

      <ReusableText
        w="10%"
        text="Decision"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        align="center"
      />
      <ReusableText
        w="10%"
        text="Actions"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        align="center"
      />
    </Flex>
  );
}
