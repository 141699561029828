import React from "react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";

export default function CreateEmployeeHeader() {
  return (
    <ReusableEmployeeHeader
      title="Create Employees"
      text="Add new employee details here"
    />
  );
}
