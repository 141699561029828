import React from "react";
import ViewEmployee from "../../employee/view employee/ViewEmployee";
import { AuthContext } from "../../../util/context";
import { useContext } from "react";
import Login from "../../auth/Login";
import Dashboard from "../../dashboard/Dashboard";

export default function ViewEmployeePage() {
  let view;

  const { isAuthenticated, user, isUser } = useContext(AuthContext);
  const viewEmployee =
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "seed";

  if (viewEmployee) {
    view = <ViewEmployee />;
  } else {
    view = <Dashboard />;
  }
  return <>{isAuthenticated && isUser ? view : <Login />}</>;
}
