import React from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import CreateClientContainer from "./CreateClientContainer";

export default function CreateClient() {
  return (
    <DashboardReusable>
      <CreateClientContainer />
    </DashboardReusable>
  );
}
