import { Flex, Heading } from "@chakra-ui/react";
import React from "react";
import FolderList from "./FolderList";
import SmallSearch from "../UI/SmallSearch";
import { getFolders } from "../../util/http";

export default function DocumentList({ setShow, list, setList }) {
  async function searchHandler(e) {
    try {
      const response = await getFolders();
      const filtered = response?.folders?.filter((el) =>
        el?.toLowerCase()?.includes(e.target.value.trim()?.toLowerCase())
      );
      setList(filtered);
    } catch (error) {
      console.log(error);
    }
  }                                                                                                           

  return (
    <Flex
      align="start"
      p="1rem"
      flex="1"
      height="75vh"
      w="100%"
      gap="1rem"
      direction="column"
      overflow="scroll"
    >
      <SmallSearch onSearch={searchHandler} w="90%" border="1px solid #ccc" />
      <Heading size="md" color="var(--container_color)" fontWeigt="bold">
        Folders
      </Heading>
      <FolderList list={list} setShow={setShow} />
    </Flex>
  );
}
