import { Flex } from "@chakra-ui/react";
import ReusableText from "../UI/ReusableText";

export default function AppraisalTitle() {
  return (
    <Flex
      bg="#EEEFF3"
      borderTopRadius="0.5rem"
      w="100%"
      px="0.5rem"
      py="1rem"
      align="center"
      justify="center"
    >
      <ReusableText
        text="Employee Name"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="25%"
        align="center"
      />
      <ReusableText
        text="Department"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="15%"
        align="center"
      />

      <ReusableText
        text="Appraisal Title"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="20%"
        align="center"
      />

      <ReusableText
        text="Date"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="15%"
        align="center"
      />
      <ReusableText
        text="Status"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="15%"
        align="center"
      />
      <ReusableText
        text="Action"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="10%"
        align="center"
      />
    </Flex>
  );
}
