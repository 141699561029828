import { Flex, useToast } from "@chakra-ui/react";
import BriefHeader from "./BriefHeader";
import BriefSubHeader from "./BriefSubHeader";
import BriefTab from "./BriefTab";
import { useContext, useEffect, useState } from "react";
import { viewBrief } from "../../../util/http";
import { useParams } from "react-router-dom";
import { getStoredItem } from "../../../util/lib";
import { AuthContext } from "../../../util/context";
import EditBrief from "../view brief/EditBrief";
import AddTags from "../view brief/AddTags";
import UpdateTag from "../view brief/UpdateTag";

export default function NewBriefContainer() {
  const params = useParams();
  const { user } = useContext(AuthContext);
  const userType = getStoredItem("user-type");

  const [type, setType] = useState("update");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [brief, setBrief] = useState("");
  const [progress, setProgress] = useState(0);
  const toast = useToast();

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        setLoading(true);
        const response = await viewBrief(params.id);
        setLoading(false);
        setProgress(80);
        setProgress(100);
        setBrief(response.brief);
      } catch (error) {
        setLoading(false);
        setProgress(100);
        console.log(error);
      }
    }
    fetchData();
  }, [params]);
  return (
    <>
      <Flex w="100%" align="start" gap="1.5rem" direction="column">
        <BriefHeader
          brief={brief}
          show1={setShow1}
          show={setShowModal}
          show2={setShow}
          setType={setType}
        />
        <BriefSubHeader brief={brief} />
        <BriefTab brief={brief} />
      </Flex>
      {show && <UpdateTag briefId={brief?.id} show={setShow} />}
      {show1 && <AddTags briefId={brief?.brief_id} show={setShow1} />}
      {showModal && (
        <EditBrief editType={type} show={setShowModal} brief={brief} />
      )}
    </>
  );
}
