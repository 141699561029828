import React from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import ViewEmployeeContainer from "./ViewEmployeeContainer";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getEmployee } from "../../../util/http";
import Loader from "../../UI/Loader";
import NewViewEmployeeContainer from "./new-view-employee/NewViewEmployeeContainer";

export default function ViewEmployee() {
  const params = useParams();
  const [employee, setEmployee] = useState("");
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [salary, setSalary] = useState("");
  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        setLoading(true);
        const response = await getEmployee(params.id);
        setSalary(response.employee?.salary_breakdown);
        setLoading(false);
        setProgress(80);
        setProgress(100);
        setEmployee(response?.employee?.employee[0]);
      } catch (error) {
        setLoading(false);
        setProgress(100);
        console.log(error);
      }
    }
    fetchData();
  }, [params]);

  return (
    <DashboardReusable>
      <Loader progress={progress} setProgress={setProgress} />
      <NewViewEmployeeContainer salary={salary} employee={employee} />
      {false && <ViewEmployeeContainer loading={loading} employee={employee} />}
    </DashboardReusable>
  );
}
