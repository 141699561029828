import { Flex   } from "@chakra-ui/react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";
import BriefDetails from "./BriefDetails";
import SelectComponent2 from "../../UI/SelectComponent2";
import { useState, useEffect } from "react";
import { currency as currencyData } from "../../../util/currency";




export default function CreateBriefContainer() {
  const [currency, setCurrency] = useState({});
  const [currencies, setCurrencies] = useState([])
  const [currencyOptions, setCurrencyOptions] = useState([])

  

  useEffect(() => {
    //default
    const findCurrency = currencyData?.find(currency => {
      return (currency.Code === 'NGN')
    })

    if (findCurrency) {
      setCurrency({
        label: findCurrency.CountryName + ' (' + findCurrency.Currency + ')',
        value: findCurrency.Code,
        ...findCurrency
      })

    }

  }, [])


  useEffect(() => {
    //place the 5 top currencies at the top of the list, include naira
    const Codes = ['USD', 'NGN', 'GBP', 'EUR', 'JPY', 'CAD']
    //check Code in object and sort

    //place the currency code first on the list
    const sortedCurrencies = currencyData.sort((a, b) => {
      if (Codes.includes(a.Code) && !Codes.includes(b.Code)) return -1
      if (!Codes.includes(a.Code) && Codes.includes(b.Code)) return 1
      return 0
    })
    const options = sortedCurrencies.map(el => {
      return {
        label: el.CountryName + ' (' + el.Currency + ')',
        value: el.Code,
        ...el
      }
    })

    setCurrencyOptions(options)


  }, [])





  return (
    <Flex w="100%" align="start" direction="column" gap="1rem" p="2rem">
      <Flex justify={'space-between'} align={'center'} w={'100%'}>

        <ReusableEmployeeHeader
          title="Create Briefs"
          text="Manage your brief and their details here"
        />
        <Flex justify={'flex-end'} w={{ base: '100%', lg: '20rem' }}>
          <SelectComponent2
            options={currencyOptions}
            label={'Select currency'}
            onChange={(e) => setCurrency(e)}
            placeholder="Select currency"
           value={currency}
           Flag = {currency?.Flag}


          />
        </Flex>
      </Flex>
      <BriefDetails currency={currency} />
    </Flex>
  );
}
