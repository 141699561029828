import { Avatar, Box, Flex } from "@chakra-ui/react";
import ReusableText from "../UI/ReusableText";

import { MdOutlineLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../util/context";

export default function MenuFooter({ display }) {
  const navigate = useNavigate();
  const ctx = useContext(AuthContext);

  const user = ctx?.user;

  async function logoutHandler() {
    navigate("/login");
    ctx.logout();
  }

  const newArr = user?.image_path ? user?.image_path?.split("/") : [];
  newArr?.splice(0, 3);
  const newPath = newArr?.join("/");

  return (
    <Box p="0.5rem" cursor="pointer" w="100%">
      <Flex
        w="100%"
        borderTop="1px solid #475467"
        align="center"
        justify="space-between"
        py="1rem"
        px="1rem"
      >
        <Flex w="20%">
          <Avatar
            size="sm"
            onClick={() => navigate("/profile")}
            name={`${user?.first_name} ${user?.last_name}`}
            src={`https://${newPath}`}
          />
        </Flex>
        {display === "flex" && (
          <>
            <Flex
              onClick={() => navigate("/profile")}
              w="100%"
              ml="1rem"
              align="center"
              direction="column"
              gap="0.3rem"
            >
              <ReusableText
                text={`${
                  user?.first_name || user?.last_name
                    ? user?.first_name?.slice(0, 20)
                    : user?.email?.slice(0, 15).padEnd(17, ".")
                }`}
                size="12px"
                weight="medium"
                color="var(--body_color)"
                align="center"
                transform={true}
              />
              <ReusableText
                text={user?.designation?.toUpperCase()}
                size="10px"
                weight="medium"
                color="var(--body_color)"
                align="center"
                transform={true}
              />
            </Flex>
            <MdOutlineLogout
              onClick={logoutHandler}
              // width="10%"
              fontSize="1.5rem"
              color="red"
            />
          </>
        )}
      </Flex>
    </Box>
  );
}
