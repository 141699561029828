import React, { Fragment, useContext } from "react";
import DashboardReusable from "../UI/DashboardReusable";
import EmployeeContainer from "./EmployeeContainer";
import ArchiveEmployee from "./ArchiveEmployee";
import { useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { getEmployees } from "../../util/http";
import { useState } from "react";
import Loader from "../UI/Loader";
import { AuthContext } from "../../util/context";

export default function Employee() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [list, setList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [employeePerPage, setEmployeePerPage] = useState(10);
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [loading, setLoading] = useState(false);

  const lastEmployeeIndex = currentPage * employeePerPage;
  const firstEmployeeIndex = lastEmployeeIndex - employeePerPage;
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        setLoading(true);
        const response = await getEmployees();
        setLoading(false);
        setProgress(80);
        setProgress(100);
        setList(
          response?.employees
            ?.filter((el) => el.deleted_at === null && el.id !== user.id)
            .reverse()
        );
      } catch (error) {
        setProgress(100);
        setLoading(false);
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    setTotalEmployee(list?.length);
  }, [list]);

  const employeeList = list?.slice(firstEmployeeIndex, lastEmployeeIndex);

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable>
        <EmployeeContainer
          list={employeeList}
          setList={setList}
          onOpen={onOpen}
          total={totalEmployee}
          perPage={employeePerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          loading={loading}
        />
      </DashboardReusable>
      <ArchiveEmployee isOpen={isOpen} onClose={onClose} />
    </Fragment>
  );
}
