import { Flex } from "@chakra-ui/react";
import React, { Fragment } from "react";
import QueryItem from "./QueryItem";
import LoadingSkeleton from "../UI/LoadingSkeleton";

export default function QueryList({ list, loading }) {
  const data = [1, 1, 1, 1, 1, 1];
  return (
    <Fragment>
      {loading ? (
        <>
          {data.map((el, i) => (
            <LoadingSkeleton key={i} lines={1} />
          ))}
        </>
      ) : (
        <Flex w="100%" direction="column" maxHeight="85%" overflow="scroll">
          {list?.map((item, i) => (
            <QueryItem item={item} key={i} />
          ))}
        </Flex>
      )}
    </Fragment>
  );
}
