import { useContext, useState } from "react";
import ReusableModal from "../UI/ReusableModal";
import { Button, Flex, useToast } from "@chakra-ui/react";
import SelectComponent from "../UI/Select";
import TextareaComponent from "../UI/Textarea";
import { updateRequisition , updateInternalRequisition } from "../../util/http";
import { AuthContext } from "../../util/context";

export default function RequisitionDetail({ isOpen, onClose, requisition }) {


  const toast = useToast();

  const [hocDecision, setHocDecision] = useState("");
  const [hocComment, setHocComment] = useState("");

  const [hodDecision, setHodDecision] = useState("");
  const [hodComment, setHodComment] = useState("");
  const [loading, setLoading] = useState(false);

  const { user } = useContext(AuthContext);

  const updateRequisitionPromise = requisition?.client_id ? updateRequisition : updateInternalRequisition


  async function updateStatus() {
    const data = {
      hoc_decision: hocDecision,
      hod_decision: hodDecision,
      hoc_comment: hocComment,
      hod_comment: hodComment,
      status: "",
    };

    try {
      setLoading(true);
      await updateRequisitionPromise(requisition?.requisition_id, data);
      window.location.reload();
      onClose();
      toast({
        title: `Update Successful`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      onClose();
      setLoading(false);
      toast({
        title: `Error`,
        description: `${error?.response?.data.message || "something went wrong"
          }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <ReusableModal
      title="Update Requisition Status"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Flex py="1rem" w="100%" gap="1rem" direction="column">
        {user?.job_role?.toLowerCase() === "hop" || user?.job_role?.toLowerCase().includes("seed") ? (
          <SelectComponent
            label="HOP Decision"
            onChange={(e) => setHocDecision(e.value)}
            options={["Select a decision here", "Query", "Approved"]}
          />
        ) : null}
        {user?.job_role?.toLowerCase() === "hod" || user?.job_role?.toLowerCase() === "seed" ? (
          <SelectComponent
            label="HOD Decision"
            onChange={(e) => setHodDecision(e.value)}
            options={[
              "Select a decision here",
              "Query",
              "Approved",
              "Disbursed",
            ]}
          />
        ) : null}
        {user?.job_role?.toLowerCase() === "hop" || user?.job_role?.toLowerCase().includes("seed") ? (
          <TextareaComponent
            label="HOP Comment"
            onChange={(e) => setHocComment(e.target.value)}
            placeholder="Enter a description..."
          />
        ) : null}
        {user?.job_role?.toLowerCase().includes("hod") || user?.job_role?.toLowerCase().includes("seed") ? (
          <TextareaComponent
            label="HOD Comment"
            onChange={(e) => setHodComment(e.target.value)}
            placeholder="Enter a description..."
          />
        ) : null}
        <Flex w="100%" align="end" justify="end">
          <Button
            variant="solid"
            bg="brand.900"
            color="white"
            isLoading={loading}
            loadingText="Processing"
            onClick={updateStatus}
          >
            Send
          </Button>
        </Flex>
      </Flex>
    </ReusableModal>
  );
}
