import { useParams } from "react-router-dom";
import DashboardReusable from "../../UI/DashboardReusable";
import LeaveDetailContainer from "./LeaveDetailContainer";
import { useEffect, useState } from "react";
import { getLeave } from "../../../util/http";

export default function LeaveDetail() {
  const [leave, setLeave] = useState("");
  const params = useParams();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getLeave(params.id);
        setLeave(response?.leave);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <DashboardReusable>
      <LeaveDetailContainer leave={leave} />
    </DashboardReusable>
  );
}
