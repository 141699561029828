import { Flex, Avatar, Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReusableText from "../UI/ReusableText";
import { getClient } from "../../util/http";

export default function ClientInvoiceDetails({ clientId, setBriefList }) {
  const [client, setClient] = useState("");

  useEffect(() => {
    async function fetchData() {
      if (!clientId) {
        return;
      }
      try {
        const response = await getClient(clientId);

        setBriefList(response?.client[0].briefs);
        setClient(response.client[0]);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [clientId]);

  return (
    <Flex w="100%" gap="1rem" align="center" justify="center">
      <Flex height="200px" justify="center" align="start">
        <Avatar name="john doe" src="" size="xl" />
      </Flex>
      <Flex w="100%" direction="column" gap="1rem" height="200px" align="start">
        <Flex direction="column" align="start" gap="0.5rem">
          <ReusableText
            text={client?.client_name}
            color="#101828"
            size="24px"
            weight="medium"
            transform={true}
          />
          <Flex align="center" gap="0.5rem" borderRadius="1rem">
            <Box
              w="0.3rem"
              height="0.3rem"
              borderRadius="100%"
              bg="green.600"
            ></Box>
            <ReusableText
              text="Active"
              color="#1FA159"
              size="12px"
              weight="medium"
            />
          </Flex>
        </Flex>
        <Flex align="center" w="100%">
          <Flex w="20%" direction="column" align="start" gap="0.5rem">
            <ReusableText
              text="Client ID"
              size="14px"
              color="#7F7E7E"
              weight="medium"
            />
            <ReusableText
              text={client?.client_id}
              size="16px"
              color="#19191A"
              weight="medium"
            />
          </Flex>

          <Flex w="20%" direction="column" align="start" gap="0.5rem">
            <ReusableText
              text="Location"
              size="14px"
              color="#7F7E7E"
              weight="medium"
            />
            <ReusableText
              text="Lagos, Nigeria"
              size="16px"
              color="#19191A"
              weight="medium"
            />
          </Flex>

          <Flex w="20%" direction="column" align="start" gap="0.5rem">
            <ReusableText
              text="Business Type"
              size="14px"
              color="#7F7E7E"
              weight="medium"
            />
            <ReusableText
              text={client?.client_type}
              size="16px"
              color="#19191A"
              weight="medium"
            />
          </Flex>

          <Flex w="20%" direction="column" align="start" gap="0.5rem">
            <ReusableText
              text="Email"
              size="14px"
              color="#7F7E7E"
              weight="medium"
            />
            <ReusableText
              text={client?.email?.slice(0, 20)}
              title={client?.email}
              size="16px"
              color="#2BA2DA"
              weight="medium"
            />
          </Flex>

          <Flex w="20%" direction="column" align="start" gap="0.5rem">
            <ReusableText
              text="Phone Number"
              size="14px"
              color="#7F7E7E"
              weight="medium"
            />
            <ReusableText
              text={client?.phone_number}
              size="16px"
              color="#2BA2DA"
              weight="medium"
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
