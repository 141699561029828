import { Avatar, Badge, Box, Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";

export default function ViewSubHeader({ client }) {
  return (
    <Flex w="100%" align="center" justify="space-between">
      <Flex py="1rem" w="100%" align="center" gap="1rem">
        <Avatar
          name={client?.client_name}
          size={{ lg: "xl", md: "lg", base: "lg" }}
        />
        <Flex height="100px" direction="column" align="start" gap="0.5rem">
          <ReusableText
            text={client?.client_name}
            color="var(--container_color)"
            size={{ lg: "24px", md: "18px", base: "16px" }}
            weight="medium"
            transform={true}
          />
          <Flex align="center" gap="0.5rem" borderRadius="1rem">
            <Box
              w="0.3rem"
              height="0.3rem"
              borderRadius="100%"
              bg="green.600"
            ></Box>
            <ReusableText
              text="Active"
              color="#1FA159"
              size="12px"
              weight="medium"
            />
          </Flex>
        </Flex>
      </Flex>
      {client?.retainership && (
        <Flex w="50%" align="end" direction="column" gap="6px">
          <Badge p="6px" color="green.600" bg="green.200">
            Retainership
          </Badge>
          <ReusableText
            text={`Due Date: ${client?.retainership?.valid_till}`}
            color="var(--container_color)"
            size={{ lg: "12px", md: "10px", base: "8px" }}
            weight="medium"
          />

          <ReusableText
            text={`Amount Paid: ₦${new Intl.NumberFormat().format(
              client?.retainership?.amount
            )}.00`}
            color="var(--container_color)"
            size={{ lg: "12px", md: "10px", base: "8px" }}
            weight="medium"
          />
          <ReusableText
            text={`Available Balance:  ₦${new Intl.NumberFormat().format(
              client?.retainership?.available_balance
            )}.00`}
            color="var(--container_color)"
            size={{ lg: "12px", md: "10px", base: "8px" }}
            weight="medium"
          />
        </Flex>
      )}
    </Flex>
  );
}
