import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Avatar,
} from "@chakra-ui/react";
import { useContext } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MdOutlineDateRange } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../util/context";
import DarkMode from "../UI/DarkMode";

export default function DashboardHeader() {
  const today = new Date();
  const hour = today.getHours();
  const navigate = useNavigate();
  const ctx = useContext(AuthContext);

  async function logoutHandler() {
    navigate("/login");
    ctx.logout();
  }

  const newArr = ctx?.user?.image_path?.split("/");
  newArr?.splice(0, 3);
  const newPath = newArr?.join("/");

  return (
    <Flex
      w="100%"
      align="center"
      borderBottom="1px solid #ccc"
      justify="space-between"
      bg="var(--container)"
      p="1rem"
      boxShadow="sm"
    >
      <Flex align="start" direction="column">
        <Heading size="md" color="var(--container_color)" fontWeight="bold">
          Hello {ctx?.user?.first_name}👋
        </Heading>
        <Heading size="sm" color="var(--container_color)" fontWeight="medium">
          Good{" "}
          {hour < 12
            ? "Morning"
            : hour > 12 && hour < 17
            ? "Afternoon"
            : "Evening"}
        </Heading>
      </Flex>
      <Flex align="center" gap="1rem">
        <Flex align="center" gap="1rem">
          <AiOutlineClockCircle color="var(--container_color)" size={20} />
          <Heading size="xs" fontWeight="normal" color="var(--container_color)">
            {new Date().toLocaleTimeString()}
          </Heading>
          <MdOutlineDateRange color="var(--container_color)" size={20} />
          <Heading size="xs" fontWeight="normal" color="var(--container_color)">
            {new Date().toLocaleDateString()}
          </Heading>
        </Flex>
        <DarkMode />

        <Menu>
          <MenuButton
            bg="var(--container)"
            _hover={{ bg: "transparent" }}
            _expanded={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            <Flex align="center" gap="1rem">
              <Avatar
                sze="xs"
                src={`https://${newPath}`}
                name={`${ctx?.user?.first_name} ${ctx?.user?.last_name}`}
              />
              <Flex align="start" direction="column">
                <Heading
                  size="sm"
                  color="var(--container_color)"
                  fontWeight="medium"
                >
                  {ctx?.user?.first_name?.slice(0, 15)}
                </Heading>

                <Heading
                  size="xs"
                  color="var(--container_color)"
                  fontWeight="normal"
                  textTransform="capitalize"
                >
                  {ctx?.user?.designation?.toUpperCase()}
                </Heading>
              </Flex>
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
            <MenuItem onClick={logoutHandler} colorScheme="red">
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
}
