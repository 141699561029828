import { Flex } from "@chakra-ui/react";
import React from "react";
import PermissionItem from "./PermissionItem";

export default function PermissionList({ list }) {
  return (
    <Flex border="1px solid #ccc" w="100%" align="start" direction="column">
      {list?.map((el, i) => (
        <PermissionItem key={i} item={el} />
      ))}
    </Flex>
  );
}
