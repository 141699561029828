import React from "react";
import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import InputComponent from "../../UI/Input";
import SelectComponent from "../../UI/Select";
import TextareaComponent from "../../UI/Textarea";
import { useState } from "react";
import {
  addCommentBrief,
  getEmployees,
  listDepartments,
} from "../../../util/http";
import { useEffect } from "react";
import Modal from "../../overlay/Modal";
import Overlay from "../../overlay/OverLay";
import { AiOutlineClose } from "react-icons/ai";
import ReusableText from "../../UI/ReusableText";

export default function EditBrief({ show, brief, editType }) {
  const [title, setTitle] = useState(brief?.brief_title);
  const [type, setType] = useState(brief?.brief_type);
  const [lawyer, setLawyer] = useState([]);
  const [description, setDescription] = useState(brief?.description);
  const [max, setMax] = useState(brief?.maximum_spend);
  const [status, setStatus] = useState("");
  const [empNames, setEmpNames] = useState([]);
  const [empDetail, setEmpDetail] = useState([]);
  const [dept, setDept] = useState([]);
  const [assigned, setAssigned] = useState([]);
  const [unAssigned, setUnassigned] = useState([]);
  const [assignedId, setAssignedId] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await listDepartments();
        setDept(
          response?.departments?.map(
            (el) =>
              el?.department?.charAt(0).toUpperCase() +
              el?.department?.toLowerCase().slice(1)
          )
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    async function fetchData() {
      try {
        const employeeResponse = await getEmployees();
        const employeeObj = employeeResponse?.employees
          ?.filter((el) => el.deleted_at === null)
          .map((el) => {
            return { name: `${el.first_name} ${el.last_name}`, id: el.id };
          });
        const employeeNames = employeeObj?.map((el) => el.name);
        setEmpDetail(employeeObj);
        setEmpNames(employeeNames);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const briefLawyers = brief?.lawyer && JSON.parse(brief?.lawyer);
    setAssigned(briefLawyers);
  }, []);

  useEffect(() => {
    const briefLawyersId = brief?.lawyer_id && JSON.parse(brief?.lawyer_id);
    setAssignedId(briefLawyersId);
  }, []);

  useEffect(() => {
    const arr1 = empNames?.filter((el) => assigned?.indexOf(el) === -1);
    const arr2 = assigned?.filter((el) => empNames?.indexOf(el) === -1);

    const uniqueEmp = arr1?.concat(arr2);
    setUnassigned(uniqueEmp);
  }, [assigned, empNames]);

  function handleDelete(i) {
    const deleteVal = [...assigned];
    const ids = [...assignedId];
    deleteVal.splice(i, 1);
    ids.splice(i, 1);
    setAssignedId(ids);
    setAssigned(deleteVal);
  }

  async function editBriefHandler() {
    const uniqueArray = lawyer?.filter(function (item, i) {
      return lawyer.indexOf(item) === i;
    });
    const lawyer_id = uniqueArray.map((el) => {
      let arr = [];
      for (let key in empDetail) {
        if (empDetail[key]?.name?.toLowerCase() === el?.toLowerCase()) {
          arr.push(empDetail[key]?.id);
        }
      }
      return arr;
    });

    const data = {
      brief_title: title ? title : brief?.brief_title,
      brief_type: type ? type : brief?.brief_type,
      lawyer: lawyer?.length > 0 ? uniqueArray : assigned,
      lawyer_id:
        lawyer?.length > 0
          ? lawyer_id?.flatMap((el) => String(el))
          : assignedId,
      description: description ? description : brief?.description,
      status: status ? status : brief?.status,
      maximum_spend: max ? max : brief?.maximum_spend,
    };

    try {
      setLoading(true);
      await addCommentBrief(data, brief?.brief_id);
      show(false);
      toast({
        title: `Update Successful`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      show(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Overlay hide={() => show(false)} />
      <Modal hide={() => show(false)}>
        <Flex align="start" direction="column" gap="1rem" py="2rem">
          <Box
            onClick={() => show(false)}
            position="absolute"
            top="0"
            right="0"
            p="1rem"
          >
            <AiOutlineClose fontSize={24} color="red" cursor="pointer" />
          </Box>
          <ReusableText
            text="Edit Brief"
            align="start"
            size="24px"
            weight="bold"
            color="#333"
          />
          {editType === "update" && (
            <InputComponent
              onChange={(e) => setTitle(e.target.value)}
              label="Brief Title"
              placeholder="Enter Brief Title"
              value={title}
            />
          )}

          {editType === "status" && (
            <Flex align="start" w="100%" direction="column">
              <SelectComponent
                label="Brief Status"
                onChange={(e) => setStatus(e.value)}
                options={
                  brief?.status?.toLowerCase() === "draft"
                    ? ["Select brief status", "Pending"]
                    : ["Select brief Status", "Completed"]
                }
              />
              <ReusableText
                text={`Current Brief Status: ${brief?.status}`}
                align="start"
                size="14px"
                weight="medium"
                color="#333"
              />
            </Flex>
          )}

          {editType === "update" && (
            <InputComponent
              label="Maximum Spend"
              placeholder="Enter maximum spend"
              value={max}
              onChange={(e) => {
                if (e.target.value.length > 3 || +e.target.value > 100) {
                  toast({
                    title: "Maximum spend cannot be greater than 100",
                    description: "",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right",
                  });
                  setMax("");
                } else {
                  setMax(e.target.value);
                }
              }}
            />
          )}
          {editType === "update" && (
            <Flex align="start" w="100%" direction="column">
              <SelectComponent
                label="Change Brief Type"
                onChange={(e) => setType(e.value)}
                options={dept || []}
              />
              <ReusableText
                text={`Current Brief Type: ${brief?.brief_type}`}
                align="start"
                size="14px"
                weight="medium"
                color="#333"
              />
            </Flex>
          )}
          {editType === "update" && (
            <TextareaComponent
              onChange={(e) => setDescription(e.target.value)}
              label="Brief Description"
              value={description}
            />
          )}
          {editType === "update" && (
            <SelectComponent
              onChange={(e) => {
                const lawyers = e?.map((el) => el.value);
                setLawyer((prev) => [...assigned, ...lawyers]);
              }}
              options={unAssigned || []}
              label="Reassign Lawyer"
              multiple={true}
            />
          )}
          {editType === "update" && (
            <Flex align="start" w="100%" direction="column">
              <ReusableText
                text="Assigned Lawyers"
                align="start"
                size="16px"
                weight="medium"
                color="#333"
              />
              <Flex w="100%" align="center" gap="1rem" wrap="wrap">
                {assigned?.map((el, i) => (
                  <Box key={i} bg="#ccc" p="3px" position="relative">
                    <ReusableText
                      text={el}
                      key={i}
                      size="14px"
                      wweight="normal"
                      color="#333"
                      bg="#ccc"
                      transform={true}
                      p="3px"
                    />
                    <Button
                      onClick={() => {
                        handleDelete(i);
                      }}
                      position="absolute"
                      top="-4"
                      colorScheme="red"
                      variant="ghost"
                      right="-4"
                      p="0.1rem"
                    >
                      X
                    </Button>
                  </Box>
                ))}
              </Flex>
            </Flex>
          )}
          <Flex align="end" justify="end" w="100%">
            <Button
              variant="solid"
              bg="brand.900"
              color="white"
              isLoading={loading}
              loadingText="Processing"
              onClick={editBriefHandler}
            >
              Save Update
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
