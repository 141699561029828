import ReactDOM from "react-dom";
import { Fragment } from "react";
import classes from "./modal.module.css";

const Backdrop = ({ hide }) => {
  return <div onClick={hide} className="backdrop" />;
};
const ModalOverlay = ({ children }) => {
  return (
    <div className={classes.modal}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

const portalElement = document.getElementById("overlays");

const Modal = ({ hide, children }) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(<Backdrop hide={hide} />, portalElement)}
      {ReactDOM.createPortal(
        <ModalOverlay>{children}</ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default Modal;
