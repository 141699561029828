import { Button, Flex, Heading, useDisclosure } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import { AddIcon } from "@chakra-ui/icons";
import AddUpdate from "./AddUpdate";
import UpdateDetail from "./UpdateDetail";
import { useContext, useState } from "react";
import SalaryProgress from "../../employee/view employee/SalaryProgress";
import { useEffect } from "react";
import { getEmployeeHistory, listUpdates } from "../../../util/http";
import { AuthContext } from "../../../util/context";
import HistoryDetail from "./HistoryDetail";

export default function Updates({
  id,
  name,
  department,
  designation,
  showBtn = true,
  salary,
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [show, setShow] = useState(false);
  const [showSalary, setShowSalary] = useState(false);
  const [list, setList] = useState([]);
  const [detail, setDetail] = useState("");
  const [history, setHistory] = useState([]);
  const [historyDetail, setHistoryDetail] = useState("");
  const [showHistory, setShowHistory] = useState(false);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getEmployeeHistory(id);
        setHistory(response?.history);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await listUpdates(id);
        setList(response.document_updates);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [id]);

  function showPopup() {
    setShow(true);
  }

  return (
    <>
      <Flex w="100%" align="start" direction="column" gap="1.5rem">
        {showBtn && (
          <Flex w="100%" align="end" gap="1rem" justify="end">
            <Button
              variant="solid"
              bg="brand.900"
              color="white"
              leftIcon={<AddIcon />}
              onClick={onOpen}
            >
              Add
            </Button>
            <Button
              variant="outline"
              colorScheme="purple"
              onClick={() => setShowSalary(true)}
            >
              Salary Report
            </Button>
          </Flex>
        )}
        <Heading size="md" fontWeight="bold" color="var(--container_color)">
          Employee Updates
        </Heading>
        <>
          <Flex w="100%" align="start" direction="column">
            {list?.length > 0 ? (
              <>
                {list?.map((el, i) => (
                  <Flex
                    key={i}
                    w={{ lg: "80%", md: "100%", base: "100%" }}
                    align="center"
                    justify="space-between"
                    gap="1rem"
                    borderBottom="1px solid #ccc"
                    cursor="pointer"
                    py="8px"
                  >
                    <ReusableText
                      onClick={() => {
                        setDetail(el);
                        showPopup();
                      }}
                      text={el?.update_type}
                      size="15px"
                      weight="medium"
                      color="var(--container_color)"
                    />
                    <Button
                      onClick={() => {
                        setDetail(el);
                        showPopup();
                      }}
                      variant="ghost"
                      colorScheme="blue"
                    >
                      View
                    </Button>
                  </Flex>
                ))}
              </>
            ) : (
              <Flex
                w="100%"
                align="center"
                color="var(--container_color)"
                justify="center"
                h="20vh"
              >
                No Updates Found...
              </Flex>
            )}
          </Flex>
        </>

        <Heading size="md" fontWeight="bold" color="var(--container_color)">
          Employee History
        </Heading>
        <>
          <Flex w="100%" align="start" direction="column">
            {history?.length > 0 ? (
              <>
                {history?.map((el, i) => (
                  <Flex
                    key={i}
                    w={{ lg: "80%", md: "100%", base: "100%" }}
                    align="center"
                    justify="space-between"
                    gap="1rem"
                    borderBottom="1px solid #ccc"
                    cursor="pointer"
                    py="8px"
                  >
                    <ReusableText
                      onClick={() => {
                        setHistoryDetail(el);
                        setShowHistory(true);
                      }}
                      text={el?.reason}
                      size="15px"
                      weight="medium"
                      color="var(--container_color)"
                    />
                    <Button
                      onClick={() => {
                        setHistoryDetail(el);
                        setShowHistory(true);
                      }}
                      variant="ghost"
                      colorScheme="blue"
                    >
                      View
                    </Button>
                  </Flex>
                ))}
              </>
            ) : (
              <Flex
                w="100%"
                color="var(--container_color)"
                align="center"
                justify="center"
                h="20vh"
              >
                No History Found...
              </Flex>
            )}
          </Flex>
        </>
      </Flex>
      <AddUpdate id={user?.id} isOpen={isOpen} onClose={onClose} />
      {showHistory && (
        <HistoryDetail show={setShowHistory} detail={historyDetail} />
      )}
      {show && <UpdateDetail detail={detail} show={setShow} />}
      {showSalary && (
        <SalaryProgress
          id={id}
          name={name}
          department={department}
          designation={designation}
          show={setShowSalary}
          salary={salary}
        />
      )}
    </>
  );
}
