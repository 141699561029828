import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Flex,
} from "@chakra-ui/react";
import BriefOverview from "./BriefOverview";

export default function BriefTab({ brief }) {
  return (
    <Flex w="100%">
      <Tabs colorScheme="blue" w="100%" size="md" variant="enclosed">
        <TabList w="100%">
          <Tab>Overview</Tab>
        </TabList>

        <TabPanels w="100%">
          <TabPanel>
            <BriefOverview brief={brief} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
