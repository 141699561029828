import { Button, Divider, Flex } from "@chakra-ui/react";
import ReusableText from "../../../UI/ReusableText";
import { AiOutlineCloudDownload, AiOutlineClose } from "react-icons/ai";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { salaryReport } from "../../../../util/http";
import { useContext } from "react";
import { AuthContext } from "../../../../util/context";
import PayslipTemplate from "../../../UI/PayslipTemplate";
import DownloadPage from "../../../UI/DownloadPage";
import DeductSalary from "./DeductSalary";

export default function SalaryReport({ employee, salary }) {
  const slipRef = useRef();
  const [list, setList] = useState([]);
  const [showDownload, setShow] = useState(false);
  const [date, setDate] = useState("");
  const [showDeduct, setShowDeduct] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await salaryReport(employee?.id);
        setList(response?.report?.reverse());
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [employee]);
  return (
    <>
      {showDeduct && <DeductSalary id={employee?.id} show={setShowDeduct} />}
      <Flex w="100%" align="start" direction="column" gap="1.5rem" py="1rem">
        <Flex w="100%" align="center" justify="space-between">
          <ReusableText
            text={`${user?.first_name} Salary Report`}
            weight="bold"
            size="18px"
            color="var(--container_color)"
          />
          <Button
            variant="solid"
            bg="brand.900"
            onClick={() => {
              setShowDeduct(true);
            }}
            color="white"
          >
            Add Deduction
          </Button>
        </Flex>
        <Divider />
        <Flex w="100%" align="start" direction="column">
          <Flex w="100%" align="center" justify="space-between">
            <ReusableText
              color="var(--container_color)"
              text="Date"
              weight="medium"
              size="16px"
              w="33%"
            />
            <ReusableText
              color="var(--container_color)"
              text="Salary"
              weight="medium"
              size="16px"
              w="33%"
            />
            <ReusableText
              color="var(--container_color)"
              text="Action"
              weight="medium"
              size="16px"
              w="33%"
            />
          </Flex>
          {list?.length > 0 ? (
            <>
              {list?.map((el, i) => (
                <Flex
                  key={i}
                  borderBottom="1px solid #ccc"
                  py="0.5rem"
                  w="100%"
                  align="center"
                  justify="space-between"
                >
                  <Flex w="33%" align="start" direction="column" gap="0.2rem">
                    <ReusableText
                      text={new Date(el?.updated_at)?.toLocaleDateString()}
                      weight="medium"
                      size="16px"
                      color="var(--container_color)"
                    />
                  </Flex>
                  <Flex w="33%" align="start" direction="column" gap="0.2rem">
                    <ReusableText
                      text={`₦${new Intl.NumberFormat().format(el?.salary)}.00`}
                      weight="medium"
                      size="16px"
                      color="var(--container_color)"
                    />
                  </Flex>
                  <Flex
                    cursor="pointer"
                    w="33%"
                    align="start"
                    direction="column"
                    gap="0.2rem"
                  >
                    <Button
                      onClick={() => {
                        setDate(new Date(el.updated_at)?.toLocaleDateString());
                        setShow(true);
                      }}
                      variant="ghost"
                      colorScheme="green"
                    >
                      <AiOutlineCloudDownload color="var(--container_color)" />
                    </Button>
                  </Flex>
                </Flex>
              ))}
            </>
          ) : (
            <Flex
              w="100%"
              align="center"
              justify="center"
              h="20vh"
              color="var(--container_color)"
            >
              No Report Found...
            </Flex>
          )}
        </Flex>
      </Flex>
      {showDownload && (
        <>
          <Flex align="center" p="1rem" w="100" justify="space-between">
            <DownloadPage
              element={slipRef}
              name={`${employee?.first_name} ${employee?.last_name}`}
              setShow={setShow}
            />
            <Button colorScheme="red" onClick={() => setShow(false)}>
              <AiOutlineClose />
            </Button>
          </Flex>
          <Flex ref={slipRef} w="100%" align="center" justify="center">
            <PayslipTemplate
              name={`${employee?.first_name} ${employee?.last_name}`}
              designation={employee?.designation}
              date={date}
              salary={salary}
            />
          </Flex>
        </>
      )}
    </>
  );
}
