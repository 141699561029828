import React, { Fragment, useEffect, useState } from "react";
import DashboardReusable from "../UI/DashboardReusable";
import ClientContainer from "./ClientContainer";
import ArchiveEmployee from "../employee/ArchiveEmployee";
import { useDisclosure } from "@chakra-ui/react";
import { getClients } from "../../util/http";
import Loader from "../UI/Loader";

export default function Client() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [list, setList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [clientPerPage, setClientPerPage] = useState(6);
  const [totalClients, setTotalClients] = useState(0);

  const lastclientIndex = currentPage * clientPerPage;
  const firstclientIndex = lastclientIndex - clientPerPage;

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        setLoading(true);
        const response = await getClients();
        setLoading(false);
        setProgress(80);
        setProgress(100);
        setList(
          response?.clients?.filter((el) => el.deleted_at === null).reverse()
        );
      } catch (error) {
        setLoading(false);
        setProgress(100);
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    setTotalClients(list?.length);
  }, [list]);

  const clientList = list?.slice(firstclientIndex, lastclientIndex);

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable>
        <ClientContainer
          setList={setList}
          setProgress={setProgress}
          progress={progress}
          list={clientList}
          onOpen={onOpen}
          total={totalClients}
          perPage={clientPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          loading={loading}
        />
      </DashboardReusable>
      <ArchiveEmployee
        title="Delete Client?"
        text="Are you sure you would like to delete Noah Orlando? Project history with Noah Orlando will be deleted as well."
        isOpen={isOpen}
        onClose={onClose}
        modalTitle="Client Deleted"
        modalText="You have successfully deleted client CID2303/0085"
      />
    </Fragment>
  );
}
