import { FormControl, FormLabel  , Flex  , Image} from "@chakra-ui/react";

import Select from "react-select";

export default function SelectComponent2({
  label,
  onChange,
  options,
  value,
  multiple = false,
  required = true,
  defaultValue = '' ,
  Flag,
  isClearable ,
}) {


  return (
    <>
      <FormControl isRequired={required}>
        <Flex align={'center'}>
        <FormLabel fontWeight="medium" color="var(--container_color)">
          {label}
        </FormLabel>
        {
          Flag ? <Image src={Flag} w={'1rem'} h={'1rem'} /> : null
        }
        </Flex>
        <Select
          styles={{ textTransform: "capitalize" }}
          options={options}
          isSearchable={true}
          isMulti={multiple}
          value={value}
          onChange={onChange}
          isClearable = {isClearable}
          defaultValue={defaultValue}


          // theme={(theme) => ({
          //   ...theme,
          //   borderRadius: 0,
          //   colors: {
          //     ...theme.colors,
          //     primary25: "#364273",
          //     primary: "#364273",
          //   },
          // })}
        />
      </FormControl>
    </>
  );
}
