import { Avatar, Badge, Flex, Heading } from "@chakra-ui/react";

export default function BriefSubHeader({ brief }) {
  const team = brief?.lawyer && JSON.parse(brief?.lawyer);

  return (
    <Flex
      w="100%"
      align="center"
      gap="1rem"
      bg="#fff"
      h="200px"
      borderRadius={16}
      boxShadow="lg"
      p="1rem"
    >
      <Avatar size="2xl" name={brief?.brief_title} />
      <Flex align="start" w="100%" gap="1rem" direction="column">
        <Heading
          textTransform="capitalize"
          size="md"
          color="#222"
          fontWeight="bold"
        >
          {brief?.brief_title}
        </Heading>
        <Flex w="100%" align="center" gap="1.5rem">
          <Flex w="30%" align="start" gap="1rem" direction="column">
            <Flex w="100%" align="center" justify="space-between">
              <Heading size="sm" color="#333" fontWeight="bold">
                Progress
              </Heading>
              <Heading
                size="sm"
                color={
                  brief?.status?.toLowerCase() === "draft"
                    ? "yellow.400"
                    : brief?.status?.toLowerCase() === "pending"
                    ? "gray.400"
                    : brief?.status?.toLowerCase() === "active"
                    ? "orange.400"
                    : "green.400"
                }
                fontWeight="bold"
              >
                {brief?.status?.toLowerCase() === "draft"
                  ? "10%"
                  : brief?.status?.toLowerCase() === "pending"
                  ? "30%"
                  : brief?.status?.toLowerCase() === "active"
                  ? "70%"
                  : "100%"}
              </Heading>
            </Flex>
            <Flex
              w="100%"
              height="15px"
              align="center"
              borderRadius={24}
              bg={
                brief?.status?.toLowerCase() === "draft"
                  ? "yellow.100"
                  : brief?.status?.toLowerCase() === "pending"
                  ? "gray.100"
                  : brief?.status?.toLowerCase() === "active"
                  ? "orange.100"
                  : "green.100"
              }
            >
              <Flex
                w={
                  brief?.status?.toLowerCase() === "draft"
                    ? "10%"
                    : brief?.status?.toLowerCase() === "pending"
                    ? "30%"
                    : brief?.status?.toLowerCase() === "active"
                    ? "70%"
                    : "100%"
                }
                bg={
                  brief?.status?.toLowerCase() === "draft"
                    ? "yellow.400"
                    : brief?.status?.toLowerCase() === "pending"
                    ? "gray.400"
                    : brief?.status?.toLowerCase() === "active"
                    ? "orange.400"
                    : "green.400"
                }
                borderRadius={24}
                h="15px"
              ></Flex>
            </Flex>
          </Flex>
          <Flex align="start" direction="column" gap="1rem">
            <Heading size="sm" color="#333" fontWeight="bold">
              Status
            </Heading>
            <Badge
              colorScheme={
                brief?.status?.toLowerCase() === "pending"
                  ? "gray"
                  : brief?.status?.toLowerCase() === "completed"
                  ? "green"
                  : brief?.status?.toLowerCase() === "draft"
                  ? "red"
                  : "yellow"
              }
            >
              {brief?.status}
            </Badge>
          </Flex>

          <Flex align="start" direction="column" gap="1rem">
            <Heading size="sm" color="#333" fontWeight="bold">
              Team
            </Heading>
            <Heading size="sm" color="#333" fontWeight="normal">
              {team?.length}
            </Heading>
          </Flex>

          <Flex align="start" direction="column" gap="1rem">
            <Heading size="sm" color="#333" fontWeight="bold">
              Brief Type
            </Heading>
            <Heading size="sm" color="#333" fontWeight="normal">
              {brief?.brief_type}
            </Heading>
          </Flex>

          <Flex align="start" direction="column" gap="1rem">
            <Heading size="sm" color="#333" fontWeight="bold">
              Brief Tag
            </Heading>
            <Heading
              textTransform="capitalize"
              size="sm"
              color="#333"
              fontWeight="normal"
            >
              {brief?.tag}
            </Heading>
          </Flex>
          <Flex align="start" direction="column" gap="1rem">
            <Heading size="sm" color="#333" fontWeight="bold">
              Client
            </Heading>
            <Heading
              textTransform="capitalize"
              size="sm"
              color="#333"
              fontWeight="normal"
            >
              {brief?.client}
            </Heading>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
