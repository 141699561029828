import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

export default function ReusableModal({
  children,
  isOpen,
  onClose,
  title,
  size,
  centered = true,
}) {
  return (
    <Modal
      isCentered={centered}
      size={size ? size : "2xl"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent bg="var(--modal)">
        <ModalHeader color="var(--container_color)">{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
}
