import { Flex } from "@chakra-ui/react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";
import QueryDetail from "../QueryDetail";

export default function ViewContainer({ query }) {
  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      h="100%"
      gap="1rem"
      p={{ lg: "2rem", md: "1rem", base: "6px" }}
    >
      <ReusableEmployeeHeader
        title={`Assigned Query - ${query?.assignee}`}
        text=""
      />
      <QueryDetail query={query} />
    </Flex>
  );
}
