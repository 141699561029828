import { Flex } from "@chakra-ui/react";
import React, { Fragment } from "react";
import BriefItem from "./BriefItem";
import LoadingSkeleton from "../UI/LoadingSkeleton";

export default function BriefList({ list, loading }) {
  const data = [1, 1, 1, 1, 1, 1];


  return (
    <Fragment>
      {loading ? (
        <>
          {data.map((el, i) => (
            <LoadingSkeleton key={i} lines={1} />
          ))}
        </>
      ) : (
        <Flex w="100%" align="center" direction="column">
          {list?.length > 0 ? (
            <>
              {list?.map((item, i) => (
                <BriefItem key={i} item={item} />
              ))}
            </>
          ) : (
            <Flex align="center" w="100%" justify="center" h="50vh">
              Empty brief
            </Flex>
          )}
        </Flex>
      )}
    </Fragment>
  );
}
