import React, { useContext } from "react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import { AddIcon } from "@chakra-ui/icons";
import { AiOutlineBank } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { AuthContext } from "../../util/context";

export default function ClientHeader() {
  const { user } = useContext(AuthContext);
  return (
    <ReusableEmployeeHeader
      showMenu={user?.job_role?.toLowerCase() !== "accountant"}
      title="Clients"
      text="Manage your Clients and their details here"
      btnText="Create Client"
      icon={user?.job_role?.toLowerCase() !== "accountant" && <AddIcon />}
      menu={[
        {
          name: "Corporate",
          icon: AiOutlineBank,
          link: `/client/create/corporate`,
        },
        {
          name: "Individual",
          icon: AiOutlineUser,
          link: `/client/create/individual`,
        },
      ]}
    />
  );
}
