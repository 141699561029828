import {
  Input,
  InputGroup,
  InputRightElement,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@chakra-ui/react";
import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

export default function PasswordInput({
  label,
  value,
  onChange,
  placeholder,
  showText = false,
}) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <FormControl isRequired>
      <FormLabel fontWeight="medium" color="var(--container_color)">
        {label}
      </FormLabel>
      <InputGroup bg="white" size="md">
        <Input
          color="#000"
          pr="4.5rem"
          type={show ? "text" : "password"}
          value={value}
          onChange={onChange}
          placeholder="Enter password"
          focusBorderColor="brand.900"
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={handleClick}>
            {show ? <ViewOffIcon color="blue" /> : <ViewIcon color="blue" />}
          </Button>
        </InputRightElement>
      </InputGroup>
      {showText && (
        <FormHelperText color="error.900">
          Incorrect password, kindly confirm
        </FormHelperText>
      )}
    </FormControl>
  );
}
