import {  useState } from "react";
import ReusableModal from "../UI/ReusableModal";
import { Button, Flex, useToast, Text } from "@chakra-ui/react";
import SelectComponent from "../UI/Select";

import TextareaComponent from "../UI/Textarea";
import { accountantConfirmPayment } from "../../util/http";




export default function AccountantComment({ isOpen, onClose, requisition }) {

    const toast = useToast();

    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);

    const [paymentMethod, setPaymentMethod] = useState('Select payment method');





    async function updateStatus() {
        const data = {
            "requisition_id": requisition?.requisition_id,
            "comment": comment,
            "disburse_method": paymentMethod?.toLocaleLowerCase(),
        };

        if (!comment) {
            toast({
                title: `Error`,
                description: `Comment is required`,
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            return;
        }
        else if (!paymentMethod || paymentMethod === 'Select payment method') {
            toast({
                title: `Error`,
                description: `Payment method is required`,
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top-right",
            });
            return;

        }

        else {
            try {
                setLoading(true);
                await accountantConfirmPayment(data);
                window.location.reload();
                onClose();
                toast({
                    title: `Update Successful`,
                    description: ``,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right",
                });
                setLoading(false);
            } catch (error) {
                onClose();
                setLoading(false);
                toast({
                    title: `Error`,
                    description: `${error?.response?.data.message || "something went wrong"
                        }`,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right",
                });
            }

        }




    }

    return (
        <ReusableModal
            title="Confirm Payment"
            isOpen={isOpen}
            onClose={onClose}
        >
            <Text fontSize={'.875rem'} color={'red.500'}>
                Regarding the requisition, kindly confirm fund disbursement by submitting this form.
            </Text>

            <Flex py="1rem" w="100%" gap="1rem" direction="column">
                {/* <InputComponent label="Amount"
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    type="number"
                    placeholder="Enter amount"


                /> */}

                <SelectComponent
                    label="Payment method"
                    onChange={(e) => setPaymentMethod(e.value?.toLowerCase())}


                    options={[
                        "Cash",
                        "Cheque",
                        "Bank Transfer",
                        "Others"

                    ]}
                    value={{label: paymentMethod }}



                />
                <TextareaComponent
                    label="Comment"
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Enter a description..."
                    value={comment}

                />








                <Flex w="100%" align="end" justify="end">
                    <Button
                        variant="solid"
                        bg="brand.900"
                        color="white"
                        isLoading={loading}
                        loadingText="Processing"
                        onClick={updateStatus}
                    >
                        Send
                    </Button>
                </Flex>
            </Flex>
        </ReusableModal>
    );
}
