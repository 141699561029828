import React from "react";
import ReusableText from "../../UI/ReusableText";
import { Flex } from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { AiOutlineCheck } from "react-icons/ai";

function Reusable({ title, show }) {
  return (
    <Flex
      w="50%"
      align="center"
      justify="space-between"
      borderBottom="1px solid #ccc"
      borderLeft="1px solid #ccc"
      p="6px"
    >
      <ReusableText
        text={title}
        color="var(--gray)"
        size="18px"
        weight="medium"
      />
      {show && (
        <Flex
          align="center"
          justify="center"
          p="0.4rem"
          bg="green.500"
          color="white"
          borderRadius="100%"
        >
          <AiOutlineCheck />
        </Flex>
      )}
    </Flex>
  );
}

export default function OverallPerformance({ total }) {
  const [score, setScore] = useState("");

  useEffect(() => {
    const score = total.reduce((acc, sum) => acc + sum, 0);
    setScore(score);
  }, [total]);

  return (
    <Flex w="100%" align="start" direction="column">
      <Flex
        border="1px solid #ccc"
        fontSize={18}
        fontWeight="bold"
        w="100%"
        p="0.4rem"
        align="start"
        color="var(--gray)"
      >
        OVERALL PERFORMANCE
      </Flex>
      <Flex border="1px solid #ccc" w="100%" align="start" wrap="wrap">
        <Reusable
          title="Excellent (90-100%)"
          show={score >= 90 && score <= 100}
        />
        <Reusable
          title="Satisfactory (71-89%)"
          show={score >= 71 && score <= 89}
        />
        <Reusable title="Average (50 -70%)" show={score >= 50 && score <= 70} />
        <Reusable
          title="Below Average (30%-49%)"
          show={score >= 30 && score <= 49}
        />
        <Reusable title="Unsatisfactory (Under 29%)" show={score < 29} />
      </Flex>
    </Flex>
  );
}
