import React, { useEffect, useState } from "react";
import ReusableModal from "../UI/ReusableModal";
import SelectComponent from "../UI/Select";
import TextareaComponent from "../UI/Textarea";
import { Flex, useToast, Button } from "@chakra-ui/react";
import FileComponent from "../UI/File";
import PreviewDocument from "../brief/create brief/PreviewDocument";
import InputComponent from "../UI/Input";
import { createQueries, getEmployees } from "../../util/http";
import { useContext } from "react";
import { AuthContext } from "../../util/context";

export default function CreateQuery({ isOpen, onClose }) {
  const toast = useToast();

  const [assigned, setAssigned] = useState("");
  const [level, setLevel] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [dueDate, setDueDate] = useState("");

  const [list, setList] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getEmployees();
        let employeeObj;
        if (
          user?.job_role?.toLowerCase()?.includes("hod") ||
          user?.job_role?.toLowerCase() === "hop"
        ) {
          employeeObj = response?.employees
            ?.filter(
              (el) =>
                el.deleted_at === null &&
                el?.department?.toLowerCase() !==
                  user?.department?.toLowerCase() &&
                el?.job_role?.toLowerCase() !== "seed"
            )
            .map((el) => {
              return {
                name: `${el.first_name} ${el.last_name}`,
                id: el.employee_id,
              };
            });
        } else {
          employeeObj = response?.employees
            ?.filter((el) => el.deleted_at === null)
            .map((el) => {
              return {
                name: `${el.first_name} ${el.last_name}`,
                id: el.employee_id,
              };
            });
        }

        setAllEmployees(employeeObj);

        const employeeNames = employeeObj?.map((el) => el.name);
        setList(employeeNames);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [user]);

  async function createQueryHandler() {
    const assignee_id = allEmployees?.find((el) => el.name == assigned)?.id;
    const data = {
      assignee: assigned,
      assignee_id,
      priority: level,
      description: description,
      "documents[]": files,
    };

    if (!assigned || !level) {
      toast({
        title: `Query details missing`,
        description: "",
        status: "warning",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await createQueries(data);
      setLoading(false);
      onClose();
      toast({
        title: `Query Issued to ${assigned}`,
        description: "",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <ReusableModal title="Query Form" isOpen={isOpen} onClose={onClose}>
      <Flex align="center" w="100%" direction="column" gap="1rem">
        <SelectComponent
          label="Select Employee"
          options={["Select employee to be assigned to this query", ...list]}
          onChange={(e) => setAssigned(e.value)}
        />
        <SelectComponent
          label="Priority level"
          options={["Select priority level", "High", "Low", "Medium"]}
          onChange={(e) => setLevel(e.value)}
        />
        {false && (
          <InputComponent
            type="date"
            label="Due Date"
            onChange={(e) => setDueDate(e.target.value)}
          />
        )}
        <TextareaComponent
          label="Query description"
          placeholder="Enter a description..."
          onChange={(e) => setDescription(e.target.value)}
        />
        <FileComponent
          onChange={(e) => setFiles((prev) => [e.target.files[0], ...prev])}
        />
        <PreviewDocument setFiles={setFiles} files={files} />
        <Flex w="100%" align="end" justify="end">
          <Button
            onClick={createQueryHandler}
            bg="brand.900"
            variant="solid"
            color="white"
            isLoading={loading}
            loadingText="Processing"
          >
            Send
          </Button>
        </Flex>
      </Flex>
    </ReusableModal>
  );
}
