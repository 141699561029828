import { Divider, Flex, Button } from "@chakra-ui/react";
import Modal from "../../overlay/Modal";
import ReusableText from "../../UI/ReusableText";
import { AiOutlineClose } from "react-icons/ai";

export default function HistoryDetail({ show, detail }) {
  function closePopup() {
    show(false);
  }
  return (
    <>
      <Modal hide={() => closePopup()}>
        <Flex
          w="100%"
          align="start"
          direction="column"
          py="1.5rem"
          gap="1.5rem"
        >
          <Flex w="100%" align="center" justify="space-between">
            <ReusableText
              text={detail?.reason}
              color="#333"
              weight="bold"
              size="18px"
            />
            <Button variant="ghost" onClick={closePopup} colorScheme="red">
              <AiOutlineClose />
            </Button>
          </Flex>
          <Divider />
          <Flex w="100%" align="center" justify="space-between">
            <Flex w="50%" align="start" direction="column" gap="0.3rem">
              <ReusableText text="Reason" size="16px" weight="medium" />
              <ReusableText text={detail?.reason} size="14px" weight="normal" />
            </Flex>
            <Flex w="50%" align="start" direction="column" gap="0.3rem">
              <ReusableText text="Date" size="16px" weight="medium" />
              <ReusableText
                text={new Date(detail?.created_at).toLocaleDateString()}
                size="14px"
                weight="normal"
              />
            </Flex>
          </Flex>
          <Flex w="100%" align="start" direction="column">
            <ReusableText text="Comment" size="16px" weight="medium" />
            <ReusableText text={detail?.comment} size="14px" weight="normal" />
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
