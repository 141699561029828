import React, { useEffect, useState, useContext, Fragment } from "react";
import AuthComponent from "../UI/AuthComponent";
import { Text, Heading, Flex, useToast, Button } from "@chakra-ui/react";

import ButtonComponent from "../UI/Button";
import { useNavigate } from "react-router-dom";
import PinInputComponent from "../UI/PinInput";
import { resendOtp, verifyLogin } from "../../util/http";
import { AuthContext } from "../../util/context";
import Loader from "../UI/Loader";
import { storeItem } from "../../util/lib";

export default function VerifyLogin() {
  const navigate = useNavigate();

  const toast = useToast();

  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");

  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");

  const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState("");

  const [loading, setLoading] = useState(false);
  const [inValid, setInValid] = useState(false);
  const [countDown, setCountdown] = useState(60);
  const [progress, setProgress] = useState(0);

  const [load, setLoad] = useState(false);

  const token = `${pin1}${pin2}${pin3}${pin4}${pin5}${pin6}`;

  const ctx = useContext(AuthContext);

  useEffect(() => {
    const timer = setInterval(() => {
      if (countDown === 0) {
        clearInterval(timer);
        return;
      }

      setCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [setCountdown, countDown]);

  useEffect(() => {
    if (token.length < 6) {
      setInValid(true);
      return;
    } else {
      setInValid(false);
    }
  }, [pin1, pin2, pin3, pin4, pin5, pin6]);

  async function verifyHandler() {
    const data = { pin: token };

    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const response = await verifyLogin(data);

      setProgress(80);
      setProgress(100);
      navigate("/");
      if (response.employee) {
        storeItem("user", response?.employee, 172800000);
        storeItem("user-type", "employee", 172800000);
        ctx.setUser(response?.employee);
      } else {
        storeItem("user", response?.client, 172800000);
        storeItem("user-type", "client", 172800000);
        ctx.setUser(response?.client);
      }
      setLoading(false);
    } catch (error) {
      setProgress(100);
      setLoading(false);
      setInValid(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function resendToken() {
    const email = localStorage.getItem("login-email");
    const data = { email };

    try {
      setLoad(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const response = await resendOtp(data);
      setProgress(80);
      setProgress(100);
      setLoad(false);
      console.log(response);
      toast({
        title: `Resend successful`,
        description: `OTP successfully resent`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setCountdown(60);
    } catch (error) {
      setLoad(false);
      setProgress(100);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <AuthComponent>
        <Heading
          textAlign="center"
          color="var(--container_color)"
          fontSize="24px"
          fontWeight="medium"
        >
          Verification
        </Heading>
        <Text
          fontSize="14px"
          color="var(--container_color)"
          fontWeight="light"
          textAlign="center"
        >
          Please enter the one-time password (OTP) that has been sent to your
          mail{" "}
        </Text>
        <PinInputComponent
          setPin1={setPin1}
          setPin2={setPin2}
          setPin3={setPin3}
          setPin4={setPin4}
          setPin5={setPin5}
          setPin6={setPin6}
          valid={inValid}
        />
        <ButtonComponent
          onClick={verifyHandler}
          title="Log in"
          width="80%"
          mx="auto"
          disabled={inValid}
          loading={loading}
        />
        <Flex align="center" direction="column" justify="center" gap="0.5rem">
          <Text color="var(--gray)" fontSize="16px" fontWeight="normal">
            Did not receive an email?
          </Text>
          <Button
            variant="ghost"
            onClick={resendToken}
            isDisabled={countDown !== 0}
            style={{ color: "#2BA2DA", fontWeight: "medium" }}
            to="/forgot-password"
            loadingText="Processing"
            isLoading={load}
          >
            Click here to resend in {countDown.toString().padStart(2, 0)}
          </Button>
        </Flex>
      </AuthComponent>
    </Fragment>
  );
}
