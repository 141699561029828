import { Flex, Box } from "@chakra-ui/react";
import InputComponent from "../../UI/Input";
import ReusableText from "../../UI/ReusableText";

export default function EditAddress({
  setAddress1,
  setAddress2,
  setCity,
  setState,
  setCountry,
  setCode,
  address1,
  address2,
  city,
  state,
  country,
  code,
}) {
  return (
    <Flex
      align="center"
      height={{ lg: "400px", md: "100%", base: "100%" }}
      borderBottom="1px solid #ccc"
      justify="space-between"
      w="100%"
      direction={{ lg: "row", md: "column", base: "column" }}
      gap={{ lg: "0rem", md: "1rem", base: "1rem" }}
    >
      <Box
        pt="2rem"
        w={{ lg: "30%", md: "100%", base: "100%" }}
        h={{ lg: "350px", md: "100%", base: "100%" }}
      >
        <ReusableText text="Address" size="20px" weight="600" color="#19191A" />
      </Box>

      <Flex align="center" direction="column" gap="2rem" w="100%">
        <Flex align="center" w="100%" justify="center" gap="1rem">
          <InputComponent
            placeholder="Enter address"
            label="Address Line 1"
            type="text"
            onChange={(e) => setAddress1(e.target.value)}
            value={address1}
            readOnly={true}
          />
          <InputComponent
            label="Address Line 2"
            placeholder="Enter address"
            type="text"
            value={address2}
            onChange={(e) => setAddress2(e.target.value)}
            readOnly={true}
          />
        </Flex>
        <Flex align="center" w="100%" justify="center" gap="1rem">
          <InputComponent
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder="Enter City"
            label="City"
            type="text"
            readOnly={true}
          />
          <InputComponent
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            placeholder="Enter Country"
            label="Country"
            type="text"
            readOnly={true}
          />
        </Flex>

        <Flex align="center" w="100%" justify="center" gap="1rem">
          <InputComponent
            placeholder="Enter State"
            label="State"
            type="text"
            value={state}
            onChange={(e) => setState(e.target.value)}
            readOnly={true}
          />
          <InputComponent
            placeholder="Enter Postal Code"
            label="Postal Code"
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            readOnly={true}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
