import { Flex, useToast } from "@chakra-ui/react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";
import { AddIcon } from "@chakra-ui/icons";
import ViewSubHeader from "./ViewSubHeader";
import ClientDetails from "./ClientDetails";
import Briefs from "./briefs/Briefs";
import Invoices from "./invoices/Invoices";
import { useNavigate } from "react-router-dom";
import { updateClient } from "../../../util/http";
import EditClient from "./EditClient";
import { useState } from "react";
import LoadingSkeleton from "../../UI/LoadingSkeleton";
import { useContext } from "react";
import { AuthContext } from "../../../util/context";

export default function ViewClientContainer({ client, loading }) {
  const navigate = useNavigate();
  const toast = useToast();
  const [show, setShow] = useState(false);
  const { user } = useContext(AuthContext);

  async function unarchiveHandler() {
    try {
      await updateClient(client.id, { is_banned: "0", deleted_at: null });
      navigate("/client");
      toast({
        title: `${client?.client_name} un-archived`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function unsuspendClientHandler() {
    try {
      await updateClient(client.id, { is_banned: "0" });
      navigate("/client");
      toast({
        title: `${client?.client_name} un-suspended`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  function showModal() {
    setShow(true);
  }

  return (
    <>
      <Flex
        w="100%"
        align="center"
        direction="column"
        gap="1rem"
        p={{ lg: "2rem", md: "1rem", base: "6px" }}
      >
        <ReusableEmployeeHeader
          title="Client Details"
          text="Manage your Clients and their details here"
          btnText="Add Brief"
          icon={user?.job_role?.toLowerCase() !== "accountant" && <AddIcon />}
          show={user?.job_role?.toLowerCase() !== "accountant"}
          showDottedMenu={true}
          onClick={() => navigate("/brief/create")}
          menu={
            client?.is_banned !== "0" && client?.deleted_at == null
              ? [
                  {
                    name: "Edit Client Details",
                    icon: "",
                    navigation: false,
                    onClick: showModal,
                  },
                  {
                    name: "Unsuspend Client",
                    icon: "",
                    navigation: false,
                    onClick: unsuspendClientHandler,
                  },
                ]
              : client?.is_banned !== "0" && client?.deleted_at != null
              ? [
                  {
                    name: "Edit Client Details",
                    icon: "",
                    navigation: false,
                    onClick: showModal,
                  },
                  {
                    name: "Unsuspend Client",
                    icon: "",
                    navigation: false,
                    onClick: unsuspendClientHandler,
                  },
                  {
                    name: "Unarchive client",
                    icon: "",
                    navigation: false,
                    onClick: unarchiveHandler,
                  },
                ]
              : client?.is_banned === "0" && client?.deleted_at != null
              ? [
                  {
                    name: "Edit Client Details",
                    icon: "",
                    navigation: false,
                    onClick: showModal,
                  },
                  {
                    name: "Unarchive client",
                    icon: "",
                    navigation: false,
                    onClick: unarchiveHandler,
                  },
                ]
              : [
                  {
                    name: "Edit Client Details",
                    icon: "",
                    navigation: false,
                    onClick: showModal,
                  },
                ]
          }
        />

        {loading ? (
          <LoadingSkeleton lines={20} />
        ) : (
          <>
            <ViewSubHeader client={client} />
            <ClientDetails client={client} />
            <Briefs brief={client?.briefs} />
            <Invoices invoice={client?.invoices} />
          </>
        )}
      </Flex>
      {show && <EditClient show={setShow} client={client} />}
    </>
  );
}
