import { Flex } from "@chakra-ui/react";
import React from "react";

import StaffReportCard from "./StaffReportCard";
import LoginDurationReportCard from "./LoginDurationReportCard";

import FinanceReportCard from "./FinanceReportCard";

import InvoiceReportCard from "./InvoiceReportCard";
import RequisitionReportCard from "./RequisitionReportCard";

export default function AccountantReportList({ onOpen }) {
    return (
        <Flex
            align="center"
            justify="space-between"
            w="100%"
            gap="1rem"
            wrap="wrap"
        >

            <StaffReportCard />
            {false && <LoginDurationReportCard />}
            <FinanceReportCard />

            <InvoiceReportCard />
            <RequisitionReportCard />
        </Flex>
    );
}
