import { Button, Flex } from "@chakra-ui/react";
import ReusableText from "../UI/ReusableText";
import { AiFillEdit } from "react-icons/ai";
import { useState } from "react";
import { useEffect } from "react";
import { listLeaveType } from "../../util/http";
import EditLeaveType from "./EditLeaveType";

export default function LeaveTypeList({ show1 }) {
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [name, setName] = useState({
    name: "",
    id: "",
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await listLeaveType();
        setList(response);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <Flex w="100%" align="start" direction="column" gap="1rem">
        <Flex w="100%" align="end" justify="end">
          <Button
            variant="solid"
            color="white"
            bg="brand.900"
            onClick={() => show1(true)}
          >
            Create Leave Type
          </Button>
        </Flex>
        <Flex align="center" w="100%" bg="var(--container)">
          <Flex
            // height="300px"
            align="start"
            direction="column"
            gap="1rem"
            w="100%"
            // overflow="scroll"
          >
            {list?.map((el) => {
              const roles = el?.job_role && JSON.parse(el?.job_role);
              return (
                <Flex
                  borderBottom="1px solid #ccc"
                  w="100%"
                  py="10px"
                  align="center"
                  justify="space-between"
                >
                  <ReusableText
                    text={el?.name}
                    title={el?.description}
                    transform={true}
                    color="var(--container_color)"
                    size="15px"
                    weight="medium"
                  />
                  <Flex maxWidth="60%" align="center" gap="1rem">
                    <Flex wrap="wrap" align="center" gap="0.5rem">
                      {roles?.map((el, i) => (
                        <ReusableText
                          text={el}
                          title={el}
                          transform={true}
                          color="var(--container_color)"
                          size="12px"
                          weight="medium"
                          key={i}
                        />
                      ))}
                    </Flex>
                    <Flex align="center" gap="1rem">
                      <Button
                        onClick={() => {
                          setName({
                            name: el?.name,
                            id: el?.id,
                            leave_count: el?.leave_count,
                            roles,
                          });
                          setShow(true);
                        }}
                        variant="ghost"
                        colorScheme="green"
                      >
                        <AiFillEdit
                          color="var(--container_color)"
                          size="18px"
                        />
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
      {show && <EditLeaveType leave={name} show={setShow} />}
    </>
  );
}
