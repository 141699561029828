import {
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from "@chakra-ui/react";
import { AiOutlineEdit } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { addCommentBrief } from "../../../util/http";
import { useContext } from "react";
import { AuthContext } from "../../../util/context";
import { getStoredItem } from "../../../util/lib";

export default function BriefHeader({ show, setType, show1, brief, show2 }) {
  const toast = useToast();
  const { user } = useContext(AuthContext);
  const userType = getStoredItem("user-type");

  async function publishBrief() {
    try {
      await addCommentBrief({ status: "pending" }, brief?.brief_id);
      window.location.reload();

      toast({
        title: `Brief Published`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function completeBrief() {
    try {
      await addCommentBrief({ status: "completed" }, brief?.brief_id);

      toast({
        title: `Brief Completed`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }
  return (
    <Flex w="100%" align="center" justify="space-between">
      <Heading size="md" color="var(--container_color)" fontWeight="bold">
        Brief Details
      </Heading>
      <Flex align="center" gap="1rem">
        {userType === "employee" && (
          <Button
            variant="solid"
            color="white"
            bg="brand.900"
            leftIcon={<AiOutlineEdit />}
            onClick={() => {
              setType("update");
              show(true);
            }}
          >
            Edit Brief
          </Button>
        )}
        <Menu>
          <MenuButton bg="white" as={Button}>
            <BsThreeDotsVertical color="blue" />
          </MenuButton>
          <MenuList>
            {userType === "employee" && (
              <MenuItem onClick={() => show1(true)}>Add tags</MenuItem>
            )}
            {userType === "employee" && (
              <MenuItem onClick={() => show2(true)}>
                Update Current brief tag
              </MenuItem>
            )}
            {brief?.status?.toLowerCase() === "draft" &&
              user?.job_role?.toLowerCase() !== "lawyer" &&
              userType === "employee" && (
                <MenuItem onClick={publishBrief}>Publish Brief</MenuItem>
              )}
            {brief?.status?.toLowerCase() === "active" &&
              userType === "employee" && (
                <MenuItem onClick={completeBrief}>Mark as completed</MenuItem>
              )}
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
}
