import { Flex } from "@chakra-ui/react";
import LeaveItem from "./LeaveItem";
import LoadingSkeleton from "../UI/LoadingSkeleton";

export default function LeaveList({ list, loading }) {
  const data = [1, 1, 1, 1, 1, 1];
  return (
    <>
      {loading ? (
        <>
          {data.map((el, i) => (
            <LoadingSkeleton key={i} lines={1} />
          ))}
        </>
      ) : (
        <Flex
          w="100%"
          direction="column"
          border="1px solid #ccc"
          borderTop="none"
          maxHeight="85%"
          overflow="scroll"
        >
          {list?.map((item, i) => (
            <LeaveItem item={item} path="leave" key={i} />
          ))}
        </Flex>
      )}
    </>
  );
}
