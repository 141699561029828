import { CloseButton, Divider, Flex, Heading } from "@chakra-ui/react";
import Modal from "../../../overlay/Modal";
import { useEffect } from "react";
import { getKPIHistory } from "../../../../util/http";
import { useState } from "react";

export default function KPIHistory({ show }) {
  const [points, setPoints] = useState([]);
  function closeMenu() {
    show(false);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getKPIHistory();

        setPoints([...response?.negative_points, ...response?.positive_points]);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <Modal hide={closeMenu}>
      <Flex w="100%" align="start" py="1rem" direction="column" gap="1rem">
        <Flex w="100%" align="center" justify="space-between">
          <Heading size="md" color="#333" fontWeight="bold">
            KPI History
          </Heading>
          <CloseButton onClick={closeMenu} colorScheme="red" />
        </Flex>
        <Divider />
        <Flex w="100%" align="center" justify="space-between">
          <Heading w="30%" size="sm" color="#333" fontWeight="bold">
            Date
          </Heading>
          <Heading w="20%" size="sm" color="#333" fontWeight="bold">
            Points
          </Heading>
          <Heading
            w="50%"
            textAlign="start"
            size="sm"
            color="#333"
            fontWeight="bold"
          >
            Reason
          </Heading>
        </Flex>
        <Divider />
        {points?.length > 0 ? (
          <Flex w="100%" align="start" direction="column">
            {points?.map((el, i) => (
              <Flex
                key={i}
                py="1rem"
                borderBottom="1px solid #ccc"
                w="100%"
                align="center"
                justify="space-between"
              >
                <Heading w="30%" size="sm" color="#333" fontWeight="normal">
                  {new Date(el?.date_awarded)?.toLocaleDateString()}{" "}
                  {new Date(el?.date_awarded)?.toLocaleTimeString()}
                </Heading>
                <Heading w="20%" size="sm" color="#333" fontWeight="normal">
                  {el.point}
                </Heading>
                <Heading
                  w="50%"
                  textAlign="start"
                  size="sm"
                  color="#333"
                  fontWeight="normal"
                  textTransform="capitalize"
                >
                  {el?.reason}
                </Heading>
              </Flex>
            ))}
          </Flex>
        ) : (
          <Flex w="100%" h="20vh" align="center" justify="center">
            No points awarded yet
          </Flex>
        )}
      </Flex>
    </Modal>
  );
}
