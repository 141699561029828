import React from "react";
import DashboardReusable from "../UI/DashboardReusable";
import DocumentContainer from "./DocumentContainer";
import { useEffect, useState } from "react";
import { getFolders } from "../../util/http";

export default function Document() {
  const [list, setList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getFolders();
        setList(response?.folders);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <DashboardReusable>
      <DocumentContainer setList={setList} list={list} />
    </DashboardReusable>
  );
}
