import { Flex, Button, Heading, Divider, useToast } from "@chakra-ui/react";
import SelectComponent from "../../UI/Select";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Modal from "../../overlay/Modal";
import DoubleChart from "../../UI/DoubleChart";
import { useState } from "react";
import { useEffect } from "react";
import { generateReport, getEmployees, getTasks } from "../../../util/http";
import { filterDate } from "../../../util/lib";

function statusFilter(data, month, status) {
  return data?.filter((el) => {
    const createdMonth = new Date(el.created_at).getMonth() + 1;
    return +createdMonth === +month && el.status?.toLowerCase() === status;
  });
}

export default function TaskReportDetail({ show }) {
  const [list, setList] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [employee, setEmployee] = useState(list[0] || "");
  const [days, setDays] = useState(0);
  const [filter, setFilter] = useState("weekly");
  const [pending, setPending] = useState({
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  });
  const [ongoing, setOngoing] = useState({
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  });
  const [completed, setCompleted] = useState({
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  });
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function reportHandler() {
    const taskReport = [
      {
        pendingTasks: pending,
        ongoingTask: ongoing,
        completedTask: completed,
      },
    ];

    const data = {
      report: "Clients",
      data: JSON.stringify(taskReport),
    };

    try {
      setLoading(true);
      await generateReport(data);
      toast({
        title: `Task report generated`,
        description: "",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  useEffect(() => {
    if (filter?.toLowerCase() === "daily") {
      setDays(1);
    } else if (filter?.toLowerCase() === "weekly") {
      setDays(7);
    } else if (filter?.toLowerCase() === "monthly") {
      setDays(30);
    } else {
      setDays("");
    }
  }, [filter]);

  function closeModal() {
    show(false);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getEmployees();
        let employeeObj;
        employeeObj = response?.employees
          ?.filter((el) => el.deleted_at === null)
          .map((el) => {
            return {
              name: `${el.first_name} ${el.last_name}`,
              id: el.id,
            };
          });
        setAllEmployees(employeeObj);
        const employeeNames = employeeObj?.map((el) => el.name);
        setList(employeeNames);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const emp_id = allEmployees?.find((el) => el.name == employee)?.id;

      try {
        const response = await getTasks();
        const filtTask = response?.tasks?.reverse().filter((el, i) => {
          const assignee = el.assignee_id && JSON.parse(el?.assignee_id);
          const mappedAssignee = assignee?.map((el) => +el);
          if (!days) {
            return mappedAssignee?.includes(+emp_id);
          }
          const milliSec = +days * 24 * 60 * 60 * 1000;
          const startDate = filterDate(milliSec);
          const createdDate = new Date(el?.created_at);
          return mappedAssignee?.includes(+emp_id) && startDate < createdDate;
        });
        setPending({
          January: statusFilter(filtTask, 1, "todo")?.length,
          February: statusFilter(filtTask, 2, "todo")?.length,
          March: statusFilter(filtTask, 3, "todo")?.length,
          April: statusFilter(filtTask, 4, "todo")?.length,
          May: statusFilter(filtTask, 5, "todo")?.length,
          June: statusFilter(filtTask, 6, "todo")?.length,
          July: statusFilter(filtTask, 7, "todo")?.length,
          August: statusFilter(filtTask, 8, "todo")?.length,
          September: statusFilter(filtTask, 9, "todo")?.length,
          October: statusFilter(filtTask, 10, "todo")?.length,
          November: statusFilter(filtTask, 11, "todo")?.length,
          December: statusFilter(filtTask, 12, "todo")?.length,
        });

        setOngoing({
          January: statusFilter(filtTask, 1, "ongoing")?.length,
          February: statusFilter(filtTask, 2, "ongoing")?.length,
          March: statusFilter(filtTask, 3, "ongoing")?.length,
          April: statusFilter(filtTask, 4, "ongoing")?.length,
          May: statusFilter(filtTask, 5, "ongoing")?.length,
          June: statusFilter(filtTask, 6, "ongoing")?.length,
          July: statusFilter(filtTask, 7, "ongoing")?.length,
          August: statusFilter(filtTask, 8, "ongoing")?.length,
          September: statusFilter(filtTask, 9, "ongoing")?.length,
          October: statusFilter(filtTask, 10, "ongoing")?.length,
          November: statusFilter(filtTask, 11, "ongoing")?.length,
          December: statusFilter(filtTask, 12, "ongoing")?.length,
        });

        setCompleted({
          January: statusFilter(filtTask, 1, "completed")?.length,
          February: statusFilter(filtTask, 2, "completed")?.length,
          March: statusFilter(filtTask, 3, "completed")?.length,
          April: statusFilter(filtTask, 4, "completed")?.length,
          May: statusFilter(filtTask, 5, "completed")?.length,
          June: statusFilter(filtTask, 6, "completed")?.length,
          July: statusFilter(filtTask, 7, "completed")?.length,
          August: statusFilter(filtTask, 8, "completed")?.length,
          September: statusFilter(filtTask, 9, "completed")?.length,
          October: statusFilter(filtTask, 10, "completed")?.length,
          November: statusFilter(filtTask, 11, "completed")?.length,
          December: statusFilter(filtTask, 12, "completed")?.length,
        });
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [allEmployees, employee, days]);

  return (
    <Modal hide={closeModal}>
      <Flex align="start" direction="column" gap="2rem" w="100%">
        <Heading size="md" color="var(--container_color)" fontWeight={600}>
          Task Report
        </Heading>
        <Divider />
        <Flex w="100%" align="center" justify="space-between">
          <Flex align="center" w="60%" gap="1rem">
            <SelectComponent
              label="Employee"
              onChange={(e) => setEmployee(e.value)}
              options={["Select staff here", ...list]}
            />
            <SelectComponent
              label="Duration"
              onChange={(e) => setFilter(e.value)}
              options={["Daily", "Weekly", "Monthly", "All Time"]}
            />
          </Flex>
          <Button
            variant="solid"
            bg="brand.900"
            color="white"
            rightIcon={<ChevronDownIcon />}
            isLoading={loading}
            loadingText="Generating"
            onClick={reportHandler}
          >
            Generate Report
          </Button>
        </Flex>

        <DoubleChart
          pending={Object.values(pending)}
          ongoing={Object.values(ongoing)}
          completed={Object.values(completed)}
        />
      </Flex>
    </Modal>
  );
}
