import { Button, Flex } from "@chakra-ui/react";
import SalaryDeduction from "../SalaryDeduction";
import { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import ReusableText from "../../UI/ReusableText";
import EditDeduction from "./EditDeduction";
import { useEffect } from "react";
import { getDeductions } from "../../../util/http";

export default function Deductions() {
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [name, setName] = useState({
    id: "",
    name: "",
    amount: "",
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getDeductions();
        setList(response?.deductions);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {show1 && <EditDeduction detail={name} show={setShow1} />}
      {show && <SalaryDeduction show={setShow} />}
      <Flex w="100%" align="start" direction="column" gap="1.5rem" py="1rem">
        <Flex w="100%" align="end" justify="end">
          <Button
            vaiant="solid"
            colorScheme="yellow"
            onClick={() => setShow(true)}
          >
            Create Deduction
          </Button>
        </Flex>

        <Flex align="start" direction="column" gap="1rem" w="100%">
          {list?.length > 0 ? (
            <>
              {list?.map((el) => {
                return (
                  <Flex
                    borderBottom="1px solid #ccc"
                    w="100%"
                    py="10px"
                    align="center"
                    justify="space-between"
                  >
                    <ReusableText
                      text={el?.name}
                      title={el?.name}
                      transform={true}
                      color="var(--container_color)"
                      size="15px"
                      weight="medium"
                    />

                    <Flex align="center" gap="1rem">
                      <ReusableText
                        text={`₦${new Intl.NumberFormat().format(
                          el?.amount
                        )}.00`}
                        title={el}
                        transform={true}
                        color="var(--container_color)"
                        size="12px"
                        weight="medium"
                      />

                      <Button
                        onClick={() => {
                          setName({
                            name: el?.name,
                            id: el?.id,
                            amount: el?.amount,
                          });
                          setShow1(true);
                        }}
                        variant="ghost"
                        colorScheme="green"
                      >
                        <AiFillEdit
                          color="var(--container_color)"
                          size="18px"
                        />
                      </Button>
                    </Flex>
                  </Flex>
                );
              })}
            </>
          ) : (
            <Flex
              w="100%"
              align="center"
              color="var(--container_color)"
              justify="center"
              h="10vh"
            >
              No Deduction found...
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
}
