import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AuthContext } from "../../../util/context";
import { useNavigate } from "react-router-dom";
import EditRole from "../EditRole";
import BulkEmployeeUpload from "./BulkEmployeeUpload";
import BulkClientUpload from "./BulkClientUpload";

export default function SettingHeader({ show1, show2, show3, show, show4 }) {
  const { user } = useContext(AuthContext);
  const canView = user?.id === 1;
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showEmployee, setShowEmployee] = useState(false);
  const [showClient, setShowClient] = useState(false);

  return (
    <>
      {showEmployee && <BulkEmployeeUpload show={setShowEmployee} />}
      {showClient && <BulkClientUpload show={setShowClient} />}
      <EditRole isOpen={isOpen} onClose={onClose} />
      <Flex w="100%" align="center" justify="space-between">
        <Heading size="md" color="var(--container_color)" fontWeight="bold">
          Manage Settings
        </Heading>
        <Flex align="center" gap="1rem">
          <Menu>
            <MenuButton
              bg="var(--container)"
              color="var(--container_color)"
              _hover={{ bg: "transparent" }}
              _expanded={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              <BsThreeDotsVertical />
            </MenuButton>
            <MenuList>
              {canView ? (
                <>
                  <MenuItem onClick={onOpen}>Edit Role</MenuItem>
                  <MenuItem onClick={() => navigate("/settings/create-role")}>
                    Create Role
                  </MenuItem>
                  <MenuItem onClick={() => setShowEmployee(true)}>
                    Bulk Employee Upload
                  </MenuItem>
                  <MenuItem onClick={() => setShowClient(true)}>
                    Bulk Client Upload
                  </MenuItem>
                </>
              ) : (
                <>
                  <MenuItem onClick={() => setShowEmployee(true)}>
                    Bulk Employee Upload
                  </MenuItem>
                  <MenuItem onClick={() => setShowClient(true)}>
                    Bulk Client Upload
                  </MenuItem>
                </>
              )}
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </>
  );
}
