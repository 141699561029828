import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import ReusableDownloadDoc from "../../UI/ReusableDownloadDoc";
import ScheduleMeeting from "./ScheduleMeeting";
import { useState } from "react";
import { getStoredItem } from "../../../util/lib";

export default function AboutBrief({ brief }) {
  const [show, setShow] = useState(false);
  const userType = getStoredItem("user-type");
  return (
    <>
      {show && <ScheduleMeeting brief={brief} show={setShow} />}
      <Flex
        w="100%"
        align="start"
        direction="column"
        bg="white"
        gap="1rem"
        boxShadow="lg"
        borderRadius="16px"
        p="1rem"
      >
        <Heading size="md" color="#333" fontWeight="bold">
          About Brief
        </Heading>
        <Text color="#333" fontSize="16px" fontWeight="normal">
          {brief?.description}
        </Text>
        {userType?.toLowerCase() === "client" && (
          <Button
            onClick={() => setShow(true)}
            bg="brand.900"
            color="white"
            variant="solid"
          >
            Request Meeting
          </Button>
        )}
      </Flex>
    </>
  );
}
