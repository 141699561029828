import React from "react";
import ReusableModal from "../UI/ReusableModal";
import SelectComponent from "../UI/Select";
import { useState } from "react";
import { useEffect } from "react";
import { createRole, getRoleDetail, getRoles } from "../../util/http";
import Toggle from "../UI/Toggle";
import { Button, Flex, useToast } from "@chakra-ui/react";
import ReusableText from "../UI/ReusableText";

export default function EditRole({ isOpen, onClose }) {
  const [roles, setRoles] = useState([]);

  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [can, setCan] = useState([]);
  const [cant, setCant] = useState([]);
  const [newRole, setNewRole] = useState(can);

  const toast = useToast();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getRoles();

        setRoles(response?.roles);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (!role) {
        return;
      }
      try {
        const response = await getRoleDetail(role);
        setNewRole(response?.permissions);
        setCan(response?.permissions);
        setCant(response?.unassigned_permissions);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [role]);

  async function editRole() {
    const data = {
      name: role,
      permissions: newRole,
      description: role,
    };

    try {
      setLoading(true);
      await createRole(data);
      window.location.reload();
      onClose();
      setLoading(false);
      toast({
        title: `Successfully updated ${role}`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);

      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Edit Role">
      <Flex w="100%" align="start" direction="column" gap="1rem" py="1rem">
        <SelectComponent
          label="Select Role"
          onChange={(e) => setRole(e.value)}
          options={["Select Role", ...roles]}
        />
        {role && (
          <ReusableText
            text="Permissions"
            color="#333"
            size="20px"
            weight="bold"
            align="start"
          />
        )}
        <Flex w="100%" align="center" wrap="wrap" gap="1rem" overflow="scroll">
          {can?.map((el, i) => (
            <Toggle
              checked={true}
              key={i}
              title={el}
              onChange={(e) => {
                if (e.target.checked) {
                  setNewRole((prev) => [el, ...prev]);
                } else {
                  const removedIndex = can.indexOf(el);
                  can.splice(removedIndex);
                  setNewRole(can);
                }
              }}
            />
          ))}
        </Flex>
        {role && (
          <ReusableText
            text="Restrictions"
            color="#333"
            size="20px"
            weight="bold"
            align="start"
          />
        )}
        <Flex w="100%" align="center" wrap="wrap" gap="1rem" overflow="scroll">
          {cant?.map((el, i) => (
            <Toggle
              key={i}
              title={el}
              onChange={(e) => {
                if (e.target.checked) {
                  setNewRole((prev) => [el, ...prev]);
                } else {
                  newRole.splice(i);
                }
              }}
            />
          ))}
        </Flex>
        <Flex align="end" justify="end" w="100%">
          <Button
            variant="solid"
            bg="brand.900"
            color="white"
            isLoading={loading}
            loadingText="Processing"
            onClick={editRole}
          >
            Save Update
          </Button>
        </Flex>
      </Flex>
    </ReusableModal>
  );
}
