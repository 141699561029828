import React from "react";
import Overlay from "../overlay/OverLay";
import { FaSpinner } from "react-icons/fa";
import classes from "./spinner.module.css";

export default function Spinner() {
  return (
    <>
      <Overlay />
      <div className={classes.spinner}>
        <span className="">
          <FaSpinner />
        </span>
      </div>
    </>
  );
}
