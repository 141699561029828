import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import ImageComponent from "../UI/Image";
import Menu from "./Menu";
import logo from "../images/logo.png";
import MenuFooter from "./MenuFooter";

export default function DashboardSide({ display }) {
  return (
    <Flex
      // mt="-7rem"
      display={{ lg: "flex", md: "none", base: "none" }}
      w={display === "flex" ? "200px" : "100px"}
      bg="transparent"
      align="start"
      pl="0.5rem"
      py="1rem"
      direction="column"
      height="100vh"
      overflow="scroll"
      mr="-1rem"
    >
      <Flex w="100%" align="start" gap="2rem" direction="column">
        <Flex align="center" direction="column" gap="1rem">
          <Box w="100%" px={display === "flex" ? "1rem" : "0.2rem"}>
            <ImageComponent
              alt="employee management"
              height="64px"
              fit="contain"
              src={display === "flex" ? "/loya.png" : "/logo.png"}
            />
          </Box>
        </Flex>
        <Flex
          w="100%"
          h="85vh"
          align="start"
          direction="column"
          justify="space-between"
        >
          <Menu display={display} />
          <MenuFooter display={display} />
        </Flex>
      </Flex>
    </Flex>
  );
}
