import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";

export default function AppraisalRating() {
  return (
    <Flex w="100%" align="start" direction="column">
      <Flex border="1px solid #ccc" w="100%" align="center" justify="center">
        <ReusableText
          text="Substantially exceeds job 
        requirements"
          color="var(--gray)"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          p="0.4rem"
          bl="1px solid #ccc"
          w="20%"
          align="start"
          height="50px"
        />
        <ReusableText
          text="Exceeds job requirements"
          color="var(--gray)"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          p="0.4rem"
          bl="1px solid #ccc"
          w="20%"
          align="start"
          height="50px"
        />
        <ReusableText
          text="Meets job requirements"
          color="var(--gray)"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          p="0.4rem"
          bl="1px solid #ccc"
          w="20%"
          align="start"
          height="50px"
        />
        <ReusableText
          text="Partially meets job 
        requirements"
          color="var(--gray)"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          p="0.4rem"
          bl="1px solid #ccc"
          w="20%"
          align="start"
          height="50px"
        />
        <ReusableText
          text="Does not meet most job 
        requirements"
          color="var(--gray)"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          p="0.4rem"
          bl="1px solid #ccc"
          w="20%"
          align="start"
          height="50px"
        />
      </Flex>

      <Flex border="1px solid #ccc" w="100%" align="center" justify="center">
        <ReusableText
          text="5"
          color="var(--container_color)"
          weight="medium"
          size={{ lg: "12px", md: "10px", base: "6px" }}
          p="0.4rem"
          bl="1px solid #ccc"
          w="20%"
          align="start"
        />
        <ReusableText
          text="4"
          color="var(--container_color)"
          weight="medium"
          size={{ lg: "12px", md: "10px", base: "6px" }}
          p="0.4rem"
          bl="1px solid #ccc"
          w="20%"
          align="start"
        />
        <ReusableText
          text="3"
          color="var(--container_color)"
          weight="medium"
          size={{ lg: "12px", md: "10px", base: "6px" }}
          p="0.4rem"
          bl="1px solid #ccc"
          w="20%"
          align="start"
        />
        <ReusableText
          text="2"
          color="var(--container_color)"
          weight="medium"
          size={{ lg: "12px", md: "10px", base: "6px" }}
          p="0.4rem"
          bl="1px solid #ccc"
          w="20%"
          align="start"
        />
        <ReusableText
          text="1"
          color="var(--gray)"
          weight="medium"
          size={{ lg: "12px", md: "10px", base: "6px" }}
          p="0.4rem"
          bl="1px solid #ccc"
          w="20%"
          align="start"
        />
      </Flex>
    </Flex>
  );
}
