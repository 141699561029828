import { Button, Flex, useToast } from "@chakra-ui/react";
import InputComponent from "../../UI/Input";
import { useState } from "react";
import { getSalarySetting, updateSalarySetting } from "../../../util/http";
import { useEffect } from "react";
import ReusableSalaryField from "./ReusableSalaryField";

export default function Salary() {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const toast = useToast();
  const [salary, setSalary] = useState({
    housing: "",
    transportation: "",
    dressing: "",
    furniture: "",
    basic: "",
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getSalarySetting();
        setSalary({
          housing: +response?.setting?.housing,
          transportation: +response?.setting?.transportation,
          dressing: +response?.setting?.dressing,
          furniture: +response?.setting?.furniture,
          basic: +response?.setting?.basic,
        });
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, []);

  function addFieldName(e, i) {
    const inpData = [...fields];
    inpData[i].field = e.target.value;
    setFields(inpData);
  }

  function addFieldValue(e, i) {
    const inpData = [...fields];
    inpData[i].value = e.target.value;
    setFields(inpData);
  }

  useEffect(() => {
    const filteredData = fields?.map((el) => {
      const splittedField = el?.field?.split(" ");
      if (splittedField.length > 0) {
        return { field: el.field.replaceAll(" ", "_"), value: +el.value };
      } else {
        return { field: el.field, value: +el.value };
      }
    });
    setFields(filteredData);
  }, [fields]);

  async function salaryHandler() {
    const data = {
      ...salary,
      others: fields?.length > 0 ? JSON.stringify(fields) : [],
    };
    try {
      setLoading(true);
      await updateSalarySetting(data);
      toast({
        title: `Salary settings updated`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Flex w="100%" align="start" direction="column" gap="1.5rem" py="1rem">
        <InputComponent
          onChange={(e) => setSalary({ ...salary, basic: e.target.value })}
          value={salary.basic}
          type="number"
          placeholder="15%"
          label="Basic Salary"
        />
        <Flex w="100%" align="center" justify="space-between" gap="1rem">
          <InputComponent
            onChange={(e) => setSalary({ ...salary, housing: e.target.value })}
            value={salary.housing}
            type="number"
            placeholder="15%"
            label="Housing"
          />
          <InputComponent
            onChange={(e) =>
              setSalary({ ...salary, transportation: e.target.value })
            }
            value={salary.transportation}
            type="number"
            placeholder="15%"
            label="Transport"
          />
        </Flex>
        <Flex w="100%" align="center" justify="space-between" gap="1rem">
          <InputComponent
            onChange={(e) => setSalary({ ...salary, dressing: e.target.value })}
            value={salary.dressing}
            type="number"
            placeholder="15%"
            label="Dressing"
          />
          <InputComponent
            onChange={(e) =>
              setSalary({ ...salary, furniture: e.target.value })
            }
            value={salary.furniture}
            type="number"
            placeholder="15%"
            label="Furniture"
          />
        </Flex>

        {fields?.map((el, i) => (
          <ReusableSalaryField
            index={i}
            fields={fields}
            setFields={setFields}
            key={i}
            onChange1={addFieldName}
            onChange2={addFieldValue}
          />
        ))}

        <Flex w="100%" align="center" justify="space-between">
          <Button
            onClick={() =>
              setFields((prev) => [...prev, { field: "", value: "" }])
            }
            variant="outline"
            colorScheme="blue"
          >
            Add More
          </Button>
          <Button
            isLoading={loading}
            loadingText="Saving"
            onClick={salaryHandler}
            variant="solid"
            bg="brand.900"
            color="#fff"
          >
            Save Update
          </Button>
        </Flex>
      </Flex>
    </>
  );
}
