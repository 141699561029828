import React from "react";
import ReusableListTitle from "../UI/ReusableListTitle";

export default function ClientTitle() {
  return (
    <ReusableListTitle
      title1="Client ID"
      title2="Business Name"
      title3="Email Address"
      title4="Phone Number"
      title5="Type of business"
      title6="Actions"
    />
  );
}
