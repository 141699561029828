import { Flex } from "@chakra-ui/react";
import DocumentSide from "./DocumentSide";
import DocumentDetail from "./DocumentDetail";
import DocumentList from "./DocumentList";
import { useState } from "react";

export default function DocumentCenter({ list, setList }) {
  const [showDetail, setShowDetail] = useState(false);
  const [file, setFile] = useState("");
  return (
    <Flex align="center" w="100%">
      <DocumentSide setFile={setFile} setShow={setShowDetail} />
      <DocumentList setList={setList} list={list} />
      {showDetail && <DocumentDetail file={file} setShow={setShowDetail} />}
    </Flex>
  );
}
