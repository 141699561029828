import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Heading,
  Button,
  useToast,
} from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { updateEmployee } from "../../../../util/http";

export default function EmployeeHeader({ show, employee, showUpdate, open }) {
  const navigate = useNavigate();
  const toast = useToast();

  function updateSalary() {
    showUpdate(true);
  }

  function addUpdate() {
    open();
  }
  async function unArchiveHandler() {
    try {
      await updateEmployee(employee?.id, { deleted_at: null, is_banned: "0" });
      toast({
        title: `Successfully`,
        description: `You have successfully un-archived ${employee?.first_name}`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/employees");
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function unSuspendHandler() {
    try {
      await updateEmployee(employee?.id, { is_banned: "0" });
      toast({
        title: `Successfully un-suspended ${employee?.first_name}`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/employees");
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Flex w="100%" py="1rem" align="center" justify="space-between">
      <Heading size="md" color="var(--container_color)" fontWeight="bold">
        {employee?.first_name}'s Details
      </Heading>
      <Flex align="center" gap="1rem">
        <Button
          onClick={() => show(true)}
          bg="brand.900"
          color="#fff"
          variant="solid"
        >
          Edit
        </Button>
        <Menu>
          <MenuButton
            color="var(--container_color)"
            bg="var(--container)"
            _hover={{ bg: "transparent" }}
            _expanded={{ bg: "transparent" }}
            _focus={{ bg: "transparent" }}
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            <BsThreeDotsVertical />
          </MenuButton>
          <MenuList>
            {employee?.is_banned !== "0" && employee?.deleted_at == null ? (
              <>
                <MenuItem onClick={updateSalary}>Update Salary</MenuItem>
              </>
            ) : employee?.is_banned !== "0" && employee?.deleted_at != null ? (
              <>
                <MenuItem onClick={unSuspendHandler}>Un-suspend</MenuItem>
                <MenuItem onClick={unArchiveHandler}>Un-Archive</MenuItem>
                <MenuItem onClick={updateSalary}>Update Salary</MenuItem>
              </>
            ) : employee?.is_banned === "0" && employee?.deleted_at != null ? (
              <>
                <MenuItem onClick={unArchiveHandler}>Un-Archive</MenuItem>
                <MenuItem onClick={updateSalary}>Update Salary</MenuItem>
              </>
            ) : (
              <>
                <MenuItem onClick={updateSalary}>Update Salary</MenuItem>
              </>
            )}
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
}
