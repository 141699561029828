import React from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import CreateEmployeeContainer from "./CreateEmployeeContainer";

export default function CreateEmployee() {
  return (
    <DashboardReusable>
      <CreateEmployeeContainer />
    </DashboardReusable>
  );
}
