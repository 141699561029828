import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
} from "@chakra-ui/react";
import TaskDescription from "./TaskDescription";
import NewTaskComments from "./NewTaskComments";
import Assignee from "./Assignee";
import TaskAttachments from "./TaskAttachments";
import TaskTimeline from "./TaskTimeline";

export default function TaskOverview({ task, setTask }) {
  return (
    <Tabs w="100%" variant="enclosed">
      <TabList>
        <Tab>Task Overview</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Flex w="100%" gap="1rem" align="center" justify="space=between" flexDir={{
            base:'column' , lg:'row'
          }}>
            <Flex
              h={{lg:"100vh" , base:'max-content'}}
              w={{lg:"70%" , base:'100%'}}
              align="start"
              direction="column"
              gap="1.5rem"
            >
              <TaskDescription task={task} />
              <TaskTimeline task={task} />
              <NewTaskComments setTask={setTask} task={task} />
            </Flex>
            <Flex
              h={{lg:"100vh" , md:'100%'}}
              w={{lg:"28%" , base:'100%'}}
              align="start"
              direction="column"
              gap="1.5rem"

            >
              <Assignee task={task} />
              <TaskAttachments task={task} />
            </Flex>
          </Flex>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
