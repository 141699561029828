import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import { Fragment, useState } from "react";
import PersonalDetail from "./PersonalDetail";
import Address from "./Address";
import LoyaInternal from "./LoyaInternal";
import { useNavigate } from "react-router-dom";

import { createNewEmployee } from "../../../util/http";
import Loader from "../../UI/Loader";


export default function EmployeeDetails() {
  const toast = useToast();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [role, setRole] = useState("");

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [state, setState] = useState("");
  const [code, setCode] = useState("");
  const [department, setDepartment] = useState("Department of Administration");
  const [storage, setStorage] = useState("");
  const [designation, setDesignation] = useState("");
  const [primaryRole, setPrimaryRole] = useState("");
  const [secondaryRoles, setSecondaryRoles] = useState("");

  const [kinFirstName, setKinFirstName] = useState("");
  const [kinLastName, setKinLastName] = useState("");
  const [kinEmail, setKinEmail] = useState("");
  const [kinPhone, setKinPhone] = useState("");
  const [kinAddress1, setKinAddress1] = useState("");
  const [kinAddress2, setKinAddress2] = useState("");
  const [kinCity, setKinCity] = useState("");
  const [kinCountry, setKinCountry] = useState("");
  const [kinState, setKinState] = useState("");
  const [kinCode, setKinCode] = useState("");
  const [image, setImage] = useState("");
  const [leave, setLeave] = useState("");
  const [salary, setSalary] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  async function createEmployeeHandler() {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      personal_email: personalEmail,
      image,
      phone_number: phone,
      job_role: role,
      address1,
      address2,
      city,
      state,
      country,
      department: primaryRole,
      designation,
      storage,
      salary,
      // leave_count: leave,
      postal_code: code,
      employee_id: employeeId,
      contact_email: kinEmail,
      contact_first_name: kinFirstName,
      contact_last_name: kinLastName,
      contact_phone_number: kinPhone,
      contact_country: kinCountry,
      contact_city: kinCity,
      contact_state: kinState,
      contact_postal_code: kinCode,
      contact_address1: kinAddress1,
      contact_address2: kinAddress2,
      secondary_department: JSON.stringify(secondaryRoles),
    };

    if (
      !department ||
      !designation ||
      !role ||
      !firstName ||
      !lastName ||
      !email
    ) {
      toast({
        title: `Employee details missing`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      await createNewEmployee(data);
      setProgress(80);
      setProgress(100);
      setLoading(false);
      navigate("/employees");
      toast({
        title: `Employee created`,
        description: `You have successfully created an employee`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);
      setProgress(100);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <Flex
        align="center"
        height={{ lg: "80vh", md: "100%", base: "100%" }}
        w="100%"
        p="1rem"
        direction="column"
      >
        <Box
          w="100%"
          overflow="scroll"
          height={{ lg: "70vh", md: "100%", base: "100%" }}
        >
          <PersonalDetail
            setLeave={setLeave}
            setSalary={setSalary}
            setEmail={setEmail}
            setPhone={setPhone}
            setEmployeeId={setEmployeeId}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setRole={setRole}
            setKinAddress1={setKinAddress1}
            setKinAddress2={setKinAddress2}
            setKinCity={setKinCity}
            setKinCode={setKinCode}
            setKinCountry={setKinCountry}
            setKinEmail={setKinEmail}
            setKinFirstName={setKinFirstName}
            setKinLastName={setKinLastName}
            setKinPhone={setKinPhone}
            setKinState={setKinState}
            setImage={setImage}
            setDepartment={setDepartment}
            setStorage={setStorage}
            kinCountry={kinCountry}
            role={role}
            setAddress1={setAddress1}
            setAddress2={setAddress2}
            setCity={setCity}
            setCode={setCode}
            setCountry={setCountry}
            setState={setState}
            country={country}
            setDesignation={setDesignation}
            setPrimaryRole={setPrimaryRole}
            setSecondaryRoles={setSecondaryRoles}
            setPersonalEmail={setPersonalEmail}
          />
        </Box>
        <Flex py="2rem" align="center" justify="space-between" w="100%">
          <Button
            variant="outline"
            bg="transparent"
            colorScheme="red"
            color="error.900"
            onClick={() => navigate("/employees")}
          >
            Cancel
          </Button>
          <Button
            onClick={createEmployeeHandler}
            isDisabled={false}
            variant="solid"
            bg="brand.900"
            color="white"
            isLoading={loading}
            loadingText="Processing"
          >
            Create
          </Button>
        </Flex>
      </Flex>
    </Fragment>
  );
}
