import { Flex } from "@chakra-ui/react";
import CreateEmployeeHeader from "./CreateEmployeeHeader";
import EmployeeDetails from "./EmployeeDetails";

export default function CreateEmployeeContainer() {
  return (
    <Flex
      w="100%"
      align="center"
      direction="column"
      gap="1rem"
      p={{ lg: "2rem", md: "1rem", base: "6px" }}
    >
      <CreateEmployeeHeader />
      <EmployeeDetails />
    </Flex>
  );
}
