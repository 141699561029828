import { Flex } from "@chakra-ui/react";
import React, { Fragment } from "react";
import RetainershipItem from "./RetainershipItem";

export default function RetainershipList({ list }) {
  return (
    <Fragment>
      <Flex
        w="100%"
        direction="column"
        border="1px solid #ccc"
        borderTop="none"
        maxHeight="85%"
        overflow="scroll"
      >
        {list?.length > 0 ? (
          <>
            {list?.map((item, i) => (
              <RetainershipItem item={item} path="retainership" key={i} />
            ))}
          </>
        ) : (
          <Flex align="center" w="100%" justify="center" h="50vh">
            Empty Invoice
          </Flex>
        )}
      </Flex>
    </Fragment>
  );
}
