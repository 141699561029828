import { Box, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../UI/DeleteModal";
import { useState } from "react";
import { deleteInvoice } from "../../../util/http";
import { getStoredItem } from "../../../util/lib";
import formatTimestamp from "../../../util/formatTimestampDate";

export default function InvoicesItem({ item }) {
 
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const userType = getStoredItem("user-type");

  const [password, setPassword] = useState("");

  async function deleteHandler() {
    const data = {
      invoice_id: item.invoice_id,
      password,
    };

    try {
      await deleteInvoice(data);

      onClose();
      toast({
        title: `Invoice successfully deleted`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data?.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Flex
        w="100%"
        align="center"
        px="1rem"
        py="1rem"
        cursor="pointer"
        _hover={{ opacity: "80%" }}
        borderBottom="1px solid #333"
      >
        <ReusableText
          text={item?.invoice_id}
          color="var(--container_color)"
          size={{ lg: "14px", md: "12px", base: "6px" }}
          weight="medium"
          w="10%"
          align="center"
          onClick={() => navigate(`/invoicing/${item.invoice_id}`)}
        />
         <ReusableText
          text={formatTimestamp(item?.created_at)}
          color="var(--container_color)"
          size={{ lg: "14px", md: "12px", base: "6px" }}
          weight="medium"
          w="10%"
          align="center"
          onClick={() => navigate(`/invoicing/${item.invoice_id}`)}
        />
        <ReusableText
          text={item?.client?.slice(0, 30)}
          color="var(--container_color)"
          size={{ lg: "14px", md: "12px", base: "6px" }}
          weight="medium"
          w="30%"
          align="center"
          transform={true}
          onClick={() => navigate(`/invoicing/${item.invoice_id}`)}
        />
        <ReusableText
          text={item?.brief_title?.slice(0, 30)}
          color="var(--container_color)"
          size={{ lg: "14px", md: "12px", base: "6px" }}
          weight="medium"
          w="20%"
          align="center"
          onClick={() => navigate(`/invoicing/${item.invoice_id}`)}
        />

        <ReusableText
          text={`${item?.currency_symbol}${new Intl.NumberFormat().format(item?.amount)}.00`}
          color="var(--container_color)"
          size={{ lg: "14px", md: "12px", base: "5px" }}
          weight="medium"
          w="10%"
          align="start"
        />
        <Flex
          align="center"
          justify="center"
          w="15%"
          gap="0.5rem"
          borderRadius="1rem"
        >
          <Box
            w="0.3rem"
            height="0.3rem"
            borderRadius="100%"
            bg={
              item?.status?.toLowerCase() === "pending"
                ? "#333"
                : item?.status?.toLowerCase() === "partial"
                ? "yellow.500"
                : "#1FA159"
            }
          ></Box>
          <ReusableText
            text={item?.status}
            color={
              item?.status?.toLowerCase() === "pending"
                ? "#333"
                : item?.status?.toLowerCase() === "partial"
                ? "yellow.500"
                : "#1FA159"
            }
            size={{ lg: "12px", md: "10px", base: "6px" }}
            weight="medium"
            transform={true}
          />
        </Flex>
        <Flex align="center" w="15%" gap="1rem" justify="center">
          <AiOutlineEye
            color="var(--container_color)"
            cursor="pointer"
            onClick={() => navigate(`/invoicing/${item.invoice_id}`)}
          />
          {false && (
            <>
              {userType === "employee" && (
                <AiOutlineDelete
                  color="var(--container_color)"
                  onClick={onOpen}
                  cursor="pointer"
                />
              )}
            </>
          )}
        </Flex>
      </Flex>
      {userType === "employee" && (
        <DeleteModal
          isOpen={isOpen}
          onClose={onClose}
          onClick={deleteHandler}
          title="Delete Invoice"
          onChange={(e) => setPassword(e.target.value)}
          text={`Are you sure you want to delete invoice ${item.invoice_id}`}
        />
      )}
    </>
  );
}
