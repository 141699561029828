import { FormControl, FormLabel } from "@chakra-ui/react";

import Select from "react-select";

export default function SelectComponent({
  label,
  onChange,
  options,
  value,
  multiple = false,
  required = true,
  

}) {
  const optionList = options?.map((el) => {
    return {
      value: el?.toLowerCase().includes("select") ? "" : el,
      label: el,
    };
  });

  return (
    <>
      <FormControl isRequired={required}>
        <FormLabel fontWeight="medium" color="var(--container_color)">
          {label}
        </FormLabel>
        <Select
          styles={{ textTransform: "capitalize" }}
          options={optionList}
          isSearchable={true}
          isMulti={multiple}
          value={value}
          onChange={onChange}

          // theme={(theme) => ({
          //   ...theme,
          //   borderRadius: 0,
          //   colors: {
          //     ...theme.colors,
          //     primary25: "#364273",
          //     primary: "#364273",
          //   },
          // })}
        />
      </FormControl>
    </>
  );
}
