import { getStoredItem } from "../../util/lib";
import ReusableListTitle from "../UI/ReusableListTitle";

export default function BriefTitle() {
  const userType = getStoredItem("user-type");
  return (
    <ReusableListTitle
      title1="Brief ID"
      title2="Brief Title"
      title3="Client"
      title4={
        userType?.toLowerCase() === "client"
          ? "Pending Balance"
          : "Available Balance"
      }
      title5="Tag"
      title6="Actions"
      w1="10%"
      w2="25%"
      w3="20%"
      w4="15%"
      w5="15%"
      w6="15%"
    />
  );
}
