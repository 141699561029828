import React, { useEffect, useState } from "react";
import ReusableEmployeeHeader from "../../../UI/ReusableEmployeeHeader";
import ReusableFilter from "../../../UI/ReusableFilter";
import { Flex } from "@chakra-ui/react";
import RetainershipTitle from "./RetainershipTitle";
import { getRetainerships } from "../../../../util/http";
import RetainershipList from "./RetainershipList";

export default function RetainershipContainer() {
  const [list, setList] = useState([]);
  const [status, setStatus] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getRetainerships();
        setList(response?.retainers?.reverse());
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  async function searchHandler(e) {
    const response = await getRetainerships();
    const filteredData = response?.retainers.filter(
      (el) =>
        el.client
          ?.toLowerCase()
          .includes(e.target.value.trim().toLowerCase()) ||
        el.client_id
          ?.toLowerCase()
          .includes(e.target.value.trim().toLowerCase())
    );
    setList(filteredData);
  }

  useEffect(() => {
    async function filterData() {
      if (!status) {
        return;
      }
      try {
        const response = await getRetainerships();
        if (status === "active") {
          const activeFilter = response?.retainers?.filter((el) => {
            const dueDate = new Date(el.validity);
            const today = new Date(Date.now());
            return dueDate > today;
          });
          setList(activeFilter);
        } else if (status === "expired") {
          const expiredFilter = response?.retainers?.filter((el) => {
            const dueDate = new Date(el.validity);
            const today = new Date(Date.now());
            return dueDate < today;
          });
          setList(expiredFilter);
        } else {
          setList(response?.retainers?.reverse());
        }
      } catch (error) {
        console.log(error);
      }
    }
    filterData();
  });

  function allFilter() {
    setStatus("all");
  }

  function activeFilter() {
    setStatus("active");
  }

  function expiredFilter() {
    setStatus("expired");
  }

  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      h="100%"
      gap="1rem"
      p={{ lg: "2rem", md: "1rem", base: "6px" }}
    >
      <ReusableEmployeeHeader
        title="Retainership"
        text="Manage your retainership and their details here"
      />
      <ReusableFilter
        menu={[
          { text: "All", onClick: allFilter },
          { text: "Active", onClick: activeFilter },
          { text: "Expired", onClick: expiredFilter },
        ]}
        onSearch={searchHandler}
      />
      <Flex w="100%" direction="column">
        <RetainershipTitle />
        <RetainershipList list={list} />
      </Flex>
    </Flex>
  );
}
