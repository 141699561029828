import React from "react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";

export default function RoleHeader() {
  return (
    <ReusableEmployeeHeader
      title="Create Employee Role"
      text="Complete the form below and select the permissions that you would like the category to have."
    />
  );
}
