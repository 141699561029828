import { Button, Flex } from "@chakra-ui/react";
import { AiOutlineFile } from "react-icons/ai";
import ReusableText from "../UI/ReusableText";
import { useState } from "react";
import StaffReportDetail from "./report-chart-details/StaffReportDetail";

export default function StaffReportCard() {
  const [show, setShow] = useState(false);
  return (
    <>
      <Flex
        align="start"
        direction="column"
        w="30%"
        border="1px solid #ccc"
        borderRadius="8px"
        h="200px"
        gap="1rem"
        p="1rem"
        bg="#fff"
        boxShadow="md"
      >
        <Flex align="center" gap="1rem">
          <Flex
            bg="#CCCFDC"
            align="center"
            justify="center"
            p="0.7rem"
            borderRadius="100%"
          >
            <AiOutlineFile />
          </Flex>
          <ReusableText
            text="Salaries"
            weight="medium"
            size="16px"
            color="#101828"
            transform={true}
          />
        </Flex>
        <ReusableText
          text="View salary reports for different employees across the year"
          weight="light"
          size="14px"
          color="#7F7E7E"
          transform={true}
        />
        <Button
          onClick={() => setShow(true)}
          bg="#EEEFF3"
          color="#364273"
          variant="solid"
        >
          View Details
        </Button>
      </Flex>
      {show && <StaffReportDetail show={setShow} />}
    </>
  );
}
