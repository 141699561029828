import { Flex } from "@chakra-ui/react";
import React from "react";
import { CloseIcon } from "@chakra-ui/icons";
import ReusableText from "../UI/ReusableText";

export default function RestrictedItem({ item }) {
  return (
    <Flex align="center" justify="center" gap="1rem" px="1.5rem" py="1rem">
      <CloseIcon color="red.600" />
      <ReusableText
        text={item}
        color="var(--gray)"
        transform={true}
        size="16px"
        weight="normal"
      />
    </Flex>
  );
}
