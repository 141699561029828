import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../UI/ReusableText";

export default function TaskWrapper({ children, title, ref, status, total }) {
  return (
    <Flex
      align="start"
      w={{ lg: "30%", md: "100%", base: "100%" }}
      direction="column"
      gap="0.5rem"
      p="1rem"
      borderRadius="8px"
      ref={ref}
      position="relative"
    >
      <Flex
        align="center"
        justify="center"
        w="100%"
        position="absolute"
        top="-4px"
        left="0"
      >
        <Box
          w="30%"
          height="5px"
          mx="auto"
          bg={
            status === "todo"
              ? "#FFD11C"
              : status === "ongoing"
              ? "#59AEFF"
              : "#1FA159"
          }
          borderRadius="1rem"
        ></Box>
      </Flex>
      <Flex px="1.5rem" align="center" justify="space-between" w="100%">
        <Flex align="center" gap="0.5rem">
          <ReusableText
            text={title}
            color="var(--container_color)"
            size="16px"
            weight="medium"
          />
          <Flex
            align="center"
            justify="center"
            w="2rem"
            height="2rem"
            borderRadius="100%"
            bg="var(--body_color)"
            fontSize="1rem"
            color="var(--container_color)"
          >
            {total}
          </Flex>
        </Flex>
      </Flex>
      {children}
    </Flex>
  );
}
