import React from "react";
import Profile from "../../profile/Profile";
import { AuthContext } from "../../../util/context";
import { useContext } from "react";
import Login from "../../auth/Login";

export default function ProfilePage() {
  const ctx = useContext(AuthContext);
  return <>{ctx.isAuthenticated && ctx.isUser ? <Profile /> : <Login />}</>;
}
