import { Flex } from "@chakra-ui/react";
import DashboardCharts from "../../DashboardCharts";
import DashboardFooter from "../../DashboardFooter";
import AccountantStat from "./AccountantStat";
import ReusableText from "../../../UI/ReusableText";
import { Link } from "react-router-dom";
import RequisitionTitle from "../../../requisition/RequisitionTitle";
import RequisitionList from "../../../requisition/RequisitionList";
import { useState } from "react";
import { useEffect } from "react";
import { getRequisitions } from "../../../../util/http";

export default function Accountant() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await getRequisitions();
        setLoading(false);
        setList(response?.requisitions);
        console.log(response);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <Flex w="100%" align="start" direction="column" p="1rem">
      {false && <AccountantStat />}
      <DashboardCharts />
      <Flex w="100%" bg="var(--container)" direction="column">
        <Flex p="1rem" align="center" w="100%" justify="space-between">
          <ReusableText
            text="Recent Requisitions"
            color="var(--container_color)"
            size="18px"
            weight="medium"
          />
          <Link to="/requisitions" style={{}}>
            View all {">"}
          </Link>
        </Flex>
        <RequisitionTitle />
        <RequisitionList list={list?.slice(0, 7)} loading={loading} />
      </Flex>
    </Flex>
  );
}
