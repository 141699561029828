import { Fragment } from "react";
import DashboardReusable from "../UI/DashboardReusable";
import TaskContainer from "./TaskContainer";
import { useEffect } from "react";
import { getTasks } from "../../util/http";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../util/context";

export default function Task() {
  const [list, setList] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTasks();

        if (
          user.job_role?.toLowerCase() === "lawyer" ||
          user.job_role?.toLowerCase() === "user"
        ) {
          const filtTask = response?.tasks?.reverse().filter((el, i) => {
            const assignee = el.assignee_id && JSON.parse(el?.assignee_id);
            const mappedAssignee = assignee?.map((el) => +el);
            return mappedAssignee?.includes(+user?.id);
          });
          setList(filtTask);
        } else if (
          user?.job_role?.toLowerCase().includes("hod") ||
          user?.job_role?.toLowerCase() === "hop"
        ) {
          const filtTask = response?.tasks?.reverse().filter((el, i) => {
            const assignee = el.assignee_id && JSON.parse(el?.assignee_id);
            const mappedAssignee = assignee?.map((el) => +el);
            return (
              mappedAssignee?.includes(+user?.id) ||
              String(el.user_id) === String(user?.id)
            );
          });
          setList(filtTask);
        } else {
          setList(response?.tasks?.reverse());
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [user]);
  return (
    <Fragment>
      <DashboardReusable>
        <TaskContainer list={list} setList={setList} />
      </DashboardReusable>
    </Fragment>
  );
}
