import ReusableModal from "../UI/ReusableModal";
import { Flex, Text, Button, Center, VStack, Icon, Grid } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionFlex = motion(Flex); // Wrap Flex component with motion for animations

export default function UnApproved({ isOpen, onClose }) {
    return (
        <ReusableModal
            title="Requisition Not Approved"
            isOpen={isOpen}
            onClose={onClose}
        >
            <Center py="2rem">
                <Icon fontSize="4rem" color="red.500" /> {/* Eye-catching warning icon */}
            </Center>
            <Grid placeContent={'center'} placeItems={'center'}  >
                <Grid gap={'.5rem'} w={{
                    lg:'80%' , '2xl':'70%'
                }}>
                <Text   fontSize="md" fontWeight="medium">
                    This requisition has not yet been approved by the relevant authority.
                </Text>
                <Text  fontSize="md">
                    As a result, you, as the accountant, cannot disburse funds for this request at the moment.
                </Text>
                <Text  fontSize="md">
                    Please ensure that the requisition is approved before attempting to disburse funds.
                </Text>
                <MotionFlex justify="flex-end" mt="2rem">
                <Button size="sm" onClick={onClose} colorScheme="messenger" bg="brand.900">
                    Close
                </Button>
            </MotionFlex>
                </Grid>
            </Grid>

        </ReusableModal>
    );
}
