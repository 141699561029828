import React from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import ViewTaskContainer from "./ViewTaskContainer";
import { useEffect } from "react";
import { useState } from "react";
import { getTask } from "../../../util/http";
import { useParams } from "react-router-dom";
import NewViewTaskContainer from "./new-view-task/NewViewTaskContainer";

export default function ViewTask() {
  const [task, setTask] = useState("");

  const params = useParams();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getTask(params.id);
        setTask(response.task[0]);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [params.id]);
  return (
    <DashboardReusable>
      <NewViewTaskContainer task={task} setTask={setTask} />
      {false && <ViewTaskContainer task={task} />}
    </DashboardReusable>
  );
}
