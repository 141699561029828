import {
  Avatar,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";

import InputComponent from "../../UI/Input";
import { useState } from "react";
import { useParams } from "react-router-dom";
import SelectComponent from "../../UI/Select";
import PhoneNumberInput from "./PhoneNumber";

export default function CreateClientDetail({
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  setCountry,
  setImage,
  setBusinessName,
  setBusinessType,
}) {
  const [url, setUrl] = useState("");
  const params = useParams();

  return (
    <Flex
      w="100%"
      h={{ lg: "400px", md: "100%", base: "100%" }}
      direction="column"
      align="start"
      gap="2rem"
    >
      <input
        type="file"
        id="image"
        onChange={(e) => {
          setImage(e.target.files[0]);
          setUrl(URL.createObjectURL(e.target.files[0]));
        }}
        style={{ display: "none" }}
      />
      <label htmlFor="image">
        <Avatar
          size={{ lg: "2xl", md: "xl", base: "lg" }}
          name="loya"
          src={url}
        />
      </label>
      <Flex align="center" direction="column" gap="2rem" w="100%">
        {params.type === "individual" ? (
          <Flex align="center" w="100%" justify="center" gap="1rem">
            <InputComponent
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Enter first name"
              label="First Name"
              type="text"
            />
            <InputComponent
              label="Last Name"
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Enter last name"
              type="text"
            />
          </Flex>
        ) : (
          <Flex align="center" w="100%" justify="center" gap="1rem">
            <SelectComponent
              options={[" Limited Liability", "Corporation", "Partnership"]}
              onChange={(e) => setBusinessType(e.value)}
              label="Business Type"
            />
            <InputComponent
              label="Business Name"
              onChange={(e) => setBusinessName(e.target.value)}
              placeholder="Enter last name"
              type="text"
            />
          </Flex>
        )}

           <Flex align="center" w="100%" justify="center" gap="1rem" flexDir={{
            base:'column' , lg:'row'
           }}>
          <InputComponent
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
            label="Email"
            type="email"
          />
          {/* <FormControl>
            <FormLabel>Phone Number</FormLabel>
            <Flex border="1px solid #ccc" borderRadius="0.5rem" align="center">
              <Select
                onChange={(e) => setCountry(e.target.value)}
                border="none"
                w="30%"
              >
                <option value="234">+234</option>


              </Select>
              <Input
                type="number"
                border="none"
                onChange={(e) => setPhone(e.target.value)}
                placeholder="XXX XXX XXXX"
                focusBorderColor="transparent"
              />
            </Flex>
          </FormControl> */}
          <PhoneNumberInput setPhoneNumber={setPhone} />
        </Flex>
      </Flex>
    </Flex>
  );
}
