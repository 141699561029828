import React from "react";
import Client from "../../clients/Client";
import { AuthContext } from "../../../util/context";
import { useContext } from "react";
import Login from "../../auth/Login";
import Dashboard from "../../dashboard/Dashboard";

export default function ClientPage() {
  let view;
  const { isAuthenticated, user, isUser } = useContext(AuthContext);

  const viewClient =
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "accountant" ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "seed";

  if (viewClient) {
    view = <Client />;
  } else {
    view = <Dashboard />;
  }

  return <>{isAuthenticated && isUser ? view : <Login />}</>;
}
