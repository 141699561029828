import { Button, Flex } from "@chakra-ui/react";
import InputComponent from "../../UI/Input";

export default function ReusableSalaryField({
  fields,
  setFields,
  index,
  onChange1,
  onChange2,
}) {
  function handleDelete(i) {
    const deleteVal = [...fields];
    deleteVal.splice(i, 1);
    setFields(deleteVal);
  }
  return (
    <Flex
      w="100%"
      position="relative"
      align="center"
      gap="1rem"
      justify="space-between"
    >
      <InputComponent
        onChange={(e) => onChange1(e, index)}
        label="Enter Field Name"
        placeholder="Enter Field Name"
      />
      <InputComponent
        label="Enter Value"
        type="number"
        onChange={(e) => onChange2(e, index)}
        placeholder="Enter Value"
      />

      <Button
        onClick={() => {
          handleDelete(index);
        }}
        position="absolute"
        top="0"
        colorScheme="red"
        variant="ghost"
        right="0"
      >
        X
      </Button>
    </Flex>
  );
}
