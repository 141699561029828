import { Flex, useDisclosure } from "@chakra-ui/react";
import RequisitionItem from "./RequisitionItem";
import DeleteModal from "../UI/DeleteModal";
import LoadingSkeleton from "../UI/LoadingSkeleton";

export default function RequisitionList({ list, loading }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const data = [1, 1, 1, 1, 1, 1];
  return (
    <>
      {loading ? (
        <>
          {data.map((el, i) => (
            <LoadingSkeleton key={i} lines={1} />
          ))}
        </>
      ) : (
        <Flex
          w="100%"
          direction="column"
          border="1px solid #ccc"
          borderTop="none"
          maxHeight="85%"
          overflow="scroll"
        >
          {list?.map((item, i) => (
            <RequisitionItem
              onOpen={onOpen}
              item={item}
              path="requisitions"
              key={i}
            />
          ))}
        </Flex>
      )}
      <DeleteModal
        title="Delete Requisition?"
        text="Are you sure you would like to delete “Requisition ID RID00087”?"
        modalText="You have successfully deleted “Requisition ID RID00087”"
        modalTitle="Requisition Deleted"
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}
