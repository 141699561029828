import DashboardReusable from "../UI/DashboardReusable";
import InvoiceContainer from "./InvoiceContainer";

export default function Invoice() {
  return (
    <DashboardReusable>
      <InvoiceContainer />
    </DashboardReusable>
  );
}
