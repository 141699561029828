import { Flex } from "@chakra-ui/react";
import Loader from "../../../UI/Loader";
import StatItem from "../../StatItem";
import { BsPeopleFill } from "react-icons/bs";
import { FaTasks } from "react-icons/fa";
import { TfiBriefcase } from "react-icons/tfi";
import { MdRequestPage } from "react-icons/md";
import { useEffect, useState } from "react";
import KPI from "../lawyer/KPI";
// import { getTasks } from "../../../../util/http";




export default function HeadStat({ briefs }) {
  const [progress, setProgress] = useState(0);
  const [activeBrief, setActiveBrief] = useState(0);
  const [pendingBrief, setPendingBrief] = useState(0);
  const [completedBrief, setCompletedBrief] = useState(0);
  const [tasks, setTasks] = useState(0);

  useEffect(() => {
    async function fetchData() {
      setActiveBrief(
        briefs?.filter((el) => el.status?.toLowerCase() === "active")?.length
      );

      // pending brief
      setPendingBrief(
        briefs?.filter((el) => el.status?.toLowerCase() === "pending").length
      );

      // completed briefs
      setCompletedBrief(
        briefs?.filter((el) => el.status?.toLowerCase() === "completed").length
      );
    }
    fetchData();
  }, [briefs]);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       setProgress(20);
  //       setProgress(40);
  //       setProgress(60);
  //       const response = await getTasks();
  //       setTasks(
  //         response?.tasks?.filter(
  //           (el) => el.status?.toLowerCase() === "ongoing"
  //         )?.length
  //       );
  //       setProgress(80);
  //       setProgress(100);
  //     } catch (error) {
  //       setProgress(100);
  //     }
  //   }
  //   fetchData();
  // }, []);

  return (
    <>
      <Loader progress={progress} setProgress={setProgress} />
      <Flex
        w="100%"
        wrap="wrap"
        gap="1.5rem"
        align="center"
        justify="space-between"
        py="3rem"
        px="1rem"
        borderRadius="1rem"
        bg="var(--center)"
      >
        <StatItem
          icon={<FaTasks color="orange" fontSize={24} />}
          title="Ongoing Tasks"
          amount={tasks}
          bg="rgba(255, 213, 128, 0.4)"
        />

        <StatItem
          icon={<TfiBriefcase color="orange" fontSize={24} />}
          title="Active Briefs"
          amount={activeBrief}
          bg="rgba(255, 213, 128, 0.4)"
        />

        {false && (
          <StatItem
            icon={<TfiBriefcase color="green" fontSize={24} />}
            bg="rgba(144, 238, 144, 0.4)"
            title="Completed Briefs"
            amount={completedBrief}
          />
        )}
        <KPI />
      </Flex>
    </>
  );
}
