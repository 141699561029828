import { Button, CloseButton, Flex, Heading, useToast } from "@chakra-ui/react";
import Modal from "../../overlay/Modal";
import InputComponent from "../../UI/Input";
import { AiOutlineSchedule } from "react-icons/ai";
import { useState } from "react";
import { scheduleMeeting } from "../../../util/http";

export default function ScheduleMeeting({ show, brief }) {
  const [reason, setReason] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState();
  const toast = useToast();

  function closeModal() {
    show(false);
  }

  async function scheduleHandler() {
    const scheduleDate = new Date(date);

    const startDay = scheduleDate.getDate();
    const startMonth = scheduleDate.getMonth() + 1;
    const startYear = scheduleDate.getFullYear();
    const startHour = scheduleDate.getHours();
    const startMin = scheduleDate.getMinutes();
    const startSec = scheduleDate.getSeconds();

    const data = {
      reason,
      date: `${startYear}-${startMonth.toString().padStart(2, 0)}-${startDay
        .toString()
        .padStart(2, 0)} ${startHour.toString().padStart(2, 0)}:${startMin
        .toString()
        .padStart(2, 0)}:${startSec.toString().padStart(2, 0)}`,
      brief_id: brief?.id,
      // client_id: brief?.client_id,
    };

    if (!date || !reason) {
      toast({
        title: `Fields missing`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await scheduleMeeting(data);
      toast({
        title: `Meeting request sent`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      closeModal();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }
  return (
    <Modal hide={closeModal}>
      <Flex w="100%" align="start" direction="column" gap="1.5rem" py="1rem">
        <Flex w="100%" align="center" justify="space-between">
          <Heading size="md" color="#333" fontWeight="bold">
            Schedule Meeting
          </Heading>
          <CloseButton onClick={closeModal} />
        </Flex>
        <Heading
          textTransform="capitalize"
          size="xs"
          color="#333"
          fontWeight="normal"
        >
          use this festure to schedule meeting with our team
        </Heading>
        <InputComponent
          onChange={(e) => setReason(e.target.value)}
          value={reason}
          placeholder="Enter reason here"
          label="Reason"
        />
        <InputComponent
          onChange={(e) => setDate(e.target.value)}
          value={date}
          type="datetime-local"
          label="Date & Time"
        />
        <Button
          isLoading={loading}
          loadingText="Processing"
          onClick={scheduleHandler}
          leftIcon={<AiOutlineSchedule />}
          bg="brand.900"
          color="white"
        >
          Schedule
        </Button>
      </Flex>
    </Modal>
  );
}
