import { Flex } from "@chakra-ui/react";
import React, { Fragment } from "react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import { FiEdit2 } from "react-icons/fi";
import ProfileTab from "./ProfileTab";
import { useNavigate } from "react-router-dom";

export default function ProfileContainer() {
  const navigate = useNavigate();
  return (
    <Fragment>
      <Flex
        w="100%"
        align="start"
        direction="column"
        h="100%"
        gap="1rem"
        p={{ lg: "2rem", md: "1rem", base: "6px" }}
      >
        <ReusableEmployeeHeader
          title="Profile"
          text="Manage your profile details here"
          btnText="Edit Profile"
          show={true}
          icon={<FiEdit2 />}
          onClick={() => navigate("/profile/edit")}
        />
        <ProfileTab />
      </Flex>
    </Fragment>
  );
}
