import { Flex } from "@chakra-ui/react";
import React from "react";
import InvoiceItem from "./InvoiceItem";

export default function InvoiceList({ invoice }) {
  return (
    <Flex align="center" w="100%" direction="column">
      {invoice?.length > 0 ? (
        <>
          {invoice?.map((item, i) => (
            <InvoiceItem key={i} item={item} />
          ))}
        </>
      ) : (
        <Flex align="center" h="30vh" justify="center" w="100%">
          No Invoice found
        </Flex>
      )}
    </Flex>
  );
}
