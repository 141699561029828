import React from "react";
import Loader from "../../../UI/Loader";
import { useState } from "react";
import { Flex } from "@chakra-ui/react";
import StatItem from "../../StatItem";
import { FaTasks } from "react-icons/fa";
import KPI from "../lawyer/KPI";

export default function UserStat() {
  const [progress, setProgress] = useState(0);
  return (
    <>
      <Loader progress={progress} setProgress={setProgress} />
      <Flex
        w="100%"
        wrap="wrap"
        gap="1.5rem"
        align="center"
        justify="space-between"
        py="3rem"
        px="1rem"
        borderRadius="1rem"
        bg="var(--center)"
      >
        <StatItem
          icon={<FaTasks color="red" fontSize={24} />}
          title="Overdue Tasks"
          amount={0}
          bg="red.100"
        />
        <StatItem
          icon={<FaTasks color="orange" fontSize={24} />}
          title="Ongoing Tasks"
          amount={0}
          bg="rgba(255, 213, 128, 0.4)"
        />

        <KPI />
      </Flex>
    </>
  );
}
