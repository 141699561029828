import { Flex, Button, useToast } from "@chakra-ui/react";
import ReusableModal from "../../UI/ReusableModal";
import SelectComponent from "../../UI/Select";
import { useState } from "react";

export default function UpdatePriority({ isOpen, onClose }) {
  const [priority, setPriority] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function updateHandler() {
    if (!priority) {
      toast({
        title: "Select a priority",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);

      setLoading(false);
      onClose();
      toast({
        title: `Requisition Priority Updated`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <ReusableModal title="Update Priority" isOpen={isOpen} onClose={onClose}>
      <Flex w="100%" align="start" direction="column" gap="1rem">
        <SelectComponent
          label="Select Priority"
          options={[
            "Select Priority level",
            "Normal",
            "Urgent",
            "Important",
            "Urgent & Important",
          ]}
        />
        <Button
          isLoading={loading}
          loadingText=""
          onClick={updateHandler}
          w="100%"
          variant="solid"
          bg="brand.900"
          color="white"
        >
          Save
        </Button>
      </Flex>
    </ReusableModal>
  );
}
