import { Button, Flex } from "@chakra-ui/react";
import ReusableModal from "../../UI/ReusableModal";
import InputComponent from "../../UI/Input";
import { useState } from "react";

export default function ActivateAppraisal({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    start: "",
    end: "",
    loading: false,
  });

  async function activateHandler() {}

  return (
    <ReusableModal title="Activate Appraisal" isOpen={isOpen} onClose={onClose}>
      <Flex w="100%" align="start" direction="column" gap="1rem">
        <InputComponent
          onChange={(e) => setFormData({ ...formData, start: e.target.value })}
          value={formData.start}
          type="datetime-local"
          label="Start Date"
        />
        <InputComponent
          onChange={(e) => setFormData({ ...formData, end: e.target.value })}
          value={formData.end}
          type="datetime-local"
          label="End Date"
        />
        <Button
          isLoading={formData.loading}
          loadingText=""
          onClick={activateHandler}
          w="100%"
          variant="solid"
          bg="brand.900"
          color="white"
        >
          Activate
        </Button>
      </Flex>
    </ReusableModal>
  );
}
