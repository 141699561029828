import { useEffect } from "react";
import ReusableFilter from "../UI/ReusableFilter";
import { getEmployees } from "../../util/http";
import { useState } from "react";

export default function EmployeeSubHeader({ setList }) {
  const [status, setStatus] = useState("");
  useEffect(() => {
    async function sortData() {
      if (!status) {
        return;
      } else {
        try {
          const response = await getEmployees();

          const suspendedEmployees = response?.employees
            ?.filter((el) => el.is_banned === "1" && el.deleted_at === null)
            .reverse();

          const archivedEmployees = response?.employees
            ?.filter((el) => el.deleted_at !== null)
            .reverse();

          if (status === "all" || !status) {
            setList(
              response?.employees
                ?.filter((el) => el.deleted_at === null)
                .reverse()
            );
          } else if (status === "suspended") {
            setList(suspendedEmployees);
          } else if (status === "archived") {
            setList(archivedEmployees);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    sortData();
  }, [status]);

  function activeFilter() {
    setStatus("all");
  }

  function suspendedFilter() {
    setStatus("suspended");
  }

  function archivedFilter() {
    setStatus("archived");
  }

  async function searchHandler(e) {
    const response = await getEmployees();
    const filteredData = response?.employees.filter(
      (el) =>
        el.first_name
          ?.toLowerCase()
          .includes(e.target.value.trim().toLowerCase()) ||
        el.last_name
          ?.toLowerCase()
          .includes(e.target.value.trim().toLowerCase())
    );
    setList(filteredData);
  }

  return (
    <ReusableFilter
      menu={[
        { text: "Active", onClick: activeFilter },
        { text: "Suspended", onClick: suspendedFilter },
        { text: "Archived", onClick: archivedFilter },
      ]}
      onSearch={searchHandler}
    />
  );
}
