import { useContext } from "react";
import { AuthContext } from "../../../util/context";
import Report from "../../reports/Report";
import Login from "../../auth/Login";
import Dashboard from "../../dashboard/Dashboard";

export default function ReportPage() {
  let view;
  const { isAuthenticated, user, isUser } = useContext(AuthContext);

  const viewReport =
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "seed" ||
    user?.job_role?.toLowerCase() === "accountant"
    // hod
    || user?.job_role?.toLowerCase() === "hod";



  if (viewReport) {
    view = <Report />;
  } else {
    view = <Dashboard />;
  }
  return <>{isAuthenticated && isUser ? view : <Login />}</>;
}
