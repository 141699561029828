import DashboardReusable from "../UI/DashboardReusable";
import ProfileContainer from "./ProfileContainer";

export default function Profile() {
  return (
    <DashboardReusable>
      <ProfileContainer />
    </DashboardReusable>
  );
}
