import { Flex, Button, Heading, Divider, useToast } from "@chakra-ui/react";
import SelectComponent from "../../UI/Select";
import { ArrowDownIcon, ArrowUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "../../overlay/Modal";
import { generateReport, getInvoices } from "../../../util/http";
import { filterDate } from "../../../util/lib";
import InvoiceChart from "../charts/InvoiceChart";
import { BsDashLg } from "react-icons/bs";
import ReusableText from "../../UI/ReusableText";

function monthFilter(data, month, status) {
  return data?.filter((el) => {
    const createdMonth = new Date(el.created_at).getMonth() + 1;
    return +createdMonth === +month && el?.status?.toLowerCase() === status;
  });
}

export default function InvoiceReportDetail({ show }) {
  const [days, setDays] = useState(0);
  const [filter, setFilter] = useState("weekly");
  const [status, setStatus] = useState("");
  const [paidData, setPaidData] = useState(0);
  const [pendingData, setPendingData] = useState(0);
  const [partialData, setPartialData] = useState(0);
  const [paid, setPaid] = useState({
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  });

  const [partial, setPartial] = useState({
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  });

  const [pending, setPending] = useState({
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  });

  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function reportHandler() {
    const invoiceReport = [
      {
        paidInvoices: paid,
        pendingInvoices: pending,
        partialInvoices: partial,
      },
    ];

    const data = {
      report: "Clients",
      data: JSON.stringify(invoiceReport),
    };

    try {
      setLoading(true);
      await generateReport(data);
      toast({
        title: `Invoice report generated`,
        description: "",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  useEffect(() => {
    if (filter?.toLowerCase() === "daily") {
      setDays(1);
    } else if (filter?.toLowerCase() === "weekly") {
      setDays(7);
    } else if (filter?.toLowerCase() === "monthly") {
      setDays(30);
    } else {
      setDays("");
    }
  }, [filter]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getInvoices();

        const invoices = response?.invoices?.filter((el, i) => {
          if (!days) {
            return el;
          }
          const milliSec = +days * 24 * 60 * 60 * 1000;
          const startDate = filterDate(milliSec);
          const createdDate = new Date(el?.created_at);
          return startDate < createdDate;
        });

        if (!status || status?.toLowerCase() === "pending") {
          setPending({
            January: monthFilter(invoices, 1, "pending")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            February: monthFilter(invoices, 2, "pending")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            March: monthFilter(invoices, 3, "pending")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            April: monthFilter(invoices, 4, "pending")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            May: monthFilter(invoices, 5, "pending")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            June: monthFilter(invoices, 6, "pending")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            July: monthFilter(invoices, 7, "pending")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            August: monthFilter(invoices, 8, "pending")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            September: monthFilter(invoices, 9, "pending")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            October: monthFilter(invoices, 10, "pending")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            November: monthFilter(invoices, 11, "pending")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            December: monthFilter(invoices, 12, "pending")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
          });
        } else {
          setPending([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
        }

        if (!status || status?.toLowerCase() === "partial") {
          setPartial({
            January: monthFilter(invoices, 1, "partial")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            February: monthFilter(invoices, 2, "partial")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            March: monthFilter(invoices, 3, "partial")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            April: monthFilter(invoices, 4, "partial")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            May: monthFilter(invoices, 5, "partial")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            June: monthFilter(invoices, 6, "partial")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            July: monthFilter(invoices, 7, "partial")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            August: monthFilter(invoices, 8, "partial")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            September: monthFilter(invoices, 9, "partial")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            October: monthFilter(invoices, 10, "partial")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            November: monthFilter(invoices, 11, "partial")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            December: monthFilter(invoices, 12, "partial")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
          });
        } else {
          setPartial([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
        }

        if (!status || status?.toLowerCase() === "paid") {
          setPaid({
            January: monthFilter(invoices, 1, "paid")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            February: monthFilter(invoices, 2, "paid")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            March: monthFilter(invoices, 3, "paid")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            April: monthFilter(invoices, 4, "paid")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            May: monthFilter(invoices, 5, "paid")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            June: monthFilter(invoices, 6, "paid")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            July: monthFilter(invoices, 7, "paid")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            August: monthFilter(invoices, 8, "paid")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            September: monthFilter(invoices, 9, "paid")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            October: monthFilter(invoices, 10, "paid")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            November: monthFilter(invoices, 11, "paid")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
            December: monthFilter(invoices, 12, "paid")
              ?.map((el) => +el.amount)
              ?.reduce((acc, sum) => acc + sum, 0),
          });
        } else {
          setPaid([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [days, status]);

  useEffect(() => {
    const paidInvoicesArr = Object.values(paid);

    const totalPaidInvoices = paidInvoicesArr?.reduce(
      (acc, sum) => acc + sum,
      0
    );
    setPaidData(totalPaidInvoices);
  }, [paid]);

  useEffect(() => {
    const partialInvoicesArr = Object.values(partial);

    const totalPartialInvoices = partialInvoicesArr?.reduce(
      (acc, sum) => acc + sum,
      0
    );
    setPartialData(totalPartialInvoices);
  }, [partial]);

  useEffect(() => {
    const pendingInvoicesArr = Object.values(pending);
    const totalPendingInvoices = pendingInvoicesArr?.reduce(
      (acc, sum) => acc + sum,
      0
    );
    setPendingData(totalPendingInvoices);
  }, [pending]);

  function closeModal() {
    show(false);
  }
  return (
    <Modal hide={closeModal}>
      <Flex align="start" direction="column" gap="2rem" w="100%">
        <Heading size="md" color="var(--container_color)" fontWeight={600}>
          Invoice Report
        </Heading>
        <Divider />
        <Flex w="100%" align="center" justify="space-between">
          <Flex align="center" w="60%" gap="1rem">
            <SelectComponent
              label="Status"
              onChange={(e) => setStatus(e.value)}
              options={["Select Status", "Paid", "Pending", "Partial"]}
            />

            <SelectComponent
              label="Duration"
              onChange={(e) => setFilter(e.value)}
              options={["Daily", "Weekly", "Monthly", "All Time"]}
            />
          </Flex>
          <Button
            variant="solid"
            bg="brand.900"
            color="white"
            rightIcon={<ChevronDownIcon />}
            isLoading={loading}
            loadingText="Generating"
            onClick={reportHandler}
          >
            Generate Report
          </Button>
        </Flex>
        <Flex align="center" gap="2rem">
          <Flex align="center" gap="1rem">
            <Flex
              align="center"
              p="0.8rem"
              bg="rgba(255, 150, 93, 0.3)"
              justify="center"
              borderRadius="16px"
            >
              <ArrowUpIcon color="#FF965D" />
            </Flex>
            <Flex align="start" direction="column">
              <ReusableText
                text={`₦${new Intl.NumberFormat().format(paidData)}.00`}
                color="var(--container_color)"
                size="16px"
                weight="bold"
              />
              <ReusableText
                text="Paid Invoices"
                color="var(--container_color)"
                size="14px"
                weight="light"
              />
            </Flex>
          </Flex>

          <Flex align="center" gap="1rem">
            <Flex
              align="center"
              p="0.8rem"
              bg="rgba(48, 79, 253, 0.3)"
              justify="center"
              borderRadius="16px"
            >
              <BsDashLg color="#304FFD" />
            </Flex>
            <Flex align="start" direction="column">
              <ReusableText
                text={`₦${new Intl.NumberFormat().format(partialData)}.00`}
                color="var(--container_color)"
                size="16px"
                weight="bold"
              />
              <ReusableText
                text="Partial Invoice"
                color="var(--container_color)"
                size="14px"
                weight="light"
              />
            </Flex>
          </Flex>

          <Flex align="center" gap="1rem">
            <Flex
              align="center"
              p="0.8rem"
              bg="rgba(73, 201, 109, 0.3)"
              justify="center"
              borderRadius="16px"
            >
              <ArrowDownIcon color="#49C96D" />
            </Flex>
            <Flex align="start" direction="column">
              <ReusableText
                text={`₦${new Intl.NumberFormat().format(pendingData)}.00`}
                color="var(--container_color)"
                size="16px"
                weight="bold"
              />
              <ReusableText
                text="Pending Invoice"
                color="var(--container_color)"
                size="14px"
                weight="light"
              />
            </Flex>
          </Flex>
        </Flex>
        <InvoiceChart
          paid={Object.values(paid)}
          pending={Object.values(pending)}
          partial={Object.values(partial)}
        />
      </Flex>
    </Modal>
  );
}
