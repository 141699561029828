import { Button, CloseButton, Flex, Heading, useToast } from "@chakra-ui/react";
import Modal from "../../overlay/Modal";
import InputComponent from "../../UI/Input";
import { useState } from "react";
import { createBriefTag } from "../../../util/http";

export default function AddTags({ show, briefId }) {
  const [tag, setTag] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  function closeModal() {
    show(false);
  }

  async function createHandler() {
    const data = {
      tag,
      brief_id: briefId,
      name: tag,
    };
    if (!tag) {
      toast({
        title: `Enter tag name`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await createBriefTag(data);
      toast({
        title: `Tag created`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      closeModal();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Modal hide={closeModal}>
      <Flex w="100%" align="start" direction="column" gap="1.5rem" py="3rem">
        <Flex w="100%" align="center" justify="space-between">
          <Heading
            size="md"
            color="var(--container_color)"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Create Tag
          </Heading>
          <CloseButton onClick={closeModal} colorScheme="red" />
        </Flex>
        <InputComponent
          label="Enter Tag"
          placeholder="Enter tag name"
          onChange={(e) => setTag(e.target.value)}
        />
        <Button
          isLoading={loading}
          loadingText="Submitting"
          onClick={createHandler}
          bg="brand.900"
          color="#fff"
          size="md"
          w="100%"
        >
          Save Update
        </Button>
      </Flex>
    </Modal>
  );
}
