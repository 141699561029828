import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
} from "@chakra-ui/react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import CustomList from "./CustomList";
import AppraisalSubheader from "./AppraisalSubheader";
import { useContext } from "react";
import { AuthContext } from "../../util/context";

export default function AppraisalContainer({ list, setList }) {
  const { user } = useContext(AuthContext);

  const hodView =
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase().includes("hop");

  const canViewHod = hodView || user?.job_role?.toLowerCase() === "seed";
  const canViewHr =
    user?.job_role?.toLowerCase() === "seed" ||
    user?.job_role?.toLowerCase() === "hr";
  const canViewSeed = user?.job_role?.toLowerCase() === "seed";

  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      gap="1rem"
      p={{ lg: "2rem", md: "1rem", base: "6px" }}
    >
      <ReusableEmployeeHeader
        title="Appraisals"
        text="Manage your appraisals and their details here"
      />
      <Tabs w="100%" variant="solid-rounded">
        <TabList p="0.2rem" borderRadius="8px" w="100%" bg="#E8E9EB">
          <Tab
            borderRadius="8px"
            _selected={{ color: "#515050", bg: "white" }}
            w="50%"
            color="#222"
          >
            EMPLOYEE
          </Tab>

          <Tab
            isDisabled={!canViewHod}
            borderRadius="8px"
            _selected={{ color: "#515050", bg: "white" }}
            w="50%"
            color="#222"
          >
            HOD
          </Tab>

          <Tab
            isDisabled={!canViewHr}
            borderRadius="8px"
            _selected={{ color: "#515050", bg: "white" }}
            w="50%"
            color="#222"
          >
            HR
          </Tab>
          <Tab
            isDisabled={!canViewSeed}
            borderRadius="8px"
            _selected={{ color: "#515050", bg: "white" }}
            w="50%"
            color="#222"
          >
            MANAGEMENT
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Flex w="100%" align="start" direction="column" gap="1.5rem">
              <AppraisalSubheader
                setList={setList}
                list={list}
                tab="employee"
              />
              <CustomList list={list.employee} />
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex w="100%" align="start" direction="column" gap="1.5rem">
              <AppraisalSubheader setList={setList} list={list} tab="hod" />
              <CustomList list={list.hod} />
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex w="100%" align="start" direction="column" gap="1.5rem">
              <AppraisalSubheader setList={setList} list={list} tab="hr" />
              <CustomList list={list.hr} />
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex w="100%" align="start" direction="column" gap="1.5rem">
              <AppraisalSubheader setList={setList} list={list} tab="seed" />
              <CustomList list={list.management} />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
