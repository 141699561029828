import {
  Avatar,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import React from "react";
import InputComponent from "../../UI/Input";
import { useState } from "react";
import { getStoredItem } from "../../../util/lib";

export default function DetailForm({
  setEmail,
  setEmployeeId,
  setFirstName,
  setLastName,
  setPhone,
  setRole,
  email,
  employeeId,
  firstName,
  lastName,
  phone,
  role,
  setImage,
}) {
  const [url, setUrl] = useState("");
  const userType = getStoredItem("user-type");
  return (
    <Flex
      w="100%"
      h={{
        lg: userType === "employee" ? "500px" : "300px",
        md: "100%",
        base: "100%",
      }}
      direction="column"
      align="start"
      gap="2rem"
    >
      <input
        type="file"
        id="image"
        style={{ display: "none" }}
        onChange={(e) => {
          setImage(e.target.files[0]);
          setUrl(URL.createObjectURL(e.target.files[0]));
        }}
      />
      <label htmlFor="image">
        <Avatar
          size={{ lg: "2xl", md: "xl", base: "lg" }}
          name={`${firstName} ${lastName}`}
          src={url}
        />
      </label>
      <Flex align="center" direction="column" gap="2rem" w="100%">
        <Flex align="center" w="100%" justify="center" gap="1rem">
          <InputComponent
            placeholder="Enter first name"
            label="First Name"
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            readOnly={true}
          />
          <InputComponent
            label="Last Name"
            placeholder="Enter last name"
            type="text"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            readOnly={true}
          />
        </Flex>

        <Flex align="center" w="100%" justify="center" gap="1rem">
          <InputComponent
            placeholder="Enter email address"
            label="Email"
            type="email"
            readOnly={true}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <FormControl>
            <FormLabel>Phone Number</FormLabel>
            <Flex border="1px solid #ccc" borderRadius="0.5rem" align="center">
              <Select border="none" w="30%">
                <option value="234">+234</option>
              </Select>
              <Input
                type="number"
                border="none"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                placeholder="XXX XXX XXXX"
                focusBorderColor="transparent"
              />
            </Flex>
          </FormControl>
        </Flex>

        {userType === "employee" && (
          <Flex align="center" w="100%" justify="center" gap="1rem">
            <InputComponent
              placeholder="LOA013"
              label="Employee ID"
              type="text"
              readOnly={true}
              onChange={(e) => setEmployeeId(e.target.value)}
              value={employeeId}
            />
            <InputComponent
              label="Job Role"
              placeholder="Human Resources"
              type="text"
              readOnly={true}
              onChange={(e) => setRole(e.target.value)}
              value={role}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
