import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,

  Button,
  Link,
} from '@chakra-ui/react';
import DocViewer from '@cyntler/react-doc-viewer';

const
ViewDocument = ({ fileUrl , isOpen, onOpen, onClose }) => {

    const url = 'https://corsproxy.io/?' + encodeURIComponent(fileUrl)

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>File Viewer</ModalHeader>
          <ModalCloseButton />
          <ModalBody height={'max-content'}>
            <DocViewer  style={{
              height: '80vh' , width:'100%'
            }} documents={[{ uri: url , } ]} />
          </ModalBody>
          <ModalFooter>
            <Link href={fileUrl} download>
              <Button bg={'#364273'} colorScheme="messenger" mr={3}>
                Download
              </Button>
            </Link>
            <Button  bg={'#364273'} colorScheme="messenger" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewDocument;
