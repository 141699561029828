import CompletedList from "./CompletedList";
import TaskWrapper from "../TaskWrapper";

export default function Completed({ list, setList }) {
  return (
    <TaskWrapper title="COMPLETED" total={list?.length}>
      <CompletedList setList={setList} list={list} />
    </TaskWrapper>
  );
}
