import { useEffect, useState } from "react";
import { getStoredItem, storeItem } from "../../util/lib";
import { MdDarkMode, MdLightMode } from "react-icons/md";

export default function DarkMode() {
  const [mode, setMode] = useState("light");
  function setDarkMode() {
    document.querySelector("body").setAttribute("data-theme", "black");
  }

  function setLightMode() {
    document.querySelector("body").setAttribute("data-theme", "light");
  }

  useEffect(() => {
    const mode = getStoredItem("mode") || "light";
    if (mode === "light") {
      setLightMode();
    } else {
      setDarkMode();
    }
    setMode(mode);
  }, []);

  return (
    <>
      {mode === "light" ? (
        <MdDarkMode
          onClick={() => {
            setDarkMode();
            storeItem("mode", "black", 172800000);
            setMode("black");
          }}
          size={20}
          cursor="pointer"
          color="#000"
        />
      ) : (
        <MdLightMode
          color="white"
          onClick={() => {
            setLightMode();
            storeItem("mode", "light", 172800000);
            setMode("light");
          }}
          size={20}
          cursor="pointer"
        />
      )}
    </>
  );
}
