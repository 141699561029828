import { Flex } from "@chakra-ui/react";
import React, { Fragment } from "react";
import InvoicesItem from "./InvoiceItem";
import LoadingSkeleton from "../../UI/LoadingSkeleton";

export default function InvoiceList({ list, loading }) {
  const data = [1, 1, 1, 1, 1, 1, 1];
  

  return (
    <Fragment>
      {loading ? (
        <>
          {data.map((el, i) => (
            <LoadingSkeleton key={i} lines={1} />
          ))}
        </>
      ) : (
        <Flex
          w="100%"
          direction="column"
          border="1px solid #ccc"
          borderTop="none"
          maxHeight="85%"
          overflow="scroll"
        >
          {list?.length > 0 ? (
            <>
              {list?.map((item, i) => (
                <InvoicesItem item={item} path="invoicing" key={i} />
              ))}
            </>
          ) : (
            <Flex align="center" w="100%" justify="center" h="50vh">
              Empty Invoice
            </Flex>
          )}
        </Flex>
      )}
    </Fragment>
  );
}
