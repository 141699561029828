import { Avatar, Badge, Box, Flex, Heading } from "@chakra-ui/react";
import ReusableText from "../../../UI/ReusableText";

export default function TaskSubHeader({ task }) {
  const team = task?.assignee && JSON.parse(task?.assignee);

  return (
    <Flex
      w="100%"
      align="center"
      gap="1rem"
      bg="#fff"
      h="200px"
      borderRadius={16}
      boxShadow="lg"
      p="1rem"
    >
      <Avatar size="2xl" name={task?.title} />
      <Flex align="start" w="100%" gap="1rem" direction="column">
        <Heading
          textTransform="capitalize"
          size="md"
          color="#222"
          fontWeight="bold"
        >
          {task?.title}
        </Heading>
        <Flex w="100%" align="center" gap="1.5rem">
          <Flex w="30%" align="start" gap="1rem" direction="column">
            <Flex w="100%" align="center" justify="space-between">
              <Heading size="sm" color="#333" fontWeight="bold">
                Progress
              </Heading>
              <Heading
                size="sm"
                color={
                  task?.status?.toLowerCase() === "todo"
                    ? "gray.400"
                    : task?.status?.toLowerCase() === "ongoing"
                    ? "orange.400"
                    : "green.400"
                }
                fontWeight="bold"
              >
                {task?.status?.toLowerCase() === "todo"
                  ? "10%"
                  : task?.status?.toLowerCase() === "ongoing"
                  ? "50%"
                  : "100%"}
              </Heading>
            </Flex>
            <Flex
              title={
                task?.status?.toLowerCase() === "todo"
                  ? "10%"
                  : task?.status?.toLowerCase() === "ongoing"
                  ? "50%"
                  : "100%"
              }
              w="100%"
              height="8px"
              align="center"
              borderRadius={24}
              bg={
                task?.status?.toLowerCase() === "todo"
                  ? "gray.100"
                  : task?.status?.toLowerCase() === "ongoing"
                  ? "orange.100"
                  : "green.100"
              }
            >
              <Flex
                w={
                  task?.status?.toLowerCase() === "todo"
                    ? "10%"
                    : task?.status?.toLowerCase() === "ongoing"
                    ? "50%"
                    : "100%"
                }
                bg={
                  task?.status?.toLowerCase() === "todo"
                    ? "gray.400"
                    : task?.status?.toLowerCase() === "ongoing"
                    ? "orange.400"
                    : "green.400"
                }
                borderRadius={24}
                h="8px"
              ></Flex>
            </Flex>
          </Flex>
          <Flex align="start" direction="column" gap="1rem">
            <Heading size="sm" color="#333" fontWeight="bold">
              Status
            </Heading>
            <Badge
              colorScheme={
                task?.status?.toLowerCase() === "todo"
                  ? "gray"
                  : task?.status?.toLowerCase() === "ongoing"
                  ? "orange"
                  : "green"
              }
            >
              {task?.status}
            </Badge>
          </Flex>

          <Flex align="start" direction="column" gap="1rem">
            <Heading size="sm" color="#333" fontWeight="bold">
              Start Date
            </Heading>
            <Heading
              textTransform="capitalize"
              size="sm"
              color={"#333"}
              fontWeight={"normal"}
            >
              {new Date(task?.start_date).toLocaleDateString()}
            </Heading>
          </Flex>

          <Flex align="start" direction="column" gap="1rem">
            <Heading size="sm" color="#333" fontWeight="bold">
              Due Date
            </Heading>
            <Heading
              textTransform="capitalize"
              size="sm"
              color={
                new Date() >= new Date(task?.due_date) &&
                task?.status?.toLowerCase() !== "completed"
                  ? "red.600"
                  : "#333"
              }
              fontWeight={
                new Date() >= new Date(task?.due_date) &&
                task?.status?.toLowerCase() !== "completed"
                  ? "bold"
                  : "normal"
              }
            >
              {new Date(task?.due_date).toLocaleDateString()}
            </Heading>
          </Flex>

          <Flex align="start" direction="column" gap="1rem">
            <Heading size="sm" color="#333" fontWeight="bold">
              Created By
            </Heading>
            <Heading
              textTransform="capitalize"
              size="sm"
              color={"#333"}
              fontWeight={"normal"}
            >
              {task?.assigned_by}
            </Heading>
          </Flex>

          <Flex align="start" direction="column" gap="1rem">
            <Heading size="sm" color="#333" fontWeight="bold">
              Client
            </Heading>
            <Heading
              textTransform="capitalize"
              size="sm"
              color="#333"
              fontWeight="normal"
            >
              {task?.client}
            </Heading>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
