import React, { useContext } from "react";
import NavItem from "../UI/NavItem";
import bell from "../images/Bell.png";
import { Flex } from "@chakra-ui/react";
import { getStoredItem } from "../../util/lib";
import { AuthContext } from "../../util/context";
import {
  MdDashboard,
  MdOutlineCalendarMonth,
  MdTransform,
} from "react-icons/md";
import { BsBriefcase, BsChatLeftDots } from "react-icons/bs";
import { FaTasks, FaUserAlt, FaUserFriends } from "react-icons/fa";
import { AiFillFolder, AiOutlineForm, AiOutlineSetting } from "react-icons/ai";
import {
  TbFileInvoice,
  TbMoneybag,
  TbMessageReport,
  TbTransform,
} from "react-icons/tb";

export default function Menu({ display }) {
  const userType = getStoredItem("user-type");
  const { user } = useContext(AuthContext);

  const viewEmployee =
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "seed";

  const viewClient =
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "accountant" ||
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "seed";

  const viewInvoice =
    user?.job_role?.toLowerCase() === "accountant" ||
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    userType === "client" ||
    user?.job_role?.toLowerCase() === "seed";

  const viewRequisition =
    user?.job_role?.toLowerCase() === "accountant" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "seed" ||
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "user" ;

  const viewSettings =
    user?.job_role?.toLowerCase() === "seed" ||
    user?.job_role?.toLowerCase() === "hr";

  const viewCalendar = true;
  // user?.job_role?.toLowerCase() === "lawyer" ||
  // user?.job_role?.toLowerCase().includes("hod") ||
  // user?.job_role?.toLowerCase() === "hop" ||
  // user?.job_role?.toLowerCase() === "hr" ||
  // user?.job_role?.toLowerCase() === "seed";

  const viewReport =
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "seed"||
    user?.job_role?.toLowerCase() === "accountant"||
    user?.job_role?.toLowerCase() === "hod";


  const viewDocument =
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "seed" ||
    user?.job_role?.toLowerCase() === "hr";

  const viewTasks =
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "seed" ||
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "user";

  const viewBrief =
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "seed" ||
    userType === "client";

  return (
    <Flex
      w="100%"
      h={userType === "client" ? "67vh" : ""}
      align="center"
      justify="space-between"
      direction="column"
    >
      <Flex align="start" w="100%" direction="column">
        <NavItem
          label="Dashboard"
          display={display}
          icon={<MdDashboard size={display === "flex" ? 20 : 25} />}
          title="Dashboard"
          link=""
        />{" "}
        {viewBrief && (
          <NavItem
            label="Briefs"
            display={display}
            icon={<BsBriefcase size={display === "flex" ? 20 : 25} />}
            title="Briefs "
            link={viewBrief ? "brief" : ""}
          />
        )}
        {userType === "employee" && viewTasks && (
          <NavItem
            label="Tasks"
            display={display}
            icon={<FaTasks size={display === "flex" ? 20 : 25} />}
            title="Tasks "
            link={`${userType === "employee" && viewTasks ? "task" : ""}`}
          />
        )}
        {userType === "employee" && viewClient && (
          <NavItem
            label="Clients"
            display={display}
            icon={<FaUserAlt size={display === "flex" ? 20 : 25} />}
            title="Clients"
            link={`${userType === "employee" && viewClient ? "client" : ""}`}
          />
        )}
        {userType === "employee" && viewEmployee && (
          <NavItem
            label="Employees"
            display={display}
            icon={<FaUserFriends size={display === "flex" ? 20 : 25} />}
            title="Employees"
            link={`${
              userType === "employee" && viewEmployee ? "employees" : ""
            }`}
          />
        )}
        {userType === "employee" && viewDocument && (
          <NavItem
            label="Documents"
            display={display}
            icon={<AiFillFolder size={display === "flex" ? 20 : 25} />}
            title="Documents"
            link={`${
              userType === "employee" && viewDocument ? "document" : ""
            }`}
          />
        )}
        {viewCalendar && (
          <NavItem
            label="Calendar"
            display={display}
            icon={
              <MdOutlineCalendarMonth size={display === "flex" ? 20 : 25} />
            }
            title="Calendar"
            link={`${viewCalendar ? "calendar" : ""}`}
          />
        )}
        {viewInvoice && (
          <NavItem
            label="Invoicing"
            display={display}
            icon={<TbFileInvoice size={display === "flex" ? 20 : 25} />}
            title="Invoicing"
            link={`${viewInvoice ? "invoicing" : ""}`}
          />
        )}
        {false && (
          <NavItem
            display={display}
            icon={<BsChatLeftDots size={display === "flex" ? 20 : 25} />}
            title="Chat"
            link="chat"
            label="Chat"
          />
        )}
        {userType === "employee" && viewRequisition && (
          <NavItem
            label="Requisition"
            display={display}
            icon={<TbMoneybag size={display === "flex" ? 20 : 25} />}
            title="Requisitions"
            link={`${
              userType === "employee" && viewRequisition ? "requisitions" : ""
            }`}
          />
        )}
        {userType === "employee" && (
          <NavItem
            label="Appraisal"
            display={display}
            icon={<TbTransform size={display === "flex" ? 20 : 25} />}
            title="Appraisal"
            link="appraisal"
          />
        )}
        {userType === "employee" && viewReport && (
          <NavItem
            label="Reports"
            display={display}
            icon={<TbMessageReport size={display === "flex" ? 20 : 25} />}
            title="Reports"
            link={`${userType === "employee" && viewReport ? "reports" : ""}`}
          />
        )}
        {userType === "employee" && (
          <NavItem
            label="Leave"
            display={display}
            icon={<AiOutlineForm size={display === "flex" ? 20 : 25} />}
            title="Leave"
            link="leave"
          />
        )}
        {userType === "employee" && (
          <NavItem
            label="Query"
            display={display}
            icon={<MdTransform size={display === "flex" ? 20 : 25} />}
            title="Query"
            link="query"
          />
        )}
        {userType === "employee" && viewSettings && (
          <NavItem
            label="Settings"
            display={display}
            icon={<AiOutlineSetting size={display === "flex" ? 20 : 25} />}
            title="Settings"
            link={`${
              userType === "employee" && viewSettings ? "settings" : ""
            }`}
          />
        )}
      </Flex>
      {false && (
        <NavItem
          label="Notification"
          display={display}
          src={bell}
          title="Notifications"
          link="notification"
        />
      )}
    </Flex>
  );
}
