import { Divider, Flex, Heading } from "@chakra-ui/react";
import ReusableDownloadDoc from "../../../UI/ReusableDownloadDoc";

export default function TaskAttachments({ task }) {
  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      bg="white"
      gap="1rem"
      boxShadow="lg"
      borderRadius="16px"
      p="1rem"
    >
      <Heading size="sm" color="#333" fontWeight="bold">
        Task Attachments
      </Heading>
      <Divider />
      <Flex
        maxHeight="400px"
        overflow="scroll"
        w="100%"
        gap="1rem"
        align="start"
        direction="column"
      >
        <ReusableDownloadDoc docs={task?.attachments} />
      </Flex>
    </Flex>
  );
}
