import { Button, CloseButton, Flex, Heading, useToast } from "@chakra-ui/react";
import Modal from "../overlay/Modal";
import InputComponent from "../UI/Input";
import { useState } from "react";
import TextareaComponent from "../UI/Textarea";
import SelectComponent from "../UI/Select";
import { useEffect } from "react";
import { createLeaveType, listDepartments } from "../../util/http";

export default function CreateLeaveType({ show }) {
  const [type, setType] = useState("");
  const [duration, setDuration] = useState("");
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [role, setRole] = useState([]);
  const [list, setList] = useState([]);
  const toast = useToast();

  const roles = [
    "seed",
    "hr",
    "accountant",
    "hod",
    "hop",
    "lawyer",
    "user",
  ].map((a) => a.charAt(0).toUpperCase() + a.slice(1));

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await listDepartments();
        setList(response?.departments?.map((el) => el?.department));
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  async function leaveHandler() {
    if (!type || role?.length === 0 || !duration) {
      toast({
        title: `Details missing`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const data = {
      name: type,

      job_role: JSON.stringify(role?.map((el) => el.toLowerCase())),
      leave_count: duration,
    };

    try {
      setLoading(true);
      await createLeaveType(data);
      show(false);
      toast({
        title: `Leave type created`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  function closeModal() {
    show(false);
  }
  return (
    <Modal hide={closeModal}>
      <Flex w="100%" align="start" direction="column" gap="1.5rem" py="1rem">
        <Flex w="100%" align="center" justify="space-between">
          <Heading
            size="md"
            color="var(--container_color)"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Add Leave Type
          </Heading>
          <CloseButton onClick={closeModal} colorScheme="red" />
        </Flex>
        <InputComponent
          onChange={(e) => setType(e.target.value)}
          label="Leave Type"
          placeholder="Enter leave type"
        />

        <InputComponent
          onChange={(e) => setDuration(e.target.value)}
          label="Duration"
          placeholder="Enter leave duration"
          type="number"
        />
        <SelectComponent
          multiple={true}
          options={roles || []}
          label="Select Roles"
          onChange={(e) => {
            const role = e?.map((el) => el.value);
            setRole(role);
          }}
        />

        <Button
          isLoading={loading}
          loadingText="Creating"
          onClick={leaveHandler}
          bg="brand.900"
          color="#fff"
          size="md"
          w="100%"
        >
          Create
        </Button>
      </Flex>
    </Modal>
  );
}
