import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Box, Flex } from "@chakra-ui/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import CalendarDetail from "./CalendarDetail";
import { useState } from "react";

export default function CalenderCenter({ list, show, setStart, setType }) {
  const [showEvent, setShow] = useState(false);
  const [event, setEvent] = useState(null);

  function renderEventContent(e) {
    return (
      <>
        <Flex
          onClick={() => {
            setShow(true);
            setEvent(e);
          }}
          cursor="pointer"
          w="100%"
          align="start"
          direction="column"
        >
          <p style={{ wordWrap: "break-word" }}>{`${new Date(
            e.event.startStr
          ).getDate()}/${new Date(e.event.startStr).getMonth() + 1}-${new Date(
            e.event.endStr
          ).getDate()}/${new Date(e.event.endStr).getMonth() + 1}`}</p>
          <p
            style={{
              wordWrap: "break-word",
              textTransform: "capitalize",
            }}
          >
            {e.event.title}
          </p>
        </Flex>
      </>
    );
  }

  return (
    <>
      {showEvent && <CalendarDetail event={event} show={setShow} />}
      <Box w="100%" h="100%">
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            start: "title",
            center: "",
            end: "today,prev,next",
          }}
          events={list}
          dateClick={(arg) => {
            setType("custom");
            setStart(arg.dateStr);
            show(true);
            console.log(arg);
          }}
          eventContent={renderEventContent}
          selectable={true}
        />
      </Box>
    </>
  );
}
