import { Flex, useToast, Button } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";
import ReusablePassword from "./ReusablePassword";
import { useNavigate } from "react-router-dom";
import LoginActivity from "./LoginActivity";
import { useState } from "react";
import { updatePassword } from "../../../util/http";
import { useContext } from "react";
import { AuthContext } from "../../../util/context";

export default function PasswordContainer() {
  const navigate = useNavigate();
  const toast = useToast();
  const ctx = useContext(AuthContext);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  async function updateHandler() {
    if (!oldPassword && !newPassword && !confirmPassword) {
      toast({
        title: `Empty password fields not allowed`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (!oldPassword) {
      toast({
        title: `Empty password field not allowed`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (!newPassword) {
      toast({
        title: `Empty password field not allowed`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (!confirmPassword) {
      toast({
        title: `Empty password field not allowed`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      toast({
        title: `Passwords do not match`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const data = {
      old_password: oldPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    };

    try {
      setLoading(true);
      const response = await updatePassword(ctx.user?.id, data);
      setLoading(false);
      toast({
        title: `Success`,
        description: `Password Successfully updated`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      window.location.reload();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Flex w="100%" direction="column" gap="1rem">
      <ReusableText
        text="Password"
        color="var(--container_color)"
        size="16px"
        weight="bold"
      />
      <ReusableText
        text="Please enter your current password to change your password."
        color="var(--container_color)"
        size="14px"
        weight="light"
      />
      <ReusablePassword
        onChange={(e) => setOldPassword(e.target.value)}
        label="Current password"
        value={oldPassword}
      />
      <ReusablePassword
        onChange={(e) => setNewPassword(e.target.value)}
        label="New password"
        value={newPassword}
      />
      <ReusablePassword
        onChange={(e) => setConfirmPassword(e.target.value)}
        label="Confirm new password"
        value={confirmPassword}
      />

      <Flex py="1rem" w="100%" align="center" justify="end" gap="1rem">
        <Button
          onClick={() => navigate("/profile")}
          variant="outline"
          color="var(--container)"
        >
          Cancel
        </Button>
        <Button
          onClick={updateHandler}
          variant="solid"
          bg="var(--container)"
          color="var(--container_color)"
          isLoading={loading}
          loadingText="Processing"
        >
          Update Password
        </Button>
      </Flex>
      <LoginActivity />
    </Flex>
  );
}
