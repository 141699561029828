import React from "react";
import ViewInvoice from "../../invoice/view-invoice/ViewInvoice";
import { AuthContext } from "../../../util/context";
import { useContext } from "react";
import Login from "../../auth/Login";
import { getStoredItem } from "../../../util/lib";
import Dashboard from "../../dashboard/Dashboard";

export default function ViewInvoicePage() {
  let view;
  const { isAuthenticated, user, isUser } = useContext(AuthContext);
  const userType = getStoredItem("user-type");

  const viewInvoice =
    user?.job_role?.toLowerCase() === "accountant" ||
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    userType === "client" ||
    user?.job_role?.toLowerCase() === "seed";

  if (viewInvoice) {
    view = <ViewInvoice />;
  } else {
    view = <Dashboard />;
  }
  return <>{isAuthenticated && isUser ? view : <Login />}</>;
}
