import { Icon, Link, Text, Flex, Heading , Image  , Button} from "@chakra-ui/react";
import { motion } from "framer-motion";

import { FaArrowCircleRight } from "react-icons/fa";
// import { BiErrorCircle } from "react-icons/bi";

//const MotionIcon = motion(Icon);
const MotionLink = motion(Link);

const ErrorBoundary = () => {
  return (
    <Flex
     p={'1rem'}
      direction="column"
      align="center"
      justify="center"
      h="100vh"
      textAlign="center"
    >
      <Heading
        fontSize={{
          base: "1.5rem",
          lg: "2.5rem",
        }}
        color={'red.500'}

      >
        Error!
      </Heading>

      <Image src="/error_boundary.gif" alt="Error Image" h={{
        base: '15rem' , lg:'20rem' , '2xl':'25rem'
      }} w={{
        base: '15rem' , lg:'20rem' , '2xl':'25rem'
      }} />

      <Text mt={2} color={'gray.800'}>Oops! Something went wrong.</Text>
      <MotionLink
        href="/"
        mt={4}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        textDecoration="none"
        _focus={{ boxShadow: "none" }}
      >
        <Button gap={'1rem'} colorScheme="red"  align="center">
          <Icon  as={FaArrowCircleRight} w={6} h={6} mr={1} />
          <Text>Go to Home</Text>
        </Button>
      </MotionLink>
    </Flex>
  );
};

export default ErrorBoundary;
