import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Box,
} from "@chakra-ui/react";
import ImageComponent from "./Image";
import Menu from "../dashboard/Menu";
import MenuFooter from "../dashboard/MenuFooter";
import logo from "../images/logo.png";

export default function MobileMenu({ onClose, isOpen }) {
  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent bg="#101828">
        <DrawerCloseButton />
        <DrawerHeader>
          <Flex align="center" direction="column" gap="1rem">
            <Box>
              <ImageComponent
                alt="employee management"
                height="64px"
                src={logo}
              />
            </Box>
          </Flex>
        </DrawerHeader>

        <DrawerBody>
          <Flex
            w="280px"
            bg="#101828"
            align="start"
            p="1rem"
            direction="column"
            height="100vh"
            overflow="scroll"
          >
            <Flex w="100%" align="start" gap="2rem" direction="column">
              <Flex
                w="100%"
                h="85vh"
                align="start"
                direction="column"
                justify="space-between"
              >
                <Menu display="flex" />
                <MenuFooter display="flex" />
              </Flex>
            </Flex>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
