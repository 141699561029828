import { getStoredItem } from "../../../util/lib";
import AppraisalDetail from "../../appraisal/appraisal-detail/AppraisalDetail";
import Dashboard from "../../dashboard/Dashboard";

export default function ViewAppraisal() {
  const userType = getStoredItem("user-type");
  return (
    <>
      {userType?.toLowerCase() === "employee" ? (
        <AppraisalDetail />
      ) : (
        <Dashboard />
      )}
    </>
  );
}
