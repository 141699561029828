import ReusableInvoiceForm from "../UI/ReusableInvoiceForm";

export default function InvoiceForm({
  onChange1,
  onChange2,
  onChange3,
  array,
  setArray,
  type,
  onBlur,
  currency
}) {
  return (
    <ReusableInvoiceForm
      array={array}
      setArray={setArray}
      onChange1={onChange1}
      onChange2={onChange2}
      onChange3={onChange3}
      onBlur={onBlur}
      type={type}
      currency={currency}
    />
  );
}
