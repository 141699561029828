import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Button } from "@chakra-ui/react";

import { AiOutlineCloudDownload } from "react-icons/ai";

export default function DownloadPage({ element, name, setShow }) {
  const downloadPdfDocument = () => {
    const input = element.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("l", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;

      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save(`${name}.pdf`);
      setShow(false);
    });
  };
  return (
    <Button
      bg="brand.900"
      color="white"
      p="8px"
      leftIcon={<AiOutlineCloudDownload />}
      variant="solid"
      onClick={downloadPdfDocument}
    >
      Download now
    </Button>
  );
}
