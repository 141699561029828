import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function ClientChart({ expenses, income, expected }) {
  useEffect(() => {
    setChart({
      series: [
        {
          name: "Expenses",
          data: expenses,
        },
        {
          name: "Total Income",
          data: income,
        },
        {
          name: "Expected Income",
          data: expected,
        },
      ],
    });
  }, [expenses, income, expected]);

  const [chart, setChart] = useState({
    series: [
      {
        name: "Expenses",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Total Income",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Expected Income",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        title: {
          text: "Amount  (₦)",
        },
      },
      fill: {
        opacity: 1,
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return "₦ " + val;
          },
        },
      },
    },
  });
  return (
    <Box w="100%">
      <ReactApexChart
        options={chart.options}
        series={chart.series}
        type="bar"
        height={350}
        width="100%"
      />
    </Box>
  );
}
