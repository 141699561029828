import { useEffect, useState } from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import ViewContainer from "./ViewContainer";
import { getQuery } from "../../../util/http";
import { useParams } from "react-router-dom";

export default function ViewQuery() {
  const [query, setQuery] = useState("");

  const params = useParams();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getQuery(params.id);
        setQuery(response?.query);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <DashboardReusable>
      <ViewContainer query={query} />
    </DashboardReusable>
  );
}
