import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import { Flex } from "@chakra-ui/react";
import DocumentCenter from "./DocumentCenter";

export default function DocumentContainer({ list, setList }) {
  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      h="100%"
      gap="1rem"
      p="2rem"
    >
      <ReusableEmployeeHeader
        title="Documents"
        text="Manage your documents and files here"
      />
      <DocumentCenter setList={setList} list={list} />
    </Flex>
  );
}
