import { Flex } from "@chakra-ui/react";
import React, { Fragment } from "react";
import ReceiptItem from "./ReceiptItem";

export default function ReceiptList({ onOpen, list }) {
  return (
    <Fragment>
      <Flex
        w="100%"
        direction="column"
        border="1px solid #ccc"
        borderTop="none"
        maxHeight="85%"
        overflow="scroll"
      >
        {list?.length > 0 ? (
          <>
            {list?.map((item, i) => (
              <ReceiptItem
                onOpen={onOpen}
                item={item}
                path="invoicing"
                key={i}
              />
            ))}
          </>
        ) : (
          <Flex align="center" justify="center" h="20vh">
            No Receipt Found
          </Flex>
        )}
      </Flex>
    </Fragment>
  );
}
