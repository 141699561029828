import { Flex } from "@chakra-ui/react";
import React from "react";

import TaskItem from "./to-do/TaskItem";

export default function TaskListWrapper({ data, setList }) {
  return (
    <Flex
      height="80vh"
      overflow="scroll"
      w="100%"
      align="start"
      direction="column"
      gap="1rem"
      p="1.5rem"
      justify="start"
      // bg="rgba(228, 231, 236, 0.25)"
      // bg="#fff"
    >
      {data.map((item, i) => (
        <TaskItem setList={setList} item={item} key={i} />
      ))}
    </Flex>
  );
}
