import { Divider, Flex, Button } from "@chakra-ui/react";
import Modal from "../../overlay/Modal";
import Overlay from "../../overlay/OverLay";
import ReusableText from "../../UI/ReusableText";
import { AiOutlineClose, AiOutlineCloudDownload } from "react-icons/ai";
import { useEffect } from "react";
import { salaryReport } from "../../../util/http";
import { useState } from "react";
import PayslipTemplate from "../../UI/PayslipTemplate";
import DownloadPage from "../../UI/DownloadPage";
import { useRef } from "react";

export default function SalaryProgress({
  show,
  name,
  id,
  department,
  designation,
  salary,
}) {
  const slipRef = useRef();
  const [list, setList] = useState([]);
  const [showDownload, setShow] = useState(false);
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");

  function closeMenu() {
    show(false);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await salaryReport(id);
        setList(response?.report?.reverse());
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [id]);

  return (
    <>
      <Overlay hide={closeMenu} />
      <Modal hide={closeMenu}>
        <Flex w="100%" align="start" direction="column" gap="1.5rem" py="1rem">
          <Flex w="100%" align="center" justify="space-between">
            <ReusableText
              text={`${name} Salary Report`}
              weight="bold"
              size="18px"
              color="var(--container_color)"
            />
            <Button variant="ghost" onClick={closeMenu} colorScheme="red">
              <AiOutlineClose />
            </Button>
          </Flex>
          <Divider />
          <Flex w="100%" align="start" direction="column">
            <Flex w="100%" align="center" justify="space-between">
              <ReusableText
                color="var(--container_color)"
                text="Date"
                weight="medium"
                size="16px"
                w="33%"
              />
              <ReusableText
                color="var(--container_color)"
                text="Salary"
                weight="medium"
                size="16px"
                w="33%"
              />
              <ReusableText
                color="var(--container_color)"
                text="Action"
                weight="medium"
                size="16px"
                w="33%"
              />
            </Flex>
            {list?.length > 0 ? (
              <>
                {list?.map((el, i) => (
                  <Flex
                    key={i}
                    borderBottom="1px solid #ccc"
                    py="0.5rem"
                    w="100%"
                    align="center"
                    justify="space-between"
                  >
                    <Flex w="33%" align="start" direction="column" gap="0.2rem">
                      <ReusableText
                        text={new Date(el?.updated_at)?.toLocaleDateString()}
                        weight="medium"
                        size="16px"
                        color="var(--container_color)"
                      />
                    </Flex>
                    <Flex w="33%" align="start" direction="column" gap="0.2rem">
                      <ReusableText
                        text={`₦${new Intl.NumberFormat().format(
                          el?.salary
                        )}.00`}
                        weight="medium"
                        size="16px"
                        color="var(--container_color)"
                      />
                    </Flex>
                    <Flex
                      cursor="pointer"
                      w="33%"
                      align="start"
                      direction="column"
                      gap="0.2rem"
                    >
                      <Button
                        onClick={() => {
                          setAmount(
                            `₦${new Intl.NumberFormat().format(el?.salary)}.00`
                          );
                          setDate(
                            new Date(el.updated_at)?.toLocaleDateString()
                          );
                          setShow(true);
                        }}
                        variant="ghost"
                        colorScheme="green"
                      >
                        <AiOutlineCloudDownload />
                      </Button>
                    </Flex>
                  </Flex>
                ))}
              </>
            ) : (
              <Flex
                w="100%"
                align="center"
                justify="center"
                h="20vh"
                color="var(--container_color)"
              >
                No Report Found...
              </Flex>
            )}
          </Flex>
        </Flex>
        {showDownload && (
          <>
            <Flex align="center" p="1rem" w="100" justify="space-between">
              <DownloadPage element={slipRef} name={name} setShow={setShow} />
              <Button colorScheme="red" onClick={() => setShow(false)}>
                <AiOutlineClose />
              </Button>
            </Flex>
            <Flex ref={slipRef} w="100%" align="center" justify="center">
              <PayslipTemplate
                name={name}
                designation={designation}
                department={department}
                date={date}
                amount={amount}
                salary={salary}
              />
            </Flex>
          </>
        )}
      </Modal>
    </>
  );
}
