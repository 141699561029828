import { Text } from "@chakra-ui/react";
import React from "react";

export default function ReusableTitleText({
  w,
  size,
  weight,
  color,
  align,
  text,
}) {
  return (
    <Text
      w={w}
      fontSize={size}
      fontWeight={weight}
      color={color}
      textAlign={align}
    >
      {text}
    </Text>
  );
}
