import {
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import SettingHeader from "./SettingHeader";
import SettingCenter from "../SettingCenter";
import MoreSettings from "../MoreSettings";
import DepartmentList from "../DepartmentList";
import DesignationList from "../DesignationList";
import LeaveTypeList from "../LeaveTypeList";
import CreateLeaveType from "../CreateLeaveType";
import CreateDesignation from "../CreateDesignation";
import CreateDepartment from "../CreateDepartment";
import CreateAppraisal from "../CreateAppraisal";
import { useState } from "react";
import UpdateSalary from "../SalaryDeduction";
import Salary from "./Salary";
import Deductions from "./Deductions";
import AppraisalList from "./AppraisalList";

export default function NewSettingContainer() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  return (
    <>
      {show3 && <CreateLeaveType show={setShow3} />}
      {show2 && <CreateDesignation show={setShow2} />}
      {show1 && <CreateDepartment show={setShow1} />}
      {show && <CreateAppraisal show={setShow} />}
      {show4 && <UpdateSalary show={setShow4} />}
      <Flex w="100%" gap="2rem" align="start" direction="column">
        <SettingHeader show={setShow} />
        <Tabs variant="soft-rounded" colorScheme="blue" w="100%">
          <TabList>
            <Tab
              color={
                document.querySelector("body").getAttribute("data-theme") ===
                "dark"
                  ? "white"
                  : ""
              }
            >
              Roles & permissions
            </Tab>
            <Tab
              color={
                document.querySelector("body").getAttribute("data-theme") ===
                "dark"
                  ? "white"
                  : ""
              }
            >
              VAT & Storage
            </Tab>
            <Tab
              color={
                document.querySelector("body").getAttribute("data-theme") ===
                "dark"
                  ? "white"
                  : ""
              }
            >
              Departments
            </Tab>
            <Tab
              color={
                document.querySelector("body").getAttribute("data-theme") ===
                "dark"
                  ? "white"
                  : ""
              }
            >
              Designation
            </Tab>
            <Tab
              color={
                document.querySelector("body").getAttribute("data-theme") ===
                "dark"
                  ? "white"
                  : ""
              }
            >
              Leaves
            </Tab>
            <Tab
              color={
                document.querySelector("body").getAttribute("data-theme") ===
                "dark"
                  ? "white"
                  : ""
              }
            >
              Salary
            </Tab>
            <Tab
              color={
                document.querySelector("body").getAttribute("data-theme") ===
                "dark"
                  ? "white"
                  : ""
              }
            >
              Deductions
            </Tab>
            <Tab
              color={
                document.querySelector("body").getAttribute("data-theme") ===
                "dark"
                  ? "white"
                  : ""
              }
            >
              Appraisals
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <SettingCenter />
            </TabPanel>
            <TabPanel>
              <MoreSettings />
            </TabPanel>
            <TabPanel>
              <DepartmentList show1={setShow1} />
            </TabPanel>
            <TabPanel>
              <DesignationList show1={setShow2} />
            </TabPanel>
            <TabPanel>
              <LeaveTypeList show1={setShow3} />
            </TabPanel>
            <TabPanel>
              <Salary />
            </TabPanel>
            <TabPanel>
              <Deductions />
            </TabPanel>
            <TabPanel>
              <AppraisalList />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  );
}
