import { Flex } from "@chakra-ui/react";
import AboutBrief from "./AboutBrief";
import Activity from "./Activity";
import Comments from "./Comments";
import Budget from "./Budget";
import Team from "./Team";
import Attachments from "./Attachments";
import Feedback from "./Feedback";
import { getStoredItem } from "../../../util/lib";

export default function BriefOverview({ brief }) {
  const userType = getStoredItem("user-type");
  return (
    <Flex w="100%" align="center" justify="space-between">
      <Flex
        w="70%"
        h="100vh"
        overflow="scroll"
        align="start"
        direction="column"
        gap="1.5rem"
      >
        <AboutBrief brief={brief} />
        <Activity brief={brief} />
        <Comments brief={brief} />
        {brief?.status?.toLowerCase() === "completed" &&
          userType?.toLowerCase() === "client" && (
            <Feedback brief_id={brief?.id} />
          )}
      </Flex>
      <Flex
        h="100vh"
        overflow="scroll"
        w="28%"
        align="start"
        direction="column"
        gap="1.5rem"
      >
        <Budget brief={brief} />
        <Team brief={brief} />
        <Attachments brief={brief} />
      </Flex>
    </Flex>
  );
}
