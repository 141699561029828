import { Flex, Box } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";

import Toggle from "../../UI/Toggle";

export default function RolePermission({ loading, permissions, setTasks }) {
  return (
    <Flex w="100%" align="center" py="1rem" borderBottom="1px solid #ccc">
      <Box w="30%" height="50vh">
        <ReusableText
          text="Permissions"
          size="20px"
          color="#19191A"
          weight="bold"
        />
      </Box>
      <Flex
        w="100%"
        height="50vh"
        overflow="scroll"
        align="start"
        direction="column"
        gap="1rem"
      >
        {permissions?.map((el, i) => (
          <Flex height="120px" w="60%" direction="column" gap="1rem">
            <Toggle
              key={i}
              onChange={(e) => {
                if (e.target.checked) {
                  setTasks((prev) => [el, ...prev]);
                }
              }}
              title={el}
            />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
