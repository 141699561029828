import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../UI/ReusableText";
import PermissionList from "./PermissionList";

export default function UserCan({ permitted }) {
  return (
    <Flex align="start" w="50%" h="70vh" overflow="scroll" direction="column">
      <Box w="100%" bg="#B5FBB3" p="1rem">
        <ReusableText text="Users Can" color="#515050" weight="bold" />
      </Box>
      <PermissionList list={permitted} />
    </Flex>
  );
}
