import React, { Fragment, useContext } from "react";
import Brief from "../../brief/Brief";
import { AuthContext } from "../../../util/context";
import Login from "../../auth/Login";
import Dashboard from "../../dashboard/Dashboard";
import { getStoredItem } from "../../../util/lib";

export default function BriefPage() {
  let view;
  const { isAuthenticated, user, isUser } = useContext(AuthContext);
  const userType = getStoredItem("user-type");

  const viewBrief =
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "seed" ||
    userType === "client";

  if (viewBrief) {
    view = <Brief />;
  } else {
    view = <Dashboard />;
  }
  return <Fragment>{isAuthenticated && isUser ? view : <Login />}</Fragment>;
}
