import { Flex } from "@chakra-ui/react";
import ReusableText from "../UI/ReusableText";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FcFolder } from "react-icons/fc";
import ShareDocument from "./ShareDocument";

export default function DocumentItem({ item }) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  return (
    <Fragment>
      {show && <ShareDocument show={setShow} />}

      <Flex
        w="22%"
        bg="white"
        boxShadow="lg"
        py="1rem"
        px="1rem"
        borderBottom="1px solid #ccc"
        borderRadius={16}
        align="center"
        direction="column"
        cursor="pointer"
        onClick={(e) => {
          e.stopPropagation();
          if (e.target.localName === "button") {
            return;
          } else {
            navigate(`/document/${item}`);
          }
        }}
      >
        <Flex w="100%" align="start" justify="space-between"></Flex>
        <Flex align="center" direction="column" gap="1rem">
          <FcFolder cursor="pointer" fontSize="100px" color="yellow" />
          <ReusableText
            text={item?.slice(0, 10).padEnd(".", 12)}
            size="18px"
            color="#3F434A"
            weight="medium"
            title={item}
            transform={true}
          />
        </Flex>
      </Flex>
    </Fragment>
  );
}
