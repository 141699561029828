import React from "react";
import ImageComponent from "./Image";
import ReusableText from "./ReusableText";
import { Box, Flex } from "@chakra-ui/react";
import logo from "../images/logo.png";

export default function RetainerShipTemplate({ id, amount, client, dueDate }) {
  return (
    <Flex align="start" p="1rem" direction="column" gap-4 w="100%">
      <Flex
        align="center"
        px="1rem"
        py="1rem"
        borderBottom="1px solid #ccc"
        justify="space-between"
        w="100%"
      >
        <Box w="50px">
          <ImageComponent src={logo} />
        </Box>
        <ReusableText
          size="20px"
          weight="bold"
          color="brand.900"
          text="Retainership Receipt"
        />
      </Flex>
      <Flex align="start" py="1rem" w="100%" direction="column" gap="0.5rem">
        <Flex align="center" gap="1rem">
          <ReusableText
            text="Retainership ID:"
            color="brand.900"
            weight="bold"
          />
          <ReusableText text={id} color="brand.900" />
        </Flex>

        <Flex align="center" gap="1rem">
          <ReusableText text="Date:" color="brand.900" weight="bold" />
          <ReusableText
            text={new Date().toLocaleDateString()}
            color="brand.900"
          />
        </Flex>
      </Flex>

      <Flex align="start" px="2rem" mt="2rem" direction="column" w="100%">
        <Flex
          align="center"
          py="1rem"
          borderBottom="1px solid #ccc"
          justify="space-between"
          w="100%"
        >
          <ReusableText text="Client Name" color="brand.900" weight="bold" />
          <ReusableText text={client} transform={true} color="brand.900" />
        </Flex>
      </Flex>
      <ReusableText
        text="Retainership Detail"
        size="16px"
        p="1rem"
        color="brand.900"
        weight="bold"
        align="start"
      />
      <Flex align="start" px="2rem" mt="2rem" direction="column" w="100%">
        <Flex
          align="center"
          py="1rem"
          borderBottom="1px solid #ccc"
          justify="space-between"
          w="100%"
        >
          <ReusableText
            text={`Total Amount Paid`}
            color="brand.900"
            weight="bold"
          />
          <ReusableText
            text={amount}
            size="20px"
            color="green.400"
            weight="bold"
          />
        </Flex>

        <Flex
          align="center"
          py="1rem"
          borderBottom="1px solid #ccc"
          justify="space-between"
          w="100%"
        >
          <ReusableText text={`Due Date`} color="brand.900" weight="bold" />
          <ReusableText
            text={dueDate}
            size="20px"
            color="green.400"
            weight="bold"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
