import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Box,
} from "@chakra-ui/react";
import EditForm from "./EditForm";
import PasswordContainer from "../password/PasswordContainer";
import Notification from "../notification/Notification";
import { getStoredItem } from "../../../util/lib";

export default function EditTab() {
  const userType = getStoredItem("user-type");

  return (
    <Tabs w="100%" variant="soft-rounded" colorScheme="gray">
      <TabList>
        <Tab>My Details</Tab>
        <Tab>Password</Tab>
        {userType === "employee" && false && <Tab>Notifications</Tab>}
      </TabList>

      <TabPanels>
        <TabPanel>
          <Box w="full">
            <EditForm />
          </Box>
        </TabPanel>
        <TabPanel>
          <PasswordContainer />
        </TabPanel>
        {userType === "employee" && (
          <TabPanel>{false && <Notification />}</TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}
