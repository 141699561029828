export default function Toggle({ title, onChange, checked }) {
  return (
    <div className="flex items-center center gap-2">
      <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
        <input
          onChange={onChange}
          type="checkbox"
          name="toggle"
          defaultChecked={checked}
          id={title}
          className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
        />
        <label
          htmlFor={title}
          className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
        ></label>
      </div>
      <label
        htmlFor={title}
        className="text-[14px] capitalize cursor-pointer font-medium text-[#344054]"
      >
        {title}
      </label>
    </div>
  );
}
