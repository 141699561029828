/* eslint-disable no-unused-vars */
function formatTimestamp(timestamp) {
    // Check if timestamp is undefined, null, or an empty string
    if (timestamp === undefined || timestamp === null || timestamp === '') {
      return '';
    }

    // Create a Date object from the input timestamp
    const date = new Date(timestamp);

    // Check if the Date object is valid
    if (isNaN(date.getTime())) {
      return '';
    }

    // Helper function to get the month name
    function getMonthName(monthIndex) {
      const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
      return months[monthIndex];
    }

    // Helper function to pad a number with leading zeros
    function padWithZero(number) {
      return number.toString().padStart(2, '0');
    }

    // Determine whether it's AM or PM
    const period = date.getHours() < 12 ? 'AM' : 'PM';

    // Format the date as '12 Apr 2023 at 04:30 PM'
    return `${date.getDate()} ${getMonthName(date.getMonth())} ${date.getFullYear()}`;
  }

  export default formatTimestamp;
