import { Button, Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../UI/ReusableText";

export default function ReusableFooter({
  total,
  itemPerPage,
  setCurrentPage,
  currentPage,
}) {
  const totalPage = total / +itemPerPage;
  return (
    <Flex
      w="100%"
      p="1rem"
      borderTop="none"
      align="center"
      justify="space-between"
      borderBottomLeftRadius="0.75rem"
      borderBottomRightRadius="0.75rem"
    >
      <Button
        onClick={() => setCurrentPage((prev) => (prev !== 1 ? prev - 1 : prev))}
        color="var(--container_color)"
        variant="outline"
        borderRadius="1.5rem"
        isDisabled={currentPage === 1}
        size="sm"
      >
        Previous
      </Button>

      <ReusableText
        size={{ lg: "16px", md: "14px", base: "12px" }}
        text={`${currentPage} - ${itemPerPage * currentPage} of ${total}`}
      />
      <Button
        onClick={() =>
          setCurrentPage((prev) => (prev < +totalPage ? prev + 1 : prev))
        }
        color="var(--container_color)"
        variant="outline"
        borderRadius="1.5rem"
        isDisabled={currentPage >= totalPage}
        size="sm"
      >
        Next
      </Button>
    </Flex>
  );
}
