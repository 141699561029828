import { useEffect, useState } from "react";
import StatItem from "../StatItem";
import { TfiBriefcase } from "react-icons/tfi";
import Loader from "../../UI/Loader";
import { Flex } from "@chakra-ui/react";

export default function ClientStat({ client }) {
  const [activeBrief, setActiveBrief] = useState(0);
  const [pendingBrief, setPendingBrief] = useState(0);
  const [completedBrief, setCompletedBrief] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        // active brief
        setActiveBrief(
          client?.briefs?.filter((el) => el.status?.toLowerCase() === "active")
            ?.length
        );

        // pending brief
        setPendingBrief(
          client?.briefs?.filter((el) => el.status?.toLowerCase() === "pending")
            .length
        );

        // completed briefs
        setCompletedBrief(
          client?.briefs?.filter(
            (el) => el.status?.toLowerCase() === "completed"
          ).length
        );
      } catch (error) {
        setProgress(100);
        console.log(error);
      }
    }
    fetchData();
  }, [client]);

  return (
    <>
      <Loader progress={progress} setProgress={setProgress} />
      <Flex
        w="100%"
        wrap="wrap"
        gap="1.5rem"
        align="center"
        justify="space-between"
        py="1rem"
      >
        <StatItem
          icon={<TfiBriefcase color="orange" fontSize={24} />}
          title="Active Briefs"
          amount={activeBrief}
          bg="rgba(255, 213, 128, 0.4)"
        />
        <StatItem
          icon={<TfiBriefcase color="#333" fontSize={24} />}
          title="Pending Briefs"
          amount={pendingBrief}
          bg="rgba(211, 211, 211, 0.4)"
        />
        <StatItem
          icon={<TfiBriefcase color="green" fontSize={24} />}
          bg="rgba(144, 238, 144, 0.4)"
          title="Completed Briefs"
          amount={completedBrief}
        />
      </Flex>
    </>
  );
}
