import React, { useEffect, useState } from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import AllInvoiceContainer from "./AllInvoiceContainer";
import { getInvoices } from "../../../util/http";
import InvoiceTab from "./InvoiceTab";

export default function AllInvoice() {
  return (
    <DashboardReusable>
      <InvoiceTab />
    </DashboardReusable>
  );
}
