import { Flex, useDisclosure } from "@chakra-ui/react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import ReportList from "./ReportList";
import { Fragment } from "react";
import BriefReport from "./report-chart-details/BriefReport";
import { AuthContext } from "../../util/context";
import { useContext } from "react";
import AccountantReportList from "./AccountantReportList";
import HodReportList from "./HodReportList";
export default function ReportContainer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, } = useContext(AuthContext);

  let UserReportList
  if (user?.job_role?.toLowerCase() === "accountant") {
    UserReportList = AccountantReportList


  } else if (user?.job_role?.toLowerCase() === "hod") {
    UserReportList = HodReportList

  }
  else {
    UserReportList = ReportList
  }


  return (
    <Fragment>
      <Flex
        w="100%"
        align="start"
        direction="column"
        h="100%"
        gap="1rem"
        p="2rem"
      >
        <ReusableEmployeeHeader
          title="Reports"
          text="Overview of all platform reports"
        />

        {/* <ReportList onOpen={onOpen} /> */}
        <UserReportList onOpen={onOpen} />
      </Flex>
      {false && (
        <BriefReport isOpen={isOpen} onClose={onClose} onopen={onOpen} />
      )}
    </Fragment>
  );
}
