import { AddIcon } from "@chakra-ui/icons";
import { Avatar, Button, Flex, Heading } from "@chakra-ui/react";

function ReusableTeam({ el }) {
  return (
    <Flex w="100%" align="center" justify="space-between">
      <Flex align="center" gap="1rem">
        <Avatar size="xs" name={el} />
        <Flex align="start" direction="column" gap="2px">
          <Heading size="sm" color="#333" fontWeight="medium">
            {el}
          </Heading>
          <Heading size="xs" color="#333" fontWeight="normal">
            Lawyer
          </Heading>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default function Assignee({ task }) {
  const lawyers = task?.assignee && JSON.parse(task?.assignee);
  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      bg="white"
      gap="1rem"
      boxShadow="lg"
      borderRadius="16px"
      p="1rem"
    >
      <Flex w="100%" align="center" justify="space-between">
        <Heading size="sm" color="#333" fontWeight="bold">
          Team({lawyers?.length})
        </Heading>
        {false && (
          <Button variant="ghost" leftIcon={<AddIcon />}>
            Add Lawyer
          </Button>
        )}
      </Flex>
      <Flex
        w="100%"
        gap="1rem"
        maxHeight="400px"
        overflow="scroll"
        align="start"
        direction="column"
      >
        {lawyers?.map((el, i) => (
          <ReusableTeam el={el} key={i} />
        ))}
      </Flex>
    </Flex>
  );
}
