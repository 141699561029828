import { Flex, useDisclosure } from "@chakra-ui/react";
import { Fragment } from "react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";
import ViewDetail from "./ViewDetail";
import UpdatePriority from "./UpdatePriority";
import { AuthContext } from "../../../util/context";
import { useContext } from "react";

export default function ViewContainer({ requisition }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { user } = useContext(AuthContext);

  return (
    <Fragment>
      <UpdatePriority isOpen={isOpen} onClose={onClose} />
      <Flex
        w="100%"
        align="start"
        direction="column"
        h="100%"
        gap="1rem"
        p={{ lg: "2rem", md: "1rem", base: "6px" }}
      >
        <ReusableEmployeeHeader
          title="Requisition"
          text="Manage your requisition and their details here"
          show={
            user?.job_role?.toLowerCase().includes("hod") ||
            user?.job_role?.toLowerCase() === "hop"
          }
          btnText="Edit Priority"
          onClick={onOpen}
        />
        <ViewDetail requisition={requisition} />
      </Flex>
    </Fragment>
  );
}
