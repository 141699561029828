import { Button, Flex, useToast } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import AppraisalHeader from "./AppraisalHeader";
import AppraisalRating from "./AppraisalRating";
import AppraisalFormHeader from "./AppraisalFormHeader";
import Accountability from "./Accountability";
import TextareaComponent from "../../UI/Textarea";
import OverallPerformance from "./OverallPerformance";
import PerformanceAndReward from "./PerformanceAndReward";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../util/context";
import { approveAppraisal, submitAppraisal } from "../../../util/http";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function AppraisalContainer({ appraisal }) {
  const [formData, setFormData] = useState([]);
  const [formData1, setFormData1] = useState([]);
  const [challenges, setChallenges] = useState("");
  const [possible_solutions, setPossibleSolution] = useState("");
  const [supervisor_comment, setSupervisorComment] = useState("");
  const [hr_comment, setHrComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState([]);
  const [supervisor, setSupervisor] = useState("");
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useContext(AuthContext);
  const params = useParams();
  const fields = useMemo(
    () => (appraisal?.fields ? JSON.parse(appraisal?.fields) : []),
    [appraisal]
  );

  useEffect(() => {
    const ids = appraisal?.supervisor?.map((el) => +el.id);
    setSupervisor(ids);
  }, [appraisal]);

  useEffect(() => {
    const arr = Array(fields?.length)
      .fill()
      .map(() => []);

    setFormData(arr);
    setFormData1(arr);
  }, [fields]);

  async function approveAppraisalHandler() {
    const supervisor = `${appraisal.supervisor[0]?.first_name} ${appraisal.supervisor[0]?.last_name}`;
    const supervisorData = {
      supervisor_comment,
      supervisor_response: JSON.stringify(formData1),
      overall_score: total,
      appraisal_id: params.id,
      supervisor,
    };

    try {
      setLoading(true);
      await approveAppraisal(supervisorData);
      toast({
        title: `Supervisor response captured`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/appraisal");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function appraisalHandler() {
    const supervisor = `${appraisal.supervisor[0]?.first_name} ${appraisal.supervisor[0]?.last_name}`;
    const empData = {
      challenges,
      possible_solutions,
      appraisal_id: params.id,
      supervisor,
      employee_response: JSON.stringify(formData),
    };

    // const data = {
    //   challenges,
    //   possible_solutions,
    //   supervisor_comment,
    //   hr_comment,
    //   position: user?.job_role,
    //   department: user?.department,
    //   employee_response: JSON.stringify(formData),
    //   supervisor_response: JSON.stringify(formData1),
    //   appraisal_id: params.id,
    // };

    try {
      setLoading(true);
      await submitAppraisal(empData);
      toast({
        title: `Submitted successfully`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/appraisal");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      gap="1rem"
      p={{ lg: "2rem", md: "1rem", base: "6px" }}
      mb="5rem"
    >
      <ReusableText
        text={appraisal?.title}
        color="var(--gray)"
        weight="bold"
        align="center"
        transform={true}
        size={24}
      />
      <AppraisalHeader appraisal={appraisal} />
      <ReusableText
        text="PERFORMANCE RATING"
        color="var(--gray)"
        weight="bold"
        align="center"
        size={20}
      />
      <AppraisalRating />
      <Flex w="100%" border="1px solid #ccc" align="start" direction="column">
        <AppraisalFormHeader />
        <Flex w="100%" gap="1rem" align="start" direction="column">
          {fields?.map((el, i) => (
            <Accountability
              appraisal={appraisal}
              key={i}
              fields={fields}
              setTotal={setTotal}
              item={el}
              index={i}
              formData={formData}
              setFormData={setFormData}
              formData1={formData1}
              setFormData1={setFormData1}
            />
          ))}
        </Flex>
      </Flex>
      <TextareaComponent
        isDisabled={
          appraisal?.appraisal_status?.toLowerCase() ===
          "awaiting_supervisor_response"
        }
        onChange={(e) => setChallenges(e.target.value)}
        value={challenges}
        label="List any challenges/ problems encountered in the course of discharging your duties"
        rows={4}
      />
      <TextareaComponent
        isDisabled={
          appraisal?.appraisal_status?.toLowerCase() ===
          "awaiting_supervisor_response"
        }
        onChange={(e) => setPossibleSolution(e.target.value)}
        value={possible_solutions}
        label="Suggest Possible Solutions"
        rows={4}
      />
      {supervisor?.includes(+user?.id) && (
        <TextareaComponent
          onChange={(e) => setSupervisorComment(e.target.value)}
          value={supervisor_comment}
          label="Supervisor’s Comment and Recommendation"
          rows={4}
        />
      )}
      <ReusableText
        text="TO BE COMPLETED BY PEOPLE MANAGEMENT DEPARTMENT"
        color="var(--gray)"
        weight="bold"
        align="center"
        size={20}
      />
      <OverallPerformance total={total} />
      <ReusableText
        text="PERFORMANCE AND REWARD"
        color="var(--gray)"
        weight="bold"
        align="center"
        size={20}
      />
      <PerformanceAndReward />
      {user?.job_role?.toLowerCase() === "hr" && (
        <TextareaComponent
          onChange={(e) => setHrComment(e.target.value)}
          value={hr_comment}
          label="HR’s Comment"
          rows={4}
        />
      )}
      <Flex w="100%" align="end" justify="end" pb="4rem">
        <Button
          isLoading={loading}
          // isDisabled={
          //   !supervisor?.includes(+user?.id) &&
          //   appraisal?.appraisal_status?.toLowerCase() ===
          //     "awaiting_supervisor_response"
          // }
          loadingText=""
          onClick={() => {
            appraisal?.appraisal_status?.toLowerCase() === "pending"
              ? appraisalHandler()
              : appraisal?.appraisal_status?.toLowerCase() ===
                  "awaiting_supervisor_response" &&
                supervisor?.includes(+user?.id)
              ? approveAppraisalHandler()
              : console.log("");
          }}
          variant="solid"
          bg="brand.900"
          color="white"
        >
          Submit
        </Button>
      </Flex>
    </Flex>
  );
}
