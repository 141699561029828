import { Button, Flex, useToast } from "@chakra-ui/react";
import ReusableModal from "../../UI/ReusableModal";
import SelectComponent from "../../UI/Select";
import TextareaComponent from "../../UI/Textarea";
import FileComponent from "../../UI/File";
import { useState } from "react";
import PreviewDocument from "../../brief/create brief/PreviewDocument";
import { addUpdates } from "../../../util/http";

export default function AddUpdate({ isOpen, onClose, id }) {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const toast = useToast();

  async function updateHandler() {
    const data = {
      update_type: type,
      description,
      "documents[]": files,
      user_id: id,
    };

    if (!type || !description || files.length === 0) {
      toast({
        title: "Update details missing",
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await addUpdates(data);
      onClose();
      toast({
        title: "Update Added",
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <ReusableModal title="Add New Update" isOpen={isOpen} onClose={onClose}>
      <Flex w="100%" align="start" gap="1rem" direction="column" py="1rem">
        <SelectComponent
          onChange={(e) => setType(e.value)}
          label="Update Type"
          options={["Training", "Certificate"]}
        />
        <TextareaComponent
          label="Description"
          placeholder="Enter description"
          onChange={(e) => setDescription(e.target.value)}
        />
        <FileComponent
          title="Attach Documents"
          onChange={(e) => setFiles((prev) => [...prev, e.target.files[0]])}
        />
        <PreviewDocument files={files} setFiles={setFiles} />
        <Button
          variant="solid"
          bg="brand.900"
          color="white"
          isLoading={loading}
          loadingText=""
          onClick={updateHandler}
        >
          Submit
        </Button>
      </Flex>
    </ReusableModal>
  );
}
