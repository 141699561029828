import { AddIcon } from "@chakra-ui/icons";
import { Avatar, Button, Flex, Heading } from "@chakra-ui/react";

function ReusableTeam({ el, lead }) {
  return (
    <Flex w="100%" align="center" justify="space-between">
      <Flex px="0.1rem" w="100%" align="center" gap="1rem">
        <Flex flex="1" align="center" gap="1rem">
          <Avatar size="xs" name={el} />
          <Flex align="start" direction="column" gap="2px">
            <Heading size="sm" color="#333" fontWeight="medium">
              {el}
            </Heading>
            <Heading size="xs" color="#333" fontWeight="normal">
              {el?.toLowerCase() === lead?.toLowerCase()
                ? "Team Lead"
                : "Lawyer"}
            </Heading>
          </Flex>
        </Flex>
        {el?.toLowerCase() === lead?.toLowerCase() && (
          <Avatar size="2xs" name="Team Lead" />
        )}
      </Flex>
    </Flex>
  );
}

export default function Team({ brief }) {
  const lawyers = brief?.lawyer && JSON.parse(brief?.lawyer);
  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      bg="white"
      gap="1rem"
      boxShadow="lg"
      borderRadius="16px"
      p="1rem"
    >
      <Flex w="100%" align="center" justify="space-between">
        <Heading size="sm" color="#333" fontWeight="bold">
          Team({lawyers?.length})
        </Heading>
        {false && (
          <Button variant="ghost" leftIcon={<AddIcon />}>
            Add Lawyer
          </Button>
        )}
      </Flex>
      <Flex
        w="100%"
        gap="1rem"
        maxHeight="400px"
        overflow="scroll"
        align="start"
        direction="column"
      >
        {lawyers?.map((el, i) => (
          <ReusableTeam lead={brief?.team_lead_name} el={el} key={i} />
        ))}
      </Flex>
    </Flex>
  );
}
