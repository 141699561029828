import ReusableModal from "../../UI/ReusableModal";
import { Grid, Text, Icon, Flex, Button , Box } from "@chakra-ui/react";
import { GoAlert } from "react-icons/go";

const CurrencyInfo = ({ currency, onClose, isOpen, loading  , onClick }) => {

  return (
    <ReusableModal title={"Confirm Currency"} isOpen={isOpen} onClose={onClose}>
      <Grid
        padding={{
          base: ".5rem",
          lg: "1rem",
        }}
        gap={4}
        templateColumns="1fr"
        placeItems={"center"}
      >
        <Icon
          as={GoAlert}
          color="red.500"
          fontSize={{
            base: "2.5rem",
            lg: "3rem",
            "2xl": "4rem",
          }}
          alignSelf="flex-start"
        />
        <Text fontSize={{ base: "sm", lg: "md" }}>
          Please note that the currency you selected{" "}
          <Text color={"#515050"} as={"strong"}>
            ({`${currency?.Currency} ${currency.Code}`})
          </Text>{" "}
          when creating a brief will be used for generating invoices and
          requisitions related to the project.
        </Text>
        <Text fontSize={{ base: "sm", lg: "md" }}>
          Ensuring consistency in currency usage is crucial for accurate
          financial management. Kindly double-check the currency selected before
          finalizing your brief to avoid discrepancies and streamline your
          financial processes.
        </Text>
        <Box w={'full'}>
        <Flex justify={"space-between"} >
            <Button variant="outline" colorScheme="red" onClick={onClose}>
              Cancel
            </Button>
          <Button
            isLoading={loading}
            loadingText="Processing"
            variant="solid"
            color="white"
            bg="brand.900"
            onClick={onClick}
          >
            Create Brief
          </Button>
        </Flex>
        </Box>
      </Grid>
    </ReusableModal>
  );
};

export default CurrencyInfo;
