import { Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";

export default function InvoiceStat({
  total,
  show = false,
  pending,
  subTotal,
  vat,
  currency
}) {

  console.log(currency)
  return (
    <Flex  direction="column"  gap="1rem" w="100%" >
      <Flex align="center" gap="1rem" >
        <ReusableText
          size="16px"
          color="var(--light_gray)"
          weight="bold"
          w="40%"
          align="end"
          text="Subtotal"
        />
        <ReusableText
          size="16px"
          color="var(--gray)"
          weight="medium"
          w="60%"
          text={`${currency?.Symbol ? currency?.Symbol: '₦' }${new Intl.NumberFormat().format(+subTotal)}`}
        />
      </Flex>
      <Flex align="center" gap="1rem" >
        <ReusableText
          size="16px"
          color="var(--light_gray)"
          weight="bold"
          w="40%"
          align="end"
          text="VAT"
        />
        <ReusableText
          size="16px"
          color="var(--gray)"
          weight="medium"
          w="60%"
          text={`${currency?.Symbol ? currency?.Symbol: '₦'}${new Intl.NumberFormat().format(vat)}`}
        />
      </Flex>
      <Flex align="center" gap="1rem" >
        <ReusableText
          size="16px"
          color="var(--light_gray)"
          weight="bold"
          w="40%"
          align="end"
          text="Grand Total"
        />
        <ReusableText
          size="16px"
          color="var(--gray)"
          weight="medium"
          w="60%"
          text={`${currency?.Symbol ? currency?.Symbol: '₦'}${new Intl.NumberFormat().format(+total)}`}
        />
      </Flex>
      {show && (
        <Flex
          align="center"
          gap="1rem"

        >
          <ReusableText
            size="16px"
            color="var(--light_gray)"
            weight="bold"
            w="40%"
            align="end"
            text="Pending Balance"
          />
          <ReusableText
            size="16px"
            color="red.400"
            weight="medium"
            w="60%"
            text={`${currency?.Symbol ? currency?.Symbol: '₦'}${new Intl.NumberFormat().format(pending)}`}
          />
        </Flex>
      )}
    </Flex>
  );
}
