import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import { AiOutlineEye, AiFillCheckCircle } from "react-icons/ai";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { listAppraisals } from "../../../util/http";
import ActivateAppraisal from "./ActivateAppraisal";

export default function AppraisalList({ show1 }) {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState({
    name: "",
    id: "",
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await listAppraisals();
        setList(response?.appraisals);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <ActivateAppraisal isOpen={isOpen} onClose={onClose} />
      <Flex w="100%" align="start" direction="column" gap="1rem">
        <Flex w="100%" align="end" justify="end">
          <Button
            variant="solid"
            bg="brand.900"
            color="white"
            onClick={() => navigate("/appraisal/create")}
          >
            Create Appraisal
          </Button>
        </Flex>
        <Flex align="start" direction="column" w="100%" bg="var(--container)">
          <Flex
            borderBottom="1px solid #ccc"
            w="100%"
            py="10px"
            align="center"
            justify="space-between"
          >
            <ReusableText
              text="Appraisal Title"
              transform={true}
              color="var(--container_color)"
              size="18px"
              weight="bold"
              w="50%"
            />
            <ReusableText
              text="Status"
              transform={true}
              color="var(--container_color)"
              size="18px"
              weight="bold"
              w="10%"
            />
            <ReusableText
              text="Date"
              transform={true}
              color="var(--container_color)"
              size="18px"
              weight="bold"
              w="10%"
            />
            <ReusableText
              text="Employees"
              transform={true}
              color="var(--container_color)"
              size="18px"
              weight="bold"
              w="10%"
            />
            <ReusableText
              text="Departments"
              transform={true}
              color="var(--container_color)"
              size="18px"
              weight="bold"
              w="10%"
            />
            <ReusableText
              text="Action"
              transform={true}
              color="var(--container_color)"
              size="18px"
              weight="bold"
              w="10%"
            />
          </Flex>
          <Flex align="start" direction="column" gap="1rem" w="100%">
            {list?.map((el) => {
              const lawyers = el?.lawyer_id ? JSON.parse(el?.lawyer_id) : [];
              const departments = el?.departments
                ? JSON.parse(el?.departments)
                : [];
              return (
                <Flex
                  borderBottom="1px solid #ccc"
                  w="100%"
                  py="10px"
                  align="center"
                  justify="space-between"
                >
                  <ReusableText
                    text={el?.title}
                    title={el?.title}
                    transform={true}
                    color="var(--container_color)"
                    size="15px"
                    weight="medium"
                    w="50%"
                  />
                  <ReusableText
                    text={el?.status}
                    transform={true}
                    color="var(--container_color)"
                    size="15px"
                    weight="medium"
                    w="10%"
                  />
                  <ReusableText
                    text={new Date(el?.due_date).toLocaleDateString()}
                    transform={true}
                    color="var(--container_color)"
                    size="15px"
                    weight="medium"
                    w="10%"
                  />
                  <ReusableText
                    text={lawyers?.length}
                    transform={true}
                    color="var(--container_color)"
                    size="15px"
                    weight="medium"
                    w="10%"
                  />
                  <ReusableText
                    text={departments?.length}
                    transform={true}
                    color="var(--container_color)"
                    size="15px"
                    weight="medium"
                    w="10%"
                  />

                  <Flex w="10%" align="center" gap="1rem">
                    <Button
                      onClick={() => {
                        navigate(`/appraisal/${el?.id}`);
                      }}
                      variant="ghost"
                      colorScheme="green"
                    >
                      <AiOutlineEye
                        color="var(--container_color)"
                        size="18px"
                      />
                    </Button>
                    <Button
                      onClick={onOpen}
                      variant="ghost"
                      colorScheme="green"
                    >
                      <AiFillCheckCircle
                        color="var(--container_color)"
                        size="18px"
                      />
                    </Button>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
