import { Button, Flex, Heading } from "@chakra-ui/react";
import ReusableModal from "../../UI/ReusableModal";

export default function ConfirmReceipt({ isOpen, onClose, onClick }) {
  return (
    <ReusableModal size="md" isOpen={isOpen} onClose={onClose}>
      <Flex w="100%" align="center" direction="column" gap="1.5rem" py="1rem">
        <Heading size="md" color="#333" fontWeight="medium" textAlign="center">
          Are you sure receipts details are correct?
        </Heading>
        <Heading size="xs" color="#333" fontWeight="normal" textAlign="center">
          NOTE: Receipt details cannot be changed once created
        </Heading>
        <Flex w="100%" align="center" justify="space-between">
          <Button variant="solid" colorScheme="red" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="solid" colorScheme="green" onClick={onClick}>
            Yes, Proceed
          </Button>
        </Flex>
      </Flex>
    </ReusableModal>
  );
}
