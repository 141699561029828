import { Button, Divider, Flex, Heading, useToast } from "@chakra-ui/react";
import Overlay from "../../overlay/OverLay";
import InputComponent from "../../UI/Input";
import TextareaComponent from "../../UI/Textarea";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "../../overlay/Modal";
import { useState } from "react";
import ReusableAmountInput from "../../UI/ReusableAmountInput";
import { updateSalary } from "../../../util/http";

export default function UpdateSalary({ name, show, salary, id }) {
  const [val, setVal] = useState(+salary);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  function closeMenu() {
    show(false);
  }

  async function updateHandler() {
    const data = {
      salary: val,
      reason,
    };

    if (!val || !reason) {
      toast({
        title: `Missing details`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await updateSalary(id, data);
      closeMenu();
      toast({
        title: `Salary Updated`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Overlay hide={closeMenu} />
      <Modal hide={() => closeMenu()}>
        <Flex w="100%" align="start" direction="column" gap="1.5rem">
          <Flex w="100%" align="center" justify="space-between">
            <Heading size="md" color="var(--container_color)">
              Update {name} Salary
            </Heading>
            <Button variant="ghost" onClick={closeMenu} colorScheme="red">
              <AiOutlineClose />
            </Button>
          </Flex>
          <Divider />
          <ReusableAmountInput
            onChange={(e) => setVal(e.target.rawValue)}
            value={val}
            label="Amount"
          />

          <TextareaComponent
            onChange={(e) => setReason(e.target.value)}
            value={reason}
            label="Reason"
          />

          <Flex w="100%" align="start">
            <Button
              isLoading={loading}
              loadingText=""
              onClick={updateHandler}
              variant="solid"
              bg="brand.900"
              color="white"
            >
              Submit
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
