import {
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  Button,
  Checkbox,
  Text
} from "@chakra-ui/react";
import { Fragment } from "react";
import ReusableText from "./ReusableText";
import ReusableAmountInput from "./ReusableAmountInput";
import { useState } from "react";


export default function ReusableInvoiceForm({
  array,
  onBlur,
  onChange1,
  onChange2,
  onChange3,
  setArray,
  currency,
  selectedCurrency,

}) {




  function handleDelete(i) {
    const deleteVal = [...array];
    deleteVal.splice(i, 1);
    setArray(deleteVal);
  }
  return (
    <Fragment>
      {array.map((item, i) => (
        <Flex
          w="100%"
          key={i}
          align="center"
          direction={{ base: "column", md: "column", lg: "row" }}
          justify="space-between"
          gap="2rem"
          position="relative"
        >
          <ReusableText
            color="var(--container_color)"
            text={+i + 1}
            align="center"
          />
          <FormControl flex="1">
            <Input
              size="md"
              variant="outline"
              border="1px solid #ccc"
              focusBorderColor="brand.900"
              borderRadius="8px"
              type="text"
              bg="white"
              placeholder="Description"
              onKeyUp={onBlur}
              onChange={(e) => onChange1(e, i)}
            />
          </FormControl>

          <FormControl w="30%">
           {currency? <Text fontWeight={'500'} mb={'.1rem'} color={'red.600'} fontSize={'.775rem'}>{currency?.Currency ? currency?.Currency: '' }
            </Text> : null}
            <InputGroup>
              <InputLeftAddon children={
               currency? currency?.Symbol : '₦'
              } bg="white" />
              <ReusableAmountInput
                onKeyUp={onBlur}
                onChange={(e) => onChange2(e, i)}
                placeholder="Amount"
                label={''}
                type={false}
              />
            </InputGroup>
          </FormControl>

          <Checkbox
            onChange={(e) => {
              onChange3(e, i);
            }}
            w="10%"
            color="var(--container_color)"
          >
            VAT
          </Checkbox>

          {array.length > 1 && (
            <Button
              onClick={() => {
                array.length > 1 && i !== 0 && handleDelete(i);
              }}
              position="absolute"
              top="0"
              colorScheme="red"
              variant="ghost"
              right="0"
            >
              X
            </Button>
          )}

        </Flex>
      ))}
    </Fragment>
  );
}
