import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function NewBriefChart({ data }) {
  useEffect(() => {
    setChart({
      series: data,
    });
  }, [data]);

  const [chart, setChart] = useState({
    series: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    options: {
      labels: [
        "Administration",
        "Department of practice",
        "Business development",
        "Corporate and commercial",
        "Dispute Management",
        "Employment and labour law",
        "ICT and media",
        "Maritime and aviation",
        "Property Law",
      ],
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  return (
    <Box w="100%">
      <ReactApexChart
        options={chart.options}
        series={chart.series}
        type="donut"
        width="100%"
        height={200}
      />
    </Box>
  );
}
