import React from "react";
import ReusableFooter from "../UI/ReusableFooter";

export default function ClientFooter({
  total,
  perPage,
  currentPage,
  setCurrentPage,
}) {
  return (
    <ReusableFooter
      total={total}
      itemPerPage={perPage}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  );
}
