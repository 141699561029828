import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import EmployeeHeader from "./EmployeeHeader";
import Detail from "./Detail";
import SalaryReport from "./SalaryReport";
import { useState } from "react";
import Updates from "./Updates";
import EditEmployee from "../EditEmployee";
import UpdateSalary from "../UpdateSalary";
import AddUpdate from "../../../profile/updates/AddUpdate";

export default function NewViewEmployeeContainer({ employee, salary }) {
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <AddUpdate isOpen={isOpen} onClose={onClose} id={employee?.id} />
      {show && <EditEmployee show={setShow} employee={employee} />}
      {showUpdate && (
        <UpdateSalary
          name={`${employee?.first_name} ${employee?.last_name}`}
          salary={employee?.salary}
          show={setShowUpdate}
          id={employee?.id}
        />
      )}
      <Flex w="100%" align="start" direction="column">
        <EmployeeHeader
          open={onOpen}
          showUpdate={setShowUpdate}
          employee={employee}
          show={setShow}
        />
        <Tabs w="100%" variant="enclosed">
          <TabList>
            <Tab>Details</Tab>
            <Tab>Salary</Tab>
            <Tab>Update/History</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Detail employee={employee} />
            </TabPanel>
            <TabPanel>
              <SalaryReport salary={salary} employee={employee} />
            </TabPanel>
            <TabPanel>
              <Updates
                id={employee?.id}
                name={`${employee?.first_name} ${employee?.last_name}`}
                department={employee?.department}
                designation={employee?.designation}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  );
}
