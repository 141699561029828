import { Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";

export default function BriefInvoiceTitle() {
  return (
    <Flex
      align="center"
      py="1rem"
      bg="#EEEFF3"
      justify="space-between"
      w="100%"
    >
      <ReusableText text="" w="5%" />
      <ReusableText text="Description" w="70%" align="start" />
      <ReusableText text="Amount" w="25%" align="center" />
    </Flex>
  );
}
