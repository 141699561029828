import { Button, CloseButton, Flex, Heading, useToast } from "@chakra-ui/react";
import Modal from "../../overlay/Modal";
import SelectComponent from "../../UI/Select";
import { useState } from "react";
import { useEffect } from "react";
import { assignBriefTag, getBriefTagList } from "../../../util/http";

export default function UpdateTag({ show, briefId }) {
  const [tag, setTag] = useState("");
  const [list, setList] = useState([]);
  const [listDetail, setListDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getBriefTagList();
        setListDetail(
          response?.map((el) => {
            return { name: el?.name, id: el?.id };
          })
        );
        setList(response?.map((el) => el?.name));
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  function closeModal() {
    show(false);
  }

  async function updateHandler() {
    const tag_id = listDetail?.find((el) => el.name == tag)?.id;
    const data = {
      tag,
      brief_id: briefId,
      tag_id,
    };

    if (!tag) {
      toast({
        title: `Select a tag`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    try {
      setLoading(true);
      await assignBriefTag(data);
      toast({
        title: `Brief tag updated`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      closeModal();
      setLoading(false);
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Modal hide={closeModal}>
      <Flex w="100%" align="start" direction="column" gap="1.5rem" py="3rem">
        <Flex w="100%" align="center" justify="space-between">
          <Heading
            size="md"
            color="var(--container_color)"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Update Status
          </Heading>
          <CloseButton onClick={closeModal} colorScheme="red" />
        </Flex>
        <SelectComponent
          label="Select Tag"
          onChange={(e) => setTag(e.value)}
          options={list || []}
        />
        <Button
          isLoading={loading}
          loadingText="Submitting"
          onClick={updateHandler}
          bg="brand.900"
          color="#fff"
          size="md"
          w="100%"
        >
          Save Update
        </Button>
      </Flex>
    </Modal>
  );
}
