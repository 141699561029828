import React from "react";
import ReusableText from "./ReusableText";
import { Flex, Box } from "@chakra-ui/react";
import ImageComponent from "./Image";
import logo from "../images/logo.png";

export default function ReceiptTemplate({
  id,
  client,
  amount,
  channel,
  description,
}) {
  return (
    <Flex align="start" p="1rem" direction="column" gap-4 w="100%">
      <Flex
        align="center"
        px="1rem"
        py="1rem"
        borderBottom="1px solid #ccc"
        justify="space-between"
        w="100%"
      >
        <Box w="50px">
          <ImageComponent src={logo} />
        </Box>
        <ReusableText
          size="20px"
          weight="bold"
          color="var(--container_color)"
          text="Payment Receipt"
        />
      </Flex>
      <Flex align="start" py="1rem" w="100%" direction="column" gap="0.5rem">
        <Flex align="center" gap="1rem">
          <ReusableText
            text="Receipt ID:"
            color="var(--container_color)"
            weight="bold"
          />
          <ReusableText text={id} color="var(--container_color)" />
        </Flex>

        <Flex align="center" gap="1rem">
          <ReusableText
            text="Date:"
            color="var(--container_color)"
            weight="bold"
          />
          <ReusableText
            text={new Date().toLocaleDateString()}
            color="var(--container_color)"
          />
        </Flex>
      </Flex>

      <Flex align="start" px="2rem" mt="2rem" direction="column" w="100%">
        <Flex
          align="center"
          py="1rem"
          borderBottom="1px solid #ccc"
          justify="space-between"
          w="100%"
        >
          <ReusableText
            text="Sender Name"
            color="var(--container_color)"
            weight="bold"
          />
          <ReusableText
            text={client}
            transform={true}
            color="var(--container_color)"
          />
        </Flex>
      </Flex>
      <ReusableText
        text="Receipt Detail"
        size="16px"
        p="1rem"
        color="var(--container_color)"
        weight="bold"
        align="start"
      />
      <Flex align="start" px="2rem" mt="2rem" direction="column" w="100%">
        <Flex
          align="center"
          py="1rem"
          borderBottom="1px solid #ccc"
          justify="space-between"
          w="100%"
        >
          <ReusableText
            text={`Receipt for ${client}`}
            color="var(--container_color)"
            weight="bold"
          />
          <ReusableText
            text={amount}
            size="20px"
            color="green.400"
            weight="bold"
          />
        </Flex>

        <Flex
          align="center"
          py="1rem"
          borderBottom="1px solid #ccc"
          justify="space-between"
          w="100%"
        >
          <ReusableText
            text={`Payment Channel`}
            color="var(--container_color)"
            weight="bold"
          />
          <ReusableText
            text={channel}
            size="16px"
            color="#333"
            weight="medium"
          />
        </Flex>

        <Flex
          align="center"
          py="1rem"
          borderBottom="1px solid #ccc"
          justify="space-between"
          w="100%"
        >
          <ReusableText
            text={`Payment Description`}
            color="var(--container_color)"
            weight="bold"
          />
          <ReusableText
            text={description}
            size="16px"
            color="#333"
            weight="medium"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
