import OngoingList from "./OngoingList";
import TaskWrapper from "../TaskWrapper";

export default function Ongoing({ list, setList }) {
  return (
    <TaskWrapper title="ONGOING" status="ongoing" total={list?.length}>
      <OngoingList setList={setList} list={list} />
    </TaskWrapper>
  );
}
