import React from "react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

export default function EmployeeHeader() {
  const navigate = useNavigate();
  return (
    <ReusableEmployeeHeader
      show={true}
      title="Employees"
      text="Manage your employees and their details here"
      btnText="Create Employee"
      icon={<AddIcon />}
      onClick={() => navigate("/employees/create")}
      menu={["Corporate", "Individual"]}
    />
  );
}
