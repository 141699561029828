import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Box,
  Heading,
  Divider,
} from "@chakra-ui/react";
import { BsBriefcaseFill, BsListTask } from "react-icons/bs";
import { TbLogout } from "react-icons/tb";
import { AiFillCalendar } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import ReusableText from "../UI/ReusableText";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { AuthContext } from "../../util/context";
import { useEffect } from "react";
import { getQuotes, getUserEvents } from "../../util/http";
import { filterDate } from "../../util/lib";

function ReusableBar({ title, icon, bg, onClick }) {
  return (
    <Flex
      onClick={onClick}
      cursor="pointer"
      w="40%"
      _hover={{ opacity: "80%" }}
      align="center"
      justify="center"
      direction="column"
    >
      <Box bg={bg} color="white" fontSize="24px" p="1rem" borderRadius="100%">
        {icon}
      </Box>
      <ReusableText
        text={title}
        size="16px"
        color="var(--container_color)"
        weight="medium"
      />
    </Flex>
  );
}

export default function SideBar({ isOpen, onClose }) {
  const navigate = useNavigate();
  const ctx = useContext(AuthContext);
  const [quote, setQuote] = useState("");
  const [events, setEvents] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getUserEvents(ctx?.user?.id);
        setEvents(
          response?.events
            ?.filter((el) => {
              const milliSec = 1 * 24 * 60 * 60 * 1000;
              const startDate = filterDate(milliSec);
              const createdDate = new Date(el?.start_time);
              return startDate < createdDate;
            })
            ?.slice(0, 3)
        );
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, [ctx.user]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getQuotes();
        setQuote(response[0]?.quote);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <Drawer
      size={{ lg: "xs", md: "sm", base: "xs" }}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent bg="var(--container)">
        <DrawerCloseButton />

        <DrawerBody>
          <Flex
            py="2rem"
            w="100%"
            wrap="wrap"
            gap="2rem"
            align="center"
            justify="center"
          >
            <Flex w="100%" gap="1rem" align="start" direction="column">
              <Heading
                size="md"
                color="var(--container_color)"
                fontWeight="bold"
              >
                Stay Motivated
              </Heading>
              <Heading
                textTransform="capitalize"
                size="sm"
                color="var(--container_color)"
                fontWeight="normal"
              >
                {quote}
              </Heading>
            </Flex>
            <Divider />

            <Flex w="100%" gap="1rem" align="start" direction="column">
              <Heading
                size="md"
                color="var(--container_color)"
                fontWeight="bold"
              >
                Today Events
              </Heading>
              <Flex w="100%" align="start" direction="column">
                {events?.length > 0 ? (
                  <>
                    {events?.map((el) => (
                      <Heading
                        size="sm"
                        color="var(--container_color)"
                        fontWeight="normal"
                      >
                        {el?.title} at{" "}
                        {new Date(el?.start_time).toLocaleTimeString()}
                      </Heading>
                    ))}
                  </>
                ) : (
                  <Flex w="100%" align="center" h="15vh" justify="center">
                    No Upcoming events today
                  </Flex>
                )}
              </Flex>
            </Flex>
            <Divider />
            <ReusableBar
              icon={<MdDashboard />}
              bg="red.300"
              title="Dashboard"
              onClick={() => {
                navigate("/");
                onClose();
              }}
            />
            <ReusableBar
              icon={<BsBriefcaseFill />}
              bg="yellow.300"
              title="Brief"
              onClick={() => navigate("/brief")}
            />
            <ReusableBar
              onClick={() => {
                navigate("/task");
                onClose();
              }}
              icon={<BsListTask />}
              bg="orange.300"
              title="Task"
            />
            <ReusableBar
              icon={<AiFillCalendar />}
              bg="green.300"
              title="Calendar"
              onClick={() => {
                navigate("/calendar");
                onClose();
              }}
            />
            <ReusableBar
              icon={<FaUser />}
              onClick={() => {
                navigate("/profile");
                onClose();
              }}
              bg="purple.300"
              title="Profile"
            />

            <ReusableBar
              onClick={() => {
                navigate("/login");
                ctx.logout();
                onClose();
              }}
              icon={<TbLogout />}
              bg="red.800"
              title="Logout"
            />
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
