import { Flex, Button } from "@chakra-ui/react";
import React, { useState } from "react";
import ReusableText from "../../../UI/ReusableText";
import UpdateRetainership from "./UpdateRetainership";
import {
  AiFillEdit,
  AiOutlineCloudDownload,
  AiOutlineClose,
} from "react-icons/ai";
import DownloadPage from "../../../UI/DownloadPage";
import { useRef } from "react";
import RetainerShipTemplate from "../../../UI/RetainerShipTemplate";

export default function RetainershipItem({ item }) {
  const [show, setShow] = useState(false);
  const retainershipRef = useRef();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <>
          <Flex align="center" p="1rem" w="100" justify="space-between">
            <DownloadPage
              element={retainershipRef}
              name={item?.client}
              setShow={setShowModal}
            />
            <Button colorScheme="red" onClick={() => setShowModal(false)}>
              <AiOutlineClose />
            </Button>
          </Flex>
          <Flex ref={retainershipRef} w="100%" align="center" justify="center">
            <RetainerShipTemplate
              client={item?.client}
              amount={`₦${new Intl.NumberFormat().format(item?.amount)}.00`}
              id={item?.retainer_id}
              dueDate={item?.validity}
            />
          </Flex>
        </>
      )}
      <Flex
        w="100%"
        align="center"
        px="1rem"
        py="1rem"
        cursor="pointer"
        _hover={{ opacity: "80%" }}
        borderBottom="1px solid #333"
      >
        <ReusableText
          text={item?.retainer_id}
          color="#515050"
          size={{ lg: "14px", md: "12px", base: "5px" }}
          weight="medium"
          w="10%"
          align="center"
          onClick={() => setShow(true)}
        />
        <ReusableText
          text={item?.client?.slice(0, 30)}
          color="#515050"
          size={{ lg: "14px", md: "12px", base: "5px" }}
          weight="medium"
          w="25%"
          align="center"
          transform={true}
          onClick={() => setShow(true)}
        />
        <ReusableText
          text={`₦${new Intl.NumberFormat().format(item?.amount)}.00`}
          color="#515050"
          size={{ lg: "14px", md: "12px", base: "5px" }}
          weight="medium"
          w="20%"
          align="center"
        />

        <ReusableText
          text={`₦${new Intl.NumberFormat().format(
            item?.available_balance
          )}.00`}
          color="#515050"
          size={{ lg: "14px", md: "12px", base: "5px" }}
          weight="medium"
          w="20%"
          align="center"
          onClick={() => setShow(true)}
        />

        <ReusableText
          text={item?.validity}
          color={new Date(item?.validity) > new Date() ? "#515050" : "red.400"}
          size={{ lg: "12px", md: "10px", base: "5px" }}
          weight="medium"
          transform={true}
          w="15%"
          align="center"
          onClick={() => setShow(true)}
        />
        <Flex align="center" w="10%" gap="1rem" justify="center">
          <AiFillEdit cursor="pointer" onClick={() => setShow(true)} />

          <>
            <AiOutlineCloudDownload
              onClick={() => setShowModal(true)}
              cursor="pointer"
            />
          </>
        </Flex>
      </Flex>
      {show && <UpdateRetainership item={item} show={setShow} />}
    </>
  );
}
