import { useEffect } from "react";
import DashboardReusable from "../UI/DashboardReusable";
import AppraisalContainer from "./AppraisalContainer";
import {
  listAppraisals,
  listPendingAppraisals,
  listUserAppraisals,
} from "../../util/http";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../util/context";

export default function Appraisal() {
  const [list, setList] = useState({
    employee: [],
    hod: [],
    hr: [],
    management: [],
  });
  const { user } = useContext(AuthContext);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await listAppraisals();
        const appraisal = response?.appraisals?.reverse();
        if (user?.job_role?.toLowerCase() === "lawyer") {
          const response = await listUserAppraisals();
          const filteredAppraisal = response?.appraisals?.filter((el) => {
            const employee = el?.lawyer_id && JSON.parse(el?.lawyer_id);
            return employee.includes(String(user?.id));
          });
          setList({ ...list, employee: filteredAppraisal });
        } else if (
          user?.job_role?.toLowerCase().includes("hod") ||
          user?.job_role?.toLowerCase() === "hop"
        ) {
          const response = await listPendingAppraisals();
          const filteredAppraisal = response?.appraisals?.filter((el) => {
            const department =
              el?.el?.departments && JSON.parse(el?.departments);
            return department
              ?.map((el) => el?.toLowerCase())
              .includes(user?.department?.toLowerCase());
          });
          setList({ ...list, hod: filteredAppraisal });
        } else {
          const filteredAppraisal = appraisal?.filter((el) => {
            const employee = el?.lawyer_id && JSON.parse(el?.lawyer_id);
            return employee.includes(String(user?.id));
          });

          setList({
            ...list,
            hr: appraisal,
            management: appraisal,
            employee: filteredAppraisal,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [user]);

  return (
    <DashboardReusable>
      <AppraisalContainer setList={setList} list={list} />
    </DashboardReusable>
  );
}
