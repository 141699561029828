import React from "react";
import CreateEmployee from "../../employee/create employee/CreateEmployee";
import { AuthContext } from "../../../util/context";
import { useContext } from "react";
import Login from "../../auth/Login";
import Dashboard from "../../dashboard/Dashboard";

export default function CreateEmployeePage() {
  let view;

  const { isAuthenticated, user, isUser } = useContext(AuthContext);
  const viewEmployee =
    user?.job_role?.toLowerCase() === "hr" ||
    user?.job_role?.toLowerCase() === "seed";

  if (viewEmployee) {
    view = <CreateEmployee />;
  } else {
    view = <Dashboard />;
  }

  return <>{isAuthenticated && isUser ? view : <Login />}</>;
}
