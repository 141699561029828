import { Box, Flex } from "@chakra-ui/react";
import ReusableText from "../../../UI/ReusableText";
import { Link } from "react-router-dom";
import BriefTitle from "../../../brief/BriefTitle";
import BriefList from "../../../brief/BriefList";

export default function RecentBriefs({ list }) {
  return (
    <Flex w="100%" direction="column">
      <Flex py="1rem" align="center" w="100%" justify="space-between">
        <ReusableText
          text="Recent Briefs"
          color="var(--container_color)"
          size="18px"
          weight="medium"
        />
        <Link to="/brief" style={{}}>
          View all {">"}
        </Link>
      </Flex>
      <Box
        w="100%"
        borderTopLeftRadius="0.75rem"
        borderTopRightRadius="0.75rem"
        bg="var(--container)"
        boxShadow="lg"
      >
        <BriefTitle />
        <BriefList list={list?.slice(0, 7)?.reverse()} />
      </Box>
    </Flex>
  );
}
