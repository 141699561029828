import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import { useContext } from "react";
import { AuthContext } from "../../../util/context";

export default function AppraisalHeader({ appraisal }) {
  const { user } = useContext(AuthContext);
  const name =
    appraisal?.supervisor &&
    `${appraisal?.supervisor[0]?.first_name} ${appraisal?.supervisor[0]?.last_name}`;
  return (
    <Flex border="1px solid #ccc" w="100%" align="start" wrap="wrap">
      <Flex
        w="50%"
        align="center"
        justify="space-between"
        borderBottom="1px solid #ccc"
        borderLeft="1px solid #ccc"
        p="6px"
      >
        <ReusableText
          text="Name"
          color="var(--gray)"
          size="18px"
          weight="medium"
        />
        <ReusableText
          text={`${user?.first_name} ${user?.last_name}`}
          transform={true}
          color="var(--gray)"
          size="15px"
          weight="normal"
        />
      </Flex>

      <Flex
        w="50%"
        align="center"
        justify="space-between"
        borderBottom="1px solid #ccc"
        borderLeft="1px solid #ccc"
        p="6px"
      >
        <ReusableText
          text="Department"
          color="var(--gray)"
          size="18px"
          weight="medium"
        />
        <ReusableText
          text={user?.department}
          transform={true}
          color="var(--gray)"
          size="15px"
          weight="normal"
        />
      </Flex>

      <Flex
        w="50%"
        align="center"
        justify="space-between"
        borderBottom="1px solid #ccc"
        borderLeft="1px solid #ccc"
        p="6px"
      >
        <ReusableText
          text="Position"
          color="var(--gray)"
          size="18px"
          weight="medium"
        />
        <ReusableText
          text={user?.job_role}
          transform={true}
          color="var(--gray)"
          size="15px"
          weight="normal"
        />
      </Flex>

      <Flex
        w="50%"
        align="center"
        justify="space-between"
        borderBottom="1px solid #ccc"
        borderLeft="1px solid #ccc"
        p="6px"
      >
        <ReusableText
          text="Supervisor"
          color="var(--gray)"
          size="18px"
          weight="medium"
        />
        <ReusableText
          text={name}
          color="var(--gray)"
          size="15px"
          weight="normal"
        />
      </Flex>

      <Flex
        w="50%"
        align="center"
        justify="space-between"
        borderBottom="1px solid #ccc"
        borderLeft="1px solid #ccc"
        p="6px"
      >
        <ReusableText
          text="Reporting Period"
          color="var(--gray)"
          size="18px"
          weight="medium"
        />
        <ReusableText
          text={new Date(appraisal?.reporting_period).toLocaleDateString()}
          color="var(--gray)"
          size="15px"
          weight="normal"
        />
      </Flex>
      <Flex
        w="50%"
        align="center"
        justify="space-between"
        borderBottom="1px solid #ccc"
        borderLeft="1px solid #ccc"
        p="6px"
      >
        <ReusableText
          text="Date"
          color="var(--gray)"
          size="18px"
          weight="medium"
        />
        <ReusableText
          text={new Date(appraisal?.due_date)?.toLocaleDateString()}
          color="var(--gray)"
          size="15px"
          weight="normal"
        />
      </Flex>
    </Flex>
  );
}
