import React from "react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";

export default function CreateClientHeader() {
  return (
    <ReusableEmployeeHeader
      title="Client"
      text="Manage your Clents and their details here"
    />
  );
}
