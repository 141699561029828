import React from "react";
import { Button } from "@chakra-ui/react";

export default function ButtonComponent({
  variant,
  bg,
  color,
  title,
  width,
  onClick,
  mx,
  type,
  disabled = false,
  loading = false,
}) {
  return (
    <Button
      type={type}
      onClick={onClick}
      variant={variant ? variant : "solid"}
      bg={bg ? bg : "brand.900"}
      color={color ? color : "white"}
      borderRadius="2rem"
      size="md"
      w={width ? width : ""}
      mx={mx ? mx : ""}
      isDisabled={disabled}
      isLoading={loading}
      loadingText="Processing"
    >
      {title}
    </Button>
  );
}
