import React from "react";
import Document from "../../documents/Document";
import { AuthContext } from "../../../util/context";
import { useContext } from "react";
import Login from "../../auth/Login";
import Dashboard from "../../dashboard/Dashboard";

export default function DocumentPage() {
  let view;
  const { isAuthenticated, user, isUser } = useContext(AuthContext);

  const viewDocument =
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "seed" ||
    user?.job_role?.toLowerCase() === "hr";

  if (viewDocument) {
    view = <Document />;
  } else {
    view = <Dashboard />;
  }
  return <>{isAuthenticated && isUser ? view : <Login />}</>;
}
