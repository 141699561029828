import { Flex } from "@chakra-ui/react";
import React from "react";
import InvoicesTitle from "./InvoicesTitle";
import InvoiceList from "./InvoiceList";

export default function PaidInvoices({invoice}) {
  return (
    <Flex align="center" direction="column">
      <InvoicesTitle />
      <InvoiceList invoice={invoice} />
    </Flex>
  );
}
