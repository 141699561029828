import { Button, Flex, Heading } from "@chakra-ui/react";
import { useContext } from "react";
import { AuthContext } from "../../../../util/context";

export default function TaskHeader({ task, show }) {
  const { user } = useContext(AuthContext);
  return (
    <Flex w="100%" align="center" justify="space-between" p="1rem">
      <Heading
        size="md"
        color="var(--container_color)"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {task?.title}
      </Heading>

      {user?.job_role?.toLowerCase() !== "lawyer" &&
        user?.job_role?.toLowerCase() !== "hr" &&
        user?.job_role?.toLowerCase() !== "user" && (
          <Button
            onClick={() => show(true)}
            bg="brand.900"
            color="white"
            variant="solid"
          >
            Edit Task
          </Button>
        )}
    </Flex>
  );
}
