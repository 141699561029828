import {
  CloseButton,
  Flex,
  Heading,
  Divider,
  Button,
  useToast,
} from "@chakra-ui/react";
import Modal from "../../overlay/Modal";
import InputComponent from "../../UI/Input";
import { useState } from "react";
import { updateDeductions } from "../../../util/http";

export default function EditDeduction({ show, detail }) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [salary, setSalary] = useState({
    name: detail?.name,
    amount: detail?.amount,
  });

  function closeModal() {
    show(false);
  }

  async function salaryHandler() {
    try {
      setLoading(true);
      await updateDeductions(salary, detail?.id);
      toast({
        title: `Deduction Updated`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      closeModal();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Modal hide={closeModal}>
      <Flex w="100%" align="start" direction="column" gap="1.5rem" py="1rem">
        <Flex w="100%" align="center" justify="space-between">
          <Heading size="md" color="var(--container_color)" fontWeight="bold">
            Edit Deduction
          </Heading>
          <CloseButton onClick={closeModal} />
        </Flex>
        <Divider />
        <InputComponent
          onChange={(e) => setSalary({ ...salary, name: e.target.value })}
          value={salary.name}
          placeholder="Lateness, negligence"
          label="Deduction Name"
        />
        <InputComponent
          onChange={(e) => setSalary({ ...salary, amount: e.target.value })}
          value={salary.amount}
          type="number"
          placeholder="5,000"
          label="Amount"
        />

        <Button
          isLoading={loading}
          loadingText="Saving"
          onClick={salaryHandler}
          variant="solid"
          bg="brand.900"
          color="#fff"
        >
          Save Update
        </Button>
      </Flex>
    </Modal>
  );
}
