import { Button, Flex, Heading, useToast, Box } from "@chakra-ui/react";
import SelectComponent from "../../UI/Select";
import { useState } from "react";
import { getEmployees, getTask, updateTask } from "../../../util/http";
import ReusableText from "../../UI/ReusableText";
import Modal from "../../overlay/Modal";
import { useEffect } from "react";
import InputComponent from "../../UI/Input";
import { useParams } from "react-router-dom";

export default function EditTask({ show, task, setTask }) {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(task?.status);
  const [priority, setPriority] = useState(task?.priority);
  const [empNames, setEmpNames] = useState([]);
  const [empDetail, setEmpDetail] = useState([]);
  const [lawyer, setLawyer] = useState([]);
  const [assigned, setAssigned] = useState([]);
  const [unAssigned, setUnassigned] = useState([]);
  const [assignedId, setAssignedId] = useState([]);
  const [date, setDate] = useState(task?.due_date || "");
  const params = useParams();

  useEffect(() => {
    async function fetchData() {
      try {
        const employeeResponse = await getEmployees();
        const employeeObj = employeeResponse?.employees
          ?.filter((el) => {
            const canAssign =
              el?.job_role?.toLowerCase() === "lawyer" ||
              el?.job_role?.toLowerCase() === "hod" ||
              el?.job_role?.toLowerCase() === "hop" ||
              el?.job_role?.toLowerCase() === "user" ||
              el?.job_role?.toLowerCase() === "accountant" ||
              el?.job_role?.toLowerCase() === "hr";
            return el.deleted_at === null && canAssign;
          })
          .map((el) => {
            return { name: `${el.first_name} ${el.last_name}`, id: el.id };
          });
        const employeeNames = employeeObj?.map((el) => el.name);
        setEmpDetail(employeeObj);
        setEmpNames(employeeNames);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const lawyers = task?.assignee && JSON.parse(task?.assignee);
    setAssigned(lawyers);
  }, []);

  useEffect(() => {
    const lawyersId = task?.assignee && JSON.parse(task?.assignee_id);
    setAssignedId(lawyersId);
  }, []);

  useEffect(() => {
    const arr1 = empNames?.filter((el) => assigned?.indexOf(el) === -1);
    const arr2 = assigned?.filter((el) => empNames?.indexOf(el) === -1);

    const uniqueEmp = arr1?.concat(arr2);
    setUnassigned(uniqueEmp);
  }, [assigned, empNames]);

  function handleDelete(i) {
    const deleteVal = [...assigned];
    const ids = [...assignedId];
    deleteVal.splice(i, 1);
    ids.splice(i, 1);
    setAssignedId(ids);
    setAssigned(deleteVal);
  }

  function closeMenu() {
    show(false);
  }

  const toast = useToast();

  async function editHandler() {
    const uniqueArray = lawyer?.filter(function (item, i) {
      return lawyer.indexOf(item) === i;
    });

    const assignee_id = uniqueArray.map((el, i) => {
      let arr = [];
      for (let key in empDetail) {
        if (empDetail[key]?.name?.toLowerCase() === el?.toLowerCase()) {
          arr.push(empDetail[key].id || "");
        }
      }
      return arr;
    });

    const data = {
      priority,
      status,
      due_date: date,
      assignee:
        lawyer?.length > 0
          ? JSON.stringify(uniqueArray)
          : JSON.stringify(assigned),
      assignee_id:
        lawyer?.length > 0
          ? JSON.stringify(assignee_id?.flatMap((el) => el?.toString()))
          : JSON.stringify(assignedId),
    };
    try {
      setLoading(true);
      await updateTask(task?.id, data);
      const response = await getTask(params.id);
      setTask(response.task[0]);
      setLoading(false);
      closeMenu();
      toast({
        title: `Task Updated`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Modal title="Edit Task" hide={closeMenu}>
        <Flex w="100%" py="1rem" align="start" direction="column" gap="1rem">
          <Heading size="md" color="var(--container_color)" fontWeight="bold">
            Edit Task
          </Heading>
          <Flex w="100%" direction="column" align="start">
            <SelectComponent
              label="Priority"
              onChange={(e) => setPriority(e.value)}
              options={["Select Priority", "Low", "Medium", "High"]}
            />
            <ReusableText
              text={`Current priority: ${task?.priority}`}
              color="var(--container_color)"
              weight="normal"
              size="14px"
            />
          </Flex>
          <Flex w="100%" direction="column" align="start">
            <SelectComponent
              label="status"
              onChange={(e) => setStatus(e.value)}
              options={["Select Status", "Todo", "Ongoing", "Completed"]}
            />
            <ReusableText
              text={`Current Status: ${task?.status}`}
              color="var(--container_color)"
              weight="normal"
              size="14px"
            />
          </Flex>{" "}
          <Flex w="100%" direction="column" align="start">
            <InputComponent
              label="Edit Due Date"
              type="datetime-local"
              onChange={(e) => setDate(e.target.value)}
            />
            <ReusableText
              text={`Current Due date: ${new Date(
                task?.due_date
              ).toLocaleDateString()}`}
              color="var(--container_color)"
              weight="normal"
              size="14px"
            />
          </Flex>
          <Flex w="100%" direction="column" align="start">
            <SelectComponent
              label="Reassign Task"
              multiple={true}
              onChange={(e) => {
                const lawyers = e?.map((el) => el.value);
                setLawyer((prev) => [...assigned, ...lawyers]);
              }}
              options={unAssigned || []}
            />
            <Flex w="100%" align="center" gap="6px">
              <Flex align="start" w="100%" direction="column">
                <ReusableText
                  text="Assigned Lawyers"
                  align="start"
                  size="16px"
                  weight="medium"
                  color="var(--container_color)"
                />
                <Flex w="100%" align="center" gap="1rem" wrap="wrap">
                  {assigned?.map((el, i) => (
                    <Box key={i} bg="#ccc" p="3px" position="relative">
                      <ReusableText
                        text={el}
                        size="14px"
                        weight="normal"
                        color="var(--container_color)"
                        transform={true}
                      />
                      <Button
                        onClick={() => {
                          handleDelete(i);
                        }}
                        position="absolute"
                        top="-4"
                        colorScheme="red"
                        variant="ghost"
                        right="-4"
                        p="0.1rem"
                      >
                        X
                      </Button>
                    </Box>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex w="100%" align="center" justify="space-between">
            <Button onClick={closeMenu} variant="solid" colorScheme="red">
              Cancel
            </Button>
            <Button
              onClick={editHandler}
              variant="solid"
              bg="brand.900"
              color="white"
              isLoading={loading}
              loadingText="Processing"
            >
              Update
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
