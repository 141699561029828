import DashboardReusable from "../UI/DashboardReusable";
import ReportContainer from "./ReportContainer";

export default function Report() {
  return (
    <DashboardReusable>
      <ReportContainer />
    </DashboardReusable>
  );
}
