import { Flex , Grid } from "@chakra-ui/react";
import React from "react";
import FileItem from "./FileItem";
import ViewFile from "./viewFile";
import { useState } from "react";

export default function FileList({ list, show }) {
  const [file , setFile] = useState({});

  return (
    <Flex w={'full'} gap={'2rem'} flexDir={{
      base: 'column',
      md: 'row'
    }} >
    <Grid alignContent={'start'} align="start" justify="start" wrap="wrap" gap="1rem" w="100%" gridTemplateColumns={
      list?.length > 0 ? "repeat(auto-fill, minmax(200px, 1fr))" : "1fr"
    }>
      {list?.length > 0 ? (
        <>
          {list?.map((item, i) => (
            <FileItem showMenu={show} key={i} item={item} setFile = {setFile} file = {file} />
          ))}

        </>
      ) : (
        <Flex
          color="var(--container_color)"
          w="100%"
          align="center"
          justify="center"
          h="300px"
        >
          No file found...
        </Flex>
      )}
    </Grid>
    <Grid>
    {
      Object.keys(file).length > 0 ? <ViewFile file={file} setFile={setFile}/> : null
    }
    </Grid>
    </Flex>
  );
}
