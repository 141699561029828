import React from "react";
import ReusableText from "../UI/ReusableText";
import { Box, Button, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../UI/DeleteModal";
import { updateLeave } from "../../util/http";

export default function LeaveItem({ path, item }) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  async function withdrawHandler() {
    try {
      await updateLeave(item?.leave_id, {
        status: "withdrawn",
        decision: "withdrawn",
      });
      window.location.reload();
      toast({
        title: `Leave successfully withdrawn`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      onClose();
    } catch (error) {
      onClose();
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Flex
        w="100%"
        align="center"
        px="1rem"
        py="1rem"
        cursor="pointer"
        _hover={{ opacity: "80%" }}
        borderBottom="1px solid #333"
      >
        <ReusableText
          align="center"
          size={{ lg: "14px", md: "12px", base: "6px" }}
          color="var(--container_color)"
          w="10%"
          text={item?.leave_id}
          onClick={() => navigate(`/${path}/${item?.leave_id}`)}
        />
        <ReusableText
          align="center"
          size={{ lg: "14px", md: "12px", base: "6px" }}
          color="var(--container_color)"
          w="10%"
          text={item?.employee?.slice(0, 15)}
          onClick={() => navigate(`/${path}/${item?.leave_id}`)}
        />
        <ReusableText
          size={{ lg: "14px", md: "12px", base: "6px" }}
          color="var(--container_color)"
          w="20%"
          text={item?.leave_type}
          align="center"
          onClick={() => navigate(`/${path}/${item?.leave_id}`)}
        />
        <ReusableText
          size={{ lg: "14px", md: "12px", base: "6px" }}
          color="var(--container_color)"
          align="center"
          w="20%"
          text={new Date(item?.start_date)?.toLocaleDateString()}
        />
        <ReusableText
          size={{ lg: "14px", md: "12px", base: "6px" }}
          color="var(--container_color)"
          align="center"
          w="10%"
          text={new Date(item?.end_date)?.toLocaleDateString()}
        />
        <ReusableText
          size={{ lg: "14px", md: "12px", base: "6px" }}
          color="var(--container_color)"
          align="center"
          w="10%"
          text={item?.no_of_days}
        />
        <Flex w="10%" align="center" justify="center" gap="0.5rem">
          <Box
            w="0.4rem"
            height="0.4rem"
            borderRadius="100%"
            bg={
              item?.decision?.toLowerCase() === "pending"
                ? "#333"
                : item?.decision?.toLowerCase() === "approved"
                ? "green.300"
                : "red.400"
            }
          ></Box>
          <ReusableText
            align="center"
            size={{ lg: "12px", md: "10px", base: "6px" }}
            weight="medium"
            color={
              item?.decision?.toLowerCase() === "pending"
                ? "var(--gray)"
                : item?.decision?.toLowerCase() === "approved"
                ? "green.300"
                : "red.400"
            }
            text={item?.decision}
            transform={true}
          />
        </Flex>

        <Flex
          fontSize={{ lg: "14px", md: "12px", base: "10px" }}
          align="center"
          w="10%"
          justify="space-evenly"
        >
          <AiOutlineEye
            color="var(--container_color)"
            onClick={() => navigate(`/${path}/${item?.leave_id}`)}
          />
          <Button
            p="0px"
            variant="ghost"
            onClick={onOpen}
            isDisabled={item?.status?.toLowerCase() !== "pending"}
          >
            <AiOutlineDelete color="var(--container_color)" />
          </Button>
        </Flex>
      </Flex>
      <DeleteModal
        title="Withdraw Leave?"
        text="Are you sure you would like to Withdraw this leave? Withdrawing the leave will remove it from the approval list."
        modalText="You have successfully withdrawn a leave"
        modalTitle="Leave withdrawn"
        isOpen={isOpen}
        onClose={onClose}
        onClick={withdrawHandler}
      />
    </>
  );
}
