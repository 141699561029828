import {
  CloseButton,
  Flex,
  Heading,
  Divider,
  Button,
  useToast,
} from "@chakra-ui/react";
import Modal from "../../../overlay/Modal";
import SelectComponent from "../../../UI/Select";
import { useState } from "react";
import { useEffect } from "react";
import { deductSalary, getDeductions } from "../../../../util/http";

export default function DeductSalary({ show, id }) {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);
  const toast = useToast();
  const [deduction, setDeduction] = useState({
    name: "",
    duration: "",
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getDeductions();
        setAllList(
          response?.deductions?.map((el) => {
            return {
              name: el?.name,
              id: el?.id,
            };
          })
        );
        setList(response?.deductions?.map((el) => el?.name));
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  function closeModal() {
    show(false);
  }

  async function deductionHandler() {
    const deduction_id = allList?.find(
      (el) => el?.name?.toLowerCase() === deduction?.name?.toLowerCase()
    )?.id;

    const data = { deduction_id, duration: deduction.duration };

    try {
      setLoading(true);
      await deductSalary(data, id);
      toast({
        title: `Deduction Applied`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      closeModal();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Modal hide={closeModal}>
      <Flex w="100%" align="start" direction="column" gap="1.5rem" py="1rem">
        <Flex w="100%" align="center" justify="space-between">
          <Heading size="md" color="var(--container_color)" fontWeight="bold">
            Deduct from Joe Salary
          </Heading>
          <CloseButton onClick={closeModal} />
        </Flex>
        <Divider />
        <Flex w="100%" gap="1rem" align="center" justify="space-between">
          <SelectComponent
            onChange={(e) => setDeduction({ ...deduction, name: e.value })}
            label="Select Deduction Type"
            options={list || []}
          />

          <SelectComponent
            onChange={(e) => setDeduction({ ...deduction, duration: e.value })}
            label="Select Deduction Duration"
            options={[
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ]}
          />
        </Flex>

        <Button
          isLoading={loading}
          loadingText="Saving"
          onClick={deductionHandler}
          variant="solid"
          bg="brand.900"
          color="#fff"
        >
          Save Update
        </Button>
      </Flex>
    </Modal>
  );
}
