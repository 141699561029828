import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { Fragment, useState, useEffect, useContext } from "react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AddIcon } from "@chakra-ui/icons";
import ReusableFilter from "../UI/ReusableFilter";
import RequisitionTitle from "./RequisitionTitle";
import RequisitionList from "./RequisitionList";
import CreateRequisition from "./CreateRequisition";
import ReusableFooter from "../UI/ReusableFooter";
import { getRequisitions } from "../../util/http";
import { AuthContext } from "../../util/context";

export default function RequisitionContainer({
  list,
  total,
  perPage,
  currentPage,
  setCurrentPage,
  setList,
  loading,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setStatus] = useState("");
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function sortData() {
      if (!status) {
        return;
      } else {
        try {
          const res = await getRequisitions();



          let response;
          const departmentInternalRequisitions = response?.internal_requisitions
          ?.filter(
            (el) =>
              el.brief_type?.toLowerCase() ===
              user?.department?.toLowerCase() && el?.deleted_at === null
          )
          .reverse()




        const internalRequisitions = res?.internal_requisitions
          ?.filter(
            (el) =>
              +el.initiator?.initiator_id === +user?.id &&
              el?.deleted_at === null
          )
          .reverse();



        if (
          user.job_role?.toLowerCase() === "lawyer" ||
          user?.job_role?.toLowerCase() === "hr" ||
          user?.job_role?.toLowerCase() === 'user'
        ) {


          const requisitions = res?.requisitions
            ?.filter(
              (el) => {
                return (+el.initiator?.initiator_id === +user?.id &&
                  el?.deleted_at === null)

              }
            )
            .reverse()

         response =   [...requisitions, ...internalRequisitions,];
        } else if (
          user?.job_role?.toLowerCase().includes("hod")
        ) {
          const personalRequisition = res?.requisitions
            ?.filter(
              (el) => {
                return (+el.initiator?.initiator_id === +user?.id &&
                  el?.deleted_at === null)

              }
            )
            .reverse()

          const departmentRequisition = res?.requisitions
            ?.filter(
              (el) =>
                el.brief_type?.toLowerCase() ===
                user?.department?.toLowerCase() && el?.deleted_at === null
            )
            .reverse()




          response =   [
              ...personalRequisition, ...departmentRequisition, ...departmentInternalRequisitions
            ]
          ;

        }

        else if (
          user?.job_role?.toLowerCase() === 'user'
        ) {


         response =    res?.internal_requisitions
              ?.filter(
                (el) => {
                  return (+el.initiator?.initiator_id === +user?.id &&
                    el?.deleted_at === null)

                }
              )
              .reverse()
          ;
        }




        else {
          const external = res?.requisitions
            ?.filter((el) => el?.deleted_at === null)
            .reverse()

          const internal = res?.internal_requisitions
            ?.filter((el) => el?.deleted_at === null)
            .reverse()

          const merged = [...external, ...internal]
          //sort by created_at
          const sorted = merged.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });


         response  = sorted
        }

          const filteredRequisitions = response
            ?.filter((el) => el.status === status && el.deleted_at === null)
            .reverse();

          const paidRequisition = response?.filter(
            (el) => +el.account_status === 1 && el.deleted_at === null)
            .reverse();
          const unpaidRequisition = response?.filter(
            (el) => +el.account_status === 0 && el.deleted_at === null)
            .reverse();


          if (status === "all" || !status) {
            setList(response?.filter((el) => el.deleted_at === null).reverse());
          } else if (status === "archived") {
            setList(response?.filter((el) => el.deleted_at !== null).reverse());
          }
          // 6 & 7
          else if (status === "6") {
            setList(paidRequisition);

          }
          else if (status === "7") {
            setList(unpaidRequisition);
          }

          else {
            setList(filteredRequisitions);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    sortData();
  }, [status]);

  function allReq() {
    setStatus("all");
  }

  function pendingReq() {
    setStatus("0");
  }
  function HOCReq() {
    setStatus("1");
  }
  function HODReq() {
    setStatus("2");
  }
  function approvedReq() {
    setStatus("3");
  }

  function rejectedReq() {
    setStatus("4");
  }

  function archivedReq() {
    setStatus("5");
  }

  // paid and unpaid
  function paidReq() {
    setStatus("6");
  }

  function unpaidReq() {
    setStatus("7");
  }

  async function searchHandler(e) {
    const value = e.target.value.trim().toLowerCase();
    const response = await getRequisitions();



    let filteredData;
    if (
      user?.job_role?.toLowerCase().includes("hod")
    ) {
      filteredData = response?.requisitions
        ?.filter(
          (el) =>
            el.brief_type?.toLowerCase() === user?.department?.toLowerCase() &&
            el?.brief_title?.toLowerCase()?.includes(value)
        )
        .reverse();
    } else if (
      user.job_role?.toLowerCase() === "lawyer" ||
      user?.job_role?.toLowerCase() === "hr"
    ) {
      filteredData = response?.requisitions
        ?.filter(
          (el) =>
            +el.initiator?.initiator_id === +user?.id &&
            el?.brief_title?.toLowerCase()?.includes(value)
        )
        .reverse();
    } else {
      filteredData = response?.requisitions
        ?.filter((el) => el?.brief_title?.toLowerCase()?.includes(value))
        .reverse();
    }

    setList(filteredData);
  }

  //check for accountant job role
  //!TODO: add paid and unpaid if job_role is accountant
  const accountantFilter = user.job_role?.toLowerCase() === "accountant" ? [
    { text: "Paid", onClick: paidReq },
    { text: "Unpaid", onClick: unpaidReq },
  ] : []


  return (
    <Fragment>
      <Flex
        w="100%"
        align="start"
        direction="column"
        h="100%"
        gap="1rem"
        p={{ lg: "2rem", md: "1rem", base: "6px" }}
      >
        <ReusableEmployeeHeader
          title="Requisitions"
          text="Manage your requisition and their details here"
          btnText="Create Requisition"
          show={user?.job_role?.toLowerCase() !== "accountant"}
          icon={<AddIcon />}
          onClick={onOpen}
        />
        <ReusableFilter
          menu={[
            { text: "All", onClick: allReq },
            { text: "Pending", onClick: pendingReq },
            // { text: "Approved", onClick: approvedReq },
            { text: "Approved By HOD", onClick: HODReq },
            { text: "Approved by HOC", onClick: HOCReq },
            { text: "Rejected", onClick: rejectedReq },
            // { text: "Archived", onClick: archivedReq },
            // add paid and unpaid if job_role is accountant
            ...accountantFilter




          ]}
          onSearch={searchHandler}
        />
        <Box
          w="100%"
          borderTopLeftRadius="0.75rem"
          borderTopRightRadius="0.75rem"
          bg="var(--container)"
          boxShadow="lg"
        >
          <RequisitionTitle />
          <RequisitionList loading={loading} list={list} />
          <ReusableFooter
            total={total}
            itemPerPage={perPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Box>
      </Flex>
      <CreateRequisition isOpen={isOpen} onClose={onClose} />
    </Fragment>
  );
}
