import { Box, Button, Flex, Slide, useDisclosure } from "@chakra-ui/react";
import React from "react";
import DashboardSide from "../dashboard/DashboardSide";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { TbFloatRight } from "react-icons/tb";
import { useState } from "react";
import SideBar from "../dashboard/SideBar";

export default function DashboardReusable({ children, padding = false }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [right, setRight] = useState(-12);
  const [show, setShow] = useState("flex");

  return (
    <>
      <SideBar isOpen={isOpen} onClose={onClose} />
      <Box
        onClick={onOpen}
        onMouseEnter={() => {
          setRight(0);
        }}
        onMouseLeave={() => {
          setRight(-12);
        }}
        position="fixed"
        p="1rem"
        bg="brand.900"
        zIndex={80}
        borderRadius={10}
        top="30%"
        right={right}
        fontSize={28}
        color="#fff"
        cursor="pointer"
      >
        <TbFloatRight />
      </Box>

      <Flex
        h="100vh"
        position="fixed"
        align="center"
        overflow="scroll"
        w="100%"
        bg="var(--side)"
        pt="1rem"
      >
        <DashboardSide display={show} />

        <Flex
          bg="var(--container)"
          overflow="scroll"
          height="100vh"
          w="72%"
          p={padding ? padding : "1rem"}
          borderLeftRadius="2rem"
          flex="1"
          position="relative"
        >
          <Button
            variant="ghost"
            display={{ lg: "flex", md: "none", base: "none" }}
            p="1rem"
            cursor="pointer"
            color="var(--container_color)"
            position="absolute"
            top="20"
            left="0"
            onClick={() =>
              show === "flex" ? setShow("none") : setShow("flex")
            }
          >
            {show === "flex" ? (
              <AiOutlineMenuFold size={20} />
            ) : (
              <AiOutlineMenuUnfold size={20} />
            )}
          </Button>

          {children}
        </Flex>
      </Flex>
    </>
  );
}
