import React from "react";
import DeleteModal from "../../UI/DeleteModal";

export default function DeleteReceipt({ isOpen, onClose }) {
  return (
    <DeleteModal
      text='"Are you sure you would like to delete “The Estate of John Doe’s” receipt?  Deleting the receipt will remove all the associated content from the LOYA platform.'
      title="Delete Receipt?"
      modalTitle="Receipt Deleted"
      modalText="You have successfully deleted “The Estate of John Doe’s” Receipt"
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}
