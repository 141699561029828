import { Flex } from "@chakra-ui/react";
import SelectComponent from "../UI/Select";
import { useEffect, useState } from "react";
import { getClients } from "../../util/http";
import ReusableAmountInput from "../UI/ReusableAmountInput";

export default function SelectTab({
  setClient,
  setTitle,
  setClientId,
  type,
  setAmount,
  briefs,
  client,
  currency,
}) {
  const [clientList, setClientList] = useState([]);
  const [allClients, setAllClients] = useState([]);
  const briefTitles = briefs?.map((el) => el.brief_title);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getClients();
        setAllClients(response?.clients);
        setClientList(
          response?.clients
            ?.filter((el) => el.deleted_at === null)
            .map((el) => el.client_name)
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  async function changeHandler(e) {
    setClient(e.value);
    const client = allClients?.find(
      (el) => el.client_name?.toLowerCase() === e.value.toLowerCase()
    );
    setClientId(client?.client_id);
  }

  return (
    <Flex align="center" w="100%" gap="1rem">
      <SelectComponent
        onChange={changeHandler}
        label="Client"
        value={client}
        options={["Select client", ...clientList]}
      />
      {type === "invoice" ? (
        <SelectComponent
          onChange={(e) => setTitle(e.value)}
          label="Brief Title"
          options={["Select brief", ...briefTitles]}

        />
      ) : (
        <ReusableAmountInput
          placeholder={`${currency?.Symbol ? currency?.Symbol : '₦' } Enter Amount `}
          label={`Enter Amount  (${currency?.Currency ? currency?.Currency : 'Naira'})`}
          onChange={(e) => setAmount(e.target.rawValue)}
        />
      )}
    </Flex>
  );
}
