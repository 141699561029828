import { Button, Flex, useToast } from "@chakra-ui/react";
import React from "react";
import PersonalDetail from "./PersonalDetail";
import EditAddress from "./EditAddress";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../util/context";
import Loader from "../../UI/Loader";
import { editClient, updateEmployee } from "../../../util/http";
import { getStoredItem, storeItem } from "../../../util/lib";

export default function EditForm() {
  const ctx = useContext(AuthContext);
  const user = ctx.user;

  const toast = useToast();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(user?.first_name || "");
  const [lastName, setLastName] = useState(user?.last_name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [phone, setPhone] = useState(user?.phone_number || "");
  const [employeeId, setEmployeeId] = useState(user?.employee_id || "");
  const [role, setRole] = useState(user?.job_role || "");

  const [address1, setAddress1] = useState(user?.address1 || "");
  const [address2, setAddress2] = useState(user?.address2 || "");
  const [city, setCity] = useState(user?.city || "");
  const [country, setCountry] = useState(user?.country || "");
  const [state, setState] = useState(user?.state || "");
  const [code, setCode] = useState(user?.code || "");
  const [image, setImage] = useState("");

  const [kinFirstName, setKinFirstName] = useState(
    user?.contact_first_name || ""
  );
  const [kinLastName, setKinLastName] = useState(user?.contact_last_name || "");
  const [kinCity, setKinCity] = useState(user?.contact_city || "");
  const [kinCountry, setKinCountry] = useState(user?.contact_country || "");
  const [kinState, setKInState] = useState(user?.contact_state || "");
  const [kinCode, setKinCode] = useState(user?.contact_code || "");
  const [kinAddress1, setKinAddress1] = useState(user?.contact_address1 || "");
  const [kinAddress2, setKinAddress2] = useState(user?.contact_address2 || "");
  const [kinPhone, setKinPhone] = useState(user?.contact_phone_number || "");
  const [kinEmail, setKinEmail] = useState(user?.contact_email || "");

  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const userType = getStoredItem("user-type");

  async function updateHandler() {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      image,
      phone_number: phone,
      job_role: role,
      address1,
      address2,
      city,
      state,
      country,
      postal_code: code,
      employee_id: employeeId,
      contact_email: kinEmail,
      contact_first_name: kinFirstName,
      contact_last_name: kinLastName,
      contact_phone: kinPhone,
      contact_country: kinCountry,
      contact_city: kinCity,
      contact_state: kinState,
      contact_postal_code: kinCode,
      contact_address1: kinAddress1,
      contact_address2: kinAddress2,
    };

    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      let response;
      if (userType === "employee") {
        response = await updateEmployee(user.id, data);
        storeItem("user", response?.employee, 86400000);
        storeItem("user-type", "employee", 86400000);
        ctx.setUser(response?.employee);
      } else {
        response = await editClient(user.id, data);
        storeItem("user", response?.client, 86400000);
        storeItem("user-type", "client", 86400000);
        ctx.setUser(response?.client);
      }
      console.log(response);
      navigate("/profile");
      setProgress(80);
      setProgress(100);
      setLoading(false);

      toast({
        title: `Update Successful`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setProgress(100);
      console.log(error);
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Loader progress={progress} setProgress={setProgress} />
      <Flex direction="column" w="100%">
        <PersonalDetail
          setEmail={setEmail}
          setPhone={setPhone}
          setEmployeeId={setEmployeeId}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setRole={setRole}
          email={email}
          phone={phone}
          lastName={lastName}
          firstName={firstName}
          role={role}
          employeeId={employeeId}
          setImage={setImage}
          kinAddress1={kinAddress1}
          kinAddress2={kinAddress2}
          kinCity={kinCity}
          kinFirstName={kinFirstName}
          kinLastName={kinLastName}
          kinCode={kinCode}
          kinCountry={kinCountry}
          kinState={kinState}
          setKinAddress1={setKinAddress1}
          setKinAddress2={setKinAddress2}
          setKinFirstname={setKinFirstName}
          setKInState={setKInState}
          setKinCity={setKinCity}
          setKinCountry={setKinCountry}
          setKinLastName={setKinLastName}
          setKinCode={setKinCode}
          kinPhone={kinPhone}
          setKinPhone={setKinPhone}
          kinEmail={kinEmail}
          setKinEmail={setKinEmail}
          setAddress1={setAddress1}
          setAddress2={setAddress2}
          setCity={setCity}
          setCode={setCode}
          setCountry={setCountry}
          setState={setState}
          address1={address1}
          address2={address2}
          city={city}
          state={state}
          code={code}
          country={country}
        />

        <Flex w="100%" py="1rem" align="center" justify="end" gap="1rem">
          <Button
            onClick={() => navigate("/profile")}
            variant="outline"
            color="brand.900"
          >
            Cancel
          </Button>
          <Button
            onClick={updateHandler}
            variant="solid"
            bg="brand.900"
            color="white"
            isLoading={loading}
            loadingText="Processing"
          >
            Save Changes
          </Button>
        </Flex>
      </Flex>
    </>
  );
}
