import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import ReusableText from "../UI/ReusableText";

export default function ArchiveEmployee({
  isOpen,
  onClose,
  title,
  text,
  modalTitle,
  modalText,
}) {
  const toast = useToast();
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody py="2rem" w="100%">
          <Flex align="center" direction="column" gap="1rem">
            <Flex
              w="2.5rem"
              align="center"
              justify="center"
              h="2.5rem"
              borderRadius="100%"
              bg="red.100"
            >
              <InfoOutlineIcon color="error.900" />
            </Flex>
            <ReusableText
              text={title ? title : "Archive Employee?"}
              size="18px"
              color="grey.900"
              weight="bold"
            />
            <ReusableText
              size="14px"
              color="#7F7E7E"
              text={
                text
                  ? text
                  : "Are you sure you would like to delete Tunde Ogunleye? If you do, this user will no longer have access to the Looya Platform."
              }
              align="center"
              weight="normal"
            />
          </Flex>
        </ModalBody>

        <ModalFooter w="100%">
          <Flex w="100%" align="center" justify="space-between" gap="1rem">
            <Button
              onClick={() => {
                onClose();
                toast({
                  title: modalTitle ? modalTitle : "Employee Archived",
                  description: modalText
                    ? modalText
                    : "You have successfully archived Tunde Ogunleye",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                  position: "top-right",
                });
              }}
              borderRadius="1.5rem"
              variant="solid"
              colorScheme="red"
            >
              Yes, Archive
            </Button>
            <Button
              borderRadius="1.5rem"
              colorScheme="teal"
              onClick={onClose}
              variant="outline"
            >
              Cancel
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
