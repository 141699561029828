import { Text } from "@chakra-ui/react";
import React from "react";

export default function ReusableText({
  size,
  color,
  weight,
  text,
  w,
  p,
  align,
  decoration = false,
  transform = false,
  cursor,
  onClick,
  bg,
  height,
  title,
  bl,
}) {
  return (
    <Text
      title={title ? title : text}
      textDecoration={decoration ? "underline" : ""}
      fontSize={size}
      color={color ? color : "#515050"}
      fontWeight={weight ? weight : "medium"}
      w={w}
      textAlign={align}
      textTransform={transform ? "capitalize" : ""}
      cursor={cursor}
      onClick={onClick}
      bg={bg}
      p={p}
      height={height}
      borderLeft={bl}
    >
      {text}
    </Text>
  );
}
