import { Button, Flex, Heading } from "@chakra-ui/react";
import InputComponent from "../UI/Input";

export default function AppraisalFields({ keys, setKey }) {
  return (
    <Flex w="100%" align="start" direction="column" gap="1rem">
      {keys?.map((el, index) => (
        <Flex key={index} align="start" direction="column" gap="16px" w="100%">
          <Flex w="100%" align="center" gap="1rem" justify="space-between">
            <InputComponent
              onChange={(e) => {
                const newArr = [...keys];
                newArr[index].key = e.target.value;
                setKey(newArr);
              }}
              label="KEY RESULT AREAS"
              placeholder="ACCOUNTABILITY & WORK ATTITUDE"
            />
            <InputComponent
              onChange={(e) => {
                const newArr = [...keys];
                newArr[index].percentage = e.target.value;
                setKey(newArr);
              }}
              type="number"
              label="PERCENTAGE"
              placeholder="E.G 15%"
            />
          </Flex>
          <Heading size="sm" color="var(--container_color)" fontWeight="bold">
            Add Performance Objectives
          </Heading>
          <Flex w="100%" align="start" direction="column" gap="1rem">
            {keys[index]?.objectives?.map((el, i) => {
              return (
                <Flex key={i} w="100%" align="center" gap="1rem">
                  <InputComponent
                    onChange={(e) => {
                      const newArr = [...keys];
                      newArr[index].objectives[i] = e.target.value;
                      setKey(newArr);
                    }}
                    required={false}
                    placeholder="Performance Objective"
                  />
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      ))}

      <Button
        onClick={() =>
          setKey((prev) => [
            ...prev,
            {
              objectives: [[], [], [], [], [], [], [], [], [], []],
              key: "",
              percentage: "",
            },
          ])
        }
        variant="solid"
        bg="brand.900"
        color="white"
      >
        Create Assessment Key
      </Button>
    </Flex>
  );
}
