import { Flex } from "@chakra-ui/react";
import React from "react";
import RestrictedItem from "./RestrictedItem";

export default function RestrictedList({ list }) {
  return (
    <Flex border="1px solid #ccc" w="100%" align="start" direction="column">
      {list?.map((el, i) => (
        <RestrictedItem key={i} item={el} />
      ))}
    </Flex>
  );
}
