import { useEffect, useState } from "react";
import HeadStat from "./HeadStat";
import RecentBriefs from "./RecentBriefs";
import { getBriefs } from "../../../../util/http";
import { useContext } from "react";
import { AuthContext } from "../../../../util/context";
import { Flex } from "@chakra-ui/react";

export default function Heads() {
  const [list, setList] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getBriefs();
        setList(
          response?.briefs
            ?.reverse()
            ?.filter(
              (el) =>
                el.brief_type?.toLowerCase() === user?.department?.toLowerCase()
            )
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [user]);

  return (
    <Flex w="100%" align="start" direction="column" p="1rem">
      <HeadStat briefs={list} />
      <RecentBriefs list={list} />
    </Flex>
  );
}
