import { Flex } from "@chakra-ui/react";
import React, { useContext } from "react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import { getStoredItem } from "../../util/lib";
import Client from "./client/Client";
import { AuthContext } from "../../util/context";
import HR from "./roles-stats/hr/HR";
import Accountant from "./roles-stats/accountant/Accountant";
import Heads from "./roles-stats/head-of-department/Heads";
import Lawyer from "./roles-stats/lawyer/Lawyer";
import DashboardCharts from "./DashboardCharts";
import User from "./roles-stats/user/User";
import DashboardHeader from "./DashboardHeader";
import RecentBriefs from "./roles-stats/head-of-department/RecentBriefs";
import { useState } from "react";
import { useEffect } from "react";
import { getBriefs } from "../../util/http";

export default function DashboardCenter() {
  const userType = getStoredItem("user-type");
  const { user } = useContext(AuthContext);
  const [list, setList] = useState([]);
 

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getBriefs();
        setList(response?.briefs?.reverse());
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [user]);

  let view;

  if (user?.job_role?.toLowerCase() === "hr") {
    view = <HR />;
  } else if (user?.job_role?.toLowerCase() === "accountant") {
    view = <Accountant />;
  } else if (
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop"
  ) {
    view = <Heads />;
  } else if (user?.job_role?.toLowerCase() === "lawyer") {
    view = <Lawyer />;
  } else if (userType === "client") {
    view = <Client />;
  } else if (user?.job_role?.toLowerCase() === "user") {
    view = <User />;
  } else {
    view = (
      <>
        <Flex direction="column" w="100%" align="center" gap="1rem" p="1rem">
          {true && <DashboardCharts />}
          <RecentBriefs list={list} />
        </Flex>
      </>
    );
  }

  return (
    <Flex w="100%" align="start" direction="column" h="100%" gap="2rem">
      <DashboardHeader />
      <ReusableEmployeeHeader />
      {view}
    </Flex>
  );
}
