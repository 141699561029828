import { Badge, Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../../UI/ReusableText";
import { useNavigate } from "react-router-dom";

export default function BriefItem({ item }) {
  const navigate = useNavigate();
  return (
    <Flex
      onClick={() => navigate(`/brief/${item?.brief_id}`)}
      direction="column"
      w="25%"
      gap="0.5rem"
      cursor="pointer"
      shadow="lg"
      p="1rem"
    >
      <ReusableText
        text={item?.brief_title}
        color="var(--light_gray)"
        size={{ lg: "16px", md: "14px", base: "12px" }}
        weight="medium"
        transform={true}
      />

      <ReusableText
        size={{ lg: "14px", md: "12px", base: "10px" }}
        color="var(--gray)"
        weight="light"
        text={item?.description.slice(0, 119)}
        transform={true}
        height="80px"
      />
      <Flex align="center" gap="0.5rem" justify="start" w="80%">
        <Badge
          bg={
            item.status?.toLowerCase() === "pending"
              ? "gray.100"
              : item.status?.toLowerCase() === "completed"
              ? "green.100"
              : item.status?.toLowerCase() === "draft"
              ? "red.100"
              : "yellow.100"
          }
          p="0.4rem"
          size="lg"
        >
          {item?.status}
        </Badge>
        {false && (
          <ReusableText
            text={`₦${new Intl.NumberFormat().format(item?.grand_total)}`}
            size={{ lg: "20px", md: "18px", base: "14px" }}
            weight="bold"
            color="#2BA2DA"
          />
        )}
      </Flex>
    </Flex>
  );
}
