import {
  Button,
  Flex,
  Heading,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  useDisclosure,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlineMenu } from "react-icons/ai";
import MobileMenu from "./MobileMenu";

export default function ReusableEmployeeHeader({
  show = false,
  title,
  text,
  btnText,
  icon,
  onClick,
  showMenu = false,
  showDottedMenu = false,
  showPublish = false,
  menu = [],
  onClickPublish,
  btnTextPublish,
}) {
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <Flex w="100%" align="center" justify="space-between">
        <Flex align="center" gap="1rem">
          <Box
            onClick={onOpen}
            display={{ lg: "none", md: "block", base: "block" }}
          >
            <AiOutlineMenu fontSize={20} />
          </Box>
          <Flex align="start" direction="column" gap="0.5rem">
            <Heading
              as="h2"
              fontSize={{ lg: "30px", md: "20px", base: "16px" }}
              fontWeight="medium"
              color="var(--container_color)"
            >
              {title}
            </Heading>
            <Text
              fontSize={{ lg: "16px", md: "15px", base: "8px" }}
              fontWeight="normal"
              color="var(--container_color)"
              display={{ lg: "block", md: "block", base: "none" }}
            >
              {text}
            </Text>
          </Flex>
        </Flex>
        <Flex align="center" gap="1rem">
          {show && (
            <Button
              color="white"
              bg="brand.900"
              variant="solid"
              leftIcon={icon}
              onClick={onClick}
              size="md"
            >
              {btnText}
            </Button>
          )}

          {showPublish && (
            <Button
              color="white"
              bg="brand.900"
              variant="solid"
              onClick={onClickPublish}
              size="sm"
            >
              {btnTextPublish}
            </Button>
          )}

          {showMenu && (
            <Menu>
              <MenuButton
                color="white"
                bg="brand.900"
                _hover={{ bg: "brand.900" }}
                _expanded={{ bg: "brand.900" }}
                _focus={{ bg: "brand.900" }}
                as={Button}
                leftIcon={icon}
                size="sm"
              >
                {btnText}
              </MenuButton>
              <MenuList>
                {menu?.map((item, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => navigate(item.link)}
                    _focus={{ bg: "#fff" }}
                    _hover={{ bg: "#fff" }}
                    _expanded={{ bg: "#fff" }}
                    w="100%"
                  >
                    <Flex align="center" gap="1rem">
                      {item.icon && <item.icon />}
                      <Text>{item.name}</Text>
                    </Flex>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )}

          {showDottedMenu && (
            <Menu>
              <MenuButton
                color="#333"
                // _hover={{ bg: "brand.900" }}
                _expanded={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
                as={Button}
                bg="transparent"
                border="1px solid #ccc"
                size="sm"
              >
                <BsThreeDots color="blue" />
              </MenuButton>
              <MenuList>
                {menu?.map((item, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      if (item.navigation) {
                        navigate(item.link);
                      } else {
                        item.onClick();
                      }
                    }}
                    _focus={{ bg: "#fff" }}
                    _hover={{ bg: "#fff" }}
                    _expanded={{ bg: "#fff" }}
                    w="100%"
                  >
                    <Flex align="center" gap="1rem">
                      {item.icon && <item.icon />}
                      <Text>{item.name}</Text>
                    </Flex>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>
      <MobileMenu isOpen={isOpen} onClose={onClose} />
    </>
  );
}
