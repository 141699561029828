import { Button, Flex, Heading, useToast, Divider } from "@chakra-ui/react";
import Modal from "../../../overlay/Modal";
import Overlay from "../../../overlay/OverLay";
import InputComponent from "../../../UI/Input";
import ReusableAmountInput from "../../../UI/ReusableAmountInput";
import { useState } from "react";
import { updateRetainerships } from "../../../../util/http";

export default function UpdateRetainership({ show, item }) {
  const [amount, setAmount] = useState(+item?.amount);
  const [validity, setValidity] = useState(item?.validity);
  const [max, setMax] = useState(+item?.maximum_spend);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function updateRetainerHandler() {
    const data = {
      validity,
      amount,
      maximum_spend: max,
    };

    try {
      setLoading(true);
      await updateRetainerships(item?.retainer_id, data);
      window.location.reload();
      show(false);
      toast({
        title: `Retainership Updated`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Overlay hide={() => show(false)} />
      <Modal hide={() => show(false)}>
        <Flex w="100%" align="start" direction="column" gap="1.5rem">
          <Heading textTransform="uppercase" size="md" color="#333">
            Update {item?.client} retainership
          </Heading>
          <Divider />
          <InputComponent
            onChange={(e) => setValidity(e.target.value)}
            value={validity}
            label="Validity"
            type="date"
          />
          <InputComponent
            onChange={(e) => setMax(e.target.value)}
            value={max}
            label="Maximum Spend"
            type="number"
          />
          <ReusableAmountInput
            onChange={(e) => setAmount(e.target.rawValue)}
            value={amount}
            label="Amount"
          />
          <Flex w="100%" align="start" justify="space-between">
            <Button
              onClick={() => show(false)}
              variant="solid"
              bg="red.500"
              color="white"
            >
              Cancel
            </Button>
            <Button
              isLoading={loading}
              loadingtext=""
              onClick={updateRetainerHandler}
              variant="solid"
              bg="green.500"
              color="white"
            >
              Update
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
