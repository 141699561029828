import React, { Fragment, useEffect, useState } from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import ViewClientContainer from "./ViewClientContainer";
import { getClient } from "../../../util/http";
import { useParams } from "react-router-dom";
import Loader from "../../UI/Loader";

export default function ViewClient() {
  const [client, setClient] = useState("");
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        setLoading(true);
        const response = await getClient(params.id);
        setLoading(false);
        setProgress(80);
        setProgress(100);
        setClient(response?.client[0]);
      } catch (error) {
        setLoading(false);
        setProgress(100);
        console.log(error);
      }
    }
    fetchData();
  }, [params.id]);
  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable>
        <ViewClientContainer loading={loading} client={client} />
      </DashboardReusable>
    </Fragment>
  );
}
