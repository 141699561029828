import React from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import EditContainer from "./EditContainer";

export default function EditProfile() {
  return (
    <DashboardReusable>
      <EditContainer />
    </DashboardReusable>
  );
}
