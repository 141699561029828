import { useState  ,  useEffect} from "react";
import ReusableText from "../UI/ReusableText";
import { Box, Flex, useDisclosure, useToast  , Icon , } from "@chakra-ui/react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../UI/DeleteModal";
import { deleteRequisition } from "../../util/http";
import { IoIosCheckmarkCircle } from "react-icons/io";
import formatTimestamp from "../../util/formatTimestampDate";




export default function RequisitionItem({ path, item }) {






  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [password, setPassword] = useState("");
  const [paid , setPaid] = useState(false);


  useEffect(()=> {
    if(item?.account_status) {
      setPaid(true);

    }
  } , [item])

  async function deleteHandler() {
    try {
      await deleteRequisition(item?.requisition_id);
      onClose();
      toast({
        title: `Requisition deleted`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      window.location.reload();
    } catch (error) {
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Flex
        w="100%"
        align="center"
        px="1rem"
        py="1rem"
        cursor="pointer"
        _hover={{ opacity: "80%" }}
        borderBottom="1px solid #333"
      >
        <Flex w="5%" align="center" justify="center">
          <Box
            borderRadius="100%"
            w="1rem"
            h="1rem"
            bg={
              item?.priority_level?.toLowerCase() === "urgent and important"
                ? "red.700"
                : item?.priority_level?.toLowerCase() === "urgent"
                ? "red.200"
                : item?.priority_level?.toLowerCase() === "important"
                ? "red.400"
                : "#333"
            }
          ></Box>
        </Flex>
        <ReusableText
          align="center"
          size={{ lg: "14px", md: "12px", base: "6px" }}
          color="var(--container_color)"
          w="10%"
          text={item?.requisition_id}
          onClick={() => navigate(`/${path}/${item?.requisition_id}`)}
        />
         <ReusableText
          align="center"
          size={{ lg: "14px", md: "12px", base: "6px" }}
          color="var(--container_color)"
          w="10%"
          text={formatTimestamp(item?.created_at)}
          onClick={() => navigate(`/${path}/${item?.requisition_id}`)}
        />

        <ReusableText
          size={{ lg: "14px", md: "12px", base: "6px" }}
          color="var(--container_color)"
          w="20%"
          text={item?.brief_title?.slice(0, 30)}
          align="center"
          onClick={() => navigate(`/${path}/${item?.requisition_id}`)}
        />
        <ReusableText
          size={{ lg: "14px", md: "12px", base: "6px" }}
          color="var(--container_color)"
          align="center"
          w="20%"
          text={item?.reason?.slice(0, 20).padEnd(22, ".")}
          title={item?.reason}
          onClick={() => navigate(`/${path}/${item?.requisition_id}`)}
        />
        <ReusableText
          size={{ lg: "14px", md: "12px", base: "6px" }}
          color="var(--container_color)"
          align="center"
          w="10%"
          text={`${item?.currency_symbol ? item?.currency_symbol : '₦' }${new Intl.NumberFormat().format(item?.amount)}.00`}
          onClick={() => navigate(`/${path}/${item?.requisition_id}`)}
        />
        <ReusableText
          size={{ lg: "14px", md: "12px", base: "6px" }}
          color="var(--container_color)"
          align="center"
          w="15%"
          text={`${item?.initiator?.first_name} ${item?.initiator?.last_name}`}
          onClick={() => navigate(`/${path}/${item?.requisition_id}`)}
        />
        <Flex w="10%" align="center" justify="center" gap="0.5rem">
          <Box
            w="0.4rem"
            height="0.4rem"
            borderRadius="100%"
            bg={
              +item?.status === 0
                ? "#333"
                : +item?.status === 1
                ? "green.300"
                : +item?.status === 2
                ? "green.500"
                : +item?.status === 3
                ? "green.700"
                : "green.900"
            }
          ></Box>
          <ReusableText
            align="center"
            size={{ lg: "12px", md: "10px", base: "6px" }}
            weight="medium"
            color={
              +item?.status === 0
                ? "var(--container_color)"
                : +item?.status === 1
                ? "green.300"
                : +item?.status === 2
                ? "green.500"
                : +item?.status === 3
                ? "green.700"
                : "green.900"
            }
            text={
              +item?.status === 0
                ? "Pending"
                : +item?.status === 1
                ? "Approved by HOC"
                : +item?.status === 2
                ? "Approved by HOD"
                : +item?.status === 3
                ? "Approved"
                : "Disbursed"
            }
          />
        </Flex>

        <Flex
          fontSize={{ lg: "14px", md: "12px", base: "6px" }}
          align="center"
          w="10%"
          justify="space-evenly"
        >
          <AiOutlineEye
            color="var(--container_color)"
            onClick={() => navigate(`/${path}/${item?.requisition_id}`)}
          />

          {+item?.status === 0 && (
            <AiOutlineDelete color="var(--container_color)" onClick={onOpen} />
          )}
          {paid ? <Icon as={IoIosCheckmarkCircle} color="green.500" /> : null}
        </Flex>
      </Flex>
      <DeleteModal
        onChange={(e) => setPassword(e.target.value)}
        onClick={deleteHandler}
        isOpen={isOpen}
        onClose={onClose}
        title="Delete Requisition?"
        text="Are you sure you want to delete this requisition?"
      />
    </>
  );
}
