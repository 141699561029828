import React from "react";
import Appraisal from "../../appraisal/Appraisal";
import { getStoredItem } from "../../../util/lib";
import Dashboard from "../../dashboard/Dashboard";

export default function AppraisalPage() {
  const userType = getStoredItem("user-type");
  return (
    <>
      {userType?.toLowerCase() === "employee" ? <Appraisal /> : <Dashboard />}
    </>
  );
}
