import {
  Button,
  CloseButton,
  Divider,
  Flex,
  Heading,
  useToast,
} from "@chakra-ui/react";
import Modal from "../overlay/Modal";
import Overlay from "../overlay/OverLay";
import InputComponent from "../UI/Input";
import { useState } from "react";
import { updateLeaveType } from "../../util/http";
import SelectComponent from "../UI/Select";

export default function EditLeaveType({ show, id, leave }) {
  const roles = [
    "seed",
    "hr",
    "accountant",
    "hod",
    "hop",
    "lawyer",
    "user",
  ].map((a) => a.charAt(0).toUpperCase() + a.slice(1));
  const [loading, setLoading] = useState(false);
  const [leaveType, setLeaveType] = useState({
    name: leave?.name,
    leave_count: leave?.leave_count,
    job_role: leave.roles,
  });

  const toast = useToast();

  function closeMenu() {
    show(false);
  }

  async function editHandler() {
    try {
      setLoading(true);
      await updateLeaveType(leaveType, leave?.id);
      closeMenu();
      window.location.reload();
      toast({
        title: `Leave Type updated`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // toast({
      //   title: `Error`,
      //   description: `${
      //     error?.response?.data.message || "something went wrong"
      //   }`,
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      //   position: "top-right",
      // });
    }
  }

  return (
    <>
      <Overlay hide={closeMenu} />
      <Modal hide={closeMenu}>
        <Flex w="100%" align="start" direction="column" gap="1rem" py="1rem">
          <Flex w="100%" align="center" justify="space-between">
            <Heading size="md" color="var(--container_color)" fontWeight="bold">
              Edit Leave Type
            </Heading>
            <CloseButton onClick={closeMenu} />
          </Flex>
          <Divider />
          <InputComponent
            label="Leave Type"
            onChange={(e) =>
              setLeaveType({ ...leaveType, name: e.target.value })
            }
            value={leaveType?.name}
          />
          <InputComponent
            label="Leave Type"
            onChange={(e) =>
              setLeaveType({ ...leaveType, leave_count: e.target.value })
            }
            type="number"
            value={leaveType?.leave_count}
          />
          <SelectComponent
            multiple={true}
            options={roles || []}
            label="Select Roles"
            onChange={(e) => {
              const role = e?.map((el) => el.value);
              setLeaveType({ ...leaveType, job_role: role });
            }}
          />
          <Button
            variant="solid"
            bg="brand.900"
            color="white"
            isLoading={loading}
            loadingText=""
            onClick={editHandler}
          >
            Save Update
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
