import {
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReusableText from "../../../UI/ReusableText";
import AllBriefs from "./AllBriefs";
import CompletedBriefs from "./CompletedBriefs";
import OngoingBriefs from "./OngoingBrief";
import PendingBriefs from "./PendingBriefs";

export default function Briefs({ brief }) {
  const [completed, setCompleted] = useState([]);
  const [pending, setPending] = useState([]);
  const [ongoing, setOngoing] = useState([]);

  useEffect(() => {
    const completedBriefs = brief?.filter(
      (brief) => brief.status?.toLowerCase() === "completed"
    );
    const pendingBriefs = brief?.filter(
      (brief) => brief.status?.toLowerCase() === "pending"
    );
    const ongoingBriefs = brief?.filter(
      (brief) => brief.status?.toLowerCase() === "active"
    );
    setCompleted(completedBriefs);
    setOngoing(ongoingBriefs);
    setPending(pendingBriefs);
  }, [brief]);

  return (
    <Flex w="100%" direction="column" gap="1rem">
      <ReusableText
        text="Briefs"
        size="18px"
        color="var(--light_gray)"
        weight="medium"
      />
      <Tabs variant="solid-rounded" colorScheme="blue">
        <TabList borderBottom="1px solid #ccc" pb="1rem">
          <Tab>All</Tab>
          <Tab>Completed</Tab>
          <Tab>Ongoing</Tab>
          <Tab>Pending</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <AllBriefs brief={brief} />
          </TabPanel>
          <TabPanel>
            <CompletedBriefs brief={completed} />
          </TabPanel>
          <TabPanel>
            <OngoingBriefs brief={ongoing} />
          </TabPanel>
          <TabPanel>
            <PendingBriefs brief={pending} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
