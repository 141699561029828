import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";
import InputComponent from "../../UI/Input";

export default function RoleName({ setName, setDescription }) {
  return (
    <Flex w="100%" align="center" py="1rem" borderBottom="1px solid #ccc">
      <Box w="30%" height="200px">
        <ReusableText
          text="Role Name"
          size="20px"
          color="#19191A"
          weight="bold"
        />
      </Box>
      <Flex
        w="100%"
        height="200px"
        align="center"
        gap="1.5rem"
        direction="column"
      >
        <InputComponent
          onChange={(e) => setName(e.target.value)}
          label="Role Name"
          placeholder="Enter role name"
        />
        <InputComponent
          onChange={(e) => setDescription(e.target.value)}
          label="Role Description"
          placeholder="Enter role description"
        />
      </Flex>
    </Flex>
  );
}
