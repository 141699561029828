import React, { useEffect, useState } from "react";
import DashboardReusable from "../UI/DashboardReusable";
import LeaveContainer from "./LeaveContainer";
import { getLeaves } from "../../util/http";
import { useContext } from "react";
import { AuthContext } from "../../util/context";

export default function Leave() {
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);



  const lastIndex = currentPage * perPage;
  const firstIndex = lastIndex - perPage;

  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await getLeaves();
        if (
          user.job_role?.toLowerCase() === "lawyer" ||
          user.job_role?.toLowerCase() === "user"
        ) {
          setList(
            response?.leaves
              ?.filter((el) => el.employee_id === user?.employee_id)
              .reverse()
          );
        } else if (
          user?.job_role?.toLowerCase().includes("hod") 
        ) {
          setList(
            response?.leaves
              ?.filter(
                (el) =>
                  el.department?.toLowerCase() ===
                  user?.department?.toLowerCase()
              )
              .reverse()
          );
        } else {
          setList(response?.leaves?.reverse());
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    fetchData();
  }, [user]);

  useEffect(() => {
    setTotal(list.length);
  }, [list]);

  const leaveList = list.slice(firstIndex, lastIndex);

  return (
    <DashboardReusable>
      <LeaveContainer
        list={leaveList}
        total={total}
        perPage={perPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setList={setList}
        loading={loading}
      />
    </DashboardReusable>
  );
}
