import React from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import CreateBriefContainer from "./CreateBriefContainer";

export default function CreateBrief() {
  return (
    <DashboardReusable>
      <CreateBriefContainer />
    </DashboardReusable>
  );
}
