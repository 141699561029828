import { Flex, Card, Text, CardBody, Heading } from "@chakra-ui/react";

export default function StatItem({ title, amount, icon, bg, colored, w }) {
  return (
    <Card
      boxShadow="xl"
      borderRadius="1.4rem"
      w={{ lg: w ? w : "30%", md: "45%", base: "100%" }}
      mx="auto"
      bg={colored ? colored : "#fff"}
      py="6px"
    >
      <CardBody>
        <Flex w="100%" align="center" justify="space-between">
          <Flex w="70%" align="start" direction="column">
            <Text color={colored ? "#fff" : "#000"}>{title}</Text>
            <Heading
              fontWeight="extrabold"
              size="md"
              color={colored ? "#fff" : "#000"}
            >
              {amount}
            </Heading>
          </Flex>
          <Flex
            align="center"
            justify="center"
            // w="100px"
            p="1rem"
            bg={bg}
            borderRadius="1rem"
          >
            {icon}
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}
