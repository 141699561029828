import React from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import ViewInvoiceContainer from "./ViewInvoiceContainer";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getInvoice } from "../../../util/http";

export default function ViewInvoice() {
  const params = useParams();

  const [invoice, setInvoice] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await getInvoice(params.id);
        setLoading(false);
        setInvoice(response.invoice);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <DashboardReusable>
      <ViewInvoiceContainer loading={loading} invoice={invoice} />
    </DashboardReusable>
  );
}
