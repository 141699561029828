import { Flex } from "@chakra-ui/react";
import React from "react";
import RoleHeader from "./RoleHeader";
import RoleCenter from "./RoleCenter";

export default function RoleContainer() {
  return (
    <Flex w="100%" align="center" direction="column" gap="1rem" p="2rem">
      <RoleHeader />
      <RoleCenter />
    </Flex>
  );
}
