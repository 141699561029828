import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import FileList from "../FileList";
import SmallSearch from "../../UI/SmallSearch";
import { getFolderFiles } from "../../../util/http";
import { useParams } from "react-router-dom";

export default function DocumentDetailCenter({ list, setList, show }) {
  const params = useParams();
  async function searchHandler(e) {
    try {
      const response = await getFolderFiles({ folder: params.id });
      const filtered = response?.files?.filter((el) =>
        el?.file_name
          ?.toLowerCase()
          ?.includes(e.target.value.trim()?.toLowerCase())
      );
      setList(filtered);
    } catch (error) {
      // console.log(error);
    }
  }
  return (
    <Flex
      align="start"
      p="1rem"
      flex="1"
      maxHeight="75vh"
      direction="column"
      gap="1rem"
      overflow="scroll"
      w="100%"
    >
      <SmallSearch onSearch={searchHandler} w="90%" border="1px solid #ccc" />
      <ReusableText
        text={params.id}
        color="var(--container_color)"
        size="18px"
        weight="medium"
        align="start"
        transform={true}
      />
      <FileList show={show} list={list} />
    </Flex>
  );
}
