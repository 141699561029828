import React from "react";
import ReusableText from "../UI/ReusableText";
import { Flex } from "@chakra-ui/react";

export default function LeaveTitle() {
  return (
    <Flex
      bg="#EEEFF3"
      borderTopRadius="0.5rem"
      w="100%"
      px="0.5rem"
      py="1rem"
      align="center"
      justify="center"
    >
      <ReusableText
        text="Employee ID"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="10%"
        align="center"
      />
      <ReusableText
        text="Full Name"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="10%"
        align="center"
      />
      <ReusableText
        text="Type of Leave"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="20%"
        align="center"
      />
      <ReusableText
        text="Start Date"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="20%"
        align="center"
      />
      <ReusableText
        text="End Date"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="10%"
        align="center"
      />
      <ReusableText
        text="No. of Days"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        w="10%"
        align="center"
      />

      <ReusableText
        w="10%"
        text="Status"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        align="center"
      />
      <ReusableText
        w="10%"
        text="Actions"
        color="#7F7E7E"
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        align="center"
      />
    </Flex>
  );
}
