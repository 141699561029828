import React from "react";

import DashboardCenter from "./DashboardCenter";
import DashboardReusable from "../UI/DashboardReusable";

export default function Dashboard() {
  
  return (
    <DashboardReusable padding={true}>
      <DashboardCenter />
    </DashboardReusable>
  );
}


