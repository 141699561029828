import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Roles from "./Roles";
import Permission from "./Permission";
import { getRoleDetail, getRoles } from "../../util/http";
import Loader from "../UI/Loader";

export default function SettingCenter() {
  const [roles, setRoles] = useState([]);
  const [permitted, setPermitted] = useState([]);
  const [notPermitted, setNotPermitted] = useState([]);
  const [users, setUsers] = useState([]);
  const [progress, setProgress] = useState(0);
  const [description, setDescription] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getRoles();
        setRoles(response.roles);
        setRole(response?.roles[0]);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getRoleDetail(role.toLowerCase());
        setDescription(response?.description[0]);
        setProgress(80);
        setProgress(100);
        setUsers(response?.users);
        setPermitted(response?.permissions);
        setNotPermitted(response?.unassigned_permissions);
      } catch (error) {
        setProgress(100);
        console.log(error);
      }
    }
    fetchData();
  }, [role]);

  return (
    <>
      <Loader progress={progress} setProgress={setProgress} />
      <Flex align="center" w="100%">
        <Roles roles={roles} setRole={setRole} role={role} />
        <Permission
          description={description}
          role={role}
          permitted={permitted}
          users={users}
          notPermitted={notPermitted}
        />
      </Flex>
    </>
  );
}
