import { useContext, useEffect, useState } from "react";
import ClientInvoice from "./ClientInvoice";
import ClientStat from "./ClientStat";
import { AuthContext } from "../../../util/context";
import { getClient } from "../../../util/http";
import Loader from "../../UI/Loader";

export default function Client() {
  const [client, setClient] = useState("");
  const [progress, setProgress] = useState(0);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getClient(user?.client_id);

        setClient(response?.client[0]);
        setProgress(80);
        setProgress(100);
        // active brief
      } catch (error) {
        setProgress(100);
        console.log(error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <Loader progress={progress} setProgress={setProgress} />
      <ClientStat client={client} />
      <ClientInvoice list={client?.invoices?.slice(0, 10)} />
    </>
  );
}
