import { Flex } from "@chakra-ui/react";
import TaskHeader from "./TaskHeader";
import TaskSubHeader from "./TaskSubHeader";
import TaskOverview from "./TaskOverview";
import { useState } from "react";
import EditTask from "../../edit-task/EditTask";
import ViewTaskSubHeader from "../ViewTaskSubHeader";

export default function NewViewTaskContainer({ task, setTask }) {
  const [show, setShow] = useState(false);
  return (
    <>
      <Flex w="100%" align="start" gap="1.5rem" direction="column">
        <TaskHeader show={setShow} task={task} />
        <ViewTaskSubHeader task={task} />
        <TaskSubHeader task={task} />
        <TaskOverview setTask={setTask} task={task} />
      </Flex>
      {show && <EditTask setTask={setTask} show={setShow} task={task} />}
    </>
  );
}
