export function getStoredItem(key) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    // window.location.reload();
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function storeItem(key, value, ttl) {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function filterDate(milliseconds) {
  const daysMilli = Date.now() - milliseconds;
  return new Date(daysMilli);
}

export function sortData(data) {
  const mapped = data.map((item, i) => {
    return { i, value: +item.index };
  });

  mapped.sort((a, b) => {
    if (a.value > b.value) {
      return 1;
    }
    if (a.value < b.value) {
      return -1;
    }
    return 0;
  });

  return mapped.map((v) => data[v.i]);
}
