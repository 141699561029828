import { Box, Divider, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import ReusableText from "./ReusableText";
import ImageComponent from "./Image";

function ReusableTitle({ title, text, weight, size, color, w }) {
  return (
    <Flex w={w ? w : "45%"} py="8px" align="center" justify="space-between">
      <ReusableText
        text={title}
        transform={true}
        weight={weight ? weight : "bold"}
        color={color ? color : "var(--container_color)"}
        size={size ? size : "16px"}
      />
      <ReusableText
        text={text}
        transform={true}
        weight={weight ? weight : "medium"}
        color={color ? color : "var(--container_color)"}
        size={size ? size : "15px"}
      />
    </Flex>
  );
}

export default function PayslipTemplate({ name, designation, date, salary }) {
  const keys = salary?.deductions ? Object.keys(salary?.deductions) : [];
  const value = salary?.deductions ? Object.values(salary?.deductions) : [];

  const deductions = value?.reduce((acc, sum) => +acc + +sum, 0);
  const additions =
    +salary?.basic +
    +salary?.housing +
    +salary?.transportation +
    +salary?.furniture +
    +salary?.dressing;

  const netSalary = +additions - +deductions;
  return (
    <Flex
      w="100%"
      position="relative"
      zIndex={80}
      align="start"
      direction="column"
      p="1rem"
    >
      <Box w="50px">
        <ImageComponent src="/logo.png" alt="logo" />
      </Box>
      <Flex w="100%" py="1rem" align="center" direction="column" gap="8px">
        <Heading size="md" color="var(--container_color)">
          PAYSLIP
        </Heading>
        <ReusableText
          text="LOYA Inc"
          color="var(--container_color)"
          size="15px"
        />
      </Flex>
      <Divider />
      <Flex w="100%" align="center" justify="space-between" wrap="wrap">
        <ReusableTitle title="Date:" text={new Date().toLocaleDateString()} />
        <ReusableTitle title="Employee Name:" text={name} />
        <ReusableTitle title="Pay Period:" text={date} />
        <ReusableTitle title="Designation:" text={designation} />
      </Flex>
      <Divider />
      <Flex w="100%" align="start" direction="column">
        <Flex
          bg="#ccc"
          py="8px"
          px="1rem"
          w="100%"
          borderTopRadius={16}
          align="center"
          justify="space-between"
        >
          <Heading size="sm" color="var(--container_color)" fontWeight="bold">
            Additions
          </Heading>
          <Heading size="sm" color="var(--container_color)" fontWeight="bold">
            Deductions
          </Heading>
        </Flex>
        <Flex w="100%" align="center" justify="space-between" wrap="wrap">
          <Flex px="1rem" w="50%" align="start" direction="column">
            <ReusableTitle
              title="Basic Salary:"
              w="100%"
              text={`₦${new Intl.NumberFormat().format(salary?.basic)}.00`}
            />
            <ReusableTitle
              title="Housing:"
              w="100%"
              text={`₦${new Intl.NumberFormat().format(salary?.housing)}.00`}
            />
            <ReusableTitle
              title="Transport:"
              w="100%"
              text={`₦${new Intl.NumberFormat().format(
                salary?.transportation
              )}.00`}
            />
            <ReusableTitle
              title="Dressing:"
              w="100%"
              text={`₦${new Intl.NumberFormat().format(salary?.dressing)}.00`}
            />
            <ReusableTitle
              title="Furniture:"
              w="100%"
              text={`₦${new Intl.NumberFormat().format(salary?.furniture)}.00`}
            />
          </Flex>
          <Flex
            w="50%"
            px="1rem"
            align="start"
            justify="space-between"
            direction="column"
          >
            {keys?.map((el, i) => (
              <ReusableTitle
                title={`${el}:`}
                key={i}
                w="100%"
                color="red.500"
                text={value[i]}
              />
            ))}
          </Flex>
        </Flex>
        <Flex w="100%" px="1rem" align="center" justify="space-between">
          <ReusableTitle
            title="Total Additions:"
            w="50%"
            size="18px"
            color="green.600"
            text={`₦${new Intl.NumberFormat().format(additions)}.00`}
          />
          <ReusableTitle
            title="Total Deductions:"
            w="45%"
            size="18px"
            color="red.600"
            text={`₦${new Intl.NumberFormat().format(deductions)}.00`}
          />
        </Flex>
        <ReusableTitle
          title="Net Salary:"
          size="18px"
          w="50%"
          text={`₦${new Intl.NumberFormat().format(netSalary)}.00`}
        />
      </Flex>
      <Divider />
      <Flex w="100%" py="1rem" align="center" justify="center">
        <ReusableText
          text="This is a system generated slip"
          color="var(--container_color)"
          size="18px"
          align="center"
        />
      </Flex>
    </Flex>
  );
}
