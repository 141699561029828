import React from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import ViewBriefContainer from "./ViewBriefContainer";
import NewBriefContainer from "../new-view-brief/NewBriefContainer";

export default function ViewBrief() {
  return (
    <DashboardReusable>
      {false && <ViewBriefContainer />}
      <NewBriefContainer />
    </DashboardReusable>
  );
}
