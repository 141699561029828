import {FormControl , FormLabel , Flex , Input}  from '@chakra-ui/react'
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import style from './style.module.css'




function PhoneNumberInput({setPhoneNumber , phonenumber ,label='Phone Number'}) {








  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Flex border="1px solid #ccc" borderRadius="0.5rem" align="center">

        <PhoneInput


          value={phonenumber}
          onChange={setPhoneNumber}
         country={'ng'}
          border="none"
          focusBorderColor="transparent"
          containerClass= {style.container}
          inputClass={style.input}
          




        />
        </Flex>





    </FormControl>
  );
}

export default  PhoneNumberInput




















