import { useContext } from "react";
import ReusableFilter from "../UI/ReusableFilter";
import { AuthContext } from "../../util/context";
import { listAppraisals } from "../../util/http";

export default function AppraisalSubheader({ tab, setList, list }) {
  const { user } = useContext(AuthContext);
  let menu = [
    { text: "Active", onClick: "" },
    { text: "Pending", onClick: "" },
    { text: "Submitted", onClick: "" },
    { text: "Expired", onClick: "" },
  ];

  async function searchHandler(e) {
    const response = await listAppraisals();
    let filteredData;
    if (user?.job_role?.toLowerCase() === "lawyer") {
      filteredData = response?.appraisals?.filter((el) => {
        const employee = el?.lawyer_id && JSON.parse(el?.lawyer_id);
        return (
          employee.includes(String(user?.id)) &&
          el.title?.toLowerCase().includes(e.target.value.trim().toLowerCase())
        );
      });
      setList({ ...list, employee: filteredData });
    } else if (
      user?.job_role?.toLowerCase().includes("hod") ||
      user?.job_role?.toLowerCase() === "hop"
    ) {
      filteredData = response?.appraisals?.filter((el) => {
        const department = el?.el?.departments && JSON.parse(el?.departments);
        return (
          department
            ?.map((el) => el?.toLowerCase())
            .includes(user?.department?.toLowerCase()) &&
          el.title?.toLowerCase().includes(e.target.value.trim().toLowerCase())
        );
      });
      setList({ ...list, hod: filteredData });
    } else {
      filteredData = response?.appraisals?.filter((el) =>
        el.title?.toLowerCase().includes(e.target.value.trim().toLowerCase())
      );

      setList({
        ...list,
        hr: filteredData,
        management: filteredData,
      });
    }
  }

  return <ReusableFilter onSearch={searchHandler} menu={menu} />;
}
