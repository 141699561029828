import {
  Avatar,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuOptionGroup,
  useToast,
  Button,
  useDisclosure,
  AvatarGroup,
} from "@chakra-ui/react";

import ReusableText from "../../UI/ReusableText";

import { BsThreeDotsVertical } from "react-icons/bs";
import { MdAttachFile } from "react-icons/md";
import { RiMessage2Line } from "react-icons/ri";

import { useDrag } from "react-dnd";
import { Fragment, useContext } from "react";

import DeleteModal from "../../UI/DeleteModal";
import { useState } from "react";
import { deleteTask, getTasks, updateTask } from "../../../util/http";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { AuthContext } from "../../../util/context";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export default function TaskItem({ id, item, setList }) {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const date = new Date(item?.due_date);
  //international
  const dateString = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  }).format(date);

  


  const lawyers = item?.assignee && JSON.parse(item?.assignee);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function update() {
      const data = {
        status,
      };
      try {
        if (!status) {
          return;
        } else {
          await updateTask(item.id, data);
          const response = await getTasks();

          if (
            user.job_role?.toLowerCase() === "lawyer" ||
            user.job_role?.toLowerCase() === "user"
          ) {
            const filtTask = response?.tasks?.reverse().filter((el, i) => {
              const assignee = el.assignee_id && JSON.parse(el?.assignee_id);
              const mappedAssignee = assignee?.map((el) => +el);
              return mappedAssignee?.includes(+user?.id);
            });
            setList(filtTask);
          } else if (
            user?.job_role?.toLowerCase().includes("hod") ||
            user?.job_role?.toLowerCase() === "hop"
          ) {
            const filtTask = response?.tasks?.reverse().filter((el, i) => {
              return String(el.user_id) === String(user?.id);
            });
            setList(filtTask);
          } else {
            setList(response?.tasks?.reverse());
          }

          setStatus("");
          toast({
            title: `Status Updated`,
            description: ``,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
      } catch (error) {
        toast({
          title: `Error`,
          description: `${
            error?.response?.data.message || "something went wrong"
          }`,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
    update();
  }, [status]);

  async function deleteTaskHandler() {
    // const data = {
    //   task_id: item.id,
    //   password,
    // };

    if (item?.comments?.length > 0) {
      toast({
        title: `Active task cannot be deleted`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      await deleteTask(item.id);

      onClose();
      toast({
        title: `Task successfully deleted`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      console.log(error);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data?.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Fragment>
      <Flex
        w="100%"
        shadow="lg"
        borderRadius="8px"
        align="start"
        direction="column"
        gap="0.5rem"
        p="0.5rem"
        cursor="pointer"
        height="270px"
        bg="#fff"
      >
        <Flex w="100%" align="center" justify="space-between" p="0.5rem">
          <Flex
            borderRadius="0.5rem"
            px="0.5rem"
            bg={
              item.priority === "high"
                ? "#FFE4D8"
                : item.priority === "medium"
                ? "#FFF9D1"
                : "#D0D0D0"
            }
            align="center"
            gap="0.5rem"
          >
            <Box
              w="0.3rem"
              height="0.3rem"
              bg={
                item.priority === "high"
                  ? "#FF3D46"
                  : item.priority === "medium"
                  ? "#DBAE14"
                  : "#515050"
              }
              borderRadius="100%"
            ></Box>
            <ReusableText
              text={item?.priority}
              color={
                item.priority === "high"
                  ? "#FF3D46"
                  : item.priority === "medium"
                  ? "#DBAE14"
                  : "#515050"
              }
              size={{ lg: "14px", md: "12px", base: "12px" }}
              weight="medium"
              transform={true}
            />
          </Flex>
          <Flex align="center" gap="1rem">
            <ReusableText
              text={dateString}
              color="#3F434A"
              size={{ lg: "14px", md: "12px", base: "8px" }}
              weight="normal"
            />
            <Menu>
              <MenuButton
                bg="#fff"
                _hover={{ bg: "transparent" }}
                _expanded={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
                as={Button}
              >
                <BsThreeDotsVertical cursor="pointer" color="#949493" />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => navigate(`/task/${item.id}`)}>
                  View
                </MenuItem>
                {true && (
                  <>
                    <MenuOptionGroup
                      defaultValue="asc"
                      title="Move"
                      type="radio"
                    >
                      {false && (
                        <MenuItemOption onClick={() => setStatus("todo")}>
                          Todo
                        </MenuItemOption>
                      )}
                      <MenuItemOption onClick={() => setStatus("ongoing")}>
                        In Progress
                      </MenuItemOption>
                      <MenuItemOption onClick={() => setStatus("completed")}>
                        Completed
                      </MenuItemOption>
                    </MenuOptionGroup>
                    <MenuItem onClick={onOpen}>Delete</MenuItem>
                  </>
                )}
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
        <ReusableText
          text={item.title?.slice(0, 30)}
          size="18px"
          color="#515050"
          weight="medium"
          transform={true}
          onClick={() => navigate(`/task/${item.id}`)}
        />
        <ReusableText
          text={item?.description?.slice(0, 70)}
          size={{ lg: "16px", md: "14px", base: "12px" }}
          color="#7F7E7E"
          weight="normal"
          height="100px"
        />{" "}
        <ReusableText
          text={item?.brief?.slice(0, 40)}
          size={{ lg: "16px", md: "14px", base: "12px" }}
          color="#15516D"
          weight="bold"
          transform={true}
        />
        <Flex
          title={
            item?.status?.toLowerCase() === "todo"
              ? "10%"
              : item?.status?.toLowerCase() === "ongoing"
              ? "50%"
              : "100%"
          }
          w="100%"
          height="8px"
          align="center"
          borderRadius={24}
          bg={
            item?.status?.toLowerCase() === "todo"
              ? "gray.100"
              : item?.status?.toLowerCase() === "ongoing"
              ? "orange.100"
              : "green.100"
          }
        >
          <Flex
            w={
              item?.status?.toLowerCase() === "todo"
                ? "10%"
                : item?.status?.toLowerCase() === "ongoing"
                ? "50%"
                : "100%"
            }
            bg={
              item?.status?.toLowerCase() === "todo"
                ? "gray.400"
                : item?.status?.toLowerCase() === "ongoing"
                ? "orange.400"
                : "green.400"
            }
            borderRadius={24}
            h="8px"
          ></Flex>
        </Flex>
        <Flex mt="1rem" align="center" justify="space-between" w="100%">
          <Flex align="center" gap="1rem">
            <Flex align="center" gap="8px">
              <MdAttachFile />
              <ReusableText
                text={item?.attachments?.length}
                size="16px"
                color="#15516D"
                weight="bold"
              />
            </Flex>
            <Flex align="center" gap="8px">
              <RiMessage2Line />
              <ReusableText
                text={item?.comments?.length}
                size="16px"
                color="#15516D"
                weight="bold"
              />
            </Flex>
          </Flex>
          <AvatarGroup size="xs" max={4}>
            {lawyers?.map((el, i) => (
              <Avatar key={i} name={el} src="" />
            ))}
          </AvatarGroup>
        </Flex>
      </Flex>
      <DeleteModal
        onClick={deleteTaskHandler}
        title="Delete Task?"
        text={`Are you sure you want to delete ${item?.title?.toUpperCase()}?`}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Fragment>
  );
}
