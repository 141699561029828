import { useState } from "react";
import {
  createAppraisal,
  getEmployees,
  listDepartments,
} from "../../util/http";
import DashboardReusable from "../UI/DashboardReusable";
import { useToast, Heading, Flex, Button, Divider } from "@chakra-ui/react";
import { useEffect } from "react";
import InputComponent from "../UI/Input";
import SelectComponent from "../UI/Select";
import CheckboxComponent from "../UI/Checkbox";
import AppraisalFields from "./AppraisalFields";
import { useNavigate } from "react-router-dom";

export default function CreateAppraisalPage() {
  const [title, setTitle] = useState("");
  const [due_date, setDueDate] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [empDetail, setEmpDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [employees, setEmployees] = useState("");
  const [key, setKey] = useState([]);
  const [showDept, setShowDept] = useState({
    all: false,
    department: false,
  });
  const [showEmp, setShowEmp] = useState({
    all: true,
    employee: false,
  });
  const toast = useToast();
  const navigate = useNavigate();

  async function createHandler() {
    if (!title || key.length === 0) {
      toast({
        title: `Please fill all fields`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    const employeeLists = showDept.all ? employeeList : employees;

    const lawyer_id = employeeLists?.map((el, i) => {
      let arr = [];
      for (let key in empDetail) {
        if (empDetail[key]?.name?.toLowerCase() === el?.toLowerCase()) {
          arr.push(String(empDetail[key].id) || "");
        }
      }
      return arr;
    });

    const data = {
      title,
      // due_date,
      departments: JSON.stringify(showDept.all ? departmentList : departments),
      lawyer_id: JSON.stringify(lawyer_id.flatMap((el) => el?.toString())),
      fields: JSON.stringify(key),
    };

    try {
      setLoading(true);
      await createAppraisal(data);
      toast({
        title: `Appraisal Created`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      navigate("/appraisal");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getEmployees();
        showDept.all
          ? setEmployeeList(
              response?.employees
                ?.filter((el) => {
                  return el.deleted_at === null;
                })
                .map((el) => `${el?.first_name} ${el?.last_name}`)
            )
          : setEmployeeList(
              response?.employees
                ?.filter((el) => {
                  const mappedDept = departments.map((el) => el?.toLowerCase());
                  return (
                    el.deleted_at === null &&
                    mappedDept.includes(el?.department?.toLowerCase())
                  );
                })
                .map((el) => `${el?.first_name} ${el?.last_name}`)
            );
        showDept.all
          ? setEmpDetail(
              response?.employees
                ?.filter((el) => {
                  return el.deleted_at === null;
                })
                .map((el) => {
                  return {
                    name: `${el?.first_name} ${el?.last_name}`,
                    id: el?.id,
                  };
                })
            )
          : setEmpDetail(
              response?.employees
                ?.filter((el) => {
                  const mappedDept = departments.map((el) => el?.toLowerCase());
                  return (
                    el.deleted_at === null &&
                    mappedDept.includes(el?.department?.toLowerCase())
                  );
                })
                .map((el) => {
                  return {
                    name: `${el?.first_name} ${el?.last_name}`,
                    id: el?.id,
                  };
                })
            );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [departments, showDept.all]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await listDepartments();
        setDepartmentList(response?.departments?.map((el) => el?.department));
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <DashboardReusable>
      <Flex w="100%" align="start" direction="column" gap="1.5rem" py="1rem">
        <Heading
          py="1rem"
          size="md"
          color="var(--container_color)"
          fontWeight="bold"
        >
          Create Appraisal
        </Heading>
        <Divider />
        <Flex
          bg="var(--container)"
          w="100%"
          align="center"
          gap="1rem"
          justify="space-between"
        >
          <InputComponent
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            placeholder="Enter title"
            label="Appraisal Title"
          />
          {false && (
            <InputComponent
              onChange={(e) => setDueDate(e.target.value)}
              value={due_date}
              type="date"
              label="Due Date"
            />
          )}
        </Flex>
        <Flex w="100%" align="center" gap="1rem">
          <CheckboxComponent
            checked={showDept.all}
            onChange={(e) => {
              setShowDept({ ...showDept, all: e.target.checked });
              if (e.target.checked) {
                setShowDept({ department: false, all: e.target.checked });
              }
            }}
            title="All Employees"
          />
          <CheckboxComponent
            checked={showDept.department}
            onChange={(e) => {
              setShowDept({ ...showDept, department: e.target.checked });
              if (e.target.checked) {
                setShowDept({ all: false, department: e.target.checked });
              }
            }}
            title="Select Departments"
          />
        </Flex>
        {showDept.department && (
          <SelectComponent
            multiple={true}
            label="Select Departments"
            options={departmentList || []}
            onChange={(e) => {
              setDepartments(e?.map((el) => el.value));
            }}
          />
        )}
        {departments?.length > 0 && !showDept.all && (
          <Flex w="100%" align="center" gap="1rem">
            <CheckboxComponent
              checked={showEmp.all}
              onChange={(e) => {
                setShowEmp({ ...showEmp, all: e.target.checked });
                if (e.target.checked) {
                  setShowEmp({ employee: false, all: e.target.checked });
                }
              }}
              title="All Employees"
            />
            <CheckboxComponent
              checked={showEmp.employee}
              onChange={(e) => {
                setShowEmp({ ...showEmp, employee: e.target.checked });
                if (e.target.checked) {
                  setShowEmp({ all: false, employee: e.target.checked });
                }
              }}
              title="Select Employees"
            />
          </Flex>
        )}
        {showEmp.employee && (
          <SelectComponent
            multiple={true}
            onChange={(e) => {
              setEmployees(e?.map((el) => el.value));
            }}
            label="Select Employee"
            options={employeeList || []}
          />
        )}
        <AppraisalFields keys={key} setKey={setKey} />
        <Flex w="100%" align="start">
          <Button
            isLoading={loading}
            loadingText=""
            onClick={createHandler}
            variant="solid"
            bg="brand.900"
            color="white"
          >
            Submit
          </Button>
        </Flex>
      </Flex>
    </DashboardReusable>
  );
}
