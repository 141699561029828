import { Button, Flex } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function RoleFooter({ onClick, loading }) {
  const navigate = useNavigate();

  return (
    <Flex w="100%" py="1.5rem" align="center" justify="space-between">
      <Button
        onClick={() => navigate("/settings")}
        variant="outline"
        colorScheme="red"
      >
        Cancel
      </Button>
      <Button
        isLoading={loading}
        loadingText="Processing"
        onClick={onClick}
        variant="solid"
        bg="brand.900"
        color="white"
      >
        Save
      </Button>
    </Flex>
  );
}
