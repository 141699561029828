import { Flex } from "@chakra-ui/react";
import React from "react";
import ReusableTitleText from "../UI/ReusableTitleText";

export default function ReusableListTitle({
  title1,
  title2,
  title3,
  title4,
  title5,
  title6,
  w1,
  w2,
  w3,
  w4,
  w5,
  w6,
  align1,
  align4,
  align5,
  align6,
  show = true,
}) {
  return (
    <Flex
      w="100%"
      align="center"
      borderTopLeftRadius="0.75rem"
      borderTopRightRadius="0.75rem"
      bg="#EEEFF3"
      py="1rem"
      justify="center"
    >
      {show && (
        <ReusableTitleText
          weight="medium"
          size={{ lg: "12px", md: "10px", base: "6px" }}
          color="#7F7E7E"
          align=""
          text=""
          w="5%"
        />
      )}
      <ReusableTitleText
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        color="#7F7E7E"
        align={align1 ? align1 : "start"}
        text={title1 ? title1 : "Employee ID"}
        w={w1 ? w1 : "10%"}
      />
      <ReusableTitleText
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        color="#7F7E7E"
        align="start"
        text={title2 ? title2 : "Full Name"}
        w={w2 ? w2 : "15%"}
      />
      <ReusableTitleText
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        color="#7F7E7E"
        align="start"
        text={title3 ? title3 : "Email Address"}
        w={w3 ? w3 : "25%"}
      />
      <ReusableTitleText
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        color="#7F7E7E"
        align={align4 ? align4 : "start"}
        text={title4 ? title4 : "Phone Number"}
        w={w4 ? w4 : "20%"}
      />
      <ReusableTitleText
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        color="#7F7E7E"
        align={align5 ? align5 : "start"}
        text={title5 ? title5 : "Roles"}
        w={w5 ? w5 : "15%"}
      />
      <ReusableTitleText
        weight="medium"
        size={{ lg: "12px", md: "10px", base: "6px" }}
        color="#7F7E7E"
        align={align6 ? align6 : "start"}
        text={title6 ? title6 : "Actions"}
        w={w6 ? w6 : "10%"}
      />
    </Flex>
  );
}
