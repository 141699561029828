import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";

import CreateClientDetail from "./CreateClientDetail";


export default function ClientPersonalDetail({
  setFirstName,
  setLastName,
  setPhone,
  setEmail,
  setCountry,
  setImage,
  setBusinessName,
  setBusinessType,
}) {
  return (
    <Flex
      align="center"
      height={{ lg: "400px", md: "100%", base: "100%" }}
      borderBottom="1px solid #ccc"
      justify="space-between"
      w="100%"
      direction={{ lg: "row", md: "column", base: "column" }}
      gap={{ lg: "0px", base: "16px", md: "16px" }}
    >
      <Box w={{ lg: "30%", base: "100%", md: "100%" }}>
        <ReusableText
          text="Personal Details"
          size="20px"
          weight="600"
          color="var(--container_color)"
        />
      </Box>
      <CreateClientDetail
        setCountry={setCountry}
        setPhone={setPhone}
        setEmail={setEmail}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setImage={setImage}
        setBusinessName={setBusinessName}
        setBusinessType={setBusinessType}
      />
    </Flex>
  );
}
