import React from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import RoleContainer from "./RoleContainer";

export default function CreateRole() {
  return (
    <DashboardReusable>
      <RoleContainer />
    </DashboardReusable>
  );
}
