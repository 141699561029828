import {
  Button,
  CloseButton,
  Divider,
  Flex,
  Heading,
  useToast,
} from "@chakra-ui/react";
import Modal from "../../overlay/Modal";
import SelectComponent from "../../UI/Select";
import { useState } from "react";
import { updateTimeline } from "../../../util/http";

export default function UpdateTimeline({ show, id }) {
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  function closeModal() {
    show(false);
  }

  async function updateHandler() {
    const data = {
      status,
    };

    if (!status) {
      toast({
        title: `Select a status`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await updateTimeline(data, id);
      window.location.reload();
      toast({
        title: `Timeline updated`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <Modal hide={closeModal}>
      <Flex w="100%" align="start" gap="1rem" direction="column" py="1rem">
        <Flex w="100%" align="center" justify="space-between">
          <Heading size="md" color="var(--container_color)" fontWeight="bold">
            Update Timeline
          </Heading>
          <CloseButton onClick={closeModal} />
        </Flex>
        <Divider />
        <SelectComponent
          label="Select Status"
          onChange={(e) => setStatus(e.value)}
          options={["Pending", "In Review", "Completed"]}
        />
        <Button
          isLoading={loading}
          loadingText=""
          onClick={updateHandler}
          bg="brand.900"
          color="white"
        >
          Save Update
        </Button>
      </Flex>
    </Modal>
  );
}
