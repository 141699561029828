import { Flex, useDisclosure } from "@chakra-ui/react";
import React from "react";
import ReusableEmployeeHeader from "../UI/ReusableEmployeeHeader";
import { AddIcon } from "@chakra-ui/icons";
import CalenderCenter from "./CalenderCenter";
import CreateEvent from "./CreateEvent";
import { useState } from "react";
import { getStoredItem } from "../../util/lib";

export default function CalenderContainer({ list }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [start, setStart] = useState("");
  const [show, setShow] = useState(false);
  const [type, setType] = useState("default");

  const userType = getStoredItem("user-type");

  return (
    <Flex
      w="100%"
      align="start"
      direction="column"
      h="100%"
      gap="1rem"
      bg="var(--container)"
      p={{ lg: "2rem", md: "1rem", base: "6px" }}
    >
      <ReusableEmployeeHeader
        title="Calendar"
        text="Stay organized and never miss a beat. Check out our calendar for upcoming events and important dates."
        show={userType?.toLowerCase() === "employee"}
        btnText="Create Event"
        icon={<AddIcon />}
        onClick={() => {
          setType("default");
          setShow(true);
        }}
      />
      <CalenderCenter
        setType={setType}
        show={setShow}
        setStart={setStart}
        list={list}
      />
      {show && (
        <CreateEvent
          start={start}
          setStart={setStart}
          isOpen={isOpen}
          onClose={onClose}
          show={setShow}
          type={type}
        />
      )}
    </Flex>
  );
}
