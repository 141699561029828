import {
  Avatar,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import InputComponent from "../../UI/Input";
import SelectComponent from "../../UI/Select";
import ReusableText from "../../UI/ReusableText";
import { getCountries, getState } from "../../../util/http";

export default function Address({
  title,
  setAddress1,
  setAddress2,
  setCity,
  setCountry,
  setState,
  setCode,
  country,
}) {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getCountries();
        const countryList = response?.map((el) => el.country);
        setCountries(countryList);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchState() {
      if (!country) {
        return;
      }
      try {
        const response = await getState({ country });
        setStates(response?.states?.map((el) => el.name));
      } catch (error) {
        console.log(error);
      }
    }
    fetchState();
  }, [country]);
  return (
    <Flex
      align="center"
      height={{ lg: "400px", md: "100%", base: "100%" }}
      borderBottom="1px solid #ccc"
      justify="space-between"
      w="100%"
      direction={{ lg: "row", md: "column", base: "column" }}
      gap={{ lg: "0px", base: "16px", md: "16px" }}
    >
      <Box pt="2rem" w={{ lg: "30%", base: "100%", md: "100%" }}>
        <ReusableText
          text={title ? title : "Address"}
          size="20px"
          weight="600"
          color="var(--container_color)"
        />
      </Box>

      <Flex align="center" direction="column" gap="2rem" w="100%">
        <Flex align="center" w="100%" justify="center" gap="1rem">
          <InputComponent
            onChange={(e) => setAddress1(e.target.value)}
            placeholder="Enter address"
            label="Address Line 1"
            type="text"
          />
          <InputComponent
            onChange={(e) => setAddress2(e.target.value)}
            label="Address Line 2"
            placeholder="Enter address"
            type="text"
          />
        </Flex>
        <Flex align="center" w="100%" justify="center" gap="1rem">
          <InputComponent
            onChange={(e) => setCity(e.target.value)}
            placeholder="Enter City"
            label="City"
            type="text"
          />
          <SelectComponent
            onChange={(e) => setCountry(e.value)}
            options={["Select Country", ...countries]}
            label="Country"
          />
        </Flex>

        <Flex align="center" w="100%" justify="center" gap="1rem">
          <SelectComponent
            onChange={(e) => setState(e.value)}
            options={["Select State", ...states]}
            label="State"
          />
          <InputComponent
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter Postal Code"
            label="Postal Code"
            type="text"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
