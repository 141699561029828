import { Flex } from "@chakra-ui/react";
import React from "react";

//import StaffReportCard from "./StaffReportCard";
//import LoginDurationReportCard from "./LoginDurationReportCard";

// import FinanceReportCard from "./FinanceReportCard";

// import InvoiceReportCard from "./InvoiceReportCard";
// import RequisitionReportCard from "./RequisitionReportCard";
import BriefReportCard from "./BriefReportCard";
import TotalAssignedReportCard from "./AssignedTaskReportCard";



export default function HodReportList({ onOpen }) {
  return (
    <Flex
      align="center"
      justify="space-between"
      w="100%"
      gap="1rem"
      wrap="wrap"
    >
      <BriefReportCard />

      <TotalAssignedReportCard />


    </Flex>
  );
}
