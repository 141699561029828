import React, { useState } from "react";
import ReusableModal from "../../UI/ReusableModal";
import InputComponent from "../../UI/Input";
import {
  Button,
  CloseButton,
  Flex,
  Grid,
  Heading,
  useDisclosure,
  useToast,
  Text ,
} from "@chakra-ui/react";
import SelectComponent from "../../UI/Select";
import TextareaComponent from "../../UI/Textarea";
import { createReceipt, getClient } from "../../../util/http";
import ReusableAmountInput from "../../UI/ReusableAmountInput";
import { useEffect } from "react";
import ReusableRetainership from "../../UI/ReusableRetainership";
import Modal from "../../overlay/Modal";
import ConfirmReceipt from "./ConfirmReceipt";

export default function CreateReceipt({ invoice, show }) {
  const toast = useToast();
  const [sender, setSender] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [channel, setChannel] = useState("");
  const [detail, setDetail] = useState("");
  const [retainership, setRetainership] = useState("");
  const [client, setClient] = useState("");
  const [loading, setLoading] = useState(false);
  const todayDate = new Date();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const year = todayDate.getFullYear();
  const month = todayDate.getMonth() + 1;
  const day = todayDate.getDate();
  const [currencySymbol, setCurrencySymbol] =  useState("₦");



  // const [currency, setCurrency] = useState("Nigerian Naira (NGN)");


  function closeModal() {
    show(false);
  }

  useEffect(() => {
    async function fetchData() {
      const client_id = invoice?.client?.client_id;
      try {
        const response = await getClient(client_id);
        setClient(response?.client[0]);
        setRetainership(response?.client[0]?.retainership);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [channel, invoice]);

  useEffect(() => {
    if (
      new Date(retainership?.valid_till) < new Date(Date.now()) &&
      channel?.toLowerCase() === "Retainership"
    ) {
      toast({
        title: `Client retainership has expired, select another payment channel`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setAmount("");
    }
  }, [retainership, channel, client]);

  useEffect(() => {
    if (
      channel?.toLowerCase() === "retainership" &&
      +amount > +client?.retainership?.available_balance
    ) {
      toast({
        title: `Insufficient retainership balance`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setAmount(+client?.retainership?.available_balance);
    }
  }, [amount, channel, client]);

  async function createReceiptHandler() {
    if (!sender) {
      toast({
        title: `Select a client`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (!amount) {
      toast({
        title: `Enter an amount`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (!channel) {
      toast({
        title: `Select a payment channel`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (!date) {
      toast({
        title: `Select a date`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const data = {
      client: sender,
      amount,
      date,
      channel: channel,
      description: detail,
      invoice_id: invoice.invoice_id,
    };

    try {
      setLoading(true);
      await createReceipt(data);
      window.location.reload();
      setLoading(false);
      closeModal();
      onClose();
      toast({
        title: `You have successfully created receipt for “Invoice No. ${invoice?.invoice_id}`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  return (
    <>
      <ConfirmReceipt
        onClick={createReceiptHandler}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Modal hide={closeModal}>
        <Flex w="100%" py="1rem" direction="column" gap="20px">
          <Flex w="100%" align="center" justify="space-between">
            <Heading size="md" color="var(--container_color)" fontWeight="bold">
              Receipt
            </Heading>
            <CloseButton onClick={closeModal} />
          </Flex>
          {channel?.toLowerCase() === "retainership" && retainership && (
            <Flex w="100%" align="end" justify="end">
              <ReusableRetainership w="100%" retainership={retainership} />
            </Flex>
          )}
          <InputComponent
            onChange={(e) => setSender(e.target.value)}
            label="Sender Name"
            placeholder="Enter Sender Name"
          />
          <Grid>
          <ReusableAmountInput
            label={`Amount`}
            // placeholder="₦ Type amount here"
            placeholder={`${invoice?.currency_symbol} Type amount here`}
            type="number"
            onChange={(e) => {
              if (+e.target.rawValue > +invoice?.pending_balance) {
                setAmount("");
                toast({
                  title: `Receipt amount cannot be greater than invoice amount`,
                  description: ``,
                  status: "warning",
                  duration: 3000,
                  isClosable: true,
                  position: "top-right",
                });
                return;
              } else {
                setAmount(e.target.rawValue);
              }
            }}
            value={amount}
          />
          <Text fontSize={'.775rem'} color={'red.500'}>
          {`The invoice currency is ${invoice?.currency || 'not specified'}.`}
          </Text>
          </Grid>

          <InputComponent
            label="Date"
            onChange={(e) => setDate(e.target.value)}
            placeholder={`${currencySymbol} Type amount here`}
            type="date"
            max={`${year}-${month.toString().padStart(2, 0)}-${day
              .toString()
              .padStart(2, 0)}`}
            min=""
          />
          <SelectComponent
            label="Channel Of Payment"
            onChange={(e) => setChannel(e.value)}
            options={[
              "Select the channel of payment that was used",
              "Cheque",
              "Cash",
              "Bank Transfer",
              `${client?.retainership ? "Retainership" : ""}`,
            ]}
          />
          <TextareaComponent
            label="Details"
            onChange={(e) => setDetail(e.target.value)}
            placeholder="Enter a description..."
          />
          <Flex w="100%" align="end" justify="end">
            <Button
              isLoading={loading}
              loadingText="Processing"
              onClick={onOpen}
              bg="brand.900"
              color="white"
            >
              Reconcile Payment
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
