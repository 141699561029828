import React, { Fragment } from "react";
import ClientItem from "./ClientItem";
import LoadingSkeleton from "../UI/LoadingSkeleton";

export default function ClientList({ onOpen, list, loading }) {
  const data = [1, 1, 1, 1, 1, 1];
  return (
    <Fragment>
      {loading ? (
        <>
          {data.map((el, i) => (
            <LoadingSkeleton key={i} lines={1} />
          ))}
        </>
      ) : (
        <>
          {list?.map((item, i) => (
            <ClientItem path="client" item={item} key={i} onOpen={onOpen} />
          ))}
        </>
      )}
    </Fragment>
  );
}
