import { Badge, Flex } from "@chakra-ui/react";
import ReusableText from "./ReusableText";

export default function ReusableRetainership({ retainership, w }) {
  return (
    <Flex
      align="end"
      justify="end"
      w={w ? w : "50%"}
      direction="column"
      gap="8px"
    >
      {retainership && (
        <Flex w="50%" align="end" direction="column" gap="6px">
          <Flex align="center" gap="1rem">
            <Badge p="6px" color="green.600" bg="green.200">
              Retainership
            </Badge>
            {new Date(retainership?.valid_till) < new Date(Date.now()) && (
              <Badge color="red.600" bg="red.200">
                expired
              </Badge>
            )}
          </Flex>
          <ReusableText
            text={`Due Date: ${retainership?.valid_till}`}
            color={`${
              new Date(retainership?.valid_till) > new Date(Date.now())
                ? "#101828"
                : "red.500"
            }`}
            size="12px"
            weight="medium"
          />

          <ReusableText
            text={`Amount Paid: ₦${new Intl.NumberFormat().format(
              retainership?.amount
            )}.00`}
            color="var(--container_color)"
            size="12px"
            weight="medium"
          />
          <ReusableText
            text={`Available Balance:  ₦${new Intl.NumberFormat().format(
              retainership?.available_balance
            )}.00`}
            color="var(--container_color)"
            size="12px"
            weight="medium"
          />
        </Flex>
      )}
    </Flex>
  );
}
