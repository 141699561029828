import { Checkbox } from "@chakra-ui/react";

export default function CheckboxComponent({
  title,
  w,
  onChange,
  defaultChecked,
  checked,
}) {
  return (
    <Checkbox
      isChecked={checked}
      defaultChecked={defaultChecked}
      onChange={onChange}
      w={w}
      color="var(--container_color)"
    >
      {title}
    </Checkbox>
  );
}
