import { Button, CloseButton, Flex, Heading, useToast } from "@chakra-ui/react";
import Modal from "../../overlay/Modal";
import InputComponent from "../../UI/Input";
import { MdTimeline } from "react-icons/md";
import { useState } from "react";
import { createTimeline } from "../../../util/http";

export default function AddTimeline({ show, id }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStart] = useState("");
  const [endDate, setEnd] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function timelineHandler() {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const startDay = start.getDate();
    const startMonth = start.getMonth() + 1;
    const startYear = start.getFullYear();
    const startHour = start.getHours();
    const startMin = start.getMinutes();
    const startSec = start.getSeconds();

    const endDay = end.getDate();
    const endMonth = end.getMonth() + 1;
    const endYear = end.getFullYear();
    const endHour = end.getHours();
    const endMin = end.getMinutes();
    const endSec = end.getSeconds();

    const data = {
      title: name,
      description,
      brief_id: id,
      status: "pending",
      start_date: `${startYear}-${startMonth
        .toString()
        .padStart(2, 0)}-${startDay.toString().padStart(2, 0)} ${startHour
        .toString()
        .padStart(2, 0)}:${startMin.toString().padStart(2, 0)}:${startSec
        .toString()
        .padStart(2, 0)}`,
      end_date: `${endYear}-${endMonth.toString().padStart(2, 0)}-${endDay
        .toString()
        .padStart(2, 0)} ${endHour.toString().padStart(2, 0)}:${endMin
        .toString()
        .padStart(2, 0)}:${endSec.toString().padStart(2, 0)}`,
    };

    if (!name || !startDate || !endDate) {
      toast({
        title: `Timeline details missing`,
        description: ``,
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (end < start) {
      toast({
        title: "Enter a valid due date",
        description: "",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await createTimeline(data);
      window.location.reload();
      toast({
        title: `Timeline created`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      closeModal();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  function closeModal() {
    show(false);
  }
  return (
    <Modal hide={closeModal}>
      <Flex w="100%" align="start" direction="column" gap="1rem" py="1rem">
        <Flex w="100%" align="center" justify="space-between">
          <Heading size="md" color="var(--container_color)" fontWeight="bold">
            Add Timeline
          </Heading>
          <CloseButton onClick={closeModal} />
        </Flex>
        <InputComponent
          onChange={(e) => setName(e.target.value)}
          value={name}
          required={false}
          placeholder="Enter timeline name"
        />
        <InputComponent
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          required={false}
          placeholder="Add description(OPTIONAL)"
        />
        <Flex w="100%" align="center" gap="1rem">
          <InputComponent
            onChange={(e) => setStart(e.target.value)}
            value={startDate}
            required={false}
            type="datetime-local"
            label="Start date"
            placeholder="Add Date"
          />
          <InputComponent
            onChange={(e) => setEnd(e.target.value)}
            value={endDate}
            required={false}
            type="datetime-local"
            label="End date"
            placeholder="Add Date"
          />
        </Flex>
        <Button
          isLoading={loading}
          loadingText=""
          leftIcon={<MdTimeline />}
          onClick={timelineHandler}
          bg="brand.900"
          color="#fff"
        >
          Add Timeline
        </Button>
      </Flex>
    </Modal>
  );
}
