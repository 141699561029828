import { Button, Flex, Text, useDisclosure, useToast } from "@chakra-ui/react";
import React, { Fragment, useContext, useState } from "react";
import ReusableText from "../../UI/ReusableText";
import RequisitionDetail from "../RequisitionDetail";
import { sendBuzz, updateRequisition } from "../../../util/http";

import { AiOutlineBell } from "react-icons/ai";
import { AuthContext } from "../../../util/context";
import AccountantComment from "../AccountantComment";
import UnApproved from "../UnApproved";
import formatTimestamp from "../../../util/formatTimestamp";

export default function ViewDetail({ requisition }) {




  const { isOpen, onOpen, onClose } = useDisclosure();
  // accountant disclosure
  const { isOpen: isOpenAccountant, onOpen: onOpenAccountant, onClose: onCloseAccountant } = useDisclosure();

  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const { user } = useContext(AuthContext);

  const isAllowed =
    // user?.job_role?.toLowerCase() === "hr" ||
    // user?.job_role?.toLowerCase() === "accountant" ||
    // user?.job_role?.toLowerCase() === "partner" ||
    user?.job_role?.toLowerCase().includes("hod") ||
    user?.job_role?.toLowerCase() === "hop" ||
    user?.job_role?.toLowerCase() === "seed";


  // Stanley's accountant comment
  const isAccountant = user?.job_role?.toLowerCase() === "accountant";

  async function updateStatus() {
    const data = {
      status: "4",
    };

    try {
      setLoading(true);
      await updateRequisition(requisition?.requisition_id, data);
      window.location.reload();
      onClose();
      toast({
        title: `Update Successful`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      onClose();
      setLoading(false);
      toast({
        title: `Error`,
        description: `${error?.response?.data.message || "something went wrong"
          }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  async function sendBuzzHandler() {
    try {
      setLoading(true);
      await sendBuzz(requisition?.requisition_id);
      toast({
        title: `Reminder sent`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // toast({
      //   title: `Error`,
      //   description: `${
      //     error?.response?.data.message || "something went wrong"
      //   }`,
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      //   position: "top-right",
      // });
    }
  }

  console.log(requisition)

  return (
    <Fragment>
      <Flex my="2rem" w="100%" direction="column" gap="2rem">

        {/* !//!START */}
        <Flex flexDir={{
          base: 'column', lg: 'row'

        }} justify={{
          lg: 'space-between'
        }}
          gap={'1rem'}

        >
          <Flex flexDir={'column'} gap={{ base: '1rem', lg: '2rem' }} w={'100%'}>
            <Flex align="start" direction="column" gap="4px">
              <ReusableText
                text="Brief Title"
                size={{ lg: "14px", md: "12px", base: "10px" }}
                color="var(--gray)"
                weight="medium"
              />
              <ReusableText
                text={requisition?.brief_title}
                size={{ lg: "16px", md: "14px", base: "12px" }}
                color="var(--light_gray)"
                weight="medium"
              />
            </Flex>


            <Flex align="start" direction="column" gap="4px">
              <ReusableText
                text="Amount Requested"
                size={{ lg: "14px", md: "12px", base: "10px" }}
                color="var(--gray)"
                weight="medium"
              />
              <ReusableText
                text={`${requisition?.currency_symbol ? requisition?.currency_symbol: '₦'}${new Intl.NumberFormat().format(requisition?.amount)}.00`}
                size={{ lg: "16px", md: "14px", base: "12px" }}
                color="var(--light_gray)"
                weight="medium"
              />
            </Flex>
          </Flex>

          {/* //!MY ELEMENT */}
          <Flex flexDir={'column'} gap={{ base: '1rem', lg: '2rem' }} w={'100%'}>
            <Flex align="start" direction="column" gap="4px">
              <ReusableText
                text="Requisition 1D"
                size={{ lg: "14px", md: "12px", base: "10px" }}
                color="var(--gray)"
                weight="medium"
              />
              <ReusableText
                text={requisition?.requisition_id}
                size={{ lg: "16px", md: "14px", base: "12px" }}
                color="var(--light_gray)"
                weight="medium"
              />
            </Flex>

            <Flex align="start" direction="column" gap="4px">
              <ReusableText
                text="Raised By"
                size={{ lg: "14px", md: "12px", base: "10px" }}
                color="var(--gray)"
                weight="medium"
              />
              <ReusableText
                text={
                  `${requisition?.initiator?.first_name} ${requisition?.initiator?.last_name}`
                }
                size={{ lg: "16px", md: "14px", base: "12px" }}
                color="var(--light_gray)"
                weight="medium"
              />
            </Flex>
            <Flex align="start" direction="column" gap="4px">
              <ReusableText
                text="Date"
                size={{ lg: "14px", md: "12px", base: "10px" }}
                color="var(--gray)"
                weight="medium"
              />
              <ReusableText
                text={
                  new Date(requisition?.created_at)?.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                }
                size={{ lg: "16px", md: "14px", base: "12px" }}
                color="var(--light_gray)"
                weight="medium"
              />
            </Flex>
          </Flex>





        </Flex>


        {/* //!END */}












        <Flex w="100%" align="start" direction="column" gap="4px">
          <ReusableText
            text="Reason for Requisition"
            size={{ lg: "14px", md: "12px", base: "10px" }}
            color="var(--gray)"
            weight="medium"
          />
          <ReusableText
            text={requisition?.reason}
            size={{ lg: "16px", md: "14px", base: "12px" }}
            color="var(--light_gray)"
            weight="medium"
          />
        </Flex>
        <Flex w="100%" align="center" gap="1rem">
          <Flex w="100%" align="start" direction="column" gap="4px">
            <ReusableText
              text="HOP Decision"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              color="var(--gray)"
              weight="medium"
            />
            <ReusableText
              text={`${requisition?.hoc_decision ? requisition?.hoc_decision : ''}`}
              size={{ lg: "16px", md: "14px", base: "12px" }}
              color="var(--light_gray)"
              weight="medium"
            />
             <ReusableText
              text={`${requisition?.hoc_decision_date ? new Date(requisition?.hoc_decision_date)?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              }) : ''}`}
              size={{  md: "14px", base: "12px" }}
              color="var(--light_gray)"

            />
          </Flex>

          <Flex w="100%" align="start" direction="column" gap="4px">
            <ReusableText
              text="HOP Comment"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              color="var(--gray)"
              weight="medium"
            />
            <ReusableText
              text={requisition?.hoc_comment}
              size={{ lg: "16px", md: "14px", base: "12px" }}
              color="var(--light_gray)"
              weight="medium"
            />
          </Flex>
        </Flex>

        <Flex w="100%" align="center" gap="1rem">
          <Flex w="100%" align="start" direction="column" gap="4px">
            <ReusableText
              text="HOD Decision"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              color="var(--gray)"
              weight="medium"
            />
            <ReusableText
              text={`${requisition?.hod_decision ? requisition?.hod_decision : ''}`}
              size={{ lg: "16px", md: "14px", base: "12px" }}
              color="var(--light_gray)"
              weight="medium"
            />
              <ReusableText
              text={`${requisition?.hod_decision_date ? new Date(requisition?.hod_decision_date)?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              }) : ''}`}
              size={{ md: "14px", base: "12px" }}
              color="var(--light_gray)"
              weight={'500'}

            />
          </Flex>

          <Flex w="100%" align="start" direction="column" gap="4px">
            <ReusableText
              text="HOD Comment"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              color="var(--gray)"
              weight="medium"
            />
            <ReusableText
              text={requisition?.hod_comment}
              size={{ lg: "16px", md: "14px", base: "12px" }}
              color="var(--light_gray)"
              weight="medium"
            />
          </Flex>
        </Flex>

        <Flex w="100%" align="center" gap="1rem">
          <Flex w="100%" align="start" direction="column" gap="4px">
            <ReusableText
              text="Status"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              color="var(--gray)"
              weight="medium"
            />
            <ReusableText
              text={
                +requisition?.status === 0
                  ? "Pending"
                  : +requisition?.status === 1
                    ? "Approved by HOC"
                    : +requisition?.status === 2
                      ? "Approved by HOD"
                      : +requisition?.status === 3
                        ? "Approved"
                        : "Disbursed"
              }
              size={{ lg: "16px", md: "14px", base: "12px" }}
              color="var(--light_gray)"
              weight="medium"
            />
          </Flex>
          <Flex w="100%" align="start" direction="column" gap="4px">
            <ReusableText
              text="Priority Level"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              color="var(--gray)"
              weight="medium"
            />
            <ReusableText
              text={requisition?.priority_level}
              size={{ lg: "16px", md: "14px", base: "12px" }}
              color="var(--light_gray)"
              weight="medium"
            />
          </Flex>

        </Flex>
        {/*
        // !TODO: API consumption
         */}
        {requisition?.account_status ? <Flex w="100%" align="center" gap="1rem">
          <Flex w="100%" align="start" direction="column" gap="4px">
            <ReusableText
              text=" Payment Made"
              size={{ lg: "14px", md: "12px", base: "10px" }}
              color="var(--gray)"
              weight="medium"
            />
           { requisition?.accountant ? <Flex align={'center'} gap={'.2rem'}>
              <Text color={'gray.600'} w={'10ch'} size={{ lg: "16px", md: "14px", base: "12px" }}>
                By:
              </Text>
              <ReusableText
                text={requisition?.accountant}
                size={{ lg: "16px", md: "14px", base: "12px" }}
                color="var(--light_gray)"
                weight="medium"
              />
            </Flex> : null}

          { requisition?.account_disburse_date ? <Flex align={'center'} gap={'.2rem'}>
              <Text color={'gray.600'} w={'10ch'} size={{ lg: "16px", md: "14px", base: "12px" }}>
                Date:
              </Text>
              <ReusableText
                text={formatTimestamp(
                  requisition?.account_disburse_date
                )}
                size={{ lg: "16px", md: "14px", base: "12px" }}
                color="var(--light_gray)"
                weight="medium"
              />
            </Flex> : null}

          { requisition?.account_comment ?  <Flex align={'center'} gap={'.2rem'}>
              <Text size={{ lg: "16px", md: "14px", base: "12px" }} w={'10ch'} color={'gray.600'}>
                Comment:
              </Text>
              <ReusableText
                text={requisition?.account_comment}
                size={{ lg: "16px", md: "14px", base: "12px" }}
                color="var(--light_gray)"
                weight="medium"
              />
            </Flex> : null}


          </Flex>

        </Flex> : null}

        <Flex w="100%" align="center" justify="space-between" mb={'2rem'}>
          {isAllowed && (
            <Button
              onClick={onOpen}
              bg="brand.900"
              color="white"
              variant="solid"
              size="sm"
            >
              Add Comment
            </Button>
          )}
          {user?.job_role?.toLowerCase() === "accountant" &&
            +requisition?.status === 3 && (
              <Button
                onClick={updateStatus}
                bg="brand.900"
                color="white"
                variant="solid"
                size="sm"
              >
                Disbursed
              </Button>
            )}
          {isAccountant && !requisition?.account_status ? (
            <Button
              onClick={onOpenAccountant}
              bg="brand.900"
              color="white"
              variant="solid"
              size="sm"
            >
              Confirm Payment
            </Button>
          ) : null}
          <Button
            onClick={sendBuzzHandler}
            bg="yellow.300"
            color="brand.900"
            variant="solid"
            isLoading={loading}
            loadingText="Processing"
            leftIcon={<AiOutlineBell />}
            size="sm"
          >
            Send Reminder
          </Button>
        </Flex>
      </Flex>
      <RequisitionDetail
        isOpen={isOpen}
        onClose={onClose}
        requisition={requisition}
      />
      {+requisition?.status === 3 ? <AccountantComment
        isOpen={isOpenAccountant}
        onClose={onCloseAccountant}
        requisition={requisition}

      /> : <UnApproved isOpen={isOpenAccountant}
        onClose={onCloseAccountant} />}
    </Fragment>
  );
}
