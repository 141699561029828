import { Button, Flex, useDisclosure } from "@chakra-ui/react";
import { useContext } from "react";
import RequestExtension from "./RequestExtension";
import { AuthContext } from "../../../util/context";

export default function ViewTaskSubHeader({ task }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const twoDays =
    new Date(task?.due_date) - new Date(Date.now() + 1 * 24 * 60 * 60 * 1000);
  const validTwoDays = new Date(Date.now() + twoDays);
  const btnIsActive = new Date() <= validTwoDays;
  const { user } = useContext(AuthContext);

  const canRequest =
    user?.job_role?.toLowerCase() === "lawyer" ||
    user?.job_role?.toLowerCase() === "user";

  return (
    <>
      <Flex align="center" justify="space-between" w="100%">
        {canRequest && (
          <Button
            variant="solid"
            bg="brand.900"
            color="white"
            onClick={onOpen}
            size="sm"
            isDisabled={btnIsActive}
          >
            Request extension
          </Button>
        )}
      </Flex>

      <RequestExtension isOpen={isOpen} onClose={onClose} task={task} />
    </>
  );
}
