import { Flex, Button, Divider, Heading, useToast } from "@chakra-ui/react";
import SelectComponent from "../../UI/Select";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Modal from "../../overlay/Modal";
import ReusableChart from "../../UI/ReusableChart";
import { generateReport, getEmployees, getLeaves } from "../../../util/http";
import { useEffect, useState } from "react";
import { filterDate } from "../../../util/lib";

function monthFilter(data, month) {
  return data?.filter((el) => {
    const createdMonth = new Date(el.created_at).getMonth() + 1;
    return +createdMonth === +month;
  }).length;
}

export default function LeaveReportDetail({ show }) {
  const [filter, setFilter] = useState("weekly");
  const [days, setDays] = useState(0);
  const [list, setList] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [employee, setEmployee] = useState("");

  const [data, setData] = useState({
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  });

  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function reportHandler() {
    const leave = [data];
    const data1 = {
      report: "briefs",
      data: JSON.stringify(leave),
    };
    try {
      setLoading(true);
      await generateReport(data1);
      toast({
        title: `Leave report generated`,
        description: "",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  useEffect(() => {
    if (filter?.toLowerCase() === "daily") {
      setDays(1);
    } else if (filter?.toLowerCase() === "weekly") {
      setDays(7);
    } else if (filter?.toLowerCase() === "monthly") {
      setDays(30);
    } else {
      setDays("");
    }
  }, [filter]);

  function closeModal() {
    show(false);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getEmployees();
        let employeeObj;

        employeeObj = response?.employees
          ?.filter((el) => el.deleted_at === null)
          .map((el) => {
            return {
              name: `${el.first_name} ${el.last_name}`,
              id: el.employee_id,
            };
          });

        setAllEmployees(employeeObj);

        const employeeNames = employeeObj?.map((el) => el.name);
        setList(employeeNames);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const emp_id = allEmployees?.find((el) => el.name == employee)?.id;

      try {
        const response = await getLeaves();

        const leaves = response?.leaves?.filter((el) => {
          if (!days) {
            return el.employee_id === emp_id;
          }
          const milliSec = +days * 24 * 60 * 60 * 1000;
          const startDate = filterDate(milliSec);
          const createdDate = new Date(el?.created_at);
          return startDate < createdDate && el.employee_id === emp_id;
        });

        setData({
          January: monthFilter(leaves, 1),
          February: monthFilter(leaves, 2),
          March: monthFilter(leaves, 3),
          April: monthFilter(leaves, 4),
          May: monthFilter(leaves, 5),
          June: monthFilter(leaves, 6),
          July: monthFilter(leaves, 7),
          August: monthFilter(leaves, 8),
          September: monthFilter(leaves, 9),
          October: monthFilter(leaves, 10),
          November: monthFilter(leaves, 11),
          December: monthFilter(leaves, 12),
        });
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [allEmployees, employee, days]);

  return (
    <Modal hide={closeModal}>
      <Flex align="start" direction="column" gap="2rem" w="100%">
        <Heading size="md" color="var(--container_color)" fontWeight={600}>
          Leave Report
        </Heading>
        <Divider />
        <Flex w="100%" align="center" justify="space-between">
          <Flex align="center" w="60%" gap="1rem">
            <SelectComponent
              label="Employee"
              onChange={(e) => setEmployee(e.value)}
              options={["Select staff here", ...list]}
            />
            <SelectComponent
              label="Duration"
              onChange={(e) => setFilter(e.value)}
              options={["Daily", "Weekly", "Monthly", "All Time"]}
            />
          </Flex>
          <Button
            variant="solid"
            bg="brand.900"
            color="white"
            rightIcon={<ChevronDownIcon />}
            isLoading={loading}
            loadingText="Generating"
            onClick={reportHandler}
          >
            Generate Report
          </Button>
        </Flex>

        <ReusableChart data={Object.values(data)} />
      </Flex>
    </Modal>
  );
}
