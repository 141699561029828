import {
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  InputRightAddon,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import ReusableText from "../../UI/ReusableText";

export default function BriefInvoice({ setMax, max }) {
  const toast = useToast();
  return (
    <Flex align="center" justify="space-between" w="100%">
      <ReusableText
        text="Brief Invoice"
        size="16px"
        weight="medium"
        color="var(--container_color)"
      />
      {false && (
        <Flex align="start" direction="column" gap="4px" w="30%">
          <ReusableText
            text="Maximum Spend"
            size="16px"
            weight="medium"
            color="#515050"
          />
          <InputGroup>
            <Input
              onChange={(e) => {
                if (e.target.value.length > 3 || +e.target.value > 100) {
                  toast({
                    title: "Maximum spend cannot be greater than 100",
                    description: "",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right",
                  });
                  setMax("");
                } else {
                  setMax(e.target.value);
                }
              }}
              type="number"
              placeholder="Maximum Spend (20%)"
              max={100}
              maxLength={3}
              value={max}
            />
            <InputRightAddon bg="white" children="%" />
          </InputGroup>
        </Flex>
      )}
    </Flex>
  );
}
