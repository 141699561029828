import { Flex } from "@chakra-ui/react";
import { Fragment } from "react";
import ReusableEmployeeHeader from "../../UI/ReusableEmployeeHeader";
import LeaveDetailCenter from "./LeaveDetailCenter";

export default function LeaveDetailContainer({ leave }) {
  return (
    <Fragment>
      <Flex
        w="100%"
        align="start"
        direction="column"
        h="100%"
        gap="1rem"
        p={{ lg: "2rem", md: "1rem", base: "6px" }}
      >
        <ReusableEmployeeHeader
          title="Leave"
          text="Manage your employees leave request here"
        />
        <LeaveDetailCenter leave={leave} />
      </Flex>
    </Fragment>
  );
}
