import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import ReusableForm from "./ReusableForm";
import { useState } from "react";
import { useEffect } from "react";

export default function Accountability({
  setFormData,
  item,
  index,
  formData,
  setFormData1,
  formData1,
  fields,
  setTotal,
  appraisal,
}) {
  const [filteredObjective, setFilteredObjective] = useState([]);
  const [response, setResponse] = useState([]);
  const [response1, setResponse1] = useState([]);
  const [percentage, setPercentage] = useState("");

  useEffect(() => {
    const newFormData = [...formData];
    newFormData[index] = response;
    setFormData(newFormData);
  }, [response, setFormData, formData, index]);

  useEffect(() => {
    const newFormData = [...formData1];
    newFormData[index] = response1;
    setFormData1(newFormData);
  }, [response1, setFormData1, formData1, index]);

  useEffect(() => {
    const filteredObjectives = item?.objectives?.filter(
      (el) => typeof el === "string"
    );
    setFilteredObjective(filteredObjectives);
  }, [item]);

  useEffect(() => {
    const maxAverage = filteredObjective?.length * 5;
    const empResp = formData[index];
    const supervisorResp = formData1[index];
    const mapped = empResp
      ?.map((el, i) =>
        supervisorResp[i]?.key === el.key
          ? (+el?.value + +supervisorResp[i]?.value) / 2
          : 0
      )
      .reduce((acc, sum) => acc + sum, 0);
    const total = +mapped / +maxAverage;
    const percentage = +total * +item?.percentage;
    setPercentage(percentage);
  }, [index, formData, formData1, filteredObjective, item]);

  useEffect(() => {
    let arr = [];
    for (let i = 0; i <= formData.length - 1; i++) {
      const filteredObjectives = fields[i]?.objectives?.filter(
        (el) => typeof el === "string"
      );
      const maxAverage = filteredObjectives?.length * 5;
      const mapped1 = formData1[i];
      const mapped = formData[i]
        ?.map((el, i) => {
          return mapped1[i]?.key === el.key
            ? (+el?.value + +mapped1[i]?.value) / 2
            : 0;
        })
        .reduce((acc, sum) => acc + sum, 0);
      const total = +mapped / +maxAverage;
      // console.log(total);
      const percentage = +total * +fields[i]?.percentage;
      arr.push(percentage);
    }
    setTotal(arr);
  }, [formData, formData1, fields]);

  return (
    <Flex w="100%" align="start" direction="column">
      <Flex w="100%" align="center" borderLeft="1px solid #ccc">
        <ReusableText
          text={`${item?.key} (${item?.percentage}%)`}
          transform={true}
          color="var(--gray)"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          w="10%"
          align="start"
          p="0.4rem"
        />
        <Flex w="90%" align="start" direction="column">
          {filteredObjective?.map((el, i) => (
            <ReusableForm
              appraisal={appraisal}
              key={i}
              onChange={(e) =>
                setResponse([...response, { value: e.target.value, key: el }])
              }
              onChange1={(e) =>
                setResponse1([...response1, { value: e.target.value, key: el }])
              }
              title={el}
            />
          ))}
        </Flex>
      </Flex>
      <Flex w="100%" align="center">
        <ReusableText
          text="Total"
          color="var(--gray)"
          weight="bold"
          size={{ lg: "14px", md: "10px", base: "6px" }}
          w="10%"
          align="start"
          p="0.4rem"
        />
        <Flex
          w="90%"
          height="40px"
          align="end"
          justify="end"
          border="1px solid #ccc"
          color="var(--light_gray)"
        >
          {percentage}%
        </Flex>
      </Flex>
    </Flex>
  );
}
