import { useEffect } from "react";
import DashboardReusable from "../UI/DashboardReusable";
import RequisitionContainer from "./RequisitionContainer";
import { useState } from "react";
import { getRequisitions } from "../../util/http";
import { useContext } from "react";
import { AuthContext } from "../../util/context";

export default function Requisition() {
  const [list, setList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const lastIndex = currentPage * perPage;
  const firstIndex = lastIndex - perPage;

  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await getRequisitions();


        const departmentInternalRequisitions = response?.internal_requisitions
          ?.filter(
            (el) =>
              el.brief_type?.toLowerCase() ===
              user?.department?.toLowerCase() && el?.deleted_at === null
          )
          .reverse()




        const internalRequisitions = response?.internal_requisitions
          ?.filter(
            (el) =>
              +el.initiator?.initiator_id === +user?.id &&
              el?.deleted_at === null
          )
          .reverse();


        setLoading(false);
        if (
          user.job_role?.toLowerCase() === "lawyer" ||
          user?.job_role?.toLowerCase() === "hr" ||
          user?.job_role?.toLowerCase() === 'user'
        ) {


          const requisitions = response?.requisitions
            ?.filter(
              (el) => {
                return (+el.initiator?.initiator_id === +user?.id &&
                  el?.deleted_at === null)

              }
            )?.reverse()
          const merged = [...requisitions, ...internalRequisitions,]
          //sort by created_at
          const sorted = merged?.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });
          setList(
            sorted


          );
        } else if (
          user?.job_role?.toLowerCase().includes("hod")
        ) {
          const personalRequisition = response?.requisitions
            ?.filter(
              (el) => {
                return (+el.initiator?.initiator_id === +user?.id &&
                  el?.deleted_at === null)

              }
            )
            .reverse()

          const personalInternalRequisition = response?.internal_requisitions
            ?.filter(
              (el) => {
                return (+el.initiator?.initiator_id === +user?.id &&
                  el?.deleted_at === null)

              }
            )
            .reverse()

          const departmentRequisition = response?.requisitions
            ?.filter(
              (el) =>
                el.brief_type?.toLowerCase() ===
                user?.department?.toLowerCase() && el?.deleted_at === null
            )
            .reverse()

          //sort by date
          const merged = [
            ...personalInternalRequisition, ...personalRequisition, ...departmentRequisition, ...departmentInternalRequisitions
          ]
          //remove duplicated

          const unique = [...new Set(merged)];
          
          //sort by created_at
          const sorted = unique?.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });

          setList(
            sorted

          );

        }

        else if (
          user?.job_role?.toLowerCase() === 'user'
        ) {

          setList(
            response?.internal_requisitions
              ?.filter(
                (el) => {
                  return (+el.initiator?.initiator_id === +user?.id &&
                    el?.deleted_at === null)

                }
              )
              .reverse()
          );
        }




        else {
          const external = response?.requisitions
            ?.filter((el) => el?.deleted_at === null)
            .reverse()

          const internal = response?.internal_requisitions
            ?.filter((el) => el?.deleted_at === null)
            .reverse()

          const merged = [...external, ...internal]
          //sort by created_at
          const sorted = merged.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });


          setList(
            sorted

          );
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    fetchData();
  }, [user?.department, user?.id, user?.job_role]);

  useEffect(() => {
    setTotal(list.length);
  }, [list]);

  const requisitionList = list.slice(firstIndex, lastIndex);


  return (
    <DashboardReusable>
      <RequisitionContainer
        list={requisitionList}
        total={total}
        perPage={perPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setList={setList}
        loading={loading}
      />
    </DashboardReusable>
  );
}
