import { Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import React from "react";

export default function TaskDate({ title1, title2, onChange1, onChange2, w }) {
  return (
    <Flex align="center" w={w ? w : "50%"}>
      <FormControl w="50%">
        <FormLabel height="20px">{title1}</FormLabel>
        <Input onChange={onChange1} type="time" />
      </FormControl>
      <FormControl w="50%">
        <FormLabel height="20px">{title2}</FormLabel>
        <Input onChange={onChange2} type="date" />
      </FormControl>
    </Flex>
  );
}
