import { Flex, SkeletonCircle, SkeletonText } from "@chakra-ui/react";

export default function LoadingSkeleton({ lines }) {
  return (
    <Flex
      align="center"
      gap="1rem"
      w="100%"
      padding="4"
      boxShadow="lg"
      bg="white"
    >
      <SkeletonCircle isLoaded={false} size="10" />
      <SkeletonText mt="4" noOfLines={lines} spacing="4" skeletonHeight="4" />
    </Flex>
  );
}
