import { useState } from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import AppraisalContainer from "./AppraisalContainer";
import { useEffect } from "react";
import { viewAppraisals } from "../../../util/http";
import { useParams } from "react-router-dom";

export default function AppraisalDetail() {
  const [appraisal, setAppraisal] = useState("");
  const params = useParams();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await viewAppraisals(params.id);
        console.log(response);
        setAppraisal(response?.appraisal);
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [params]);

  return (
    <DashboardReusable>
      <AppraisalContainer appraisal={appraisal} />
    </DashboardReusable>
  );
}
