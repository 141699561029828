import { Flex } from "@chakra-ui/react";
import React from "react";
import BriefReportCard from "./BriefReportCard";
import ClientReportCard from "./ClientReportCard";
import StaffReportCard from "./StaffReportCard";
import LoginDurationReportCard from "./LoginDurationReportCard";
import TotalAssignedReportCard from "./AssignedTaskReportCard";
import FinanceReportCard from "./FinanceReportCard";
import LeaveReportCard from "./LeaveReportCard";
import QueryReportCard from "./QueryReportCard";
import InvoiceReportCard from "./InvoiceReportCard";
import RequisitionReportCard from "./RequisitionReportCard";

export default function ReportList({ onOpen }) {
  return (
    <Flex
      align="center"
      justify="space-between"
      w="100%"
      gap="1rem"
      wrap="wrap"
    >
      <BriefReportCard />
      <ClientReportCard />
      <StaffReportCard />
      {false && <LoginDurationReportCard />}
      <TotalAssignedReportCard />
      <FinanceReportCard />
      <LeaveReportCard />
      <QueryReportCard />
      <InvoiceReportCard />
      <RequisitionReportCard />
    </Flex>
  );
}
