import { Flex } from "@chakra-ui/react";
import ReusableText from "../../UI/ReusableText";
import { MdDevices } from "react-icons/md";
import { useEffect, useState } from "react";

import { getDeviceHistory } from "../../../util/http";

export default function LoginActivity() {
  const [device, setDevice] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getDeviceHistory();
        setDevice(response?.slice(0, 3));
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <Flex py="1rem" borderTop="1px solid #ccc" direction="column" gap="1rem">
      <ReusableText
        text="Where you’re logged in"
        color="var(--container_color)"
        size="18px"
        weight="bold"
      />

      <Flex w="100%" align="start" direction="column">
        {device?.map((el) => (
          <Flex
            py="1rem"
            borderBottom="1px solid #ccc"
            align="center"
            gap="1rem"
          >
            <MdDevices color="var(--container_color)" />
            <Flex direction="column" gap="6px">
              <Flex align="center" gap="1rem">
                <ReusableText
                  text={`${el?.browser} on ${el?.os} ${
                    el?.device_brand ? el?.device_brand : ""
                  }`}
                  size="14px"
                  weight="bold"
                  color="var(--container_color)"
                />
              </Flex>
              <ReusableText
                text={`${el?.city} ${el?.country} ${new Date(
                  el?.updated_at
                )?.toDateString()} at ${new Date(
                  el?.updated_at
                )?.toTimeString()}`}
                color="var(--container_color)"
                fontWeight="bold"
                size="16px"
              />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
