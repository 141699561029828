import { Flex, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import RoleName from "./RoleName";
import RolePermission from "./RolePermission";
import RoleFooter from "./RoleFooter";
import { useNavigate } from "react-router-dom";
import { createRole, getAvailablePermissions } from "../../../util/http";

export default function RoleCenter() {
  const navigate = useNavigate();
  const toast = useToast();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tasks, setTasks] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getAvailablePermissions();
        setPermissions(response.permissions);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  async function createRoleHandler() {
    const data = {
      name: name,
      description: description,
      permissions: tasks,
    };

    if (!name || !description || tasks?.length === 0) {
      toast({
        title: "Fill in role details",
        description: "",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
      setLoading(true);
      await createRole(data);
      setLoading(false);
      navigate("/settings");
      toast({
        title: "Role Created Successfully ",
        description: "You have successfully created a role for Partners ",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: `Error`,
        description: `${
          error?.response?.data.message || "something went wrong"
        }`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  }
  return (
    <Flex w="100%" align="center" direction="column" bg="white">
      <RoleName setName={setName} setDescription={setDescription} />
      <RolePermission
        loading={loading}
        permissions={permissions}
        setTasks={setTasks}
      />
      <RoleFooter loading={loading} onClick={createRoleHandler} />
    </Flex>
  );
}
