import { useEffect } from "react";
import DashboardReusable from "../../UI/DashboardReusable";
import ViewContainer from "./ViewContainer";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getRequisition } from "../../../util/http";
import TextSkeleton from "../../UI/TextSkeleton";
import LoadingSkeleton from "../../UI/LoadingSkeleton";


export default function ViewRequisition() {
  const params = useParams();
  const [requisition, setRequisition] = useState("");
  const [loading, setLoading] = useState(false);

 

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await getRequisition(params.id);
        console.log(response);
        setLoading(false);
        setRequisition(response?.requisition);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
    fetchData();
  }, []);
  return (
    <DashboardReusable>
      {loading ? (
        <LoadingSkeleton lines={10} />
      ) : (
        <ViewContainer requisition={requisition} />
      )}
    </DashboardReusable>
  );
}
